import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { Button } from '@tesla/design-system-react';
import { getSelectedAccessories, getTrimCode } from 'selectors';
import { toggleAccessoryItemWithShipping } from 'actions';
import { arrayOf, func, shape, string } from 'prop-types';
import { i18n } from 'utils';
import { ACCESSORIES_OPTION_MAP, ACCESSORIES_VARIANT_MAP } from 'dictionary';

const AccessoryToggleButton = ({ accessory, selectedAccessoryKeys = [], toggleAccessory, configOptions = [], trimVariant = '' }) => {
  const { options = [] } = accessory;
  const { partNumber} = options?.find(opt => {
    const optionCode = ACCESSORIES_OPTION_MAP?.[opt?.sizeOption?.code] || '';
    const variantMap = ACCESSORIES_VARIANT_MAP?.[opt?.sizeOption?.code] || '';
    return (optionCode && configOptions?.includes(optionCode)) || (variantMap && variantMap?.includes(trimVariant));
  }) || options?.[0];
  const isSelected = selectedAccessoryKeys.includes(partNumber);
  const label = i18n(isSelected ? 'common.remove' : 'common.add');

  return (
    <Button
      variant={isSelected ? 'tertiary' : 'primary'}
      className="tds-btn--width-full"
      onClick={() =>
        toggleAccessory({
          partNumber,
        })
      }
    >
      {label}
    </Button>
  );
};

AccessoryToggleButton.propTypes = {
  accessory: shape({
    options: arrayOf(shape({partNumber: string})),
  }),
  selectedAccessoryKeys: arrayOf(string),
  toggleAccessory: func,
  configOptions: arrayOf(string),
  trimVariant: string.isRequired,
};

const mapStateToProps = state => ({
  selectedAccessoryKeys: Object.keys(getSelectedAccessories(state)),
  configOptions: state?.Configuration?.option_codes || [],
  trimVariant: _get(state, `OMS.lexicon.sku.trims.${getTrimCode(state)}.variant.code`, ''),
});

const mapDispatchToProps = dispatch => ({
  toggleAccessory: item => dispatch(toggleAccessoryItemWithShipping(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessoryToggleButton);
