import React from 'react';
import { connect } from 'react-redux';
import { bool } from 'prop-types';
import { i18n, htmlToReact } from 'utils';

const VatDisclaimer = ({ showVatDisclaimer }) => {
  if (!showVatDisclaimer) {
    return null;
  }
  return <span className="tds-text--caption">{htmlToReact(i18n('SummaryPanel.disclaimers.vatDisclaimer'))}</span>;
};

VatDisclaimer.propTypes = {
  showVatDisclaimer: bool,
};

VatDisclaimer.defaultProps = {
  showVatDisclaimer: false,
};

function mapStateToProps(state) {
  return {
    showVatDisclaimer: state?.ReviewDetails?.showVatDisclaimer || false,
  };
}

export default connect(mapStateToProps)(VatDisclaimer);
