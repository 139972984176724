import React from 'react';
import { connect } from 'react-redux';
import _has from 'lodash/has';
import _get from 'lodash/get';
import { string, shape, bool } from 'prop-types';
import { parseSelectedBy } from 'utils';
import TextLoader from '../TextLoader';

/* Copy component */
const Copy = ({ group, field, component, classes, isEnabledForEditDesign, inheritClasses, isSelected }) => {
  if (!isSelected || !group || !_has(group, field)) {
    return null;
  }
  const rn = _get(window.tesla, 'rn', null);
  const isEditDesign = !!rn;

  if (isEditDesign && !isEnabledForEditDesign) {
    return null;
  }

  return (
    <TextLoader
      data={group}
      field={field}
      key={`Copy:${group}:${field}`}
      tag={{ component }}
      inheritClasses={inheritClasses}
      className={`tds-text_color--30${classes ? ` ${classes}` : ''}`}
    />
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isSelected: ownProps?.selected_by ? parseSelectedBy(
      _get(ownProps, 'selected_by', {}),
      state?.Configuration.option_string
    ) : true,
  };
}

Copy.propTypes = {
  group: shape({
    selected: bool,
    code: string,
    name: string,
    formattedPrice: string,
  }).isRequired,
  field: string.isRequired,
  component: string,
  classes: string,
  isEnabledForEditDesign: bool,
  inheritClasses: bool,
  isSelected: bool,
};

Copy.defaultProps = {
  component: 'div',
  classes: '',
  isEnabledForEditDesign: true,
  inheritClasses: true,
  isSelected: true,
};

export default connect(mapStateToProps)(Copy);
