import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { Card } from '@tesla/design-system-react';
import classNames from 'classnames';
import AssetLoader from '../AssetLoader';
import PreviousButton from '../GalleryButtons/PreviousButton';
import NextButton from '../GalleryButtons/NextButton';

const FeatureCardsGallery = ({ content = [], group = {} }) => {
  const fullQualityAsset = (asset = {}) => ({ asset_opts: 'f_auto,q_auto:best', ...asset })

  const mergedContent = content.map((item, index) => {
    if (Array.isArray(item)) {
      return item.map(({ title_field, description_field, ...subItem }, subIndex) => ({
        ...subItem,
        title: _get(group, [title_field, index, subIndex], ''),
        description: _get(group, [description_field, index, subIndex], ''),
        assetLabels: group?.card_image_description?.[index] ?? []
      }))
    }

    const { title_field, description_field, ...rest } = item;

    return {
      ...rest,
      title: _get(group, [title_field, index], ''),
      description: _get(group, [description_field, index], ''),
      assetLabels: group?.card_image_description?.[index] ?? []
    }
  });

  return (
    <>
      <div className="feature-cards-gallery-modal">
        {mergedContent.map((card, index) => {
          if (Array.isArray(card)) {
            return (
              <CardsGallery
                cards={
                  card.map((subCard, subIndex) => (
                    <Card
                      key={[subCard.asset.url ?? subCard.title, subIndex].join('_')}
                      layout="vertical"
                      asset={<AssetLoader asset={fullQualityAsset(subCard.asset)} label={card.assetLabels[subIndex]} />}
                      density="dense"
                    >
                      {subCard.title && <div className='tds-text--h2'>{subCard.title}</div>}
                      {subCard.description && <p>{subCard.description}</p>}
                    </Card>))
                }
              />

            )
          }

          if (Array.isArray(card.assets)) {
            return (
              <CardsGallery
                cards={card.assets.map(({ asset }, subIndex) => (
                  <Card
                    key={[asset.url ?? card.title, subIndex].join('_')}
                    layout="vertical"
                    asset={<AssetLoader asset={fullQualityAsset(asset)} label={card.assetLabels[subIndex]} />}
                    density="dense"
                  />))}>
                <Card density='dense'>
                  {card.title && <div className='tds-text--h2'>{card.title}</div>}
                  {card.description && <p>{card.description}</p>}
                </Card>
              </CardsGallery>
            )
          }

          return (
            <Card
              key={[card.asset?.url ?? card.title, index].join('_')}
              asset={<AssetLoader asset={fullQualityAsset(card.asset)} useProvidedDimensions />}
              layout="vertical"
              density="dense"
              className={classNames({ 'full-width': card.fullWidth })}
            >
              {card.title && <div className='tds-text--h2'>{card.title}</div>}
              {card.description && <p>{card.description}</p>}
            </Card>
          )
        })}
      </div>
    </>
  );
};

const [PREV, NEXT] = ['prev', 'next'];

const CardsGallery = ({ cards = [], children }) => {
  const container = useRef();
  const [canNavigate, setCanNavigate] = useState([NEXT]);

  useEffect(() => {
    const handleScroll = () => {
      const element = container.current;
      if (!element) return;

      const isStart = element.scrollLeft === 0;
      const isEnd = element.scrollLeft + 10 >= element.scrollWidth - element.clientWidth; // give some slack

      if (isStart) {
        setCanNavigate([NEXT]);
      } else if (isEnd) {
        setCanNavigate([PREV]);
      } else {
        setCanNavigate([PREV, NEXT]);
      }
    };

    const element = container.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const scrollTo = (direction) => () => {
    if (container.current) {
      const children = Array.from(container.current.children);
  
      if (children.length > 0) {
        const containerRect = container.current.getBoundingClientRect();
  
        const visibleIndex = children.findIndex((child) => {
          const childRect = child.getBoundingClientRect();
          return childRect.left >= containerRect.left && childRect.right <= containerRect.right;
        });
  
        const targetIndex =
          direction === PREV
            ? Math.max(0, visibleIndex - 1)
            : Math.min(children.length - 1, visibleIndex + 1);
  
        children[targetIndex]?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  };

  const showButtons = cards.length > 1;

  return (
    <div>
      <div className='feature-cards-gallery-buttons' >
        <If condition={showButtons}>
          <PreviousButton classes={classNames('tds-icon-btn tds-icon-btn--small prev', { hidden: !canNavigate.includes(PREV) })} onClick={scrollTo(PREV)} size='small' />
        </If>
        <div className='feature-cards-gallery' ref={container}>
          {cards}
        </div>
        <If condition={showButtons}>
          <NextButton classes={classNames('tds-icon-btn tds-icon-btn--small next', { hidden: !canNavigate.includes(NEXT) })} onClick={scrollTo(NEXT)} />
        </If>
      </div>
      {children}
    </div>
  )
}

FeatureCardsGallery.defaultProps = {
  content: [],
};

FeatureCardsGallery.propTypes = {
  content: PropTypes.array,
};

export default FeatureCardsGallery;
