/* eslint-disable no-param-reassign */
import { formatCurrency } from '@tesla/coin-common-components';
import _get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { i18n, getCustomPricingIndicatorV2, htmlToReact } from 'utils';
import TextLoader from '../../TextLoader';
import { array, bool, number, shape, string } from 'prop-types';
import { isMetShowExtendedServiceAgreementCondition } from '../../../common/selectors';

const VehicleOptions = ({
  options,
  baseConfigOptions,
  configuration,
  CustomGroups,
  priceData,
  includeFeesinBatteryCalculationPayment,
  useOptionPriceOverridePayment,
  trimOptions,
  isMetShowExtendedServiceAgreementCondition,
}) => {
  const getOptionIndicatorOverwrite = option => {
    const paymentPageOverride = true;

    return getCustomPricingIndicatorV2(
      [option],
      configuration,
      priceData,
      includeFeesinBatteryCalculationPayment,
      useOptionPriceOverridePayment && trimOptions.includes(option.code),
      CustomGroups,
      paymentPageOverride
    );
  };

  return (
    <div className="">
      <ol className="tds-list cf-summary-list">
        {/* Vehicle Trim Title and Base Price */}
        {baseConfigOptions?.map((item, key) => (
          <li className="tds-list-item tds-flex" key={`${item.name + key}`}>
            <span className="label--option tds-flex-item">
              {`${item.long_name} ${(() =>
                (item.spec_description && ` - ${item.spec_description}`) || '')()}`
                ? htmlToReact(
                    `${item.long_name} ${(() =>
                      (item.spec_description && ` - ${item.spec_description}`) || '')()}`
                  )
                : ''}
            </span>
            <span className="value price-indicator">
              {(item => {
                const indicatorOption = getOptionIndicatorOverwrite(item);
                const result =
                  typeof indicatorOption === 'number'
                    ? formatCurrency(indicatorOption)
                    : indicatorOption;
                return indicatorOption !== false ? result : item.formattedPrice;
              })(item)}
            </span>
          </li>
        ))}

        {/* Included and Selected Options */}
        {options?.map(option => {
          return (
            <li className="tds-list-item tds-flex">
              <TextLoader
                key={`Summary:${option.code}`}
                data={option}
                tag="span"
                field="long_name"
                className="tds-flex-item"
              />
              <span className="value price-indicator">
                {(option => {
                  const indicatorOption = option?.option_override_pricing
                    ? getOptionIndicatorOverwrite(option)
                    : false;
                  const result =
                    typeof indicatorOption === 'number'
                      ? formatCurrency(indicatorOption)
                      : indicatorOption;
                  return indicatorOption !== false ? result : option.formattedPrice;
                })(option)}
                <If condition={option.extraPrice}>
                  <span className="double-currency">
                    {formatCurrency(option.extraPrice.amount, {
                      currency: option.extraPrice.currency,
                      useDynamicRounding: true,
                    })}
                  </span>
                </If>
              </span>
            </li>
          );
        })}

        {isMetShowExtendedServiceAgreementCondition &&
          <li class="tds-list-item tds-flex">
            <div class="text-loader--content tds-flex-item" tabindex="-1">
              <span>
                {i18n('Review.extended_service_agreement_option', null, null, {
                  returnNullWhenEmpty: true,
                })}
              </span>
            </div>
            <span class="value price-indicator">
              {i18n('Review.extended_service_agreement_option_included', null, null, {
                returnNullWhenEmpty: true,
              })}
            </span>
          </li>
        }
      </ol>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    configuration_options: options,
    base_configuration_options: baseConfigOptions,
  } = state?.ReviewDetails || {};

  const { apiResultsPerTrim, apiResults = {} } = state?.Pricing?.calculatorResult?.data || {};

  const { showDistinctTaxTotal } = state?.FinancingOptions || {};
  return {
    options,
    baseConfigOptions,
    CustomGroups: state.CustomGroups,
    priceData: {
      ...apiResults,
      apiResultsPerTrim,
    },
    configuration: _get(state, 'Configuration.option_codes', []),
    trimOptions: _get(state, 'CustomGroups.TRIM.options', []),
    includeFeesinBatteryCalculationPayment: _get(
      state,
      'FinancingOptions.includeFeesinBatteryCalculationPayment',
      false
    ),
    useOptionPriceOverridePayment: _get(
      state,
      'FinancingOptions.useOptionPriceOverridePayment',
      false
    ),
    isMetShowExtendedServiceAgreementCondition: isMetShowExtendedServiceAgreementCondition(state),
  };
}

VehicleOptions.propTypes = {
  options: array,
  baseConfigOptions: array,
  vehicleBasePrice: number,
  vehicleTrimTitle: string,
  configuration: array,
  CustomGroups: shape({}),
  priceData: shape({}),
  includeFeesinBatteryCalculationPayment: bool,
  useOptionPriceOverridePayment: bool,
  trimOptions: array,
};

VehicleOptions.defaultProps = {
  options: {},
  baseConfigOptions: [],
  vehicleBasePrice: 0,
  vehicleTrimTitle: '',
  configuration: [],
  CustomGroups: {},
  priceData: {},
  includeFeesinBatteryCalculationPayment: false,
  useOptionPriceOverridePayment: false,
  trimOptions: [],
};

export default connect(mapStateToProps)(VehicleOptions);
