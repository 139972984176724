import {
  FINANCIAL_MODAL_STANDALONE,
  FINANCIAL_MODAL_CN,
  FIN_MODAL_CUSTOMIZE,
  MODAL_MEDIUM,
  FinanceTypes,
} from 'dictionary';
import { bool, number, shape, string, arrayOf } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  getFinanceTabs,
  getSelectedFinanceProduct,
  getPricingSummary,
  getVATAmount,
  hideFinanceModal,
  getExtraPricingContextAmounts,
  getTradeInType,
  getSCTTax,
  getIncentivesTotal,
  getFeesTotalOnce,
} from 'selectors';
import { i18n, convertMultipleCurrency, htmlToReact } from 'utils';
import FinanceDisclaimer from '../../GroupComponents/FinanceDisclaimer';
import TaxesAndFeesDisclaimer from '../../../containers/PaymentOverview/Sections/TaxesAndFeesDisclaimer';
import VisitTeslaFinancing from '../../../containers/PaymentOverview/Sections/VisitTeslaFinancing';
import ModalTrigger from '../../ModalTrigger';
import PricingDisclaimers from './PricingDisclaimers';
import BannerModalTrigger from './BannerModalTrigger';
import { formatCurrency } from '@tesla/coin-common-components';
import { getFinanceType, getPrice, getInterestRate } from '../../../common/selectors';
import BriefCalculatedResult from './BriefCalculatedResult';
import classNames from 'classnames';
import _get from 'lodash/get';
import {
  WEB_CONFIGURATOR_PAYMENT_FINANCING_CALCULATOR,
  GIO_TAG_ENABLE,
} from '../../../common/gioStatistics';
import { isGrayMarket } from '@tesla/markets-vehicle';
import { formatNumber } from '@tesla/coin-common-components';

const FinanceTabs = ({
  incentives,
  paymentLabel,
  formattedPurchasePrice,
  formattedMonthlyPaymentPrice,
  formattedTotalPriceWithoutIncentives,
  financeProductId,
  vatDisclaimer,
  reviewRefactor,
  financeType,
  hidePaymentModalTrigger,
  tradeInTypeDisclaimer,
  isGrayMarket,
  sctTax,
  priceBeforeSavings,
  showLoanFieldsOrderPage,
  loanType,
  interestRate,
  additionalInfoDisclaimer,
  hideTaxesAndFeesExcluded,
  showVisitTeslaFinancing,
  exchangeRateDisclaimer,
  extraCurrency,
  otherFeesInSummaryDisclaimer,
  coeBidAmount,
}) => {
  const componentName = reviewRefactor ? FINANCIAL_MODAL_CN : FINANCIAL_MODAL_STANDALONE;
  const taxExcludedDisclaimer = i18n('SummaryPanel.disclaimers.taxesAndFeesExcluded', null, null, {
    returnNullWhenEmpty: true,
  });
  const isCash = financeType === FinanceTypes.CASH;
  return (
    <div className="tab-content">
      <div className="pricing-container tds-o-padding_bottom-24">
        <div
          className={classNames('price-block', {
            'tds-o-flex-direction--column tds-flex-gap--8': !reviewRefactor,
          })}
        >
          <Choose>
            <When condition={reviewRefactor}>
              <div className="finance-section--wrapper tds-o-padding_bottom-8">
                <ul className="tds-list">
                  <li
                    className={cx('tds-list-item', {
                      'tds-text--h4': isCash,
                    })}
                  >
                    <span className="left tds-text_color--10 tds-o-padding_right-8">
                      {i18n('common.purchasePrice')}
                    </span>
                    <span className="tds-text--end tds-text_color--10">{priceBeforeSavings}</span>
                  </li>
                  <If condition={!isCash}>
                    <li className="tds-list-item tds-text--h4">
                      <span className="left tds-text--medium tds-text_color--10 tds-o-padding_right-8">
                        {paymentLabel}
                      </span>
                      <span className="tds-text--end tds-text--medium tds-text_color--10">
                        {formattedMonthlyPaymentPrice}
                      </span>
                    </li>
                  </If>
                  <If condition={incentives?.key}>
                    <li className="tds-list-item" data-id="tax-credit-line-item">
                      <span className="left tds-text--caption tds-o-padding_right-8">
                        <BannerModalTrigger
                          contentSource={incentives?.key}
                          label={incentives?.label}
                        />
                      </span>
                      <span className="tds-text--caption">{incentives?.formattedPrice}</span>
                    </li>
                  </If>
                </ul>
                <PricingDisclaimers />
                <If condition={vatDisclaimer && !financeProductId}>
                  <p className="tds-text--caption">{vatDisclaimer}</p>
                </If>
              </div>
              <If condition={!isCash}>
                <BriefCalculatedResult financeType={financeType} />
              </If>
            </When>
            <Otherwise>
              <div className="finance-section--wrapper">
                <ul className="tds-list">
                  <li className="tds-list-item tds-text--h4">
                    <span className="left tds-text--medium tds-text_color--10 tds-o-padding_right-8">
                      {paymentLabel}
                    </span>
                    <span className="tds-text--end tds-text--medium tds-text_color--10">
                      {formattedPurchasePrice}
                    </span>
                  </li>
                  <If condition={isCash && extraCurrency}>
                    {extraCurrency.map(({ currency, totalPrice = 0 }) => (
                      <li
                        className="tds-list-item tds-align--end"
                        key={`extraContextPriceCash_${currency}`}
                      >
                        <span className="tds-text--end tds-text--medium tds-text_color--30">
                          {formatCurrency(totalPrice, {
                            currency,
                            useDynamicRounding: true,
                          })}
                        </span>
                      </li>
                    ))}
                  </If>
                  <If condition={showLoanFieldsOrderPage.includes('interestRate') && interestRate}>
                    <li className="tds-list-item" key="showLoanFieldsInterestRate">
                      <span className="left">
                        {i18n(`FinancingOptions.aprLabel`, null, null, {
                          specificOverride: [financeProductId, loanType],
                          specificOverrideOperator: 'OR',
                        })}
                      </span>
                      <span className="right">
                        {i18n('FinancingOptions.apr', {
                          APR: formatNumber(interestRate * 100, {
                            style: 'decimal',
                            maxPrecision: 2,
                          }),
                        })}
                      </span>
                    </li>
                  </If>
                  <If condition={incentives?.key && incentives?.label}>
                    <li
                      className="tds-list-item tds-o-margin_bottom"
                      data-id="tax-credit-line-item"
                    >
                      <span className="left tds-text--caption tds-o-padding_right-8">
                        <BannerModalTrigger
                          contentSource={incentives?.key}
                          label={incentives?.label}
                        />
                      </span>
                      <span className="tds-text--caption">{incentives?.formattedPrice}</span>
                    </li>
                  </If>
                </ul>
                <If condition={!hideTaxesAndFeesExcluded}>
                  <TaxesAndFeesDisclaimer />
                </If>
                <If condition={isCash && coeBidAmount}>
                  <span className="tds-text--caption">
                    {htmlToReact(
                      i18n(
                        'SummaryPanel.disclaimers.coeBidDisclaimer',
                        {
                          COE: formatCurrency(coeBidAmount),
                        },
                        null,
                        { returnNullWhenEmpty: true }
                      ) || ''
                    )}
                  </span>
                </If>
                <If condition={isCash && otherFeesInSummaryDisclaimer}>
                  <div className="tds-text--caption otherFeesInSummaryDisclaimer">
                    {otherFeesInSummaryDisclaimer}
                  </div>
                </If>
                <PricingDisclaimers />
                <If condition={showVisitTeslaFinancing}>
                  <VisitTeslaFinancing />
                </If>
                <If condition={!financeProductId}>
                  <If condition={vatDisclaimer}>
                    <p className="tds-text--caption">{vatDisclaimer}</p>
                  </If>
                  <If condition={sctTax?.total}>
                    <p className="tds-text--caption">
                      {i18n('SummaryPanel.disclaimers.sctTaxIncluded', {
                        SCT_TAX: formatCurrency(sctTax?.total),
                      })}
                    </p>
                  </If>
                  <If condition={tradeInTypeDisclaimer}>
                    <div className="tds-text--caption">{tradeInTypeDisclaimer}</div>
                  </If>
                  <If condition={isGrayMarket && taxExcludedDisclaimer}>
                    <div className="tds-text--caption">{taxExcludedDisclaimer}</div>
                  </If>
                </If>
              </div>
              <FinanceDisclaimer 
                className="tds-text--caption finance-disclaimer--condensed"
                isPaymentSummary={true}
              />
              <If condition={exchangeRateDisclaimer}>
                <p className="tds-text--caption">{exchangeRateDisclaimer}</p>
              </If>
            </Otherwise>
          </Choose>

          <If condition={!hidePaymentModalTrigger}>
            <ModalTrigger
              analyticsInteraction={FIN_MODAL_CUSTOMIZE}
              data-gio-eventname={WEB_CONFIGURATOR_PAYMENT_FINANCING_CALCULATOR}
              data-gio-track={GIO_TAG_ENABLE}
              options={{
                props: {
                  componentName: componentName,
                  props: {
                    genericWrapper: true,
                    size: MODAL_MEDIUM,
                    classes: 'tds-link',
                    isMega: true,
                  },
                },
              }}
            >
              <p className="tds-text--caption">{i18n('Review.explore_financing')}</p>
            </ModalTrigger>
          </If>
          <If condition={reviewRefactor}>
            <FinanceDisclaimer
              className="tds-text--caption finance-disclaimer--condensed"
              reviewRefactor={reviewRefactor}
              financeType={financeType}
              isPaymentSummary={true}
            />
          </If>
          <If condition={additionalInfoDisclaimer}>
            <div className="tds-text--medium">
              {additionalInfoDisclaimer}
            </div>
          </If>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const financeTabs = getFinanceTabs(state);
  const selectedTab = getSelectedFinanceProduct(state);
  const financeType = getFinanceType(state);
  const { financeProductId } = financeTabs.find(tab => tab?.id === selectedTab) || {};
  const {
    ReviewDetails: { showVAT = false, showMyVAT = false, reviewRefactor = false },
  } = state;
  const pricingSummaryObj = getPricingSummary(state, { includeCoeBid: true });
  const { model_code: modelCode = '' } = state?.Configuration || {};

  const { coeBidAmount = 0 } = state?.FinancingOptions || {};
  const purchasePriceLabel = coeBidAmount ? i18n('Review.purchase_price_with_coe') : i18n('Review.est_purchase_price');

  let paymentLabel = pricingSummaryObj?.isMonthly
    ? i18n('SummaryPanel.est_payment_label__finplat', null, null, {
        specificOverride: financeProductId,
      })
    : purchasePriceLabel;

  const market = _get(state, 'OMS.oms_params.market');
  const isUsedInventory = _get(state, 'ReviewDetails.product.isUsedInventory', false);
  const showTaxAndFeeDisclaimerForUsed = _get(state, 'ReviewDetails.showTaxAndFeeDisclaimerForUsed', false);
  const extraPricing = getExtraPricingContextAmounts(state);
  const extraVat = extraPricing && extraPricing[0]
    ? {
        [`VAT_AMOUNT_${extraPricing[0].currency}`]: formatCurrency(extraPricing[0].vatAmount ?? 0, {
          useDynamicRounding: true,
          currency: extraPricing[0].currency,
        }),
      }
    : {};
  const vatAmount = getVATAmount(state);
  let vatDisclaimer =
    showVAT &&
    i18n('SummaryPanel.disclaimers.vatAmount', {
      VAT_AMOUNT: formatCurrency(vatAmount),
      ...extraVat,
    });
  if (showMyVAT && state?.App?.isCoinReloaded) {
    if(market === 'CN' && isUsedInventory && showTaxAndFeeDisclaimerForUsed) {
      vatDisclaimer = i18n('SummaryPanel.disclaimers.taxesAndFeesExcluded__used', null, null, {
        returnNullWhenEmpty: true,
      });
    } else {
      if (modelCode === 'm3' || modelCode === 'my') {
        vatDisclaimer = i18n('SummaryPanel.disclaimers.myVatAmount', null, null, {
          returnNullWhenEmpty: true,
        });
      } else if (modelCode === 'ms' || modelCode === 'mx') {
        vatDisclaimer = i18n('SummaryPanel.disclaimers.myVatAmountSX', null, null, {
          returnNullWhenEmpty: true,
        });
      } else {
        vatDisclaimer = '';
      }
    }
  }

  const formattedTotalPriceWithoutIncentives = formatCurrency(_get(state, 'Pricing.total', 0));
  let formattedMonthlyPaymentPrice;
  if (reviewRefactor) {
    if (financeType === FinanceTypes.LEASE) {
      paymentLabel = i18n('SummaryPanel.payment__lease');
      formattedMonthlyPaymentPrice = getPrice(state, 'lease');
    }
    if (financeType === FinanceTypes.LOAN) {
      paymentLabel = i18n('SummaryPanel.payment__loan');
      formattedMonthlyPaymentPrice = getPrice(state, 'loan');
    }
  }

  const tradeInType = getTradeInType(state);
  const sctTax = getSCTTax(state);
  const priceBeforeSavings = getPrice(state, 'cash');
  const { loanType } = state?.SummaryPanel || {};
  const conversionRate = convertMultipleCurrency(state);
  const { show, primaryCurrency, secondaryCurrency, factor } = conversionRate;
  const incentivesTotalPurchasePrice = getIncentivesTotal(state, {
    includeAllCurrentIncentives: true,
    includeSavings: true,
    incentivesInPurchasePrice: true,
    absoluteValue: true,
    doFormatCurrency: false,
  });
  const feesTotal = getFeesTotalOnce(state);
  const otherFeesInSummaryDisclaimer = htmlToReact(
    i18n(
      'SummaryPanel.disclaimers.otherFeesInSummary',
      {
        FEES_TOTAL: formatCurrency(feesTotal),
        INCENTIVES_TOTAL: formatCurrency(-1 * Math.abs(incentivesTotalPurchasePrice)),
        GST: formatCurrency(vatAmount),
      },
      null,
      { returnNullWhenEmpty: true }
    ) || ''
  );

  return {
    paymentLabel,
    financeProductId,
    vatDisclaimer,
    financeType,
    reviewRefactor,
    formattedMonthlyPaymentPrice,
    formattedTotalPriceWithoutIncentives,
    hidePaymentModalTrigger: hideFinanceModal(state),
    tradeInTypeDisclaimer: i18n(`Inventory.vehicleDetails.${tradeInType}`, null, null, {
      returnNullWhenEmpty: true,
    }),
    isGrayMarket: isGrayMarket(state?.App?.countryCode),
    sctTax,
    priceBeforeSavings,
    ...pricingSummaryObj,
    showLoanFieldsOrderPage: state?.ReviewDetails?.showLoanFieldsOrderPageLineItem || [],
    interestRate: getInterestRate(state),
    loanType,
    additionalInfoDisclaimer: financeProductId && i18n('FinancingOptions.additionalInfo', null, null, {
      returnNullWhenEmpty: true
    }),
    hideTaxesAndFeesExcluded: state?.FinancingOptions?.hideTaxesAndFeesExcluded,
    showVisitTeslaFinancing: state?.ReviewDetails?.showVisitTeslaFinancing || false,
    exchangeRateDisclaimer: i18n('Incentives.extraIncentives.exchangeRate', {
      PRIMARY_CURRENCY: primaryCurrency,
      SECONDARY_CURRENCY: secondaryCurrency,
      FACTOR: factor,
    }, null, {
      returnNullWhenEmpty: true,
    }),
    extraCurrency: state?.FinancingOptions?.showSecondaryCurrencyVehiclePrice ? extraPricing : null,
    otherFeesInSummaryDisclaimer,
    coeBidAmount: state?.FinancingOptions?.coeBidAmount || 0,
  };
}

FinanceTabs.propTypes = {
  paymentLabel: string,
  financeProductId: string,
  formattedPurchasePrice: string.isRequired,
  formattedMonthlyPaymentPrice: string,
  formattedTotalPriceWithoutIncentives: string,
  incentives: shape({
    price: number,
    key: string,
    label: string,
  }),
  vatDisclaimer: string,
  financeType: string,
  reviewRefactor: bool,
  hidePaymentModalTrigger: bool,
  isGrayMarket: bool,
  sctTax: shape({}),
  priceBeforeSavings: string,
  hideTaxesAndFeesExcluded: bool,
  showVisitTeslaFinancing: bool,
  exchangeRateDisclaimer: string,
  extraCurrency: arrayOf(
    shape({
      currency: string,
    })
  ),
  otherFeesInSummaryDisclaimer: string,
  coeBidAmount: number,
};

FinanceTabs.defaultProps = {
  paymentLabel: '',
  financeProductId: null,
  monthlyPayment: {
    price: 0,
    key: '',
    label: '',
  },
  vatDisclaimer: '',
  financeType: FinanceTypes.CASH,
  reviewRefactor: false,
  hidePaymentModalTrigger: false,
  isGrayMarket: false,
  sctTax: {},
  priceBeforeSavings: '',
  hideTaxesAndFeesExcluded: true,
  showVisitTeslaFinancing: false,
  exchangeRateDisclaimer: null,
  extraCurrency: null,
  otherFeesInSummaryDisclaimer: '',
  coeBidAmount: 0,
};

export default connect(mapStateToProps)(FinanceTabs);
