import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { shape, bool, string } from 'prop-types';
import _has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { FormFieldset } from '@tesla/design-system-react';
import cx from 'classnames';
import { WHEELS, PAINT } from 'dictionary';
import { lazyWithPreload } from '../Lazy';
import { preloadAsset } from 'selectors';

import GroupDetails from './GroupDetails';
import Copy from './Copy';

const availableComponents = {
  AssetLoader: lazyWithPreload(() =>
    import(/* webpackChunkName: "asset-loader" */ '../AssetLoader')
  ),
  ActionTarget: lazyWithPreload(() =>
    import(/* webpackChunkName: "action-target" */ '../ActionTarget')
  ),
  Copy,
  DeliveryDate: lazyWithPreload(() =>
    import(/* webpackChunkName: "delivery-date" */ './DeliveryDate')
  ),
  Disclaimer: lazyWithPreload(() => import(/* webpackChunkName: "disclaimer" */ './Disclaimer')),
  DisclaimerSpecial: lazyWithPreload(() =>
    import(/* webpackChunkName: "disclaimer-special" */ './DisclaimerSpecial')
  ),
  DynamicDisclaimer: lazyWithPreload(() =>
    import(/* webpackChunkName: "dynamic-disclaimer" */ './DynamicDisclaimer')
  ),
  DynamicTextLoader: lazyWithPreload(() =>
    import(/* webpackChunkName: "dynamic-text-loader" */ '../DynamicTextLoader')
  ),
  FormattedPrice: lazyWithPreload(() =>
    import(/* webpackChunkName: "formatted-price" */ './FormattedPrice')
  ),
  Options: lazyWithPreload(() => import(/* webpackChunkName: "options" */ './Options')),
  RootGroup: lazyWithPreload(() => import(/* webpackChunkName: "root-group" */ './RootGroup')),
  SavingsToggle: lazyWithPreload(() =>
    import(/* webpackChunkName: "savings-toggle" */ '../SavingsToggle')
  ),
  FinanceDisclaimer: lazyWithPreload(() =>
    import(/* webpackChunkName: "finance-disclaimer" */ './FinanceDisclaimer')
  ),
  FinanceToggle: lazyWithPreload(() =>
    import(/* webpackChunkName: "finance-toggle" */ './FinanceToggle')
  ),
  SelectedPrice: lazyWithPreload(() =>
    import(/* webpackChunkName: "selected-price" */ './SelectedPrice')
  ),
  Specs: lazyWithPreload(() => import(/* webpackChunkName: "specs" */ './Specs')),
  Title: lazyWithPreload(() => import(/* webpackChunkName: "title" */ './Title')),
  Tooltip: lazyWithPreload(() => import(/* webpackChunkName: "tooltip" */ './Tooltip')),
  DynamicCopy: lazyWithPreload(() =>
    import(/* webpackChunkName: "dynamic-copy" */ './DynamicCopy')
  ),
  SubOptionComponent: lazyWithPreload(() =>
    import(/* webpackChunkName: "add-component" */ './SubOptionComponent')
  ),
  NotificationBanner: lazyWithPreload(() =>
    import(/* webpackChunkName: "notification-banner" */ './NotificationBanner')
  ),

  InventoryZip: lazyWithPreload(() =>
    import(/* webpackChunkName: "delivery-location-link" */ '../InventoryZip')
  ),
  NotificationBlock: lazyWithPreload(() =>
    import(/* webpackChunkName: "notification-block" */ './NotificationBlock')
  ),
  Card: lazyWithPreload(() => import(/* webpackChunkName: "status-card" */ './Card')),
  IncentivesToggle: lazyWithPreload(() => import(/* webpackChunkName: "incentives-toggle" */ './IncentivesToggle')),
  SelectedCopy: lazyWithPreload(() => import(/* webpackChunkName: "selected-title" */ './SelectedCopy')),
  OptionGroup: lazyWithPreload(() => import(/* webpackChunkName: "option-group" */ './OptionGroup')),
  StatusCard: lazyWithPreload(() => import(/* webpackChunkName: "selected-status-card" */ './StatusCard')),
  ExVatDisclaimer: lazyWithPreload(() => import(/* webpackChunkName: "ex-vat-disclaimer" */ './ExVatDisclaimer')),
  CompareFeatures: lazyWithPreload(() => import(/* webpackChunkName: "compare-features" */ './CompareFeatures')),
};

const LexiconGroup = ({
  group,
  isMain,
  withMain,
  isStarting,
  classes,
  isNested,
  enableCyberpunk,
  ...rest
}) => {
  // TODO: if groups are nested further (a child_groups group has children)
  // this will potentially render an empty section
  const { current, child_groups, options } = group;
  if (
    !group ||
    (isEmpty(current) && isEmpty(options) && isEmpty(child_groups)) ||
    (isMain && !group.asset)
  ) {
    return null;
  }
  const compType = isMain ? 'OptionVisualization' : 'OptionWidget';
  const { components = [], code } = group;

  const componentsList = components.map((component, index) => {
    const { type, props = {} } = component;
    if (!_has(availableComponents, type)) {
      return null;
    }

    const componentProps = {
      group,
      ...props,
      ...rest,
    };
    const ComponentName = availableComponents[type];
    if (isStarting) {
      const { field = 'default' } = componentProps;
      return <ComponentName key={`GroupComponent_${type}_${code}:${field}`} {...componentProps} />;
    }
    return (
      <Suspense
        // eslint-disable-next-line react/no-array-index-key
        key={`Suspense.GroupComponent_${type}_${code}:index_${index}`}
        fallback={
          <div
            className="tds-spinner tds-spinner--fade_in"
            style={{ position: 'absolute', height: '450px' }}
          ></div>
        }
      >
        <ComponentName
          // eslint-disable-next-line react/no-array-index-key
          key={`GroupComponent_${type}_${code}:index_${index}`}
          {...componentProps}
        />
      </Suspense>
    );
  });

  return (
    <>
      <If condition={isMain || withMain}>
        <div className="cf-asset-wrapper">
          <GroupDetails
            group={code}
            detail_size="full-asset"
            key={`GroupDetails_${compType}_${code}_Mobile`}
          ></GroupDetails>
        </div>
      </If>
      <If condition={!isMain && components.length}>
        <Choose>
          <When condition={code === PAINT || code === WHEELS}>
            {/* TODO make updates to mobile styles */}
            <FormFieldset
              className={cx(
                'tds-fieldset tds--align_center',
                {
                  'tds--vertical_margin--fieldset': !withMain && !enableCyberpunk,
                },
                {
                  'tds--vertical_padding': !withMain && enableCyberpunk,
                },
                {
                  'tds--padding tds--align_center': withMain,
                },
                { 'option-widget--container': !isNested },
                { [classes]: classes }
              )}
            >
              {componentsList}
            </FormFieldset>
          </When>
          <Otherwise>
            <div
              className={cx(
                {
                  'tds--vertical_padding': !withMain && enableCyberpunk,
                },
                {
                  'tds--padding tds--align_center': withMain,
                },
                { 'option-widget--container': !isNested },
                { [classes]: classes }
              )}
            >
              {componentsList}
            </div>
          </Otherwise>
        </Choose>
      </If>
    </>
  );
};

LexiconGroup.propTypes = {
  group: shape({}).isRequired,
  isStarting: bool,
  isMain: bool,
  withMain: bool,
  classes: string,
  isNested: bool,
  enableCyberpunk: bool,
};

LexiconGroup.defaultProps = {
  isStarting: false,
  withMain: false,
  isMain: false,
  classes: '',
  isNested: false,
  enableCyberpunk: false,
};

function mapStateToProps(state, ownProps) {
  const { code, group, id } = ownProps;
  const { CustomGroups, App } = state;
  const { enableCyberpunk = false } = App;
  const lexiconGroup = group || CustomGroups[code] || {};

  return {
    group: lexiconGroup,
    enableCyberpunk,
    assetPreload: preloadAsset(state, { group: lexiconGroup, id }),
  };
}

export default connect(mapStateToProps, null)(LexiconGroup);
