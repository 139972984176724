/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { string, func, bool } from 'prop-types';
import { SHOW_BANNER_MODAL } from 'dictionary';
import { getTCLBannerContent, htmlToReact } from 'utils';
import { BannerCarousel } from '@tesla/tcl-react';
import TextLoader from '../TextLoader';
import ActionTrigger from '../ActionTrigger';
import { Icon } from '@tesla/design-system-react';
import { iconChevron90 } from '@tesla/design-system-icons';
import GioStatistics from 'gioStatistics';
import _isEmpty from 'lodash/isEmpty';
import { triggerLoaderAction } from 'actions';
import { WEB_CONFIGURATOR_BANNER_LINK } from '../../common/gioStatistics';
import _get from 'lodash/get';

const TopBanner = ({
  dynamicBannerContentStringified,
  openBannerModal,
  referralCode,
  countryCode,
  isLayoutMobile,
  isLayoutTablet,
  triggerAction,
  triggerLoader,
  secondaryBannerEnabled,
  useTCLBanner,
}) => {
  const isCN = countryCode === 'CN';
  const dynamicBannerContent = dynamicBannerContentStringified
    ? JSON.parse(dynamicBannerContentStringified)
    : [];
  const topBannerRef = useRef(null);
  const topBannerSecondaryRef = useRef(null);
  const isDesktop = !(isLayoutMobile || isLayoutTablet);

  useEffect(() => {
    document.body.classList.add('has-top-banner');
    return () => {
      document.body.classList.remove('has-top-banner');
    };
  }, []);

  useEffect(() => {
    if (useTCLBanner) return;
    if (!_isEmpty(dynamicBannerContent?.[1])) {
      document.body.classList.add('has-secondary-top-banner');
    } else {
      document.body.classList.remove('has-secondary-top-banner');
    }
  }, [secondaryBannerEnabled, dynamicBannerContent]);

  const gioTrackBanner = () => {
    isCN && GioStatistics.manuallyTrack(WEB_CONFIGURATOR_BANNER_LINK);
  };
  const onClick = data => {
    gioTrackBanner();
    const { modalContent, modalTitle, modalSubtitle, modalBtnCopy, modalBtnBehavior } = data || {};
    const content =
      modalTitle && modalContent
        ? [
            {
              modalTitle,
              modalSubtitle,
              modalContent,
              modalBtnCopy,
              modalBtnBehavior,
            },
          ]
        : [];
    openBannerModal({ content });
  };

  const addReferralToLink = content => {
    if (!isCN) {
      return content;
    }
    const regEx = /["']https?:\/\/.*?["']/gi;
    let urls = content.match(regEx);
    if (Array.isArray(urls)) {
      urls = _.uniq(urls);
      urls.forEach(function(url) {
        if (!url || url.indexOf('referral') >= 0 || !referralCode) {
          return;
        }
        url = url.replaceAll("'", '').replaceAll('"', '');
        let targetUrl = new URL(url);
        targetUrl.searchParams.append('referral', referralCode);
        content = _.replace(content, url, targetUrl.toString());
      });
    }
    return content;
  };

  const getContent = bannerContent => {
    const [{ description, btnCopy, btnBehavior }] = bannerContent;
    const replacedDescription = addReferralToLink(description);
    return (
      <>
        <If condition={description}>
          <TextLoader data={replacedDescription} transitionWrapper={false} />
        </If>
        &nbsp;
        <If condition={btnCopy && (btnBehavior?.length || bannerContent[0]?.modalContent)}>
          <div className="modal-trigger-container">
            <Choose>
              <When condition={btnBehavior?.length && isDesktop}>
                <TextLoader
                  data={{
                    content: btnCopy,
                    behavior: btnBehavior,
                  }}
                />
              </When>
              <When condition={btnCopy && isDesktop}>
                <button
                  className="modal-trigger tds-link"
                  onClick={() => onClick(bannerContent[0])}
                >
                  {htmlToReact(btnCopy)}
                </button>
              </When>
              <When condition={!isDesktop}>
                <Icon data={iconChevron90} />
              </When>
            </Choose>
          </div>
        </If>
      </>
    );
  };

  if (!getContent(dynamicBannerContent)) {
    return null;
  }

  if (useTCLBanner) {
    const tclBannerContent = getTCLBannerContent(dynamicBannerContent);
    const onButtonClick = data => {
      if (data?.modalData?.modalContent) {
        onClick(data?.modalData);
      } else if (data?.btnBehavior?.length) {
        triggerLoader(data?.btnBehavior);
      }
    };
    return (
      <div className="top-banner tcl-banner" ref={topBannerRef}>
        <ActionTrigger>
          <BannerCarousel {...tclBannerContent} onNavigation={onButtonClick} />
        </ActionTrigger>
      </div>
    );
  } else {
    const onTouch = dynamicBannerContent => {
      if (!isDesktop) {
        if (dynamicBannerContent?.modalContent) {
          return onClick(dynamicBannerContent);
        } else if (dynamicBannerContent?.btnBehavior) {
          const actionType = dynamicBannerContent?.btnBehavior[0]?.content?.type;
          actionType && triggerAction({ actionType: actionType });
        }
      }
      gioTrackBanner();
    };
    return (
      <div className="top-banner--container">
        <div
          className="top-banner"
          ref={topBannerRef}
          onClick={() => onTouch(dynamicBannerContent[0])}
        >
          <ActionTrigger>{getContent([dynamicBannerContent[0]])}</ActionTrigger>
        </div>
        <If condition={isCN && dynamicBannerContent[1]}>
          <div
            className="top-banner top-banner--secondary"
            ref={topBannerSecondaryRef}
            onClick={() => onTouch(dynamicBannerContent[1])}
          >
            <ActionTrigger>{getContent([dynamicBannerContent[1]])}</ActionTrigger>
          </div>
        </If>
      </div>
    );
  }
};

TopBanner.propTypes = {
  dynamicBannerContentStringified: string,
  openBannerModal: func.isRequired,
  triggerAction: func.isRequired,
  triggerLoader: func.isRequired,
  referralCode: string,
  countryCode: string.isRequired,
  isLayoutMobile: bool.isRequired,
  isLayoutTablet: bool.isRequired,
  secondaryBannerEnabled: bool.isRequired,
  useTCLBanner: bool.isRequired,
};

TopBanner.defaultProps = {
  dynamicBannerContentStringified: '',
  referralCode: '',
  secondaryBannerEnabled: false,
};

function mapDispatchToProps(dispatch) {
  return {
    openBannerModal: props => {
      dispatch({
        type: SHOW_BANNER_MODAL,
        props,
      });
    },
    triggerAction: props => {
      dispatch({
        type: props?.actionType,
      });
    },
    triggerLoader: props => {
      dispatch(triggerLoaderAction(props));
    },
  };
}

function mapStateToProps(state) {
  const { ApplicationFlow, App } = state;
  const { referral = {} } = ApplicationFlow || {};
  const { referralCode = '' } = referral || {};
  const { useTCLBanner, secondaryBannerEnabled = false } = App || {};
  const countryCode = _get(state, 'OMS.oms_params.market');
  return {
    referralCode,
    countryCode,
    secondaryBannerEnabled: secondaryBannerEnabled,
    isCoinReloaded: state?.App?.isCoinReloaded,
    isLayoutMobile: state?.App?.isLayoutMobile,
    isLayoutTablet: state?.App?.isLayoutTablet,
    useTCLBanner,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBanner);
