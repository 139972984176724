import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { bool, string, number, func, oneOfType, arrayOf, shape } from 'prop-types';
import _get from 'lodash/get';
import { formatDate } from '@tesla/intl-date-time';

import {
  i18n,
  htmlToReact,
  constructUrl,
  getCoeUrl,
  getOrderDisclaimerSource,
  getLoadedRN,
  getOrderAgreementUrl,
  isEMEAMarket,
  isPreOrder,
  normalizeT4BLocale,
} from 'utils';
import {
  getModelCode,
  getLoanTerm,
  getDownPayment,
  getInterestRate,
  getFinanceType,
  useModelYContent,
  getDisclaimer,
  getDepositAmount,
  isAccessoriesSelected,
  showRepairDisclosure,
  getOrderDisclaimerCopySource,
  checkIfNV35Enabled,
  isEarlyDesignSelected
} from 'selectors';
import { externalLinkClicked } from 'actions';
import {
  PAYMENT_TERMS_SECTION,
  ORDER_DISCLAIMER_SECTION,
  FINANCE_DISCLAIMER_SECTION,
  SHOW_INSPECTION_MODAL,
  EDIT_DESIGN_DISCLAIMER_SECTION,
  POST_ORDER_SWAP_SECTION,
  Models,
} from 'dictionary';

import InsuranceDisclaimer from './InsuranceDisclaimer';
import RefundPolicyLink from './RefundPolicyLink';
import DisclaimerLink from './DisclaimerLink';
import TradersLicenseDisclaimer from './TradersLicenseDisclaimer';
import LegalConsent from '../../../components/AccountForm/LegalConsent';
import { formatCurrency } from '@tesla/coin-common-components';
import ActionTrigger from '../../../components/ActionTrigger';
import ConsumerInformationLink from './ConsumerInformationLink';
import PreprodDisclaimer from './PreprodDisclaimer';
import PriceAcceptanceDisclaimer from './PriceAcceptanceDisclaimer';

const triggerAnalytics = (event, externalClicked) => {
  const target = _get(event, 'target');
  if (typeof _get(target, 'getAttribute') === 'function') {
    const dataType = target.getAttribute('data-cfg-type');
    const href = target.getAttribute('href');
    const _target = target.getAttribute('target'); // eslint-disable-line no-underscore-dangle

    if (dataType && href) {
      event.preventDefault();
      externalClicked({
        analytics: { 'cfg-type': dataType, interaction: 'expand' },
        href,
        _target,
      });
    }
  }
};

const LegalDisclaimers = ({
  type,
  displayPaymentDisclaimer,
  orderDisclaimerSource,
  sibling,
  locale,
  modelName,
  ecoBonus,
  modelCode,
  isPreSale,
  isInventory,
  useUsedInventoryDisclaimerCopy,
  externalClicked,
  isSaveDesignEnabled,
  grossPrice,
  loanTerm,
  downPayment,
  balloonPayment,
  monthlyPayment,
  totalAmtPaid,
  financedAmount,
  interestRate,
  mvpaType,
  useModelYContentState,
  disclaimerIntlDateFormat,
  withRefundPolicy,
  displayInline,
  isEnterpriseOrder,
  isNfaSigned,
  isPaymentInteractionRequired,
  showLegalConsent,
  showOrderDisclaimerAboveLegalConsent,
  financeDisclaimer,
  enableCyberpunk,
  rnLoaded,
  depositAmount,
  showInspectionModal,
  ddFile,
  showRepairLink,
  showSelectedAccessories,
  shopUrl,
  orderAgreementUrl,
  market,
  useCGSCoe,
  showLegalConsentEditDesign,
  showLegalConsentPostOrderSwap,
  canModifyOrder,
  inventorySwapDeliveryDisclaimer,
  isBelow,
  bundleSubscriptionAgreement,
  showDriveAnywhereTerms,
  isCoinReloaded,
  showPrpLink,
  isNewModelY,
}) => {
  if (type === PAYMENT_TERMS_SECTION && !displayPaymentDisclaimer) return null;
  if (type === FINANCE_DISCLAIMER_SECTION && !financeDisclaimer) return null;

  const BTC_ORDER_AGREEMENT_URL = `${sibling}/assets/pdf/BTC_What_You_Need_To_Know_${locale}.pdf`;
  const SUPERCHARGER_POLICY_URL = constructUrl(
    'about/legal?redirect=no#supercharger-fair-use',
    sibling,
    normalizeT4BLocale(locale),
  );
  const PRIVACY_POLICY_URL = constructUrl(
    'about/legal?redirect=no#privacy-statement',
    sibling,
    normalizeT4BLocale(locale),
  );
  const NEW_PRIVACY_POLICY_URL = constructUrl('legal/privacy?redirect=no', sibling, locale);
  const LEGAL_TERMS_URL = constructUrl('legal/terms?redirect=no', sibling, locale);
  const PAYMENT_TERMS_URL = constructUrl('about/legal?redirect=no#terms-of-use', sibling, locale);
  const WALL_CONNECTOR_POLICY_URL = constructUrl(
    '/go/model-3-home-charging-package',
    sibling,
    locale
  );
  const ROADSTER_RESERVATION_AGREEMENT_URL = market === 'CN' ? orderAgreementUrl : constructUrl(
    `sites/all/modules/custom/tesla_smartling/smartling/api-translations/documents/reservation-agreement/${locale.replace(
      '_',
      '-'
    )}/TeslaRoadsterReservationAgreement.pdf`,
    sibling
  );
  const SEMI_RESERVATION_AGREEMENT_URL = constructUrl(
    `sites/all/modules/custom/tesla_smartling/smartling/api-translations/documents/reservation-agreement/${locale.replace(
      '_',
      '-'
    )}/TeslaSemiReservationAgreement.pdf`,
    sibling
  );
  const coeUrl = getCoeUrl(sibling, locale, market, useCGSCoe);
  const CERTIFICATE_ENTITLEMENT_AGREEMENT = coeUrl;
  const CAR_MANTENANCE = constructUrl('support/car-maintenance', sibling, locale);
  const BUNDLE_AGREEMENT = constructUrl(bundleSubscriptionAgreement, sibling)

  const getInspectionDisclosure = () => {
    const separator = market === 'CN' ? '、' : ', ';
    return showInspectionModal
      ? separator.concat(
          i18n('Inspection.modalDisclaimer', {
            CLASS: 'tds-link',
            TRIGGER: SHOW_INSPECTION_MODAL,
          })
        )
      : '';
  };

  const getRepairDisclosure = () => {
    const separator = market === 'CN' ? '、' : ', ';
    return showRepairLink
      ? separator.concat(
          i18n('Inventory.vehicleHistory.damage.disclaimer', {
            LINK_TARGET: '_blank',
            REPAIR_DOCUMENT_URL: ddFile,
          })
        )
      : '';
  };
  const isModel3EditDesignFlow = market === 'CN' && modelCode === 'm3' && canModifyOrder;
  const date = new Date();
  const formattedYear = new Date().getFullYear().toString();
  const SHOP_SUPPORT_URL = constructUrl('support/shop?redirect=no', sibling, locale);
  const SHOP_LINK = constructUrl('?redirect=no', shopUrl, locale);
  const DRIVE_ANYWHERE_TERMS = showDriveAnywhereTerms
  ? i18n('SummaryPanel.disclaimers.driveAnwhereAgreement', {
      BUNDLE_AGREEMENT,
      LINK_TARGET: '_blank',
    })
  : '';
  const disclaimerOverrides = isNewModelY ? [modelCode, 'updated'] : modelCode;
  const OrderDisclaimer = htmlToReact(
    i18n(
      `SummaryPanel.disclaimers.${orderDisclaimerSource}`,
      {
        LINK_TARGET: '_blank',
        IS_RESERVE:
          !useModelYContentState || isModel3EditDesignFlow
            ? ''
            : i18n('SummaryPanel.disclaimers.reserveText'),
        MVPA_TYPE: mvpaType,
        LOCALE: locale,
        ORDER_AGREEMENT_URL: orderAgreementUrl,
        BTC_ORDER_AGREEMENT_URL,
        SUPERCHARGER_POLICY_URL,
        PRIVACY_POLICY_URL,
        NEW_PRIVACY_POLICY_URL,
        LEGAL_TERMS_URL,
        PAYMENT_TERMS_URL,
        WALL_CONNECTOR_POLICY_URL,
        ROADSTER_RESERVATION_AGREEMENT_URL,
        SEMI_RESERVATION_AGREEMENT_URL,
        MODEL: modelName,
        ECO_BONUS: formatCurrency(ecoBonus),
        CASH_PRICE: formatCurrency(grossPrice),
        TERM: loanTerm,
        DOWNPAYMENT: formatCurrency(downPayment),
        BALLON_PAYMENT: formatCurrency(balloonPayment),
        PAYMENT: formatCurrency(monthlyPayment),
        TOTAL_AMOUNT_PAYABLE: formatCurrency(totalAmtPaid),
        FINANCED_AMOUNT: financedAmount,
        RATE: interestRate,
        YEAR: formattedYear,
        DATE: formatDate(date, locale, disclaimerIntlDateFormat),
        INCENTIVES_INCLUDED_TOTAL: formatCurrency(Math.abs(ecoBonus)),
        CERTIFICATE_ENTITLEMENT_AGREEMENT,
        CAR_MANTENANCE,
        INSPECTION_LINK: getInspectionDisclosure(),
        REPAIR_LINK: getRepairDisclosure(),
        ACCESSORIES_TERMS: showSelectedAccessories
          ? i18n('SummaryPanel.disclaimers.shopLink', {
              SHOP_LINK,
              SHOP_SUPPORT_URL,
            })
          : '',
        DRIVE_ANYWHERE_TERMS,
      },
      null,
      { specificOverride: disclaimerOverrides, specificOverrideOperator: 'OR', returnNullWhenEmpty: true }
    ) || ''
  );
  const EnterpriseLegalDisclaimerNFA = (
    <div className="legal-disclaimer">{htmlToReact(i18n('Enterprise.legal_disclaimer_nfa'))}</div>
  );
  const EnterpriseLegalDisclaimer = htmlToReact(
    i18n(`Enterprise.legal_disclaimer.${modelCode}`, {
      LINK_TARGET: '_blank',
      ORDER_AGREEMENT_URL: orderAgreementUrl,
      SUPERCHARGER_POLICY_URL,
      PRIVACY_POLICY_URL,
      PAYMENT_TERMS_URL,
      MODEL: modelName,
      MVPA_TYPE: mvpaType,
      DRIVE_ANYWHERE_TERMS,
    })
  );
  const EnterpriseDisclaimer = isNfaSigned
    ? EnterpriseLegalDisclaimerNFA
    : EnterpriseLegalDisclaimer;

  const returnPolicySource = isPreSale
    ? 'cc_agreement_return_policy_consent_presale'
    : isInventory
    ? useUsedInventoryDisclaimerCopy
      ? 'cc_agreement_return_policy_consent_used'
      : 'cc_agreement_return_policy_consent_inventory'
    : 'cc_agreement_return_policy_consent';
  const ReturnPolicyDisclaimer = htmlToReact(i18n(`Review.${returnPolicySource}`));
  const vinInternationalTransferDisclaimerStr =
    i18n(
      'Review.vin_international_transfer_consent',
      {
        VIN_INTERNATIONAL_TRANSFER_CONSENT_LINK: constructUrl(
          'legal/additional-resources#vin-international-transfer',
          sibling,
          locale
        ),
      },
      null,
      { returnNullWhenEmpty: true }
    ) ?? '';
  const vinInternationalTransferDisclaimer = vinInternationalTransferDisclaimerStr
    ? htmlToReact(vinInternationalTransferDisclaimerStr)
    : '';
  const FinanceDisclaimer = htmlToReact(financeDisclaimer);
  const SaveDesignAgreement = htmlToReact(i18n('SummaryPanel.disclaimers.saveDesignAgreement'));
  const reservationDisclaimer = i18n(
    'SummaryPanel.disclaimers.reservationDisclaimer',
    {
      DEPOSIT_AMOUNT: formatCurrency(depositAmount),
    },
    null,
    {
      returnNullWhenEmpty: true,
    }
  );

  const cancelingOrderDisclaimer = i18n(
    'SummaryPanel.disclaimers.cancelingOrder',
    {
      FAQ_URL: constructUrl('support/ordering?redirect=no', sibling, locale),
    },
    null,
    {
      returnNullWhenEmpty: true,
    }
  );

  const postOrderSwapDisclaimerRaw = i18n(
    'SummaryPanel.disclaimers.postOrderSwapDisclaimer',
    {
      LINK_TARGET: '_blank',
      ORDER_AGREEMENT_URL: orderAgreementUrl,
      MODEL: modelName,
    },
    null,
    {
      returnNullWhenEmpty: true,
    }
  );
  const postOrderSwapDisclaimer = postOrderSwapDisclaimerRaw
    ? htmlToReact(postOrderSwapDisclaimerRaw)
    : '';
  switch (type) {
    case PAYMENT_TERMS_SECTION:
      return (
        <Choose>
          <When condition={isEnterpriseOrder}>{EnterpriseDisclaimer}</When>
          <Otherwise>
            <p className="order-disclaimer tds-text--caption">{OrderDisclaimer}</p>
          </Otherwise>
        </Choose>
      );
    case EDIT_DESIGN_DISCLAIMER_SECTION:
      return (
        <Choose>
          <When condition={market === 'CN'}>
            <LegalConsent orderDisclaimer={OrderDisclaimer} />
          </When>
          <When condition={showLegalConsentEditDesign}>
            <LegalConsent orderAgreementUrl={orderAgreementUrl} />
          </When>
          <Otherwise>
            <p className="order-disclaimer tds-text--caption">{OrderDisclaimer}</p>;
          </Otherwise>
        </Choose>
      );
    case POST_ORDER_SWAP_SECTION:
      return (
        <Choose>
          <When condition={market === 'CN'}>
            <div className="legal-disclaimer">
              <LegalConsent orderDisclaimer={OrderDisclaimer} />
            </div>
          </When>
          <Otherwise>
            <Choose>
              <When condition={showLegalConsentPostOrderSwap}>
                <LegalConsent orderAgreementUrl={orderAgreementUrl} />
              </When>
              <Otherwise>
                <p className="order-disclaimer tds-text--caption  tds--vertical_padding">
                  {postOrderSwapDisclaimer}
                </p>
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      );
    case FINANCE_DISCLAIMER_SECTION:
      return <p className="finance-disclaimer">{FinanceDisclaimer}</p>;
    case ORDER_DISCLAIMER_SECTION:
      return (
        <div className={cx('legal-disclaimer', { 'tds-scrim--black': enableCyberpunk }, { 'legal-disclaimer--condensed': isBelow })}>
          <>
            {isSaveDesignEnabled ? (
              SaveDesignAgreement
            ) : (
              <>
                <If condition={withRefundPolicy}>
                  <RefundPolicyLink displayInline={displayInline} />
                </If>
                <If condition={cancelingOrderDisclaimer}>
                  <p>{htmlToReact(cancelingOrderDisclaimer)}</p>
                </If>
                <If condition={isCoinReloaded && showPrpLink}>
                  <div className="disclaimer--savings tds-text--caption tds--vertical_padding-bottom--1x">
                    {htmlToReact(i18n('SummaryPanel.disclaimers.prpLink'))}
                  </div>
                </If>
                <If condition={!showLegalConsent.length || showOrderDisclaimerAboveLegalConsent}>
                  <div
                    className={cx(
                      'tds-text--caption',
                      { 'modifier--tds-link-primary': !enableCyberpunk },
                      { 'tds--vertical_padding': !displayInline },
                      { 'tds-display--inline': displayInline }
                    )}
                    onClick={triggerAnalytics.bind(null, externalClicked)}
                    onKeyPress={triggerAnalytics.bind(null, externalClicked)}
                    role="presentation"
                  >
                    <If condition={rnLoaded && reservationDisclaimer !== null}>
                      <p>{reservationDisclaimer}</p>
                    </If>
                    <If condition={inventorySwapDeliveryDisclaimer !== null}>
                      <p className="tds-text--caption inventorySwapDeliveryDisclaimer-margin-bottom">{inventorySwapDeliveryDisclaimer}</p>
                    </If>
                    <ActionTrigger>{OrderDisclaimer}</ActionTrigger>
                  </div>
                </If>
                <If condition={showLegalConsent.length && !isPaymentInteractionRequired}>
                  <LegalConsent
                    orderDisclaimer={OrderDisclaimer}
                    returnPolicyDisclaimer={ReturnPolicyDisclaimer}
                    orderAgreementUrl={orderAgreementUrl}
                    vinInternationalTransferDisclaimer={vinInternationalTransferDisclaimer}
                  />
                </If>
              </>
            )}
          </>
          {/* Consumer Information Notice Section */}
          <ConsumerInformationLink />
          {/* Insurance Disclaimer Section */}
          <InsuranceDisclaimer />
          {/* Preprod Disclaimer Section */}
          <PreprodDisclaimer />
          {/* Disclaimer Link Section */}
          <If condition={!isPreOrder()}>
            <DisclaimerLink displayInline={false} />
          </If>
          {/* Traders Disclaimer Section */}
          <TradersLicenseDisclaimer />
          <PriceAcceptanceDisclaimer />
        </div>
      );
    default:
      return null;
  }
};

LegalDisclaimers.propTypes = {
  type: string,
  sibling: string.isRequired,
  locale: string.isRequired,
  ecoBonus: number.isRequired,
  modelName: string.isRequired,
  displayPaymentDisclaimer: bool.isRequired,
  orderDisclaimerSource: string,
  market: string.isRequired,
  modelCode: string.isRequired,
  isPreSale: oneOfType([bool, string]).isRequired,
  isInventory: bool,
  showLegalConsentPostOrderSwap: bool,
  oldOrderAgreementVersion: oneOfType([bool, string]),
  isOldVersionOrderAgreement: oneOfType([bool, string, shape({})]),
  useUsedInventoryDisclaimerCopy: bool,
  externalClicked: func,
  isSaveDesignEnabled: bool,
  grossPrice: oneOfType([bool, number]).isRequired,
  loanTerm: number,
  downPayment: number,
  balloonPayment: number,
  monthlyPayment: string,
  totalAmtPaid: number,
  financedAmount: string,
  interestRate: number,
  mvpaType: string,
  useModelYContentState: bool,
  disclaimerIntlDateFormat: shape({}),
  withRefundPolicy: bool,
  displayInline: bool,
  isEnterpriseOrder: bool,
  isNfaSigned: bool,
  isPaymentInteractionRequired: bool,
  showLegalConsent: arrayOf(string),
  showOrderDisclaimerAboveLegalConsent: bool,
  financeDisclaimer: string,
  enableCyberpunk: bool,
  rnLoaded: bool,
  depositAmount: number,
  showInspectionModal: bool,
  showRepairLink: bool,
  ddFile: string,
  alwaysShowOrderDisclaimerAbove: bool,
  showSelectedAccessories: bool,
  shopUrl: string,
  orderAgreementUrl: string,
  useCGSCoe: bool,
  inventorySwapDeliveryDisclaimer: string,
  bundleSubscriptionAgreement: string,
  showDriveAnywhereTerms: bool,
  isCoinReloaded: bool,
  showPrpLink: bool,
  isNewModelY: bool,
};

LegalDisclaimers.defaultProps = {
  orderDisclaimerSource: '',
  isPreSale: false,
  isInventory: false,
  showLegalConsentPostOrderSwap: false,
  oldOrderAgreementVersion: false,
  isOldVersionOrderAgreement: false,
  useUsedInventoryDisclaimerCopy: false,
  externalClicked: () => {},
  type: PAYMENT_TERMS_SECTION,
  isSaveDesignEnabled: false,
  loanTerm: null,
  balloonPayment: null,
  monthlyPayment: null,
  totalAmtPaid: null,
  financedAmount: null,
  interestRate: null,
  mvpaType: null,
  useModelYContentState: false,
  disclaimerIntlDateFormat: {},
  withRefundPolicy: false,
  displayInline: true,
  isEnterpriseOrder: false,
  isNfaSigned: false,
  showLegalConsent: [],
  showOrderDisclaimerAboveLegalConsent: false,
  financeDisclaimer: '',
  enableCyberpunk: false,
  rnLoaded: false,
  depositAmount: null,
  downPayment: 0,
  showInspectionModal: false,
  showRepairLink: false,
  ddFile: '',
  alwaysShowOrderDisclaimerAbove: false,
  showSelectedAccessories: false,
  shopUrl: '',
  orderAgreementUrl: '',
  useCGSCoe: false,
  bundleSubscriptionAgreement: '',
  showDriveAnywhereTerms: false,
  isCoinReloaded: false,
  showPrpLink: false,
  isNewModelY: false,
};

function mapStateToProps(state, ownProps) {
  const { hasBTCDisclaimer } = ownProps;
  const {
    App,
    Pricing,
    OMS,
    Payment,
    ReviewDetails,
    ApplicationFlow,
    SummaryPanel,
    FinancingOptions,
    Forms,
    CustomGroups,
  } = state;
  const {
    sibling,
    locale,
    isEnterpriseOrder,
    isNfaSigned,
    enableCyberpunk = false,
    routes,
    env,
    isPostOrderSwap,
    isCoinReloaded,
    isNewModelY,
  } = App;
  const { getDMSFiles: filePath = '', bundleSubscriptionAgreement = '' } = routes || {};
  const { oms_params } = OMS;
  const { market, model: modelCode } = oms_params;
  const { canModifyOrder } = ApplicationFlow;
  const {
    USLegalDisclaimer,
    isOldVersionOrderAgreement,
    oldOrderAgreementVersion,
    isPreSale: isPreSaleSource = {},
    isSaveDesignEnabled,
    showOrderPlacementDisclaimerCash,
    showLegalConsent = [],
    showOrderDisclaimerAboveLegalConsent = false,
    useUsedInventoryDisclaimerCopy,
    product,
    useCGSCoe,
    showLegalConsentEditDesign,
    showLegalConsentPostOrderSwap,
    isReservationOriginallyRefundable,
  } = ReviewDetails;
  const { formattedPrices = {}, loanType } = SummaryPanel;
  const { data: inventoryData = {}, isUsedInventory = false, isInventory = false } = product || {};
  const { IsDemo = false, DamageDisclosureLatest = {} } = inventoryData || {};
  const {
    financeMonthlyPayment: monthlyPayment,
    financedAmountPayment: financedAmount,
  } = formattedPrices;
  const { guid: ddLatestGuid = '' } = DamageDisclosureLatest || {};
  const { source: isPreSale = false } = isPreSaleSource;
  const modelOption = getModelCode(state);
  const model = _get(OMS, `lexicon.options.${modelOption}`, {});
  const { displayPaymentDisclaimer = false } = Payment;
  const disclaimerIntlDateFormat = _get(Forms, 'disclaimerIntlDateFormat')
    ? _get(Forms, 'disclaimerIntlDateFormat')
    : { dateStyle: 'long' };
  const { financeDisclaimerKeyPayment = '' } = FinancingOptions;
  const modelName = _get(model, 'name', '').replace(' ', '&nbsp;');

  const selectedFinanceType = getFinanceType(state);
  const orderDisclaimerCash = !!(
    selectedFinanceType === 'cash' && showOrderPlacementDisclaimerCash
  );

  const orderPageDisclaimerCopySrc = getOrderDisclaimerCopySource(state);
  // TODO: clean up disclaimers for config and reservation flows
  let orderDisclaimerSource = '';
  if (market === 'CN' && isPostOrderSwap) {
    orderDisclaimerSource = getOrderDisclaimerSource(orderPageDisclaimerCopySrc, modelCode, {
      orderDisclaimerCash,
      loanType,
      modifyOrder: true,
      hasBTCDisclaimer,
      ttpKey: 'default',
    });
  } else {
    orderDisclaimerSource = isInventory
      ? 'legalDisclaimer.default'
      : getOrderDisclaimerSource(orderPageDisclaimerCopySrc, modelCode, {
          orderDisclaimerCash,
          loanType,
          modifyOrder: canModifyOrder,
          hasBTCDisclaimer,
          ttpKey: 'default',
        });
  }

  const useModelYContentState = useModelYContent(state);

  let mvpaType =
    !useModelYContentState && !isPreSale && !isPreOrder()
      ? i18n('SummaryPanel.disclaimers.orderMVPA')
      : i18n('SummaryPanel.disclaimers.reserveMVPA');
  if (market === 'CN' && canModifyOrder && checkIfNV35Enabled(state)) {
    mvpaType = isReservationOriginallyRefundable
      ? i18n('SummaryPanel.disclaimers.reserveMVPA')
      : i18n('SummaryPanel.disclaimers.orderMVPA');
  }

  const inventorySwapDeliveryDisclaimer = isEarlyDesignSelected(state)
         ? i18n('EarlyDelivery.disclaimer', null, null, { returnNullWhenEmpty: true })
      : null;

  return {
    sibling,
    locale,
    ecoBonus: Math.abs(
      _get(Pricing, 'calculatorResult.data.apiResults.incentives.available.government.total', 0)
    ),
    modelName,
    displayPaymentDisclaimer,
    USLegalDisclaimer,
    market,
    modelCode,
    isPreSale,
    isInventory,
    isOldVersionOrderAgreement,
    useUsedInventoryDisclaimerCopy,
    oldOrderAgreementVersion,
    isSaveDesignEnabled,
    grossPrice: _get(state, 'Pricing.grossPrice', false),
    loanTerm: getLoanTerm(state),
    downPayment: getDownPayment(state, {
      context: 'payment',
    }),
    balloonPayment: _get(state, 'Pricing.calculatorResult.data.result.loan.balloonPayment'),
    monthlyPayment,
    totalAmtPaid: _get(
      state,
      'Pricing.calculatorResult.data.result.loan.regional.totalAmtPaid',
      null
    ),
    financedAmount,
    interestRate: getInterestRate(state),
    orderDisclaimerSource,
    mvpaType,
    useModelYContentState,
    disclaimerIntlDateFormat,
    isEnterpriseOrder,
    isNfaSigned,
    isPaymentInteractionRequired: _get(state, 'Payment.isPaymentInteractionRequired', false),
    showLegalConsent,
    showOrderDisclaimerAboveLegalConsent,
    financeDisclaimer: getDisclaimer(state, { disclaimerKey: financeDisclaimerKeyPayment }),
    enableCyberpunk,
    rnLoaded: getLoadedRN(),
    depositAmount: getDepositAmount(state),
    showInspectionModal: IsDemo || (isUsedInventory && !isEMEAMarket(market)),
    ddFile: ddLatestGuid ? filePath + ddLatestGuid : '',
    showRepairLink: showRepairDisclosure(state),
    showSelectedAccessories: isAccessoriesSelected(state),
    shopUrl: env?.commerce?.shopBaseUrl,
    orderAgreementUrl: getOrderAgreementUrl(state),
    useCGSCoe,
    showLegalConsentEditDesign,
    showLegalConsentPostOrderSwap,
    canModifyOrder,
    inventorySwapDeliveryDisclaimer,
    bundleSubscriptionAgreement,
    showDriveAnywhereTerms: !!(CustomGroups?.DRIVE_ANYWHERE_PACKAGE?.selected),
    isCoinReloaded,
    showPrpLink: state?.ReviewDetails?.showPrpLink || false,
    isNewModelY,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    externalClicked: props => {
      dispatch(externalLinkClicked(props));
    },
  };
}

const ConnectedLegalDisclaimers = connect(mapStateToProps, mapDispatchToProps)(LegalDisclaimers);

export const PaymentTermsLegalDisclaimers = props => (
  <ConnectedLegalDisclaimers {...props} type={PAYMENT_TERMS_SECTION} />
);
export const FinanceLegalDisclaimers = props => (
  <ConnectedLegalDisclaimers {...props} type={FINANCE_DISCLAIMER_SECTION} />
);
export const OrderLegalDisclaimers = props => (
  <ConnectedLegalDisclaimers {...props} type={ORDER_DISCLAIMER_SECTION} />
);
export const PostOrderSwapDisclaimers = props => (
  <ConnectedLegalDisclaimers {...props} type={POST_ORDER_SWAP_SECTION} />
);

export default ConnectedLegalDisclaimers;
