import React, { useEffect } from 'react';
import { arrayOf, shape, string, func, bool } from 'prop-types';
import cx from 'classnames';
import { useField } from 'informed';
import { FormLayout, FormItem, FormInputChoice } from '@tesla/design-system-react';
import { isFSD } from 'utils';
import AssetTooltip from '../../components/AssetTooltip';

const ControlledCheckbox = ({ group, config, onChange, item, useLongName }) => {
  const { code: groupCode } = group;
  const { name, longName, code, formattedPrice, selected } = item;
  const { fieldApi, formFieldRef } = useField({ name: `${groupCode}:${code}` });
  const { setValue, getValue } = fieldApi;

  let checkboxCode = isFSD(code) ? 'fsd' : 'autopilot';
  if (groupCode === 'TOWING') {
    checkboxCode = 'tow-hitch';
  }

  const customSelectOption = () => {
    setValue(!selected);
    onChange({
      value: !selected,
      optCode: code,
      // Reverse because selected value gets update after the entire store is updated
      analyticsPrefix: selected ? 'uncheck' : 'check',
      checkboxCode,
      // eslint-disable-next-line prettier/prettier
    });
  };

  useEffect(() => {
    // current selected changed dependently via agent
    const currentVal = getValue();
    const isSelected = config.includes(code);
    if (currentVal && !isSelected) {
      // select
      setValue(true);
    }
    if (!currentVal && isSelected) {
      // deselect
      setValue(false);
    }
  }, [config, code, getValue, setValue]);

  return (
    <FormLayout
      className={cx('cf-form--group', { 'cf-form-custom-padding': code === '$APPB' })}
      ref={formFieldRef}
    >
      <FormItem variant="choice">
        <FormInputChoice
          id={`MiniConfigurator:Checkbox:${groupCode}:${code}`}
          name={`${groupCode}:${code}`}
          onChange={customSelectOption}
          data-code={code}
          key={`MiniConfigurator:Checkbox:${groupCode}:${code}`}
          type="checkbox"
          checked={selected}
          label={useLongName ? (longName || name) : name}
          labelProps={{
            caption: formattedPrice,
            tooltip: (
              <AssetTooltip
                classes="tds-tooltip-info"
                data={item}
                group={group}
                showAsset={false}
              />
            ),
          }}
        />
      </FormItem>
    </FormLayout>
  );
};

ControlledCheckbox.propTypes = {
  group: shape({
    code: string,
    name: string,
  }),
  item: shape({
    code: string,
    name: string,
    formattedPrice: string,
    selected: bool,
  }),
  onChange: func.isRequired,
  config: arrayOf(string),
  useLongName: bool,
};

ControlledCheckbox.defaultProps = {
  group: {},
  item: {},
  config: [],
  useLongName: false,
};

export default ControlledCheckbox;
