import _get from 'lodash/get';
import _has from 'lodash/has';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _toNumber from 'lodash/toNumber';
import _isArray from 'lodash/isArray';
import Cookie from 'js-cookie';
import { localizeUrl } from '@tesla/parse-locale';
import { getRegionByState } from '@tesla/intl-display-names';
import {
  MODEL_OPTION,
  MODEL_FULL_NAME,
  PAYMENT_TYPE_CC,
  PAYMENT_TYPE_WT,
  INTERIOR_VIEW,
  SLICE,
  MEET,
  INVENTORY_USED,
  GROUP_CONNECTIVITY,
  AUTO_TYPE_CUSTOM,
  AUTO_TYPE_INVENTORY_NEW,
  AUTO_TYPE_INVENTORY_USED,
  CLEAN_FUEL_REWARD,
  ACCIDENT,
  PRICING_BASE,
  ERROR_INVALID_LOCATION,
  ONSITE,
  TOWING,
  PROVINCE,
  FIELD_ZIP_CODE,
  CUSTOMER_LAT_LON,
  USED,
  NEW,
  ADVOCATE_MAX_LENGTH,
  ESIGN_STATUS_IGNORE,
  ESIGN_STATUS_SENT,
  ESIGN_STATUS_SIGNED,
  ESIGN_STATUS_INIT,
  LEGACY_MODEL3_TRIM_CODE,
  NV35_TRIM_CODE,
  STEERING_WHEEL,
  STEERING_YOKE,
  Models,
  SHOW_GENERIC_MODAL_TRIGGER_MODAL,
  INVENTORY_FEATURES_MODAL,
  MODAL_SMALL,
  TYPE_INVENTORY,
  TYPE_INVENTORY_ORDER,
  TYPE_CONFIGURATOR,
  DEMO,
  REPAIRED_VEHICLE,
  MODEL_YEAR,
  GROUP_SUPER_CHARGER,
  AUTOPILOT_GROUP,
  TYPE_INVENTORY_SWAP,
  EXACT_MATCH,
  SWAP_NOT_SHOWN,
  IGNORED_SWAP,
} from 'dictionary';
const { MODEL_S, MODEL_X, MODEL_Y } = Models;
import {
  i18n,
  isEMEAMarket,
  getCleanEddData,
  getFormattedDeliveryDate,
  getFormattedModelYear,
  filterSalesRegion,
  isWeChatMini,
  isWeChatBrowser,
  getQueryParameters,
  getVatRateForForwardCalculation,
  getVehicleBadgeCopy,
} from 'utils';
import { Storage } from '@tesla/coin-common-components';
import { formatCurrency, replaceStringVars, formatMonthlyPrice } from '@tesla/coin-common-components';
import {
  calculateTransportationFee,
  getDestinationAndDocFee,
  getCleanVehicleIncentives,
  getRegionalIncentives,
  getRegionalIncentivesForBase,
  getTaxCreditCurrentAvailable,
  getTaxCreditWithRegional,
  getTaxCreditWithRegionalForBase,
  getProductTypeMapping,
  getFinanceProductId,
  getFinanceProductData,
  getMonthlyFuelSavings,
  getFinanceProductType,
} from 'selectors';
import { isGrayMarket } from '@tesla/markets-vehicle';
import { getMatchingDeltas } from '../../../../inventory-search-tool/src/common/utils/misc.js';
import { Calculator } from '@web/tesla-rest-ds-services';
import { prefetch } from 'remotion';
import { getFinancialIncentives, getInventoryOptionPricing } from './financial.js';
import { CN_INCENTIVE_PLAN_202411DISCOUNT, optionGroupToAssetMapping } from '../dictionary';
import { iconMinusFilled } from '@tesla/design-system-icons';

let videoAsset = {};
let pendingRequests = {};
export const isInventory = state => state?.ReviewDetails?.product?.isInventory || false;
export const isUsedInventory = state => state?.ReviewDetails?.product?.isUsedInventory || false;
export const isComboInventory = state => state?.ReviewDetails?.product?.isComboInventory || false;
export const getIsFalconDeliverySelectionEnabled = state => state?.App?.isFalconDeliverySelectionEnabled?.enabled || false;
export const getFalconDeliverySectionVersion = state => state?.App?.isFalconDeliverySelectionEnabled?.version || null;

export function isReservationOrder() {
  const rn = _get(window.tesla, 'rn');
  return rn && rn.length;
}

export function isReservation(state) {
  return _get(state, 'ReviewDetails.product.isReservation', false);
}

/**
 * Check if early delivery design selected
 *
 * @param state
 */
export function isEarlyDesignSelected(state) {
  const { isInventorySwapEnabled } = state?.App || {};
  const { isEarlyDesignSelected } = state?.ReviewDetails?.vehicleDesign || {};
  return isInventorySwapEnabled && isEarlyDesignSelected;
}

/**
 * Check if is Post Order swap
 *
 * @param state
 */
export function getIsPostOrderSwap(state) {
  return state?.App?.isPostOrderSwap;
}

/**
 * Return order endpoint
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export function getPostOrderUrl(state) {
  const { App } = state;
  const { routes = {} } = App;
  const { publicOrder = '', saveConfigWithPayment = '', inventoryOrder = '' } = routes;
  const basePath = 'App.env.findMyTesla.drupalEndPoint';
  if (isInventoryOrderForPreOrderSwap(state)) {
    return inventoryOrder;
  }
  if (isInventory(state)) {
    return _get(state, `${basePath}.inventoryOrder`) || _get(state, 'App.env.inventory_order');
  }
  if (isReservationOrder()) {
    return saveConfigWithPayment || _get(state, `${basePath}.configSaveWithPayment`);
  }
  if (isReservation(state)) {
    return _get(state, `${basePath}.reservationOrder`);
  }
  return publicOrder || _get(state, `${basePath}.publicOrder`);
}

/**
 * Return swap endpoint
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export function getProcessSwapUrl(state) {
  const { App } = state;
  const { routes = {} } = App;
  const { postProcesSwap } = routes;

  return postProcesSwap;
}

/**
 * Return payment sign endpoint
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export function getPaymentSignUrl(state) {
  const { App } = state;
  const { routes = {} } = App;
  const { payNowOrderApi } = routes;

  return payNowOrderApi;
}

/**
 * Returns model code
 * @param  {Object} state [redux state]
 * @return {String}       [model code (ms, mx, m3)]
 */
export function getModel(state) {
  return _get(state, 'OMS.oms_params.model');
}
/**
 * Return model code
 * @param {*} state
 */
export function getModelCode(state) {
  return MODEL_OPTION[getModel(state)];
}

/**
 * Return full model name
 * @param {*} state
 */
export function getFullModelName(state) {
  return MODEL_FULL_NAME[getModel(state)];
}

/**
 * Return trim code
 * @param {*} state
 */
export function getTrimCode(state) {
  return _get(
    state,
    'CustomGroups.TRIM.currentSelected[0].code',
    _get(
      state,
      'Configuration.user_selected_options_by_group.TRIM',
      // Inventory fallback for TrimCode passed from Falcon
      _get(
        state,
        'ReviewDetails.product.data.TrimCode',
        ''
      )
    )
  );
}

/**
 * Return all available trim codes
 * @param {*} state
 */
export function getAvailableTrimCodes(state) {
  return _reduce(_get(state, 'CustomGroups.TRIM.current', []), (res, i) => {
    const { code } = i || {};
    if (!code) {
      return res;
    }
    return [...res, code];
  }, []).join(',');
}

/**
 * Return trim option
 * @param {*} state
 */
export function getTrimOption(state) {
  const trimCode = getTrimCode(state);
  return _get(
    state,
    `OMS.lexicon.options.${trimCode}`,
    {}
  );
}

export function getSavedTrimCode(state) {
  const mktOptionCodes =
    _get(state, 'Configuration.savedConfiguration.config.mktOptionCodes') || [];
  const trimOption = mktOptionCodes?.filter(option => option.group === 'TRIM')?.[0] || {};
  return trimOption?.code;
}

/**
 * Returns the market of currently loaded Lexicon.
 * @param {Object} state
 */
export function getMarket(state) {
  return _get(state, 'OMS.oms_params.market');
}

/**
 * Returns the current locale of the configurator.
 *
 * @param state
 */
export function getLocale(state) {
  return _get(state, 'App.locale', 'en_US');
}

/**
 * Returns the title status for Inventory Vehicle.
 *
 * @param state
 */
export function getInventoryTitleState(state) {
  return _get(state, 'ReviewDetails.product.data.TitleStatus', NEW)?.toLowerCase();
}

/**
 * Returns the title model code for Inventory Vehicle.
 *
 * @param state
 */
export function getInventoryModelCode(state) {
  return _get(state, 'ReviewDetails.product.data.Model');
}

export const getInventoryBasePlusTrimPrice = (state, { overrideOptions = [] } = {}) => {
  const { OptionCodePricing: options = [], ConversionRate: conversionRate = 1 } =
    (isEarlyDesignSelected(state)
      ? state?.ReviewDetails?.vehicleDesign?.swapConfig
      : state?.ReviewDetails?.product?.data) || {};
  const vatRateForForwardCalculation = getVatRateForForwardCalculation(state) || 1;
  if (options?.length) {
    // Issue with group being returned with extra ' remove "MODEL'" when issue is fixed
    const { price: basePrice = 0 } = options?.find(x => x.group === 'MODEL' || x.group === "MODEL'") || {};
    const { price: TrimPrice = 0 } = options?.find(x => x.group === 'TRIM') || {};
    const optionPrice = overrideOptions?.length
      ? overrideOptions?.reduce((res, opt) => {
          const { price = 0 } = options?.find(x => x.code === opt) || {};
          res += price;
          return (res || 0) * conversionRate;
        }, 0)
      : 0;
    return (basePrice + TrimPrice + optionPrice || 0) * conversionRate * vatRateForForwardCalculation;
  }
};
/**
 * Returns a flag to use lexicon base group for inventory.
 *
 * @param state
 */
export function getLexiconBaseGroupForInventory(state) {
  return _get(state, 'ReviewDetails.useLexiconBaseGroupForInventory', false);
}

export function getBaseConfigurationOptions(state) {
  const {
    App: { isInventoryPriceAdjustmentsEnabled = false } = {},
    ReviewDetails: { base_group = '' } = {},
  } = state;
  let baseOpts = _get(state, `CustomGroups.${base_group}.currentSelected`, []);
  const baseGroupFromLexicon = getLexiconBaseGroupForInventory(state);
  const vatRateForForwardCalculation = getVatRateForForwardCalculation(state) || 1;
  let basePlusTrimPrice = getInventoryBasePlusTrimPrice(state);
  if (isEarlyDesignSelected(state) || (isInventoryPriceAdjustmentsEnabled && baseOpts?.length)) {
    return baseOpts?.map(config => ({
      ...config,
      basePlusTrimPrice,
      isSwap: true,
    }));
  }

  if (getIsPostOrderSwap(state)) {
    const cashPrice = _get(state, 'ReviewDetails.product.data.PurchasePrice', 0);
    return baseOpts?.map((config, idx) => {
      const swapPrice = isInventoryPriceAdjustmentsEnabled ? basePlusTrimPrice : cashPrice;
      if (idx === 0) {
        return {
          ...config,
          cash_price: swapPrice,
          formattedCashPrice: formatCurrency(swapPrice),
          formattedPrice: formatCurrency(swapPrice),
          option_price_override: null,
          price_indicator_override: null,
        };
      } else {
        return {
          ...config,
          cash_price: 0,
          formattedCashPrice: i18n('common.included'),
          formattedPrice: i18n('common.included'),
          option_price_override: null,
          price_indicator_override: null,
        };
      }
    });
  }

  if (isInventory(state)) {
    if (!baseGroupFromLexicon) {
      return [];
    }
    const { product = {} } = state?.ReviewDetails || {};
    const { data = {} } = product;
    const { OptionCodePricing: optsPricing = {} } = data;
    const mappedPricingByCode = _reduce(
      optsPricing,
      (res, opt) => {
        res[`${opt.code}`] = opt?.price;
        return res;
      },
      {}
    );
    baseOpts = baseOpts.reduce((res, opt) => {
      res.push({ ...opt, price: mappedPricingByCode[opt?.code] || 0 });
      return res;
    }, []);
  }
  return baseOpts;
}

/**
 * Return foundation series code
 * @param {*} state
 */
export function getFoundationSeriesCode(state) {
  const { selected, options = [] } = state?.CustomGroups?.FOUNDATION_SERIES || {};
  const [code] = options || '';
  return selected ? code : '';
}

/**
 * Returns configured options
 * @param  {Object} state
 * @return {Object} options
 */
export function getConfigurationOptions(state) {
  const {
    // eslint-disable-next-line no-empty-pattern
    Configuration: { option_codes = [], options_by_group: {} } = {},
    App,
    ReviewDetails,
    CustomGroups,
    Pricing: {
      agent_options_price = {},
    } = {}
  } = state;
  const {
    displayDoubleCurrency = false,
    isPostOrderSwap = false,
    isInventoryPriceAdjustmentsEnabled = false,
    useInventoryAgentOptionPrice = false,
    isCoinReloaded = false,
  } = App;
  const {
    group,
    product = {},
    showModelYearPaymentPage,
    extra_group = '',
    vehicleUpgrades = [],
    showFormattedModelYear = false,
    vehicleDesign: {
      swapConfig: { Discount: swapWithDiscount = 0, OptionCodePricing = [] },
      isEarlyDesignSelected = false,
    } = {},
    showAutopilotTrial = false,
    usePricingSummaryFromLexicons = []
  } = ReviewDetails;
  const modelCode = getModelCode(state);
  const fsCode = getFoundationSeriesCode(state);
  if (isInventory(state) && !isPostOrderSwap) {
    const baseGroupFromLexicon = getLexiconBaseGroupForInventory(state);
    const { data = {}, isNewInventory = false } = product;
    const {
      OptionCodeSpecs = {},
      TrimCode: trimCode,
      TrimName: trimName,
      Year: year,
      CurrencyCodeAlternate: currencyCodeAlternate,
      PurchasePriceMap: purchasePriceMap = {},
      OptionCodePricing: optsPricing = {},
      Discount: discount = 0,
      LexiconDefaultOptions = {},
    } = data;
    const inventoryOptsList = [
      ..._get(OptionCodeSpecs, 'C_DESIGN.options', []),
      ..._get(OptionCodeSpecs, 'C_OPTS.options', []),
    ];
    let opts = [];
    const formattedYear = getFormattedModelYear({ year, showFormattedModelYear });
    const longTrimName =
      showModelYearPaymentPage && formattedYear ? `${formattedYear} ${trimName}` : trimName;
    const purchasePrice = _get(state, 'ReviewDetails.product.data.PurchasePrice', 0);
    const inventoryOptionPricing = getInventoryOptionPricing(state);
    const mappedPricingByCode = _reduce(
      inventoryOptionPricing,
      (res, opt) => {
        res[`${opt.code}`] = opt?.price;
        return res;
      },
      {}
    );
    const vatRateForForwardCalculation = getVatRateForForwardCalculation(state) || 1;
    if (!(baseGroupFromLexicon || isInventoryPriceAdjustmentsEnabled)) {
      const price = purchasePrice;
      let trimObj = {
        code: trimCode,
        long_name: longTrimName,
        formattedPrice: formatCurrency(price * vatRateForForwardCalculation),
      };
      if (displayDoubleCurrency && currencyCodeAlternate) {
        trimObj.extraPrice = {
          amount: purchasePriceMap[currencyCodeAlternate] || 0,
          currency: currencyCodeAlternate,
        };
      }
      opts.push(trimObj);
    }

    inventoryOptsList.reduce((r, opt) => {
      let long_name = opt?.name;
      const optCode = opt?.code;
      let optPriceFormatted = '';
      if (useInventoryAgentOptionPrice) {
        const agentPrice = agent_options_price[optCode];
        optPriceFormatted = agentPrice && (baseGroupFromLexicon || isInventoryPriceAdjustmentsEnabled) ? formatCurrency(agentPrice) : i18n('common.included')
      } else {
        const optPrice = mappedPricingByCode[optCode] || 0;
        optPriceFormatted = optPrice && (baseGroupFromLexicon || isInventoryPriceAdjustmentsEnabled) ? formatCurrency(optPrice * vatRateForForwardCalculation) : i18n('common.included');
      }

      if (optCode === trimCode) {
        long_name = year ? `${year} ${long_name}` : long_name;
      }

      r.push({
        ...opt,
        long_name,
        formattedPrice: optPriceFormatted,
      });
      return r;
    }, opts);

    vehicleUpgrades.forEach(itm => {
      const { optionCode: code, optionName, formattedPrice, formattedCashPrice, uiSelected, extraPrice, upgradePrice, type } = itm || {};
      const { removed = [] } = itm?.monroney_difference || {};
      const found = inventoryOptsList.find(opt => opt.code === code);
      const optionToRemove = removed?.length ? inventoryOptsList.find(opt => removed?.includes(opt.code)) : null;
      if (option_codes.includes(code) && !found) {
        opts.push({
          code,
          name: optionName,
          long_name: optionName,
          formattedPrice: formattedCashPrice,
          extraPrice,
        });
      }
      if (found && uiSelected && upgradePrice) {
        const arrIndex = opts.findIndex(opt => opt.code === code);
        opts.splice(arrIndex, 1);
      }
      if (optionToRemove) {
        const arrIndex = opts.findIndex(opt => opt.code === optionToRemove?.code);
        opts.splice(arrIndex, 1);
      }
    });

    if (usePricingSummaryFromLexicons.length) {
      usePricingSummaryFromLexicons.reduce((r, grp) => {
        const groups = [ ...LexiconDefaultOptions, ...inventoryOptsList, ...vehicleUpgrades ];
        if (groups?.find(x => grp?.includes(x?.group || x?.lexiconGroup || x?.code))) {
          const optionGroup = CustomGroups?.[grp] || {};
          const { code: groupCode = [], hidden: { isHidden } = {}, currentSelected = [] } =
            optionGroup || {};
          const formattedPrice = i18n('common.included');
          currentSelected.forEach(option => {
            let { code, selected, long_name } = option;

            // Look in opts which is options returned and find if is already added
            const duplicateOption = opts?.find(opt => opt?.code === code)
            if (isHidden || duplicateOption) {
              return null;
            }
            if (code === modelCode || code === fsCode) {
              return isHidden
                ? null
                : r.push({
                    code: groupCode,
                    long_name: optionGroup?.long_name,
                    formattedPrice,
                  });
            }

            if (selected) {
              r.push({
                code,
                long_name,
                formattedPrice,
              });
            }
          });
        }
        return r;
      }, opts);
    }

    const hasTechPackage = _get(
      state,
      'Configuration.option_codes',
      []
    )?.includes('$FB01');

    if (!hasTechPackage && !usePricingSummaryFromLexicons?.length) {
      const inventoryConnectivity = _get(
        state,
        'ReviewDetails.product.data.OptionCodeSpecs.C_CALLOUTS.options',
        []
      ).find(opt => opt.group === GROUP_CONNECTIVITY);

      const premiumConnectivityTrialdes =    {
          "type": "long_name",
          "content": `<span class='tds-link'>${inventoryConnectivity?.name}</span>`,
          "behavior": [
              {
                  "type": 'ACTION',
                  "content": {
                      "type": SHOW_GENERIC_MODAL_TRIGGER_MODAL,
                      "componentName": INVENTORY_FEATURES_MODAL,
                      "props": {
                        genericWrapper: true,
                        size: MODAL_SMALL,
                        containerCss: 'tds-flex-item',
                        classes: 'tds-text--start'
                    },
                  }
              }
          ]
      }

      const premiumConnectivityTrial = {
        code: inventoryConnectivity?.code,
        name: inventoryConnectivity?.name,
        long_name: premiumConnectivityTrialdes,
        description: inventoryConnectivity?.description,
        formattedPrice: i18n('common.included'),
      };

      opts.push(premiumConnectivityTrial);

      if (showAutopilotTrial) {
        const { isHidden = false } = CustomGroups?.AUTOPILOT_TRIAL?.hidden || {};
        if (!isHidden) {
          const apTrialLabel = i18n('Review.autopilot_trial_label');
          opts.push({
            code: apTrialLabel,
            name: apTrialLabel,
            long_name: apTrialLabel,
            formattedPrice: i18n('common.included'),
          });
        }
      }
    }



    // Show adjustment price label when order summary not expanded
    if (baseGroupFromLexicon && discount && !isInventoryPriceAdjustmentsEnabled) {
      const discountLabel = i18n('Review.price_adjustment_label');
      opts.push({
        code: discountLabel,
        name: discountLabel,
        long_name: discountLabel,
        formattedPrice: formatCurrency(-discount * vatRateForForwardCalculation),
      });
    }

    return opts;
  }
  const extraGroup = _get(state, `OMS.lexicon.groups_dictionary.${extra_group}.groups`, []);
  const reviewGroup = _get(state, `OMS.lexicon.groups_dictionary.${group}.groups`, []);
  const configGroups = extraGroup.length ? [].concat(reviewGroup, extraGroup) : reviewGroup;
  const inventoryOptions = _get(ReviewDetails, 'product.data.OptionCodeData', []);
  const hidePriceSummaryList = _get(ReviewDetails, 'hidePriceSummaryList', []);
  const hidePriceSummaryExclusionList = _get(ReviewDetails, 'hidePriceSummaryExclusionList', []);

  const upgradeCodes = [];
  if (isPostOrderSwap) {
    const upgradedOptions = _get(state, `ReviewDetails.vehicleUpgrades`, []);
    upgradedOptions?.forEach(upgrade => {
      upgrade?.currentSelected?.forEach(option => {
        upgradeCodes?.push(option?.code);
      });
    });
  }

  const selectedOptions = [
    ...configGroups
      .reduce((map, dictionaryGroup) => {
        const optionGroup = state?.CustomGroups?.[dictionaryGroup] || {};
        const {
          code: groupCode = [],
          price_indicator_override = '',
          hidden: { isHidden } = {},
        } = optionGroup || {};
        let { currentSelected = [] } = optionGroup || {};
        if (!currentSelected?.length && inventoryOptions?.length) {
          const inventoryFallbackOption = inventoryOptions?.find(opt => opt.group === dictionaryGroup)
          if (inventoryFallbackOption) {
            const inventoryFallbackOptionPrice = inventoryFallbackOption?.price || 0;
            currentSelected = [{
              ...inventoryFallbackOption,
              formattedPrice: inventoryFallbackOptionPrice ? formatCurrency(inventoryFallbackOptionPrice) : i18n('common.included'),
            }];
          }
        }
        currentSelected.forEach(option => {
          let { formattedPrice, code } = option;
          if (
            (isHidden || hidePriceSummaryList?.includes(dictionaryGroup) || hidePriceSummaryList?.includes(code))
            && !hidePriceSummaryExclusionList?.includes(code)
          ) {
            return null;
          }
          if (code === modelCode || code === fsCode) {
            const code = `${option.code}:${groupCode}`;
            return isHidden ? null : map.set(code, {
              ...optionGroup,
              ...(price_indicator_override ? { price: 0 } : {}),
              code,
              group: dictionaryGroup,
              formattedPrice: price_indicator_override || formattedPrice,
            });
          }

          if (isPostOrderSwap && !isInventoryPriceAdjustmentsEnabled) {
            if (!upgradeCodes.includes(code)) {
              // Do not show "included" if user-selected upgrade
              formattedPrice = i18n('common.included');
            }
          }

          if (isEarlyDesignSelected && !isCoinReloaded) {
            const { price = 0 } = OptionCodePricing?.find(x => x.code === option.code) || {};
            formattedPrice = price ? formatCurrency(price) : option.price_indicator;
          }

          if (_get(option, 'selected', true)) {
            const price = agent_options_price?.[option.code];
            map.set(option.code, {
              ...option,
              group: dictionaryGroup,
              formattedPrice,
              ...(isPostOrderSwap && price ? {
                formattedPrice: formatCurrency(price),
              }: {})
            });
          }
        });
        return map;
      }, new Map())
      .values(),
  ];

  if (isEarlyDesignSelected && swapWithDiscount && !isCoinReloaded) {
    const discountLabel = i18n('Review.price_adjustment_label');
    selectedOptions.push({
      name: discountLabel,
      long_name: discountLabel,
      formattedPrice: formatCurrency(-swapWithDiscount),
    });
  }

  return selectedOptions;
}

/**
 * Determines whether to use Model Y content from the state.
 *
 * @returns boolean
 */
export const useModelYContent = state => {
  return (
    _get(state, 'FinancingOptions.useModelYContent', false) ||
    !!_get(state, 'ReviewDetails.isPreSale.source', false)
  );
};

export const gotInsuranceRecord = ({
  damageDisclosure,
  firstRegistrationDate,
  licenseStatus,
  fleetVehicle,
  AcquisitionType,
  TempRegistrationCounter,
}) => {
  return (
    damageDisclosure === false &&
    firstRegistrationDate &&
    licenseStatus === false &&
    fleetVehicle === false &&
    AcquisitionType === false &&
    TempRegistrationCounter <= 0
  );
};

export const gotTempPlateAndInsuranceRecord = ({
  damageDisclosure,
  firstRegistrationDate,
  licenseStatus,
  fleetVehicle,
  AcquisitionType,
  TempRegistrationCounter,
}) => {
  return (
    damageDisclosure === false &&
    firstRegistrationDate &&
    licenseStatus === false &&
    fleetVehicle === false &&
    AcquisitionType === false &&
    TempRegistrationCounter > 0
  );
};

export const onlyUsedAsExhibitionVehicle = ({
  damageDisclosure,
  firstRegistrationDate,
  licenseStatus,
  fleetVehicle,
  AcquisitionType,
  TempRegistrationCounter,
}) => {
  return (
    damageDisclosure === false &&
    _isEmpty(firstRegistrationDate) &&
    licenseStatus === false &&
    fleetVehicle === true &&
    AcquisitionType === false &&
    TempRegistrationCounter <= 0
  );
};

export const gotTempPlateInsuranceRecordAndExhibitionVehicle = ({
  damageDisclosure,
  firstRegistrationDate,
  licenseStatus,
  fleetVehicle,
  AcquisitionType,
  TempRegistrationCounter,
}) => {
  return (
    damageDisclosure === false &&
    firstRegistrationDate &&
    licenseStatus === false &&
    fleetVehicle === true &&
    AcquisitionType === false &&
    TempRegistrationCounter > 0
  );
};

export const gotInsuranceRecordAndExhibitionVehicle = ({
  damageDisclosure,
  firstRegistrationDate,
  licenseStatus,
  fleetVehicle,
  AcquisitionType,
  TempRegistrationCounter,
}) => {
  return (
    damageDisclosure === false &&
    firstRegistrationDate &&
    licenseStatus === false &&
    fleetVehicle === true &&
    AcquisitionType === false &&
    TempRegistrationCounter <= 0
  );
};

export const getSimplifiedEsignConditionVariable = state => {
  const damageDisclosure = _get(state, 'ReviewDetails.product.data.DamageDisclosure', null);
  const firstRegistrationDate =
    _get(state, 'ReviewDetails.product.data.FirstRegistrationDate', null) ?? false;
  const licenseStatus =
    _get(state, 'ReviewDetails.product.data.RegistrationDetails.LicenseStatus', false) ?? false;
  const fleetVehicle = _get(state, 'ReviewDetails.product.data.FleetVehicle', null);
  const AcquisitionType = _get(state, 'ReviewDetails.product.data.AcquisitionType', false) ?? false;
  const TempRegistrationCounter =
    _get(state, 'ReviewDetails.product.data.TempRegistrationCounter', 0) ?? 0;
  return {
    damageDisclosure,
    firstRegistrationDate,
    licenseStatus,
    fleetVehicle,
    AcquisitionType,
    TempRegistrationCounter,
  };
};

export const isSimplifiedEsignFlowEnabled = state => {
  const simplifiedEsignConditionVariable = getSimplifiedEsignConditionVariable(state);
  const enableSimplifiedNonBrandNewConsent = _get(
    state,
    'App.enableSimplifyNonBrandNewConsent',
    false
  );
  return (
    enableSimplifiedNonBrandNewConsent &&
    (onlyUsedAsExhibitionVehicle(simplifiedEsignConditionVariable) ||
      gotInsuranceRecord(simplifiedEsignConditionVariable) ||
      gotTempPlateAndInsuranceRecord(simplifiedEsignConditionVariable) ||
      gotTempPlateInsuranceRecordAndExhibitionVehicle(simplifiedEsignConditionVariable) ||
      gotInsuranceRecordAndExhibitionVehicle(simplifiedEsignConditionVariable))
  );
};

export const isEsignFlowEnabled = state => {
  const isNonBrandNewConsentEnabled = _get(state, 'App.isNonBrandNewConsentEnabled', false);
  const isInNonBrandNewConsentCondition = _get(state, 'App.isNonBrandNewConsentCondition', false);
  const isSimplifiedEsignFlow = isSimplifiedEsignFlowEnabled(state);
  return isNonBrandNewConsentEnabled && isInNonBrandNewConsentCondition && !isSimplifiedEsignFlow;
};
export const isAtEsignStatus = (state, status) => {
  if (
    [ESIGN_STATUS_INIT, ESIGN_STATUS_SENT, ESIGN_STATUS_SIGNED, ESIGN_STATUS_IGNORE].indexOf(
      status
    ) === -1
  ) {
    return false;
  }
  const currentStatus = _get(state, 'ApplicationFlow.esignStatus', ESIGN_STATUS_INIT);
  return isEsignFlowEnabled(state) && currentStatus === status;
};

export const isNotAtEsignStatus = (state, status) => {
  const currentStatus = _get(state, 'ApplicationFlow.esignStatus', ESIGN_STATUS_INIT);
  return isEsignFlowEnabled(state) && currentStatus !== status;
};

/**
 * used to build payload to re-send esign message
 * @param state
 */
export const getProcessEsignFlowPayload = state => {
  const rn = _get(state, 'Configuration.rn');
  const accountDetail = _get(state, 'ReviewDetails.AccountDetail');
  return {
    referenceNumber: rn,
    firstName: accountDetail?.FirstName,
    lastName: accountDetail?.LastName,
    idNumber: accountDetail?.IdentificationNumber,
    idType: accountDetail?.IdentificationType,
    phoneNumber: accountDetail?.PhoneNumber,
  };
};

export const getTrimTotal = state => {
  const { ReviewDetails, Pricing, OMS } = state;
  const { base_configuration_options: options = [] } = ReviewDetails;
  const { lexicon: { options: lexiconOpts = {} } = {} } = OMS;
  const { apiResultsPerTrim } = Pricing?.calculatorResult?.data || {};
  const baseOptions = {};
  const trimOptions = {};
  const vatRateForForwardCalculation = getVatRateForForwardCalculation(state) || 1;

  options?.forEach(option => {
    const {
      base_plus_trim_pricing_payment: paymentPriceOverride,
      base_plus_trim_pricing: trimPriceOverride,
      code,
      cash_price: price,
      formattedPrice,
      price_indicator,
      long_name: label,
      price_indicator_override: priceIndicatorOverride,
    } = option || {};
    const { basePlusTrimPrice } = apiResultsPerTrim?.[code] || {};
    const priceOverride = paymentPriceOverride || trimPriceOverride;
    const overrideOptions = priceOverride?.options || [];
    let optionPrice = formattedPrice;
    let indicatorOption;

    if (priceOverride) {
      indicatorOption = isInventory(state)
        ? getInventoryBasePlusTrimPrice(state, { overrideOptions })
        : basePlusTrimPrice;
      const { optionPriceKey, options: baseTrimOptions = [] } = priceOverride || {};
      if (optionPriceKey === PRICING_BASE) {
        indicatorOption = price * vatRateForForwardCalculation;
        baseTrimOptions?.forEach(opt => {
          const { long_name: label, price, price_indicator } = lexiconOpts?.[opt] || {};
          trimOptions[opt] = {
            label,
            value: price ? formatCurrency(price) : price_indicator,
            code: opt,
            valueRaw: price || 0,
          };
        });
      }
      optionPrice = indicatorOption ? formatCurrency(indicatorOption) : optionPrice;
    } else if (priceIndicatorOverride) {
      optionPrice = priceIndicatorOverride?.content === 0 ? price_indicator : optionPrice;
    }
    baseOptions[code] = {
      label,
      value: optionPrice,
      code,
      valueRaw: indicatorOption || price || 0,
    };
  });
  const result = { ...baseOptions, ...trimOptions } || {};
  return Object.values(result);
};

export const getCurrentCustomGroupsOptions = state => state?.CustomGroups?.OPTIONS?.currentSelected;
export const getSelectedTrim = state =>
  state?.CustomGroups?.TRIM?.currentSelected?.find(item => item);
export const getTrimDescription = state => getSelectedTrim(state)?.description;
export const getIncentives = state => {
  const currentMap = state?.Pricing?.calculatorResult?.data?.apiResults?.incentives?.current || {};

  return Object.values(currentMap)?.reduce(
    (incentives, incentive) => [...incentives, ...incentive.data],
    []
  );
};

export const getProfileDashboardUri = state => {
  const { App } = state;
  const { routes, locale, base_url: baseUrl } = App;
  const { profileDashboard = '' } = routes;
  const normalizedLocale = locale === 'en_US' || locale === 'zh_CN' ? '' : locale;
  return localizeUrl(profileDashboard, {
    baseUrl,
    locale: normalizedLocale,
    delimiter: '_',
  });
};

export const getFulfillmentUri = state => {
  const { App, Configuration = {} } = state;
  const { rn } = Configuration;
  const { routes, locale, base_url: baseUrl } = App;
  const { fulfillmentTasks } = routes || {};
  const fulfillmentTasksUri = `${fulfillmentTasks}${rn}`;
  const normalizedLocale = locale === 'en_US' || locale === 'zh_CN' ? '' : locale;
  return localizeUrl(fulfillmentTasksUri, {
    baseUrl,
    locale: normalizedLocale,
    delimiter: '_',
  });
};

export const getEnterpriseLandingUri = state => {
  const { App } = state;
  const { routes, locale } = App;
  const { enterpriseLanding = '' } = routes;
  const normalizedLocale = locale === 'en_US' || locale === 'zh_CN' ? '' : locale;
  return localizeUrl(enterpriseLanding, {
    locale: normalizedLocale,
    delimiter: '_',
  });
};

export const getEnterpriseOrderUri = state => {
  const { App } = state;
  const { routes } = App;
  const { createEnterpriseOrder = '' } = routes;
  return localizeUrl(createEnterpriseOrder, {
    locale: '',
    delimiter: '_',
  });
};

export const getPostOrderSwapSearchUri = state => {
  const { App } = state;
  const { locale, base_url: baseUrl } = App;
  const rn = _get(state, 'ReviewDetails.product.data.ReservationNumber', '');
  const normalizedLocale = locale === 'en_US' || locale === 'zh_CN' ? '' : locale;
  return localizeUrl(`/inventory/${rn}`, {
    baseUrl,
    locale: normalizedLocale,
    delimiter: '_',
  });
};

export const getShowVehicleDisclaimer = state => {
  const condition = isUsedInventory(state) ? USED : NEW;
  const config = state?.ReviewDetails?.showVehicleLocationDisclaimer || {};
  const showVehicleLocationDisclaimer = config[condition] || false;

  return showVehicleLocationDisclaimer;
};

/**
 * Get request call back uri
 *
 * @returns string
 */
export const getRequestCallbackUri = state => {
  const { App } = state;
  const { locale, base_url: baseUrl } = App;
  const vin = _get(state, 'ReviewDetails.product.data.VIN', '');
  const normalizedLocale = locale === 'en_US' ? '' : locale;
  return localizeUrl(`/inventory/callback/${vin}?redirect=no`, {
    baseUrl,
    locale: normalizedLocale,
    delimiter: '_',
  });
};

export const getAdaptedAssetsFromSource = (state, source) => {
  if (!source) return null;

  const { path, defaultParams = {} } = source;
  const sourceData = _get(state, path, {});

  if (path.includes('CompositorViews')) {
    return Object.entries(sourceData).map(([key, value]) => ({
      ...defaultParams,
      view: value,
      ratio: key === INTERIOR_VIEW ? SLICE : MEET,
      type: 'image-compositor',
    }));
  }

  if (path.includes('staticAsset')) {
    return [
      {
        ...defaultParams,
        ...sourceData,
      },
    ];
  }

  if (path.includes('VehiclePhotos')) {
    const view = state?.ReviewDetails?.product?.data?.CompositorViews?.frontView;
    const getExternalZoom = () => {
      const navigationSection = state?.Navigation?.section;
      // Zoom defined upstream
      const externalZoomConfig =
        state?.ReviewDetails?.product?.data?.CompositorViewsCustom?.externalZoom || {};

      return externalZoomConfig[navigationSection] || externalZoomConfig['order'] || 1;
    };

    const adaptedAssets = [
      // Add Compositor Image First
      {
        bkba: 2,
        ratio: 'meet',
        type: 'image-compositor',
        view,
        zoom: getExternalZoom(),
      },
      ...sourceData.map(({ imageUrl }) => ({
        type: 'bg-image',
        ...defaultParams,
        url: imageUrl,
        absolute: true,
        zoom: 1,
        ratio: 'slice',
      })),
    ];

    return adaptedAssets;
  }

  return sourceData;
};

export const getGroup = (state, groupName) => _get(state, `CustomGroups.${groupName}`, _get(state, `Assets.assetGroups.${groupName}`, {}));

export const getFilteredAsset = (state, assets = []) => {
  const { hideGroupAssets } = state.ReviewDetails || {};
  if (!hideGroupAssets) {
    return;
  }
  const { trim = [], view = [], skuVariantIncluded = [] } = hideGroupAssets || {};
  const currentTrim = getTrimCode(state);
  let isTarget = false;
  if (!_isEmpty(skuVariantIncluded)) {
    const skuTrimVariant = _get(state, `OMS.lexicon.sku.trims.${currentTrim}.variant.code`);
    isTarget = !skuVariantIncluded.includes(skuTrimVariant);
  } else if (!_isEmpty(trim)) {
    isTarget = trim?.includes(currentTrim);
  }
  return isTarget && assets?.filter(asset => !view.includes(asset?.view));
};

export const getGroupAssets = (state, groupName) => {
  const group = getGroup(state, groupName);
  const { asset: groupAsset } = group;
  const getTitle = item => _get(state, `${item?.content_path}`, '');
  if (_isEmpty(groupAsset)) {
    return [];
  }
  const adaptAsset = asset => ({
    ...asset,
    title: getTitle(asset),
    ...(asset?.toggle_label ? {
      toggle_label:  _get(state, `${asset?.toggle_label}`, ''),
    } : {}),
  });
  const asset = getFilteredAsset(state, groupAsset) || groupAsset;
  return asset?.length ? asset.map(adaptAsset) : [adaptAsset(asset)];
};

export const getIsUsedPhotosEnabled = state =>
  Boolean(state?.ReviewDetails?.product?.data?.VehiclePhotos?.length);

export const getIfHideLexiconGroup = state => {
  const rn = _get(state, 'Configuration.rn', '');
  const contextApp = _get(state, 'App.contextApp', '');
  const isCoinReloaded = _get(state, 'App.isCoinReloaded', false);
  const hideLexiconGroupAfterOrderGenerated = _get(
    state,
    'App.hideLexiconGroupAfterOrderGenerated',
    false
  );
  return (
    isCoinReloaded &&
    hideLexiconGroupAfterOrderGenerated &&
    (contextApp === 'public' || contextApp === 'inventory') &&
    !!rn
  );
};

/**
 * Get Trade in type
 *
 * @param {object} state
 * @returns string vat or margin
 */
export const getTradeInType = state => {
  const { product } = state.ReviewDetails;
  const market = _get(state, 'OMS.oms_params.market');
  const isEMEA = isEMEAMarket(market);
  const { data, isUsedInventory } = product || {};
  const { TradeInType = '' } = data || {};
  if (isEMEA && isUsedInventory && TradeInType) {
    return TradeInType.toLowerCase();
  }
  return '';
};

/**
 * Get Vehicle Object for Location.
 *
 * @param {object} state
 * @returns string object
 */
export const getVehicleMappedForLocation = state => {
  const vehicle = _get(state, 'ReviewDetails.product.data', {});
  const {
    SalesMetro,
    MetroName,
    InTransit,
    InTransitMetroName,
    FactoryGatedDate,
    IsFactoryGated,
    TitleStatus,
    City,
    StateProvinceLongName,
  } = vehicle;
  const isPickupOnlyEnabled = _get(state, 'App.isPickupOnlyEnabled', false);
  const notInTransitLocation = isPickupOnlyEnabled
    ? SalesMetro || MetroName
    : MetroName || SalesMetro;
  let inventoryVehicleLocation = InTransit ? InTransitMetroName : notInTransitLocation;
  const isEMEA = isEMEAMarket(_get(state, 'OMS.oms_params.market', ''));
  inventoryVehicleLocation = InTransit && isEMEA ? SalesMetro : inventoryVehicleLocation;
  const isFactoryGated = FactoryGatedDate || IsFactoryGated;

  return {
    location: inventoryVehicleLocation,
    isInTransit: InTransit,
    isUsed: TitleStatus === INVENTORY_USED,
    city: City,
    stateProvinceLongName: StateProvinceLongName,
    isFactoryGated,
  };
};

/**
 * Check if vehicle is on site
 * @param {object} state
 *
 * @returns bool
 */

export const isVehicleOnSite = state => {
  const { query_params } = state.App || {};
  const { product, onSiteVehicleEnabled } = state.ReviewDetails || {};
  if (!onSiteVehicleEnabled) {
    return false;
  }

  const subType = product?.data?.VehicleSubType || [];
  const onSiteSale = query_params?.onSiteSale || null;
  return !!(subType.includes(ONSITE) || JSON.parse(onSiteSale));
};

/**
 * Check if vehicle has delivery restrictions
 * @param {object} state
 *
 * @returns bool
 */

export const hasDeliveryRestrictions = state => {
  const { product, deliveryRestrictionStates } = state.ReviewDetails || {};
  const { StateProvince: vehicleStateCode } = product?.data || {};
  return deliveryRestrictionStates?.includes(vehicleStateCode);

  // TODO: This logic is now incorrect with sales metro removal
};

/**
 * Check if vehicle is pick up only
 * @param {object} state
 *
 * @returns bool
 */

export const isPickupOnlyLocation = state => {
  const { isPickupOnlyEnabled } = state?.App || {};
  return isPickupOnlyEnabled || hasDeliveryRestrictions(state);
};

/**
 * Get Delivery Locations Query params
 *
 * @param {object} state
 * @param location
 * @param formatParam
 * @returns object params
 */
export const getDeliveryLocationQueryParams = (state, location = {}, formatParam = false) => {
  const { App, ReviewDetails } = state || {};
  const { query_params = {} } = App || {};
  const {
    product,
    DeliveryDetails,
    ignoreFieldsForDeliveryLocation: ignoreField = [],
  } = ReviewDetails || {};
  const { condition, data: inventoryData, isUsedInventory} = product || {};
  const {
    CountryCode: country,
    OdometerTypeShort,
    Model: model,
    geoPoints = [],
    VrlLocks = [],
    vrlList = [],
    VIN,
    IsDemo: isDemo,
  } = inventoryData || {};
  const [geoLocation] = geoPoints[0] || '';
  const vehicleLocation = geoLocation?.split(',') || [];
  const customerStateCode = location?.stateCode || DeliveryDetails?.stateCode;
  const ignoreZip = ignoreField?.includes(FIELD_ZIP_CODE);
  const ignoreCoords = ignoreField?.includes(CUSTOMER_LAT_LON);
  const province = ignoreField?.includes(PROVINCE) ? '' : customerStateCode;
  const zipCode = ignoreZip ? '' : location?.postalCode || DeliveryDetails?.PostalCode;
  const customerLat = ignoreCoords ? null : location?.latitude || DeliveryDetails?.Latitude || 0;
  const customerLon = ignoreCoords ? null : location?.longitude || DeliveryDetails?.Longitude || 0;
  const vehicleLat = vehicleLocation[0] ?? '';
  const vehicleLon = vehicleLocation[1] ?? '';
  const vrlLockLocationIDs = VrlLocks?.length ? VrlLocks.join(',') : null;
  let range = parseInt(query_params?.range) || null;
  range = range > 200 ? null : range;

  const isFalconDeliverySelectionEnabled = getIsFalconDeliverySelectionEnabled(state);
  const falconDeliverySelectionVersion = getFalconDeliverySectionVersion(state);
  const useVehicleGeoLocation = ignoreZip && isFalconDeliverySelectionEnabled && isUsedInventory;

  return (ignoreZip || zipCode) &&
    (ignoreCoords || customerLat) &&
    (ignoreCoords || customerLon) &&
    (isFalconDeliverySelectionEnabled || vrlList?.length) &&
    country &&
    OdometerTypeShort &&
    condition
    ? {
        country,
        condition,
        isDemo,
        model,
        zipcode: zipCode,
        units: OdometerTypeShort,
        vehicleLat,
        vehicleLon,
        customerLat: useVehicleGeoLocation && vehicleLat ? vehicleLat: customerLat,
        customerLon:  useVehicleGeoLocation && vehicleLon ? vehicleLon: customerLon,
        customerProvince: customerStateCode,
        id: isFalconDeliverySelectionEnabled ? VIN : null,
        range: isFalconDeliverySelectionEnabled ? range : null,
        province,
        vrlLockLocationIDs,
        vrlList,
        version: falconDeliverySelectionVersion,
        isFalconDeliverySelectionEnabled,
      }
    : null;
};

/**
 * Get Registration restriction states list
 *
 * @param {object} state
 *
 * @returns array
 */
export const getRegistrationRestrictionStates = state => {
  const { registrationStates, product, DeliveryDetails } = state?.ReviewDetails || {};
  const { stateCode = '' } = DeliveryDetails || {};
  const { StateProvince } = product?.data || {};
  const statesList = registrationStates?.[StateProvince] || [];
  if (statesList?.length) {
    return !statesList.includes(stateCode) ? statesList : [];
  }
  return [];
};

/**
 * Check if user entered location is a valid registration state
 *
 * @param {object} state
 *
 * @returns string
 */
export const getVehicleRegistrationError = state => {
  const states = getRegistrationRestrictionStates(state) || [];
  const isPickupOnly = isPickupOnlyLocation(state);
  if (isPickupOnly && states?.length) {
    return i18n('DeliveryLocation.allowed_reg_states', { STATES: states?.join(', ') });
  }
  return null;
};

/**
 * Parse Delivery Locations data
 * @param {object} state
 * @param {object} deliveryLocations
 *
 * @returns object locations
 */
export const getFilteredDeliveryLocations = (state, deliveryLocations) => {
  const { isDeliverySelectionEnabled, showMultiplePickupOnlyLocations } = state.App || {};
  if (!isDeliverySelectionEnabled) {
    return {};
  }
  if (!deliveryLocations) {
    return {
      error: ERROR_INVALID_LOCATION,
    };
  }
  const { deliveryLocationDistanceFromCarLimit: distanceLimit, showAllDeliveryLocations } = state.ReviewDetails || {};
  const { zipcode, ...params } = getDeliveryLocationQueryParams(state) || {};
  let uniq = {};
  const {
    atLocation,
    intransit,
    CHOSEN_VEHICLE: chosenVehicle = {},
    CLOSEST_PICKUP_LOCATION_TO_VEHICLE: locationsNearToVehicle,
    CLOSEST_PICKUP_LOCATIONS_TO_CUSTOMER: locationsNearToCustomer = [],
    falconDeliveryLocations = [],
    deliveryLocationsInjection = [],
  } = deliveryLocations || {};
  let locations = [];
  if (falconDeliveryLocations?.length) {
    locations = falconDeliveryLocations;
  } else if (deliveryLocationsInjection?.length) {
    locations = deliveryLocationsInjection;
    if (isPickupOnlyLocation(state)) {
      locations = [].concat(deliveryLocationsInjection[0]) || [];
    }
  } else if (atLocation || intransit) {
    const atLocationsNearToVehicle = _get(
      atLocation,
      'CLOSEST_PICKUP_LOCATION_TO_VEHICLE[0]',
      null
    );
    if (atLocationsNearToVehicle) {
      atLocationsNearToVehicle['alternateVRLs'] = getAlternateVrls(
        atLocation?.CHOSEN_VEHICLE,
        atLocationsNearToVehicle
      );
      atLocationsNearToVehicle['atLocation'] = true;
      locations.push(atLocationsNearToVehicle);
    }
    const intransitLocationsNearToVehicle = _get(
      intransit,
      'CLOSEST_PICKUP_LOCATION_TO_VEHICLE[0]',
      null
    );
    const intransitLocationsNearToCustomer = _get(
      intransit,
      'CLOSEST_PICKUP_LOCATIONS_TO_CUSTOMER[0]',
      null
    );
    if (!atLocationsNearToVehicle && intransitLocationsNearToVehicle) {
      intransitLocationsNearToVehicle['alternateVRLs'] = getAlternateVrls(
        intransit?.CHOSEN_VEHICLE,
        intransitLocationsNearToVehicle
      );
      intransitLocationsNearToVehicle['atLocation'] = false;
      locations.push(intransitLocationsNearToVehicle);
    } else if (
      intransitLocationsNearToCustomer &&
      atLocationsNearToVehicle?.distance_from_customer >
        intransitLocationsNearToCustomer?.distance_from_customer
    ) {
      intransitLocationsNearToCustomer['alternateVRLs'] = getAlternateVrls(
        intransit?.CHOSEN_VEHICLE,
        intransitLocationsNearToCustomer
      );
      intransitLocationsNearToCustomer['atLocation'] = false;
      locations.push(intransitLocationsNearToCustomer);
    }
  } else {
    const locationNearestToVehicle = locationsNearToVehicle
      ? locationsNearToVehicle[0] || locationsNearToVehicle
      : {};
    const { distance_from_car } = locationNearestToVehicle || {};
    const atLocation =
      chosenVehicle?.isAtLocation ?? !_get(state, 'ReviewDetails.product.data.InTransit');
    let alternateVRLs = null;

    locations = [].concat(locationNearestToVehicle, locationsNearToCustomer);
    if (isPickupOnlyLocation(state)) {
      locations = [].concat(locationNearestToVehicle);
      alternateVRLs = showMultiplePickupOnlyLocations
        ? getAlternateVrls(chosenVehicle, locationNearestToVehicle)
        : null;

      const locationSecondClosestToVehicle = _get(locationsNearToVehicle, '[1]', null);
      // Show second option if selected vehicle has different vrl from the locationNearestToVehicle (non customer facing)
      if (
        showMultiplePickupOnlyLocations &&
        alternateVRLs &&
        locationSecondClosestToVehicle &&
        locationSecondClosestToVehicle?.distance_from_car <= 50
      ) {
        locations.push(locationSecondClosestToVehicle);
      }
    } else if (distanceLimit && distance_from_car > distanceLimit) {
      locations = [].concat(locationsNearToCustomer);
    }
    locations = locations?.map(loc => {
      return {
        ...loc,
        atLocation,
        alternateVRLs,
      };
    });
  }

  locations = locations?.filter(({ trt_id: id } = {}) => id && !uniq[id] && (uniq[id] = true));
  if (!showAllDeliveryLocations) {
    locations = locations?.slice(0, 2);
  }

  const filteredLocations = locations?.map(loc => {
    const { trt_id, service_id, latitude, longitude } = loc || {};
    return {
      ...loc,
      transportFee: calculateTransportationFee(state, loc),
      id: `${trt_id}:${service_id}`,
      locationDetails: {
        latitude: latitude ? Number(latitude) : 0,
        longitude: longitude ? Number(longitude) : 0,
      },
    };
  });

  return {
    filtered: filteredLocations,
    selected: filteredLocations.find(x => x) || {},
    available: deliveryLocations,
    params,
    registrationZipCode: zipcode,
    registrationError: getVehicleRegistrationError(state, deliveryLocations),
  };
};

export const getAlternateVrls = (chosenVehicle, deliveryLocation) => {
  return chosenVehicle?.vrl !== deliveryLocation?.trt_id ? [chosenVehicle?.vrl] : null;
};

export const isEnterpriseOrder = state => _get(state, 'App.isEnterpriseOrder', false);

export const isEnterprisePurchaseOrder = state =>
  _get(state, 'App.isEnterprisePurchaseOrder', false);

export const getSavedConfiguration = state => _get(state, 'Configuration.savedConfiguration', null);

export const getBasePlusTrimPrice = state => {
  const { CustomGroups, Pricing } = state;
  const selectedTrim = _get(CustomGroups, 'TRIM.currentSelected[0].code', '');
  const { apiResultsPerTrim } = Pricing?.calculatorResult?.data || {};
  const { basePlusTrimPrice } = apiResultsPerTrim?.[selectedTrim] || {};
  return basePlusTrimPrice || 0;
};

export const getRN = state => _get(state, 'Configuration.rn', null);

export function getSelectedOptions({ state, exclude = [] }) {
  const lexiconOptions = _get(state, 'OMS.lexicon.options');
  const customGroups = _get(state, 'CustomGroups', {});
  const baseConfigOptions = _get(state, 'ReviewDetails.base_configuration_options', []);
  const configOptions = _get(state, 'ReviewDetails.configuration_options', []);
  const excludeOpts = exclude.reduce((res, opt) => {
    const opts = _get(customGroups, `${opt}.options`, []);
    return [...res, ...opts];
  }, []);
  const options = isEarlyDesignSelected(state) ? _get(state, 'ReviewDetails.vehicleDesign.initialDesign.options', '') : '';
  const configOptionsFiltered = excludeOpts.length
    ? configOptions.filter(opt => !excludeOpts.includes(opt?.code) && !opt?.code?.includes(':'))
    : configOptions;
  let optionCodes = baseConfigOptions
    .map(opt => opt.code)
    .concat(configOptionsFiltered.map(opt => opt.code));
  optionCodes = options ? options.split(',') : optionCodes?.filter(x => x);

  const optionCodeNames = optionCodes.reduce((r, optCode) => {
    if (lexiconOptions[optCode]) {
      r.push(lexiconOptions[optCode].long_name || lexiconOptions[optCode].name);
    }
    return r;
  }, []);
  return { optionCodes, optionCodeNames };
}

/**
 * Grab current model name from OMS state.
 *
 * @param state
 * @returns {String}
 */
export const getModelName = state => {
  const ModelCode = _get(state, 'OMS.oms_params.model');
  return i18n(`Models.${ModelCode}.short_name`);
};

export const getGAAutoType = state => {
  const isInventory = _get(state, 'ReviewDetails.product.isInventory') || false;
  const isUsedInventoryVehicle = isUsedInventory(state);
  let autoType = AUTO_TYPE_CUSTOM;

  if (isUsedInventoryVehicle) {
    autoType = AUTO_TYPE_INVENTORY_USED;
  } else if (isInventory) {
    autoType = AUTO_TYPE_INVENTORY_NEW;
  }

  return autoType;
};

/**
 * Get multi flex payload details
 *
 * @param {object} state
 * @returns object payload
 */
export const getMultiFlexDetails = state => {
  const { inventoryUpgrades, mutationIds = [], vehicleUpgradesInDefaultCurrency = [], vehicleUpgrades = [] } =
    state.ReviewDetails || {};
  const { option_codes = [] } = state.Configuration || {};
  const hasConditionalPricing = !!inventoryUpgrades?.CONDITIONAL_PRICING?.length
  const upgrades = hasConditionalPricing ? vehicleUpgrades : vehicleUpgradesInDefaultCurrency;

  let monroneyObj = {},
    totalPrice = 0;
  if (mutationIds?.length) {
    let totalRemoved = [];
    const totalMonroney = [];
    mutationIds.map(elm => {
      const { monroney = [], monroney_difference: monDiff = {} } =
        upgrades.find(({ id }) => elm === id) || {};
      const { removed = [] } = monDiff || {};
      totalMonroney.push(monroney);
      totalRemoved = [...totalRemoved, ...removed];
    });
    totalMonroney.map(monroney => {
      monroney.map(itm => {
        const { code, price } = itm || {};
        if (
          (!totalRemoved?.includes(code) || option_codes?.includes(code)) &&
          ((monroneyObj[code] && price) || !monroneyObj[code])
        ) {
          monroneyObj[code] = itm;
        }
      });
    });
    const monroney = Object.values(monroneyObj);
    monroney.forEach(x => {
      totalPrice += x.price;
    });
    return {
      mutationIds,
      monroney,
      totalPrice,
    };
  }
  return null;
};

/**
 * Get Delivery Region Disclaimer for AU, NZ, AE
 *
 * @param {object} state
 * @returns string
 */
export const getDeliveryRegionDisclaimer = state => {
  const { App, ReviewDetails } = state;
  const { showDeliveryRegionDisclaimer, isDeliverySelectionEnabled } = App;
  const { showDeliverySelectionLocation } = ReviewDetails;
  const { fleetSalesRegionsArray, deliveryRegion } = filterSalesRegion();

  let vehicleRegion = _get(ReviewDetails, 'product.data.VehicleRegion');
  if (!isDeliverySelectionEnabled && showDeliveryRegionDisclaimer && deliveryRegion) {
    return i18n('Inventory.deliveryRegionDisclaimer', {
      LOCATION: fleetSalesRegionsArray?.join(', '),
    });
  } else if (isDeliverySelectionEnabled && showDeliverySelectionLocation) {
    // temp hack used for used inventory in AU
    // we should remove this when we move all delivery selection to falcon
    vehicleRegion = vehicleRegion === 'QLD' ? 'NT, QLD' : vehicleRegion;
    return i18n('Inventory.deliveryRegionDisclaimer', {
      LOCATION: vehicleRegion,
    });
  }

  return null;
};

/**
 * Get acknowledgement documents
 *
 * @param {object} state
 * @returns object payload
 */
export const getAcknowledgementDocuments = state => {
  const { ReviewDetails } = state;
  const { userViewedDisclosures = {}, product } = ReviewDetails;
  const { DamageDisclosureGuids = [] } = product?.data || {};
  if (DamageDisclosureGuids.length) {
    return DamageDisclosureGuids.map(id => {
      return (
        userViewedDisclosures[id] || {
          Guid: id,
          FileType: '',
          FileName: '',
          Viewed: false,
        }
      );
    });
  }
};

/**
 * Show Repair Disclosure Link
 *
 * @param state
 * @returns {Boolean}
 */
export const showRepairDisclosure = state => {
  const { product } = state?.ReviewDetails || {};
  const { data: inventoryData = {}, isUsedInventory = false, isInventory = false } = product || {};
  const { VehicleHistory = '', DamageDisclosureLatest, CountryCode } = inventoryData || {};
  const { guid = '' } = DamageDisclosureLatest || {};
  const isEMEA = isEMEAMarket(CountryCode);
  const repairedHistory = !!(VehicleHistory && VehicleHistory.toUpperCase() === ACCIDENT);
  return !!(guid && (isUsedInventory ? repairedHistory && isEMEA : isInventory));
};

export function getCurrentDeliveryRegion(state) {
  const countryCode = _get(state, 'App.countryCode');
  const language = _get(state, 'App.language', '');
  const deliveryStateAddress = _get(state, 'DeliveryTiming.deliverystateAddress');
  const { region_code } = state.SummaryPanel;
  if (countryCode === 'NZ') {
    return region_code;
  }
  const geoLocationRegionCode = _get(state, 'Location.sources.geoIp.region.regionCode');
  const stateCode = deliveryStateAddress
    ? deliveryStateAddress.deliveryStateCode
    : geoLocationRegionCode;
  return _get(getRegionByState(countryCode, region_code || stateCode, language), 'region', '');
}

export function getInventoryDeliveryTime(state, props = {}) {
  const { distance: inputDistance } = props || {};
  const distance =
    inputDistance ||
    _get(state, 'ReviewDetails.DeliveryLocations.selected.transportFee.distance', null);
  const isTX =
    _get(state, 'ReviewDetails.DeliveryLocations.selected.province') === 'TX' ? true : false;
  const inventoryDeliveryEstimate = isTX
    ? _get(state, 'ReviewDetails.inventoryDeliveryEstimateTX', null)
    : _get(state, 'ReviewDetails.inventoryDeliveryEstimate', null);
  const isRefurbComplete = _get(state, 'ReviewDetails.isRefurbComplete');
  const isUsedInventoryVehicle = isUsedInventory(state);
  const useRefurbETA = isUsedInventoryVehicle && !isRefurbComplete;

  if (isVehicleOnSite(state) && !distance) {
    let deliveryDate = '';
    if (isTX) {
      deliveryDate = useRefurbETA ? `3 – 4 weeks` : '2 weeks';
    } else {
      deliveryDate = useRefurbETA ? `2 weeks` : 'Available Now';
    }
    return {
      delivery_date_name: i18n('DeliveryDate.estimatedDelivery', {
        DELIVERY_DATE: deliveryDate,
      }),
    };
  }
  if (distance !== null && inventoryDeliveryEstimate) {
    const estimate = _find(inventoryDeliveryEstimate, o => distance >= o.min && distance <= o.max);
    if (estimate) {
      const value = useRefurbETA ? estimate.refurbValue : estimate.value;
      const weeks = value === '1' ? 'week' : 'weeks';
      const deliveryDate = `${value} ${i18n(`common.${weeks}`)}`;
      return {
        delivery_date_name: i18n('DeliveryDate.estimatedDelivery', {
          DELIVERY_DATE: deliveryDate,
        }),
      };
    }
  }
  return false;
}

export function getDeliveryTime(state, props) {
  const { showDeliveryDateInfo, isEnterpriseOrderPickup } = state.App || {};
  const isInventory = _get(state, 'ReviewDetails.product.isInventory');
  if (isEnterpriseOrderPickup) {
    let pickupCopy = '';
    let pickupContactCopy = '';

    const priceAdjustmentDisclaimer = i18n('DeliveryLocation.delivery_disclaimer');

    ['7dayPickup', '7dayRegister', '10dayRegister'].forEach(itm => {
      const pickupDisclaimer = i18n(`Enterprise.deliveryDisclaimer.${itm}.title`, null, null, {
        returnNullWhenEmpty: true,
      });
      const pickupContactDisclaimer = i18n(`Enterprise.deliveryDisclaimer.${itm}.body`, null, null, {
        returnNullWhenEmpty: true,
      });
      if (pickupDisclaimer) {
        pickupCopy = pickupDisclaimer;
      }
      if (pickupContactDisclaimer) {
        pickupContactCopy = `${pickupContactDisclaimer} ${priceAdjustmentDisclaimer}`;
      }
    });

    if (!pickupCopy && !pickupContactCopy) {
      ['pre-delivery_disclaimer', 'disclaimer', 'registration_disclaimer'].forEach(itm => {
        const pickupDisclaimer = i18n(`DeliveryPickup.${itm}.delivery_pickup`, null, null, {
          returnNullWhenEmpty: true,
        });
        const pickupContactDisclaimer = i18n(`DeliveryPickup.${itm}.delivery_contact`, null, null, {
          returnNullWhenEmpty: true,
        });
        if (pickupDisclaimer) {
          pickupCopy = pickupDisclaimer;
        }
        if (pickupContactDisclaimer) {
          pickupContactCopy = `${pickupContactDisclaimer} ${priceAdjustmentDisclaimer}`;
        }
      });
    }

    return {
      delivery_date_name: pickupCopy,
      delivery_contact_disclaimer: pickupContactCopy,
    };
  }
  if (!showDeliveryDateInfo) {
    return false;
  }

  if (isInventory) {
    return getInventoryDeliveryTime(state, props);
  }

  const selectedOptionGroup = _get(state, 'CustomGroups');
  const deliveryGroup = _get(selectedOptionGroup, 'DELIVERY_TIMING');
  const deliveryGroupContent =
    deliveryGroup && deliveryGroup.extra_content ? deliveryGroup.extra_content : false;
  const deliveryTimingContent = deliveryGroupContent
    ? _get(deliveryGroupContent[0], 'type')
    : false;
  const countryCode = _get(state, 'App.countryCode');
  const geoLocationcountryCode = _get(state, 'Location.sources.geoIp.countryCode');
  const regionName = getCurrentDeliveryRegion(state);
  const showDeliveryMatrix = _get(state, 'App.showDeliveryMatrix');
  let content_path = null;
  if (deliveryTimingContent === 'delivery_time') {
    return _reduce(
      deliveryGroupContent,
      (res, groupContent) => {
        let result = res;
        result = false;
        _forEach(groupContent.content, content => {
          if (content.selected) {
            if (_has(content, 'delivery_date_path')) {
              content_path = content.delivery_date_path;
              result = {
                delivery_date_name: _get(state, `${content_path}`, ''),
                delivery_description: _get(
                  content,
                  'delivery_description',
                  i18n('DeliveryDate.deliveryDescription')
                ),
              };
              return false;
            }
            const dateVal = _get(content, 'delivery_date', '');
            if (countryCode === geoLocationcountryCode && showDeliveryMatrix) {
              const regionVal = _get(
                _find(content.regions, ['region', regionName]),
                'delivery_date',
                ''
              );
              const dateName = regionVal
                ? i18n('DeliveryDate.estimatedDelivery', { DELIVERY_DATE: regionVal })
                : dateVal;
              result = {
                delivery_date_name: dateName,
                delivery_description: _get(
                  content,
                  'delivery_description',
                  i18n('DeliveryDate.deliveryDescription')
                ),
                delivery_date_by_region: regionVal,
              };
              return false;
            }
            result = {
              delivery_date_by_region: null,
              delivery_date_name: dateVal,
            };
            return false;
          }
          return true;
        });
        return result;
      },
      false
    );
  }
  return false;
}

export function getDeviceType(state) {
  let type = 'desktop';
  const market = _get(state, 'OMS.oms_params.market');

  if (_get(state, 'App.isLayoutTablet', false)) {
    type = 'tablet';
  } else if (_get(state, 'App.isDm', false)) {
    type = 'mobile_app';
  } else if (_get(state, 'App.isLayoutMobile', false)) {
    type = 'mobile';
  }

  if (market === 'CN') {
    if (type !== 'mobile_app') {
      if (isWeChatMini()) {
        type = 'wechat_miniprogram';
      } else if (isWeChatBrowser()) {
        type = 'wechat_browser';
      } else if (_get(state, 'App.isLayoutMobile', false)) {
        type = 'mobile_browser';
      }
    }
  }

  return type;
}

/**
 * Get Advocate from URL param
 * @returns {string}
 */
export function getAdvocate() {
  const queryParameters = getQueryParameters();
  const { advocate } = queryParameters || {};
  if (advocate?.trim()?.length > 0 && advocate?.trim()?.length < ADVOCATE_MAX_LENGTH) {
    Cookie.set('advocate', advocate, { expires: 1 });
    return advocate;
  } else {
    return '';
  }
}

/**
 * Finding if configuration qualifies for referral or not
 *
 * @param   {Object} state
 * @return  {boolean}
 */
export function referralConfigValidation(state) {
  const appData = _get(state, 'ApplicationFlow');
  const referralData = _get(appData, 'referral');
  return !_isEmpty(referralData) && _get(referralData, 'isValid');
}

export function getLocaleInfo(state) {
  const { market = 'US', language = 'en' } = _get(state, 'OMS.oms_params');
  return {
    market,
    language,
    locale: market !== 'US' ? `${language}_${market.toUpperCase()}` : 'en_US',
  };
}

export function getEstimateDeliveryDate(state, { optionCodes = [] } = {}) {
  const { App } = state;
  const { locale, countryCode, isLocationSelectorEnabled, showDeliveryMatrix } = App;
  const model = _get(state, 'OMS.oms_params.model', '');
  const options = optionCodes?.length ? optionCodes : getSelectedOptions({ state }).optionCodes;
  const geoLocationCountryCode = _get(state, 'Location.sources.geoIp.countryCode');
  const regionName = getCurrentDeliveryRegion(state);
  const cleanEddData = getCleanEddData();
  let formattedDate = '';
  let deliveryData;
  if (cleanEddData) {
    if (
      (isLocationSelectorEnabled || countryCode === geoLocationCountryCode) &&
      showDeliveryMatrix
    ) {
      deliveryData = cleanEddData.find(
        obj =>
          obj.countryCode === countryCode &&
          obj.model === model &&
          options.some(opt => obj.options.includes(opt)) &&
          regionName &&
          obj.region &&
          regionName.toLowerCase().includes(obj.region.toLowerCase())
      );
    } else {
      deliveryData = cleanEddData.find(
        obj =>
          obj.countryCode === countryCode &&
          obj.model === model &&
          options.some(opt => obj.options.includes(opt))
      );
    }
    const eddForOptions =
      deliveryData && deliveryData.children
        ? deliveryData.children.reduce((acc, child) => {
            const hasOptionsMatch =
              child.options &&
              child.options.every(opt => options && options.length && options.includes(opt.trim()));

            const updatedChild = {
              ...child,
              hasOptionsMatch,
            };

            if (!acc) {
              return updatedChild;
            }
            if (!acc.hasOptionsMatch) {
              return updatedChild;
            }
            if (hasOptionsMatch) {
              if (acc.options.length === child.options.length) {
                return acc.offset > child.offset ? acc : updatedChild;
              }
              return acc.options.length > child.options.length ? acc : updatedChild;
            }
            return acc;
          }, null)
        : null;
    formattedDate =
      eddForOptions && eddForOptions.hasOptionsMatch
        ? getFormattedDeliveryDate(eddForOptions, locale, options)
        : getFormattedDeliveryDate(deliveryData, locale, options);
    const deliveryDate =
      !formattedDate || formattedDate?.delivery_date_name.length === 0
        ? null
        : {
            delivery_date_by_region: formattedDate.delivery_date_name,
            delivery_date_name: i18n('DeliveryDate.estimatedDelivery', {
              DELIVERY_DATE: !_isEmpty(formattedDate?.deliveryWindowDisplayByLocale)
                ? formattedDate?.deliveryWindowDisplayByLocale
                : formattedDate.delivery_date_name,
            }),
            deliveryWindowStart: formattedDate.deliveryWindowStart,
            deliveryWindowEnd: formattedDate.deliveryWindowEnd,
            deliveryWindowDisplay: formattedDate.deliveryWindowDisplay,
          };

    if (deliveryDate) {
      return deliveryDate;
    }
  }
  return getDeliveryTime(state);
}

export function getDeliveryDateByTrim(state) {
  const countryCode = _get(state, 'App.countryCode');
  const locale = _get(state, 'App.locale');
  const model = _get(state, 'OMS.oms_params.model', '');
  const showDeliveryMatrix = _get(state, 'App.showDeliveryMatrix', false);
  const cleanEddData = getCleanEddData();

  const res = [];
  if (cleanEddData.length && showDeliveryMatrix) {
    cleanEddData.forEach(edd => {
      if (edd.countryCode === countryCode && edd.model === model) {
        res.push({
          option: edd.options,
          delivery_date: getFormattedDeliveryDate(edd, locale),
          region: edd.region ? edd.region : null,
        });
      }
    });
    return res;
  }
  return _get(state, 'CustomGroups.DELIVERY_TIMING.extra_content[0]', []);
}

export function getFuelReward(state) {
  const incentives = state.Financial.incentives.current;
  if (!incentives) {
    return null;
  }
  const { region_code = '' } = state.SummaryPanel;
  const { stateList = [] } = state.Address;
  const beforeDelivery = _get(incentives, 'beforeDelivery', []);
  const fuelReward = beforeDelivery.filter(x => x.key === CLEAN_FUEL_REWARD);
  const amount = fuelReward.length ? fuelReward[0].amount : 0;
  const region = stateList.filter(x => x.value === region_code);
  const regionName = region.length ? region[0].label : '';
  return {
    amount,
    title: i18n('Incentives.clean_fuel_reward.title', { STATE: regionName }),
    formattedAmt: formatCurrency(-amount),
    formattedAmtWithMinus: formatCurrency(amount),
    regionName,
  };
}

/**
 * Check if vehicle has in state delivery restrictions
 * @param {object} state
 *
 * @returns bool
 */

export const hasInStateDeliveryRestrictions = state => {
  const { DeliveryLocations, inStateDeliveryRestrictions } = state.ReviewDetails || {};
  const { province } = DeliveryLocations?.selected || {};
  return inStateDeliveryRestrictions?.includes(province);
};

/**
 * Returns fee line items for Summary Panel
 * @param {object} state
 *
 * @returns array
 */

export const getFeeLineItems = state => {
  const destinationAndDocFee = getDestinationAndDocFee(state);
  const { hideDestinationDocFee, showVehiclePricePlusFees, showVehicleSubtotal } =
    state?.ReviewDetails || {};
  const { showFeesSummary, showRegistrationTax, showRegionalTax } = state?.FinancingOptions || {};
  const showdestinationAndDocFee = !!_get(
    state,
    'SummaryPanel.formattedPrices.destinationAndDocFee'
  );
  const { recyclingFee, tireFee, winterTireFee } = _get(state, 'SummaryPanel.formattedPrices', {});
  const pricingVariables = _get(state, 'Pricing.calculatorResult.data.variables', {});
  const specialTax = _get(pricingVariables, 'fees.special_tax.total', 0);
  const registrationTax = _get(pricingVariables, 'fees.registration.total', 0);
  const regionalTax = _get(pricingVariables, 'fees.regional.total', 0);
  const governmentGrant = _get(pricingVariables, 'incentives.government_grant.total', 0);
  const weightTax = _get(pricingVariables, 'fees.weight_tax.total', 0);
  const showDnDFee =
    (!hideDestinationDocFee && showdestinationAndDocFee && !showVehiclePricePlusFees) ||
    (showFeesSummary && destinationAndDocFee);
  return [
    {
      show: showVehicleSubtotal && governmentGrant,
      label: i18n('SummaryPanel.disclaimers.governmentGrant'),
      value: formatCurrency(governmentGrant),
      key: 'incentives.government_grant',
    },
    {
      show: showDnDFee,
      label: i18n('SummaryPanel.destination_fee'),
      value: formatCurrency(destinationAndDocFee),
      key: 'fees.reg_and_doc_fee',
    },
    {
      show: recyclingFee,
      label: i18n('SummaryPanel.disclaimers.recyclingFee'),
      value: recyclingFee,
      key: 'fees.recycling_fee',
    },
    {
      show: tireFee,
      label: i18n('SummaryPanel.disclaimers.tireFee'),
      value: tireFee,
      key: 'fees.tire_fee',
    },
    {
      show: winterTireFee,
      label: i18n('SummaryPanel.disclaimers.winterTireFee'),
      value: winterTireFee,
      key: 'fees.winter_tire_fee',
    },
    {
      show: specialTax,
      label: i18n('SummaryPanel.disclaimers.specialTax'),
      value: formatCurrency(specialTax),
      key: 'fees.special_tax',
    },
    {
      show: showRegistrationTax && registrationTax,
      label: i18n('SummaryPanel.disclaimers.registrationTax'),
      value: formatCurrency(registrationTax),
      key: 'fees.registration',
    },
    {
      show: !showVehicleSubtotal && governmentGrant,
      label: i18n('SummaryPanel.disclaimers.governmentGrant'),
      value: formatCurrency(governmentGrant),
      key: 'incentives.government_grant',
    },
    {
      show: showRegionalTax && regionalTax > 0,
      label: i18n('SummaryPanel.disclaimers.regionalTax'),
      value: formatCurrency(regionalTax),
      key: 'fees.regional',
    },
    {
      show: weightTax,
      label: i18n('SummaryPanel.disclaimers.weightTax'),
      value: formatCurrency(weightTax, { useDynamicRounding: true }),
      key: 'fees.weight_tax',
    },
  ];
};

export const isShowPriceIndicator = (state, option, groupCode) => {
  if (option?.conditional_pricing_indicator?.isModified) {
    return true;
  }
  const preservedPricingGroups = state?.Configuration?.preservedPricingGroups || [];
  const notifyMigratePricing = state?.ReviewDetails?.migratePricing;
  const savedTrimCode = getSavedTrimCode(state);
  const isLegacyToNV35Flow =
    LEGACY_MODEL3_TRIM_CODE.indexOf(savedTrimCode) !== -1 && checkIfNV35Enabled(state);
  const isLoading = state?.Loader?.active || false;
  const shouldShowPriceIndicator =
    preservedPricingGroups.includes(groupCode) &&
    notifyMigratePricing &&
    !option.selected &&
    !isLegacyToNV35Flow;
  const cookieInfo = state?.Configuration?.pricingChangeConfirmed || [];
  const [cookieRn] = cookieInfo;
  const currentRn = state?.Configuration?.rn;
  return shouldShowPriceIndicator && (!cookieRn || (isLoading && cookieRn === currentRn));
};

export const isShowPriceIndicatorOnDisabled = (option) => {
  if (option?.display_pricing_indicator?.isEnabled) {
    return true;
  }
}

/**
 * Get address provinces
 *
 * @param {object} state
 * @returns {array}
 */
export const getProvincesList = state => {
  const selectList = [{ label: i18n('Review.select_province_label'), value: '' }];
  const { availableProvincesForAddressVerification: provinces } = state.ReviewDetails;
  if (provinces) {
    const data = Object.keys(provinces).map(itm => {
      const { id, name, displayName } = provinces[itm] || {};
      return {
        label: displayName,
        value: name,
        id,
      };
    });
    return selectList.concat(data);
  }
  return [];
};

/**
 * Get districts
 *
 * @param {object} state
 * @returns {array}
 */
export const getDistrictsList = state => {
  const selectList = [{ label: i18n('Review.select_district_label'), value: '' }];
  const { availableDistrictsForAddressVerification: districts = [] } = state.ReviewDetails || {};
  if (districts?.length) {
    const data = districts.map(itm => {
      const { id, name, displayName } = itm || {};
      return {
        label: displayName,
        value: name,
        id,
      };
    });
    return selectList.concat(data);
  }
  return [];
};

/**
 * Get postal codes
 *
 * @param {object} state
 * @returns {array}
 */
export const getPostalCodesList = state => {
  const selectList = [{ label: i18n('Review.select_postal_code_label'), value: '' }];
  const { availablePostalCodesForAddressVerification: postalCodes = [] } =
    state.ReviewDetails || {};
  if (postalCodes?.length) {
    const data = postalCodes.map(itm => {
      const { id, name } = itm || {};
      return {
        label: name,
        value: id,
      };
    });
    return selectList.concat(data);
  }
  return [];
};

/**
 * Get earlier vehicle designs
 *
 * @param {object} state
 * @returns {array}
 */
export const getEarlyVehicleDesigns = state => {
  const incentiveTotal = state?.Financial?.incentives?.total || {};
  const { vehicleDesign, mktOptionGroups, inventorySwapGroupsOrder = [], showOdometer = false, hideVehicleTypeLabel = false } = state.ReviewDetails || {};
  const { userSelectedIncentives } = state.Financial || {};
  const { initialDesign: { options = '', specs = {} } = {}, earlyVehicleDesigns, initialConfigByGroup = {}} = vehicleDesign || {};
  const towOption = _get(state, 'CustomGroups.TOWING.options[0]', '');
  const productType = getFinanceProductType(state);
  const { total: initialDesignTotal } = state.Pricing || {};
  const { options: lexiconOpts = {} } = state.OMS?.lexicon || {};
  const { latestYear } = state?.OMS?.lexicon || {};

  if (!earlyVehicleDesigns?.length) {
    return [];
  }
  const currentYear = new Date().getFullYear();

  return earlyVehicleDesigns.reduce((result, config) => {
    const {
      OptionCodeData = [],
      OptionCodeList = '',
      PurchasePrice = 0,
      IsAtLocation,
      VrlCustomName,
      Public_Hash: hash,
      IsFederalCreditEligible,
      Discount = 0,
      Price = 0,
      FederalIncentives: {
        TaxIncentiveAmount = 0
      } = {},
      Year,
      IsDemo,
      DamageDisclosure,
      TrimName,
      Odometer,
      CountryCode,
    } = config || {};
    let sortedConfig = [];
    let specsObj = {};
    const { fuel = 0, once = 0 } = incentiveTotal || {};
    const calcResults = getFinanceResults(state, { config });
    const { finplat = {} } = calcResults || {};
    const { term, monthlyPayment } = finplat || {};
    const monthlyFuel = getMonthlyFuelSavings(state);
    const includeTaxCredit = productType !== 'lease' && (!userSelectedIncentives || userSelectedIncentives?.includes('taxCredit'));
    const taxCredit =  includeTaxCredit ? TaxIncentiveAmount : 0;
    const incentivesMonthly = term ? Math.round((once + taxCredit) / term) || 0 : 0;
    const monthlySavings = monthlyFuel +  incentivesMonthly;
    const monthlyPaymentAfterSavings = (monthlyPayment + monthlySavings) || 0;
    const discount = Discount || 0;
    const trimFamilyGroup = _get(config, 'TRIM[0]');
    const modelBadge = getVehicleBadgeCopy({
      latestYear,
      trimFamilyGroup,
      currentYear,
      showNewVehicle: false,
      hideVehicleTypeLabel,
      vehicleYear: Year,
      isDemo: IsDemo,
      hasDamageDisclosure: DamageDisclosure,
      isUsedInventory: false,
      showOdometer,
      odometer: Odometer,
      countryCode: CountryCode,
    });
    const configListFull = [];
    const configList = [];
    const flippedData = Object.fromEntries(
      OptionCodeData?.map((val) => [val?.group, val])
    );
    inventorySwapGroupsOrder?.forEach(data => {
      if (_has(flippedData, data)) {
        const configObj = flippedData[data] || {};
        const { code, group } = configObj;
        let icon = null;
        const familyGroup = _get(config, `${group}[0]`);
        const iconAsset = mapAssetToOptionCode(state, code, group, familyGroup);
        if (iconAsset) {
          icon = { ...iconAsset, width: '24px', height: '24px' };
        }
        configListFull.push({
          ...configObj,
          icon,
        });
        if (!options.includes(code)) {
          configList.push(configObj);
        }
      }
    });
    Object.keys(specs)?.forEach(specGroup => {
      const configObj = flippedData[specGroup] || {};
      const { value: specVal, unit_short: unitShort, acceleration_value: accelVal, acceleration_unit_short: accelUnitShort } = configObj || {};
      if (_toNumber(specs[specGroup]) !== _toNumber(specVal)) {
        specsObj[specGroup] = `${specVal} ${unitShort}`
      }
    });

    if (options?.includes(towOption) && !OptionCodeList?.includes(towOption)) {
      configList.push({
        code: towOption,
        name: i18n('EarlyDelivery.no_tow_hitch'),
        group: TOWING,
      });
    }

    mktOptionGroups?.forEach(grp => {
      const data = configList?.find(x => x?.group === grp);
      const initialDesignOpt = initialConfigByGroup?.[grp]?.[0];
      const lexiconOpt = lexiconOpts?.[initialDesignOpt] || {};
      const lexiconOptName = (lexiconOpt?.long_name || lexiconOpt?.name)?.replace(/[^a-zA-Z0-9]/g,'');
      const swapOptName = (data?.long_name || data?.name)?.replace(/[^a-zA-Z0-9]/g,'');

      if (data && (lexiconOptName != swapOptName)) {
        sortedConfig.push(data);
      }
    });

    if (discount) {
      const adjustmentLabel = i18n('Inventory.common.priceAdjustment', { AMOUNT: formatCurrency(-discount) });
      sortedConfig.unshift({
        name: adjustmentLabel,
        long_name: adjustmentLabel,
        code: 'DISCOUNT',
      });
    }

    const PriceAfterProbableSavings =  Price + fuel + once + TaxIncentiveAmount;
    const modelName = getModelName(state);

    if (hash) {
      result.push({
        optionCodes: OptionCodeList || '',
        PurchasePrice,
        configList: sortedConfig,
        deliveryDetails: {
          status: IsAtLocation ? VrlCustomName : '',
          availablility: i18n('EarlyDelivery.available_now'),
        },
        isAlternateDesign: true,
        id: hash,
        showTaxCreditNotEligible: _has(incentiveTotal, 'taxCredit') && !IsFederalCreditEligible,
        Discount,
        TotalPrice: Math.abs(Price + discount),
        Price,
        PriceAfterProbableSavings,
        TotalPriceAfterProbableSavings: PriceAfterProbableSavings + discount,
        finplat: {
          ...finplat,
          monthlySavings,
          monthlyPayment: monthlyPayment ? formatMonthlyPrice(monthlyPayment) : 0,
          monthlyPaymentAfterSavings: monthlyPaymentAfterSavings ? formatMonthlyPrice(monthlyPaymentAfterSavings > 0 ? monthlyPaymentAfterSavings : 0) : 0,
        },
        priceDifference: (Price - initialDesignTotal) || 0,
        modelBadge,
        configListFull,
        specs: specsObj,
        TrimName: new String(TrimName).replace(`${modelName} `, ''),
      });
    }
    return result;
  }, []);
};

/**
 * Get Trim Price
 */
export const getTrimPrice = state => {
  const trimCode = getTrimCode(state);
  return (
    state?.Pricing?.calculatorResult?.data?.apiResultsPerTrim?.[trimCode]?.basePlusTrimPrice || 0
  );
};

/**
 * Get selected region code.
 *
 * @param {object} state
 * @returns {string}
 */
export const getRegionCode = state => _get(state, 'SummaryPanel.region_code', null);

/**
 * Get regional disclaimer.
 *
 * @param {object} state
 * @returns {string}
 */
export const getRegionalDisclaimer = state =>
  i18n(`Review.regional_disclaimer.${getRegionCode(state)}`, null, null, {
    returnNullWhenEmpty: true,
  });

/**
 * Get banner source group.
 *
 * @param {object} state
 * @returns {string}
 */
export const getBannerSourceGroup = state => _get(state, 'CustomGroups.FEDERAL_TAX_CREDIT', null);

/**
 * Has regional banner modal copy.
 *
 * @param {object} state
 * @returns {bool}
 */
export const hasRegionalBannerModalCopy = state => {
  const { bannerContentParsed = [] } = state?.Banner || {};
  if (bannerContentParsed?.length) {
    return !!(bannerContentParsed?.filter(x => x?.type == 'regional')?.length);
  }
  // Check if regional banner copy exists in lexicon
  const bannerSource = getBannerSourceGroup(state);
  return !!_get(
    bannerSource,
    `federalTax.banner_description_regional_${getRegionCode(state)}`,
    _get(bannerSource, 'federalTax.banner_description_regional')
  );
};

/**
 * Has regional banner copy.
 *
 * @param {object} state
 * @returns {bool}
 */
export const hasRegionalBannerCopy = state => {
  // Check if regional banner copy exists in lexicon
  const bannerSource = getBannerSourceGroup(state);
  return !!_get(bannerSource, 'federalTax.description_regional');
};

/**
 * Get regional conditions copy.
 *
 * @param {object} state
 * @returns {mixed}
 */
export const getRegionalConditionsCopy = state =>
  i18n(`Review.regional_conditions.${getRegionCode(state)}`, null, null, {
    returnNullWhenEmpty: true,
  });

/**
 * Get banner content by type.
 *
 * @param {object} data, type
 * @returns {object|string}
 */
const getBannerContentByType = ({
  state,
  data,
  type,
  contentSource,
  regionCode,
  baseOnly = false,
}) => {
  const isInventoryOrder = isInventory(state);
  const isEnterprise = isEnterpriseOrder(state);
  let content = '';
  if (baseOnly) {
    content = isInventoryOrder ? _get(data, `${type}_inventory`, '') : '';
    content = isEnterprise ? _get(data, `${type}_enterprise`, '') : '';
    return content || _get(data, `${type}`);
  }
  if (contentSource) {
    return _get(
      data,
      `${type}_${contentSource}_${regionCode}`,
      _get(data, `${type}_${contentSource}`, '')
    );
  }
  let regionCodeSource = regionCode;
  const hasBannerWithConditions = state?.App?.hasBannerWithConditions || null;
  if (hasBannerWithConditions) {
    switch (hasBannerWithConditions) {
      case 'regional':
        const regionalIncentives = getRegionalIncentives(state);
        if (!Math.abs(regionalIncentives)) {
          regionCodeSource = '';
        }
        break;
      case 'cleanVehicle':
        const cleanVehicleIncentive = getCleanVehicleIncentives(state);
        if (!cleanVehicleIncentive) {
          return null;
        }
        break;
      default:
    }
  }
  const regionalType = `${type}_regional_${regionCodeSource}`;
  const contentBase = data?.[regionalType] || data?.[type] || '';
  content = isInventoryOrder ? _get(data, `${type}_inventory`, contentBase) : contentBase;
  content = isEnterprise ? _get(data, `${type}_enterprise`, content) : content;
  return replaceStringVars(content ?? '', {
    LOCALE: isGrayMarket(state?.App?.countryCode) ? 'en_EU' : getLocale(state),
  });
};

/**
 * Get banner content parts.
 *
 * @param {object} state
 * @returns {object}
 */
export const getBannerContent = (state, props = {}) => {
  const content = getBannerSourceGroup(state);
  const { federalTax = {} } = content || {};
  const { type } = federalTax || {};
  const commonProps = { state, data: federalTax, ...props };
  return {
    description: getBannerContentByType({ ...commonProps, type: 'description' }),
    title: getBannerContentByType({ ...commonProps, type: 'title' }),
    subtitle: getBannerContentByType({ ...commonProps, type: 'subtitle' }),
    link: getBannerContentByType({ ...commonProps, type: 'link' }),
    type: getBannerContentByType({ ...commonProps, type: 'type' }) || 'coin-general--info',
  };
};

/**
 * Get banner modal content parts.
 *
 * @param {object} state
 * @returns {Array}
 */
export const getBannerModalContent = (state, props = {}) => {
  const { bannerContentParsed = [] } = state.Banner || {};
  const contentType = (props?.contentSource || '').split(':');
  if (bannerContentParsed?.length) {
    return contentType
      ? bannerContentParsed.filter(x => contentType.includes(x?.type))
      : bannerContentParsed.filter(x => !x?.type);
  }
  const content = getBannerSourceGroup(state);
  const { federalTax = {} } = content || {};
  const commonProps = { state, data: federalTax, ...props };
  return [
    {
      modalContent: getBannerContentByType({ ...commonProps, type: 'banner_description' }),
      modalTitle: getBannerContentByType({ ...commonProps, type: 'banner_title' }),
      isLexiconContent: true,
    },
  ];
};

/**
 * Get flex options payload details
 *
 * @param {object} state
 * @returns object payload
 */
export const getFlexOptions = state => {
  const { isEarlyDesignSelected, swapConfig: { FlexibleOptionsData = [], OptionCodeList, OptionCodeData } = {} } =
    state.ReviewDetails?.vehicleDesign || {};
  const {
    Configuration: {
      option_codes
    } = {},
    App: {
      isCoinReloaded
    } = {},
  } = state || {};
  const set = [];
  const unset = [];

  if (isEarlyDesignSelected) {
    FlexibleOptionsData?.forEach(itm => {
      const { code } = itm || {};
      if ((!isCoinReloaded && OptionCodeList?.includes(code)) || (isCoinReloaded && option_codes?.includes(code))) {
        set.push({ ...itm, action: 'set' });
      } else {
        code && unset.push({ ...itm, action: 'unset' });
      }
    });

    // Since Core does not send Winter Wheel we need to check for it and add it to existing set or unset arrays
    const winterWheelOption = OptionCodeData?.find(opt => opt?.group === 'WINTER_WHEELS');
    if (winterWheelOption) {
      set?.length ? set.push({...winterWheelOption, action: 'set'}) : unset.push({...winterWheelOption, action: 'set'});
    }
    return set?.length ? set : unset;
  }

  return [];
};

/**
 * Get inital swap config
 *
 * @param {object} state
 * @returns string initial config in swap flow
 */
export const getSwapInitialOptionsList = state => {
  const { isEarlyDesignSelected, initialDesign: { options = '' } = {} } =
    state.ReviewDetails?.vehicleDesign || {};
  return isEarlyDesignSelected ? options : '';
};

/**
 * Get swap options list
 *
 * @param {object} state
 * @returns string swap options list
 */
export const getSwapOptionsList = state => {
  const { isEarlyDesignSelected, swapConfig: { OptionCodeList = '' } = {} } =
    state.ReviewDetails?.vehicleDesign || {};
  return isEarlyDesignSelected ? OptionCodeList : '';
};

/**
 * Get region name
 *
 * @param {object} state
 * @returns string name
 */
export const getRegionName = state => {
  const { Location, ReviewDetails, App } = state;
  const { geoIp } = Location?.sources || {};
  const { longName } = geoIp?.region || {};
  const { stateProvince, countryCode } = ReviewDetails?.DeliveryDetails || {};
  const country = countryCode || geoIp?.countryCode || '';
  return country !== App?.countryCode ? '' : stateProvince || longName;
};

/**
 * Get custom vehicle designs
 *
 * @param {object} state
 * @returns {array}
 */
export const getCustomVehicleDesign = state => {
  const {
    TRIM: { current: trimOptions },
  } = state.CustomGroups || {};
  const { lexicon: { options: optionCodeData = {} } = {} } = state?.OMS || {};
  const { vehicleDesign, mktOptionGroups } = state.ReviewDetails || {};
  const { apiResultsPerTrim } = state?.Pricing?.calculatorResult?.data || {};
  const { initialConfigByGroup, initialDesign } = vehicleDesign || {};
  const { options, price, grossPrice } = initialDesign || {};
  if (!options) {
    return {};
  }
  const { showAmountIncludingFees } = state?.FinancingOptions || {};
  const { fuel = 0 } = state?.Financial?.incentives?.total || {};
  let configList = [];

  mktOptionGroups.forEach(grp => {
    const optionCode = _get(initialConfigByGroup, `${grp}[0]`, '');
    const option = _get(optionCodeData, `${optionCode}`);
    if (option) {
      configList.push(option);
    }
  });
  const { name, code: trimCode } = trimOptions?.find(x => options?.includes(x?.code)) || {};
  const { incentives } = apiResultsPerTrim?.[trimCode] || {};
  const incentiveTotal = incentives?.total || {};
  const { taxCredit = 0, once = 0 } = incentiveTotal || {};
  const Price = showAmountIncludingFees ? grossPrice : price;
  const { finplat = {} } = getFinanceResults(state, { config: { Price, TrimCode: trimCode }}) || {};
  const { monthlyPayment, term } = finplat || {};
  const monthlyFuel = getMonthlyFuelSavings(state);
  const incentivesMonthly = term ? Math.round((once + taxCredit) / term) || 0 : 0;
  const monthlySavings = monthlyFuel +  incentivesMonthly;
  const monthlyPaymentAfterSavings = (monthlyPayment + monthlySavings) || 0;

  return {
    configList,
    trim: name,
    optionCodes: options,
    Price,
    showTaxCreditNotEligible: _has(incentiveTotal, 'taxCredit') && !incentives?.total?.taxCredit,
    PriceAfterProbableSavings: Price + taxCredit + fuel + once || 0,
    finplat: {
      ...finplat,
      monthlySavings,
      monthlyPayment: monthlyPayment ? formatMonthlyPrice(monthlyPayment) : 0,
      monthlyPaymentAfterSavings: monthlyPaymentAfterSavings ? formatMonthlyPrice(monthlyPaymentAfterSavings > 0 ? monthlyPaymentAfterSavings : 0) : 0,
    }
  };
};

export const getEnterpriseSearchUri = state => {
  const { App } = state;
  const { routes, locale, countryCode } = App;
  const { enterpriseSearch = '' } = routes;
  const normalizedLocale = locale === 'en_US' || locale === 'zh_CN' ? '' : locale;
  const data = Storage.get(`t4b.${countryCode}.Inventory_Filters.options`) || [];
  const params =
    data?.map(({ code, values }) => (values?.length ? `${code}=${values?.join(',')}` : null)) || [];
  const query = params?.filter(x => x)?.join('&');
  const url = localizeUrl(enterpriseSearch, {
    locale: normalizedLocale,
    delimiter: '_',
  });
  return `${url}?${query}`;
};

export const getSelectedCompoundLocation = state => {
  const { ReviewDetails } = state;
  const { DeliveryLocations = {} } = ReviewDetails;
  const { selected = {} } = DeliveryLocations || {};
  return selected || {};
};

export const getOrderDisclaimerCopySource = state => {
  return state?.ReviewDetails?.orderPageDisclaimerCopySrc || '';
};

export const checkIfNV35Enabled = state => {
  const {
    OMS: { lexicon },
  } = state;
  const market = _get(lexicon, 'market');
  if (market !== 'CN') {
    return false;
  }
  const trimInfo = lexicon?.groups.filter(({ code }) => code === 'TRIM')?.[0];
  const trimOptions = trimInfo?.options || [];
  return (
    trimOptions.filter(trimCode => NV35_TRIM_CODE.indexOf(trimCode) !== -1).length ===
    NV35_TRIM_CODE.length
  );
};

export const getDeliveryContactDetails = state => {
  const {
    firstName,
    lastName,
    email: emailAddress,
    phoneNumber: { country, number } = {},
    isDriver,
  } = state?.ReviewDetails?.DeliveryContact || {};

  return {
    firstName,
    lastName,
    emailAddress,
    phoneNumber: number,
    phoneCountry: country,
    isDriver,
  };
};

/**
 * Get selected vehicle upgrades with the format:
 * [
 *   {
 *     "group": "WINTER_WHEELS" // upgrade code,
 *     "operations": [
 *       {
 *         "action": "unset", // set or unset
 *         "option": "$WWY00" // corresponding option codes
 *       }
 *     ]
 *   }
 * ]
 *
 * @param {object} state
 * @returns {array} array of formatted upgrades or null if none selected
 */
export const getPostOrderSwapUpgradesPayload = state => {
  const upgrades = state.ReviewDetails.vehicleUpgrades;
  const upgradeOptions = upgrades?.reduce((selectedUpgrades, upgrade) => {
    if (upgrade.currentSelected?.length) {
      const operations = [];
      upgrade.currentSelected?.forEach(selectedUpgrade => {
        operations.push({
          action: 'set',
          option: selectedUpgrade.code,
        });
      });
      const group = upgrade.monroney?.find(option => option.code === upgrade.optionCode)?.group;
      if (operations.length) {
        selectedUpgrades.push({
          group,
          operations,
        });
      }
    }
    return selectedUpgrades;
  }, []);
  return upgradeOptions.length ? upgradeOptions : null;
};

/**
 * Gets currently selected vehicle upgrades
 *
 * @param {object} state
 * @returns {array} array of currentSelected vehicle upgrades, or null if none selected
 */
export const getSelectedUpgrades = state => {
  const selectedUpgrades = [];
  state?.ReviewDetails?.vehicleUpgrades?.forEach(upgrade => {
    const currentSelected = upgrade?.currentSelected;
    if (currentSelected?.length) {
      selectedUpgrades.push(...currentSelected);
    }
  });
  return selectedUpgrades?.length ? selectedUpgrades : null;
};

/**
 * Return if selected upgrade has conditional pricing
 *
 * @param {object} state
 * @returns {array} array of currentSelected vehicle upgrades, or null if none selected
 */
export const getUpgradesHaveConditionalPricing = state => {
  const selectedUpgrades = getSelectedUpgrades(state);
  const { inventoryUpgrades } = state?.ReviewDetails || {};
  return selectedUpgrades?.length
    ? selectedUpgrades?.reduce((res, upgrade) => res || (upgrade?.price !== inventoryUpgrades?.[upgrade?.category]?.upgradePrice), false)
    : false;
}

/**
 * Return if it is a vehicle with discounts
 *
 * @param {object} state
 * @returns boolean Has discounts
 */
export const vehicleHasDiscounts = () => {
  const { product } = window?.tesla || {};
  const { data: inventoryData, isUsedInventory } = product || {};
  const { Discount: discountAmount } = inventoryData || {};
  return !isUsedInventory && !!discountAmount;
};

/**
 * Return tmp remapped stateProvince to use in Core payload for deliveryStateProvince.
 *
 * @param {object} state
 * @returns {object} remapped delivery details
 */
export const getTmpDeliveryDetailsNormalized = state => {
  const { ReviewDetails: reviewDetails = {}, App } = state;
  const { DeliveryDetails: deliveryDetails = {} } = reviewDetails || {};
  const { stateCode, StateProvince: stateProvince } = deliveryDetails || {};
  return {
    ...deliveryDetails,
    stateProvince: stateCode || stateProvince,
    StateProvince: App?.countryCode === 'AE' ? stateProvince || stateCode : stateCode || stateProvince,
  };
};

export const getPluginStyle = state => {
  const { App: { enableCyberpunk } = {} } = state;

  let theme = 'tds-9';

  if (enableCyberpunk) {
    theme = 'tds-9-replicant';
  }

  return theme;
};

export const getUserRegionCode = state => {
  const { region_code } = state?.SummaryPanel || {};
  const ipRegionCode = _get(state, 'Location.components.summaryPanel.regionCode');
  const geoLocationRegionCode = _get(state, 'Location.sources.geoIp.region.regionCode');
  return ipRegionCode || region_code || geoLocationRegionCode;
};

export const getUserRegionName = state => {
  return state?.ReviewDetails?.DeliveryDetails?.stateProvince || '';
};

/**
 * Get dynamic banner content with parsed vars.
 *
 * @param {object} state
 * @returns {array} parsed dynamic banner content.
 */
export const getDynamicBannerContent = state => {
  const { bannerContent = [] } = state?.Banner || {};
  if (bannerContent?.length) {
    const region = getUserRegionCode(state);
    const { condition = 'new', isInventory: isInventoryFlag = false } = state?.ReviewDetails?.product || {};
    const { model_code: modelCode, options_by_group: optionsByGroup = {} } = state?.Configuration || {};
    const trimCode = _get(optionsByGroup, 'TRIM[0]', '');
    const isInventoryVehicle = isInventory(state);
    const falconTaxEligible = isInventoryVehicle && state?.ReviewDetails?.product?.data?.FederalIncentives?.IsTaxIncentiveEligible;
    const regionalAmount = isInventoryVehicle ? getRegionalIncentives(state) : getRegionalIncentivesForBase(state);
    const deltas = getMatchingDeltas(bannerContent, {
      region: regionalAmount ? region : '',
      regionCode: regionalAmount ? region : '',
      regionNoIncentive: region,
      condition,
      context: isInventoryFlag ? TYPE_INVENTORY_ORDER : TYPE_CONFIGURATOR,
      model: modelCode,
      trimCode,
    });
    if (_isEmpty(deltas)) {
      return deltas;
    }
    const regionName = getUserRegionName(state);
    const taxCredit = Math.abs(getTaxCreditCurrentAvailable(state));
    const regionalCredit = isInventoryVehicle ? getTaxCreditWithRegional(state) :  getTaxCreditWithRegionalForBase(state);
    const replacementParams = {
      TAX_CREDIT_AMOUNT: formatCurrency(taxCredit),
      TAX_REGIONAL_INCENTIVE_AMOUNT: regionalCredit ? formatCurrency(Math.abs(regionalCredit)) : 0,
      REGIONAL_INCENTIVE_AMOUNT: regionalAmount ? formatCurrency(Math.abs(regionalAmount)) : 0,
      REGION_NAME:regionName || region,
      LOCALE: isGrayMarket(state?.App?.countryCode) ? 'en_EU' : getLocale(state),
    };
    const replaceKeys = ['title', 'subtitle', 'description', 'modalTitle', 'modalContent'];
    const parsedResult = _reduce(
      deltas,
      (r, val) => {
        const { type, dynamicEligibility, federalTaxIneligible } = val || {};
        // federalTaxIneligible is to display regional incentives when a vehicle is not eligible for tax
        let taxEligibility =  taxCredit;
        if (!taxCredit && isUsedInventory(state)) {
          taxEligibility = falconTaxEligible;
        };
        if (dynamicEligibility && type === 'taxCredit' && ((!taxEligibility && !federalTaxIneligible) || (taxEligibility && federalTaxIneligible))) {
          return r;
        }
        const replacedVal = _reduce(
          val,
          (rv, v, k) => {
            return {
              ...rv,
              [k]: !replaceKeys.includes(k) ? v : replaceStringVars(v, replacementParams),
            };
          },
          {}
        );
        r.push(replacedVal);
        return r;
      },
      []
    );
    return parsedResult;
  }
  return [];
};

export const getInventorySteeringWheelDisclaimer = state => {
  const { Configuration, ReviewDetails, App, OMS } = state;
  const { countryCode, isEnterpriseOrder } = App;
  const { oms_params: omsParams } = OMS || {};
  const { model } = omsParams || {};
  if (isEnterpriseOrder || !isEMEAMarket(countryCode) || ![MODEL_S, MODEL_X].includes(model)) {
    return {};
  }
  const { product = {} } = ReviewDetails || {};
  const { isInventory, isUsedInventory, data = {} } = product || {};
  if (!isInventory || isUsedInventory) {
    return {};
  }
  const { STEERING_WHEEL: steeringGroupOpts = [] } = data || {};
  if (!steeringGroupOpts.includes(STEERING_YOKE)) {
    return {};
  }
  const disclaimerCopy = i18n(
    `Inventory.vehicleSteeringControlDisclaimer__${STEERING_YOKE}`,
    null,
    null,
    {
      returnNullWhenEmpty: true,
    }
  );
  if (!disclaimerCopy) {
    return '';
  }
  const { options_by_group: optionsByGroup = {} } = Configuration || {};
  const optCodes = optionsByGroup[STEERING_WHEEL] || [];
  return {
    copy: disclaimerCopy,
    optCodes,
  }
};

export const getBatterySpecs = state => {
  return state?.CustomGroups?.TRIM?.currentSelected?.[0]?.lexicon_specs || {};
};

export const getLegalDisclaimerForRequestCallback = state => {
  const { App } = state;
  const { countryCode, locale, base_url: baseUrl } = App;
  const normalizedLocale = locale === 'en_US' ? '' : locale;
  const copySource = `Inventory.common.requestCallback_agreement_${isEMEAMarket(countryCode) ? 'enhances' : 'base'}`;
  const consentCopy = i18n(
    `${copySource}.agreement`,
    {
      LINK_UPDATES: localizeUrl('/legal/tesla-updates', {
        baseUrl,
        locale: normalizedLocale,
        delimiter: '_',
      }),
      LINK_UNSUBSCRIBE: localizeUrl('/communication-preferences', {
        baseUrl,
        locale: normalizedLocale,
        delimiter: '_',
      }),
      LINK_CUSTOMER_PRIVACY_NOTICE: localizeUrl('/legal/privacy', {
        baseUrl,
        locale: normalizedLocale,
        delimiter: '_',
      }),
    },
    null,
    {
      returnNullWhenEmpty: true,
    }
  );
  return {
    disclaimer: consentCopy || i18n('Inventory.common.requestCallback_agreement', {
      LINK_CUSTOMER_PRIVACY_NOTICE: localizeUrl('/legal/privacy', {
        baseUrl,
        locale: normalizedLocale,
        delimiter: '_',
      }),
    }, null, { returnNullWhenEmpty: true }),
    checkbox: i18n(
      `${copySource}.checkbox`,
      {
        LINK_DATA_TRANSFER_POLICY: localizeUrl('/legal/additional-resources#turkey-data-transfer', {
          baseUrl,
          locale: normalizedLocale,
          delimiter: '_',
        }),
      },
      null,
      {
        returnNullWhenEmpty: true,
      }
    ),
  };
};

export const getFinanceResults = (state, props) => {
  const { config = {} } = props || {};
  const { region_code: regionCode } = state?.SummaryPanel || {};
  const { fms_incentives: Incentives, fms_fees: Fees, userSelectedIncentives } = state.Financial || {};
  const { lexicon: Lexicon = {}, oms_params = {} } = state?.OMS || {};
  const { market } = oms_params;
  const { finplatUserInputs: inputs } = state.Forms || {};
  const financeProductId = getFinanceProductId(state);
  const savedUserInputs = inputs?.[`${financeProductId}`] || {};
  const userInputFlags = inputs?.flags || {};
  const regState = regionCode ? { registrationState: regionCode } : {};

  return Calculator.getCalculatorResultsForInventorySwap({ Incentives, Fees, Lexicon }, {
    financeType: 'finplat',
    financeProductType: getProductTypeMapping(state),
    vehicle: config,
    market,
    financeProductData: getFinanceProductData(state),
    userInput: {
      ...userInputFlags,
      ...savedUserInputs,
      ...regState,
    },
    userSelectedIncentives
  });
}

export const preloadVideo = videoSrc => {
  if (!videoSrc) {
    return;
  }

  const blobUrl = videoAsset?.[videoSrc];
  const isFetched = pendingRequests?.[videoSrc];

  if (blobUrl) {
    return blobUrl;
  } else if (!isFetched) {
    pendingRequests[videoSrc] = true;
    const { waitUntilDone } = prefetch(videoSrc);
    waitUntilDone().then((url, err) => {
      if (err) {
        delete pendingRequests?.[videoSrc];
      }
      if (url) {
        videoAsset[videoSrc] = url;
        return url;
      }
    })
    return videoAsset?.[videoSrc];
  }
}

export const preloadAsset = (state, { group, id = '' } = {}) => {
  const { activeGroupId } = state?.Navigation || {};
  const { code, preload } = group || {};
  const groupCode = id || code;
  if (activeGroupId !== groupCode) {
    return;
  }
  const { type, content_path = '' } = preload || {};
  const asset = _get(state, content_path, null);
  if (!asset) {
    return;
  }
  const { videoBaseURL, videoBaseURLMobile } = state?.Assets || {};
  const isLayoutMobile = state?.App?.isLayoutMobile || state?.App?.isLayoutTablet;

  switch (type) {
    case 'video':
      const { url, urlDesktop, relative, video_opts, video_optsDesktop  } = asset || {};
      const mediaURL = isLayoutMobile ? url : (urlDesktop || url);
      let videoBaseMobile = videoBaseURLMobile;
      let videoBaseDesktop = videoBaseURL;
      if (video_opts && videoBaseURLMobile) {
        videoBaseMobile = videoBaseURLMobile.replace('f_auto:video,q_auto:best', video_opts);
      }
      if (video_optsDesktop && videoBaseURL) {
        videoBaseDesktop = videoBaseURL.replace('f_auto:video,q_auto:best', video_optsDesktop);
      }
      const videoBase = isLayoutMobile ? videoBaseMobile : videoBaseDesktop;
      const urlPath = relative ? mediaURL : `${videoBase}${mediaURL}`;
      return preloadVideo(urlPath);
    default:
      return;
  }
};

export const getVideoSource = url => {
  return videoAsset?.[url];
}

export const getSubscriptions = state => {
  const { Subscription = {}, Configuration = {} } = state || {};
  const hasInitialSubscription = !!(Configuration?.savedConfiguration?.subscriptionDetails || []).length;
  const subscription = Object.values(Subscription) || [];
  return !subscription.length && !hasInitialSubscription ? null : subscription;
};

export const isMetShowIncentiveCondition = state => {
  const market = getMarket(state);
  if (market !== 'CN') {
    return false;
  }
  const isPostOrderSwap = _get(state, 'App.isPostOrderSwap', false);
  if (isPostOrderSwap || !isInventory(state)) {
    const { incentives } = getFinancialIncentives(state);
    return !_isEmpty(_get(incentives, CN_INCENTIVE_PLAN_202411DISCOUNT));
  }
  const currentVehicleIncentive = _get(
    state,
    `ReviewDetails.product.data.IncentivesDetails.currentAvailable.${CN_INCENTIVE_PLAN_202411DISCOUNT}`,
    null
  );

  return !isUsedInventory(state) && !_isEmpty(currentVehicleIncentive);
};

export const isMetShowExtendedServiceAgreementCondition = state => {
  const currentTrim = _get(state, 'Configuration.options_by_group.TRIM[0]', '');
  const extra_content = _get(state, 'CustomGroups.BATTERY_AND_DRIVE.extra_content', []);
  const hasExtendedServiceAgreementDescription = extra_content?.find((item) => item?.type === 'components')
    ?.content?.some((component) =>
      component?.props?.field === 'extended_service_agreement_description'
      && (component?.selected_by?.or?.includes(currentTrim) || component?.selected_by?.and === currentTrim));

  const market = getMarket(state);
  return market === 'CN' && hasExtendedServiceAgreementDescription;
}

/**
 * Check if early delivery design
 *
 * @param state
 */
 export const isInventoryOrderForPreOrderSwap = state => {
  const { isInventorySwapEnabled, isCoinReloaded } = state?.App || {};
  const { isEarlyDesignSelected, swapConfig } = state?.ReviewDetails?.vehicleDesign || {};
  const { PreOrderVehicleContext = TYPE_CONFIGURATOR } = swapConfig || {};
  if (isInventorySwapEnabled && isEarlyDesignSelected) {
    return isCoinReloaded ? (PreOrderVehicleContext === TYPE_INVENTORY) : true;
  }

  return false;
}

export const mapAssetToOptionCode = (state, optionCode, optionCodeGroup, familyGroup) => {
  const groupAsset =  _get(optionGroupToAssetMapping, familyGroup) || _get(optionGroupToAssetMapping, optionCodeGroup);
  if (groupAsset) {
    return groupAsset;
  }
  const { OMS: {
    lexicon: {
      options = {}
    } = {}
  } = {} } = state;
  const asset = _get(options, `${optionCode}.extra_content`, []).find(i => i?.type === 'asset')?.content[0];
  if (asset && asset?.type !== 'product-image') {
    return asset;
  }
  return {
    type: 'tds-icon',
    iconData: iconMinusFilled,
  };
}

export const getSwapType = state => {
  const {
    DeliveryTiming: { isAvailableNow, showSimilarDesigns } = {},
    ReviewDetails: {
      vehicleDesign: { isEarlyDesignSelected, earlyVehicleDesigns = [] } = {},
    } = {},
    App: { isCoinReloaded, isInventorySwapEnabled } = {},
  } = state || {};
  if (!isCoinReloaded || !isInventorySwapEnabled) {
    return null;
  }
  if (isEarlyDesignSelected && earlyVehicleDesigns?.length) {
    return TYPE_INVENTORY_SWAP;
  } else if (isAvailableNow) {
    return EXACT_MATCH;
  } else if (showSimilarDesigns) {
    return IGNORED_SWAP;
  }

  return SWAP_NOT_SHOWN;
};

export const isNV36Trim = state => {
  const model = state?.OMS?.oms_params?.model || '';
  if (model !== MODEL_Y) {
    return false;
  }
  const selectedTrim = _get(state, 'ReviewDetails.product.data.TrimCode', '') || _get(state, 'CustomGroups.TRIM.currentSelected[0].code', '');
  const skuTrimVariant = _get(state, `OMS.lexicon.sku.trims.${selectedTrim}.variant.code`, '');
  return skuTrimVariant.includes('NV36');
};
