const InitialState = {
  object: {
    isCarouselOpen: false,
    asset: {
      bkba: 2,
      type: 'image-compositor',
      view: 'REAR34',
      crop: [1300, 450, 300, 300],
    }
  },
  deltas: {
    model: {
      my: {
        asset: {
          type: 'image-compositor',
          view: 'REAR34',
          bkba: 2,
          crop: [1200,480,350,300],
        },
      },
      mx: {
        asset: {
          type: 'image-compositor',
          view: 'REAR34',
          bkba: 2,
          crop: [1300, 600, 300, 230],
        },
      },
      ct: {
        asset: {
          type: 'image-compositor',
          view: 'REAR34',
          bkba: 1,
          crop: [1300, 600, 300, 230],
        },
      },
    },
  },
};

export default InitialState;
