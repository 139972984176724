import {
  STREET_NUMBER,
  ROUTE,
  LOCALITY,
  ADMINISTRATIVE_AREA_LEVEL_1,
  ADMINISTRATIVE_AREA_LEVEL_2,
  ADMINISTRATIVE_AREA_LEVEL_3,
  POSTAL_TOWN,
  SUBLOCALITY_LEVEL_1,
  SUBLOCALITY_LEVEL_4,
  NEIGHBORHOOD,
  PREMISE,
} from 'dictionary';

export default overrides => ({
  merge_method: 'assign',
  object: {
    addressValidationApi:
      window?.tesla?.routes?.addressValidationApi || '/configurator/api/v1/address_validate',
    accessories: [],
    geographies: [],
    selectedItems: {},
    grossTotal: 0,
    modal: {
      isOpen: false,

      // Name of accessories to show in modal
      accessories: [],
    },
    shipToAddress: {},
    shippingAddressText: null,
    errorState: null,
    googleAddressNotFound: false,
    customAddressValidationComplete: false,
    isOptInForBiddingFlow: false,
    isCustomAddressValid: false,
    isSuggestedAddressSelected: false,
    isCustomAddressSelected: false,
    customAddress: null,
    suggestedAddress: null,
    partialGoogleAddress: null,
    isAccessoriesCustomAddressEnabled: window.tesla?.accessories_custom_address_enabled || false,
    googleAutocompleteRules: {
      // summation
      street1Rule: [STREET_NUMBER, ROUTE],
      street2Rule: [],
      // order of priority
      cityRule: [
        LOCALITY,
        SUBLOCALITY_LEVEL_1,
        NEIGHBORHOOD,
        ADMINISTRATIVE_AREA_LEVEL_2,
        ADMINISTRATIVE_AREA_LEVEL_3,
        POSTAL_TOWN,
      ],
      stateRule: [ADMINISTRATIVE_AREA_LEVEL_1],
    },
    ...overrides,
    alwaysShowChargingNotIncludedDisclaimer: false,
    alwaysShowChargingIncludedDisclaimer: false,
  },
  deltas: {
    market: {
      KR: {
        googleAutocompleteRules: {
          // summation
          street1Rule: [PREMISE, SUBLOCALITY_LEVEL_4],
          street2Rule: [],
          // order of priority (OR)
          cityRule: [SUBLOCALITY_LEVEL_1],
          stateRule: [ADMINISTRATIVE_AREA_LEVEL_1],
        },
      },
      GB: {
        googleAutocompleteRules: {
          street1Rule: [STREET_NUMBER, ROUTE],
          street2Rule: [LOCALITY, SUBLOCALITY_LEVEL_1],
          cityRule: [
            POSTAL_TOWN,
            NEIGHBORHOOD,
            ADMINISTRATIVE_AREA_LEVEL_2,
            ADMINISTRATIVE_AREA_LEVEL_3,
          ],
          stateRule: [],
        },
      },
      IT: {
        googleAutocompleteRules: {
          street1Rule: [ROUTE, STREET_NUMBER],
          street2Rule: [],
          cityRule: [
            LOCALITY,
            POSTAL_TOWN,
            ADMINISTRATIVE_AREA_LEVEL_3,
            SUBLOCALITY_LEVEL_1,
            NEIGHBORHOOD,
          ],
          stateRule: [ADMINISTRATIVE_AREA_LEVEL_2],
        },
      },
      NZ: {
        googleAutocompleteRules: {
          street1Rule: [STREET_NUMBER, ROUTE],
          street2Rule: [],
          suburbRule: [SUBLOCALITY_LEVEL_1],
          cityRule: [
            LOCALITY,
            POSTAL_TOWN,
            ADMINISTRATIVE_AREA_LEVEL_3,
            SUBLOCALITY_LEVEL_1,
            NEIGHBORHOOD,
          ],
        },
      },
      AE: {
        googleAutocompleteRules: {
          street1Rule: [ROUTE, STREET_NUMBER],
          street2Rule: [],
          cityRule: [
            LOCALITY,
            POSTAL_TOWN,
            ADMINISTRATIVE_AREA_LEVEL_3,
            ADMINISTRATIVE_AREA_LEVEL_2,
            SUBLOCALITY_LEVEL_1,
            NEIGHBORHOOD,
          ],
          stateRule: [ADMINISTRATIVE_AREA_LEVEL_1],
        },
      }
    },
  },
  deltasV2: [
    {
      selected_by: {
        market: [
          'BE',
          'CZ',
          'DK',
          'DE',
          'ES',
          'FR',
          'IE',
          'LU',
          'NL',
          'NO',
          'AT',
          'PT',
          'CH',
          'SE',
          'FI',
        ],
      },
      object: {
        googleAutocompleteRules: {
          street1Rule: [ROUTE, STREET_NUMBER],
          street2Rule: [],
          cityRule: [
            LOCALITY,
            POSTAL_TOWN,
            ADMINISTRATIVE_AREA_LEVEL_3,
            ADMINISTRATIVE_AREA_LEVEL_2,
            SUBLOCALITY_LEVEL_1,
            NEIGHBORHOOD,
          ],
          stateRule: [],
        },
      },
    },
    {
      selected_by: {
        market: ['AU', 'NZ'],
        model: ['m3', 'my'],
      },
      object: {
        alwaysShowChargingNotIncludedDisclaimer: true,
      },
    },
    {
      selected_by: {
        market: 'AU',
        model: ['ms', 'mx'],
        isInventory: true,
      },
      object: {
        alwaysShowChargingNotIncludedDisclaimer: true,
      },
    },
    {
      selected_by: {
        market: ['US', 'CA', 'PR'],
        model: 'my',
        isInventory: false,
      },
      object: {
        alwaysShowChargingIncludedDisclaimer: true,
      },
    },
  ],
});
