import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isObject from 'lodash/isObject';
import _union from 'lodash/union';
import { connect } from 'react-redux';
import Analytics from 'analytics';

import { triggerLoaderAction } from 'actions';
import { replaceStringVars } from '@tesla/coin-common-components';
import { parseSelectedBy } from 'utils';
import TextLoaderRepresentation from './representation';
import AssetLoader from '../AssetLoader';
import IconChevron from '../Icons/IconChevron';

const availableIcons = {
  IconChevron,
};

const TextLoader = props => <TextLoaderRepresentation {...props} />;

TextLoader.propTypes = {
  isVisible: PropTypes.bool,
  plainText: PropTypes.bool,
};

TextLoader.defaultProps = {
  isVisible: true,
  plainText: false,
};

function mapStateToProps(state, ownProps) {
  const { data, className = '', field = '', inheritClasses = true } = ownProps;
  const { code = '' } = data || {};
  const text_schema = field ? _get(data, field) : data;
  const key = `TextLoader::${code || JSON.stringify(text_schema)}::${field}`;
  const isVisible = _get(ownProps, 'visible', true);
  const selectedOptions = _get(state, 'Configuration.option_codes', []);

  let actions;
  const fieldTagMap = {
    title: 'h3',
    main_title: 'h4',
  };
  const defaultTag = _get(fieldTagMap, field, 'div');
  let tag = {
    component: _get(ownProps, 'tag.component', defaultTag),
    props: _get(ownProps, 'tag.props', {}),
  };
  const overrideTagComponent = _get(ownProps, 'tag.overrideComponent', null);
  let optimize;

  let schema_content = _isObject(text_schema) ? text_schema.content : null;
  let extraProps = {};

  if (_get(text_schema, 'behavior') || _get(text_schema, 'root.behavior')) {
    const behaviorSet = _get(text_schema, 'behavior', _get(text_schema, 'root.behavior'), []);
    let classNames = [];
    let actionClass = [];
    let iconSprite = '';
    // eslint-disable-next-line consistent-return
    behaviorSet.forEach(behavior => {
      const behaviorContent = _get(behavior, 'content', {});
      classNames = _union(classNames, _get(behaviorContent, 'classNames', []));
      actionClass = _union(actionClass, _get(behaviorContent, 'actionClass', []));
      iconSprite = _get(behaviorContent, 'iconSprite', '');
      optimize = _get(behavior, 'optimize');
      switch (behavior.type) {
        case 'ACTION':
          actions = [].concat(actions || [], behavior.content);
          if (behavior.content.selected_by) {
            schema_content = behavior.content.selected ? schema_content : null;
            return schema_content;
          }
          return actions;
        case 'REPLACE_VARS':
          if (typeof text_schema.content === 'string') {
            const { selected_by, source: contentSource = {} } = behaviorContent || {};
            const isSelected = selected_by ? parseSelectedBy(selected_by, selectedOptions, null, state) : true;
            if (!isSelected) {
              schema_content = null;
              return schema_content;
            }
            const source = Object.keys(contentSource)?.reduce((res, key) => {
              const path = contentSource[key] || '';
              res = { ...res, [key]: _get(state, path) };
              return res;
            }, {});
            schema_content = replaceStringVars(text_schema.content, source);
            return schema_content;
          }
          break;
        case 'REPLACE_CONTENT': {
          if (typeof text_schema.content === 'string') {
            const source = _get(state, `OMS.lexicon.${behavior.content.source}`);
            if (behavior.content.selected_by) {
              // conditional replacement
              if (!parseSelectedBy(behavior.content.selected_by, selectedOptions)) {
                schema_content = text_schema.content;
                return schema_content;
              }
            }
            schema_content = source;
            return schema_content;
          }
          const source = _get(state, `OMS.lexicon.${behavior.content.source}`);
          if (behavior.content.selected_by) {
            // conditional replacement
            if (!parseSelectedBy(behavior.content.selected_by, selectedOptions)) {
              schema_content = text_schema;
              return schema_content;
            }
          }
          schema_content = { ...source, behavior: true };
          return schema_content;
        }
        case 'CONTENT_SELECTED':
          if (behavior.content.selected_by) {
            // conditional target present
            if (behavior.content.source) {
              // custom selectedBy source to check against
              if (
                !parseSelectedBy(
                  behavior.content.selected_by,
                  _get(state, `${behavior.content.source}`, [])
                )
              ) {
                schema_content = null;
                return schema_content;
              }
              return schema_content;
            }
            schema_content = behavior.content.selected ? schema_content : null;
            return schema_content;
          }
          break;
        case 'CONTAINER':
          tag = {
            component: overrideTagComponent || behavior.content.tag || defaultTag,
            props: behavior.content.props || {},
          };
          return tag;
        case 'ASSET':
          const { asset, selected_by } = behaviorContent || {};
          const { classes = '' } = asset || {};
          if (asset) {
            if (selected_by) {
              const isSelected = parseSelectedBy(selected_by, selectedOptions, null, state);
              schema_content = isSelected ? <AssetLoader asset={asset} className={classes} /> : null;
            } else {
              schema_content = <AssetLoader asset={asset} className={classes} />
            }
          }
          return schema_content;
        case 'ICON':
          const { asset: iconAsset } = behaviorContent || {};
          const { props = {}, icon } = iconAsset || {};
          const IconName = availableIcons[icon] || null;
          if (IconName) {
            return schema_content = <IconName {...props} />
          }
          return null;
        default:
      }
    });
    extraProps = {
      ...extraProps,
      className: (inheritClasses ? classNames.concat(className) : classNames).join(' '),
      actionClass: actionClass.join(' '),
      iconSprite: iconSprite,
    };
  }
  const textSchema =
    _has(text_schema, 'behavior') || _has(text_schema, 'root.behavior')
      ? schema_content
      : text_schema;
  return {
    text_schema: textSchema,
    actions,
    tag,
    isLayoutMobile: _get(state, 'App.isLayoutMobile', false),
    isLayoutTablet: _get(state, 'App.isLayoutTablet', false),
    key,
    isVisible,
    field,
    optimize,
    code,
    ...extraProps,
    availableGroups: Object.keys(_get(state, 'CustomGroups', {})),
    inheritClasses,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { analyticsInteraction } = ownProps;

  return {
    onAction: (actions, group) => {
      if (analyticsInteraction) {
        Analytics.fireTagEvent({
          event: Analytics.event,
          interaction: analyticsInteraction,
          'cfg-type': Analytics.cfgType,
        });
      }
      if (actions) {
        dispatch(triggerLoaderAction(actions, group));
      }
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TextLoader);
