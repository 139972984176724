import React from 'react';
import { connect } from 'react-redux';
import { bool, func, string } from 'prop-types';
import _get from 'lodash/get';
import _map from 'lodash/map';
import cx from 'classnames';
import {
  REGISTRATION_TYPE,
  REGISTRATION_TYPE_BUSINESS,
  REGISTRATION_TYPE_PRIVATE,
} from 'dictionary';
import { i18n } from 'utils';
import { setRegistrationType } from 'actions';
import {
  FormFieldset,
  FormInputChoiceGroup,
  FormInputChoice,
  FormItem,
  FormLabel,
} from '@tesla/design-system-react';

const RegistrationTypeSelector = ({
  setRegistrationType,
  registrationTypeForOrder,
  radioName,
  isOrderModification,
  classes,
  justifyCenter,
}) => {
  const registrationTypeSwitchChanged = opt => {
    if (opt) setRegistrationType(opt);
  };

  return (
    <FormItem className={classes}>
      <FormLabel htmlFor={REGISTRATION_TYPE}>
        {i18n('Review.RegistrationType', null, null, { returnNullWhenEmpty: true })}
      </FormLabel>
      <FormInputChoiceGroup
       layout="horizontal"
       id={REGISTRATION_TYPE}
       className={cx({'tds-flex--justify-center': justifyCenter})}
      >
        {_map([REGISTRATION_TYPE_PRIVATE, REGISTRATION_TYPE_BUSINESS], opt => (
          <FormInputChoice
            onChange={() => registrationTypeSwitchChanged(opt)}
            id={opt + radioName}
            type="radio"
            name={`order-type${radioName}`}
            value={opt}
            checked={registrationTypeForOrder === opt}
            disabled={isOrderModification}
            label={i18n(`Review.account_in_${opt}`)}
          />
        ))}
      </FormInputChoiceGroup>
    </FormItem>
  );
};

RegistrationTypeSelector.propTypes = {
  setRegistrationType: func.isRequired,
  registrationTypeForOrder: string.isRequired,
  radioName: string,
  isOrderModification: bool.isRequired,
  justifyCenter: bool,
};

RegistrationTypeSelector.defaultProps = {
  radioName: '',
  justifyCenter: false,
};

const mapStateToProps = state => {
  return {
    registrationTypeForOrder: _get(state, 'ReviewDetails.RegistrationDetail.RegistrantType'),
    isOrderModification: _get(state, 'ApplicationFlow.canModifyOrder'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setRegistrationType: type => {
      dispatch(setRegistrationType(type));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationTypeSelector);
