import React, { Component } from 'react';
import { string, shape, arrayOf, bool, func } from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import classnames from 'classnames';
import { getCustomPricingIndicatorV2, getDisclaimerOverride } from 'utils';
import TextLoader from '../TextLoader';
import ExVatDisclaimer from './ExVatDisclaimer';
import { formatCurrency } from '@tesla/coin-common-components';
import { getPricePostfix } from 'selectors';

/* Group selected option price component */
class SelectedPrice extends Component {
  constructor(props) {
    super(props);
    this.getIndicatorForOption = this.getIndicatorForOption.bind(this);
    this.options = {};
  }

  getIndicatorForOption(option) {
    const {
      configuration,
      priceData,
      includeFeesinBatteryCalculation,
      CustomGroups,
      pricePostfix,
    } = this.props;

    const response = getCustomPricingIndicatorV2(
      [option],
      configuration,
      priceData,
      includeFeesinBatteryCalculation,
      false,
      CustomGroups,
      false,
      false,
      pricePostfix
    );

    this.options = {
      [option.code]: response,
    };
    return response;
  }

  render() {
    const { group, configuration, isLayoutMobile, pricePostfix, isCoinReloaded, isWinterWheel, isTitleHidden = false } = this.props;
    if (!group && !group.currentSelected && !group.currentSelected.length) {
      return null;
    }
    const disclaimerOverride = getDisclaimerOverride(_get(group, 'current', []), configuration);
    const { currentSelected = [], code } = group;
    if (!currentSelected.length) {
      return null;
    }
    return (
      <>
        {currentSelected.map(
          option =>
            !_get(option, 'shape.type', []).includes('descriptive') && (
              <div
                className="child-group--selected_option_details"
                key={`SelectedOptionPricing:${option.code}:${code}`}
              >
                <div
                  className={classnames('tds-flex tds-flex--flex_cells custom-content', {
                    'has-extra-price': option.extraPrice,
                  })}
                >
                  {isCoinReloaded && option.recommended && (
                    <div className="tds-flex-item tds-text--center tds-flex--justify-center group-option--recommended">
                      <TextLoader data={option} field="recommended" />
                    </div>
                  )}
                  <If condition={!isTitleHidden}>
                    <div
                      className={classnames(
                        'tds-flex-item tds-flex--auto',
                        { 'tds-flex--justify-center': isLayoutMobile },
                        { 'tds-flex--justify-flex-end': !isLayoutMobile }
                      )}
                    >
                      <TextLoader
                        tag={{ component: isCoinReloaded ? 'h2' : 'p' }}
                        data={option}
                        field="name"
                        className={classnames('group-option--detail-container_name tds-text--medium', {
                          'tds--no_padding tds-text--h4': isCoinReloaded,
                          'tds-text--h6': isWinterWheel && isCoinReloaded,
                        })}
                      />
                    </div>
                  </If>
                  <If condition={option.formattedPrice}>
                    <div
                      className={classnames('tds-flex-item tds-flex--auto group-option--price', {
                        'tds-flex--justify-center': isLayoutMobile,
                      })}
                    >
                      <Choose>
                        <When condition={this.getIndicatorForOption(option)}>
                          {!!_get(this.options, `${option.code}`, '').trim() && (
                            <p
                              className={classnames('text-loader--content group-option--detail-container_price', {'tds-o-padding_bottom-2': isCoinReloaded})}
                              data-id={`${code}-price`}
                            >
                              {_get(this.options, `${option.code}`)}
                            </p>
                          )}
                        </When>
                        <Otherwise>
                          {!!option.formattedPrice && (
                            <TextLoader
                              data={{
                                ...option,
                                formattedPrice:
                                  option.price > 0
                                    ? `${option.formattedPrice}${pricePostfix}`
                                    : option.formattedPrice,
                              }}
                              tag={{
                                component: 'p',
                                props: { 'data-id': `${code}-price` },
                              }}
                              field="formattedPrice"
                              className={classnames('group-option--detail-container_price', {'tds-o-padding_bottom-2': isCoinReloaded})}
                            />
                          )}
                        </Otherwise>
                      </Choose>
                      <If condition={option.extraPrice}>
                        <TextLoader
                          data={{
                            ...option,
                            formattedPrice: `${formatCurrency(option.extraPrice.amount, {
                              currency: option.extraPrice.currency,
                              useDynamicRounding: true,
                            })}${pricePostfix}`,
                          }}
                          tag={{
                            component: 'p',
                          }}
                          field="formattedPrice"
                          className={classnames('group-option--detail-container_price', {'tds-o-padding_bottom-2': isCoinReloaded})}
                        />
                      </If>
                    </div>
                  </If>
                  {!isCoinReloaded && option.recommended && (
                    <div className="tds-flex-item tds-text--center tds-flex--justify-center group-option--recommended">
                      <TextLoader data={option} field="recommended" />
                    </div>
                  )}
                </div>
                <Choose>
                  <When condition={disclaimerOverride !== false}>
                    <p className="group--option-disclaimer_details text-loader--content">
                      {disclaimerOverride}
                    </p>
                  </When>
                  <Otherwise>
                    <If condition={option.disclaimer_details_override || option.disclaimer_details}>
                      <div className="group--option-disclaimer--wrapper">
                        <If condition={option.disclaimer_details_override}>
                          {option.disclaimer_details_override.selected &&
                            option.disclaimer_oncondition && (
                              <TextLoader
                                key={`ChildGroup_OptionDisclaimerDetails:${option.code}`}
                                data={option}
                                field="disclaimer_oncondition"
                                className="group--option-disclaimer_details_text"
                              />
                            )}
                        </If>
                        <If condition={option.disclaimer_details}>
                          <TextLoader
                            data={option}
                            field="disclaimer_details"
                            className="group--option-disclaimer_details"
                          />
                        </If>
                      </div>
                    </If>
                  </Otherwise>
                </Choose>
                <If condition={isCoinReloaded && isWinterWheel && option?.short_description}>
                  <div className="group--option-disclaimer--wrapper">
                    <TextLoader
                      data={option}
                      field="short_description"
                      className="tds-text--caption tds--vertical_padding-top"
                    />
                  </div>
                </If>
                <ExVatDisclaimer />
              </div>
            )
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { CustomGroups, Pricing, Configuration, FinancingOptions, App } = state;
  const { isLayoutMobile, isCoinReloaded = false, pricePostfixGroups = [] } = App;
  const { option_codes } = Configuration;
  const { includeFeesinBatteryCalculation = false } = FinancingOptions;
  const { group = {} } = ownProps;
  const { code } = group;
  const { apiResultsPerTrim, apiResults } = Pricing?.calculatorResult?.data || {};
  return {
    CustomGroups,
    priceData: {
      ...apiResults,
      apiResultsPerTrim,
    },
    configuration: option_codes,
    includeFeesinBatteryCalculation,
    isLayoutMobile,
    pricePostfix: !pricePostfixGroups.length || pricePostfixGroups.includes(group) ? getPricePostfix(state) : '',
    isCoinReloaded,
    isWinterWheel: group?.code === 'WINTER_WHEELS_OPTIONS',
  };
}

SelectedPrice.propTypes = {
  group: shape({}).isRequired,
  configuration: arrayOf(string).isRequired,
  priceData: shape({}),
  includeFeesinBatteryCalculation: bool,
  CustomGroups: shape({}).isRequired,
  recalculatePrice: func,
  isLayoutMobile: bool,
  pricePostfix: string,
  isCoinReloaded: bool,
  isWinterWheel: bool,
};

SelectedPrice.defaultProps = {
  includeFeesinBatteryCalculation: false,
  priceData: {},
  recalculatePrice: () => {},
  isLayoutMobile: false,
  pricePostfix: '',
  isCoinReloaded: false,
  isWinterWheel: false,
};

export default connect(mapStateToProps)(SelectedPrice);
