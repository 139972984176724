import React from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { string, number, bool, shape, arrayOf } from 'prop-types';
import {
  i18n,
  getVehicleLocationString,
  getDeliveryAndRegistrationDisclaimer,
  isEMEAMarket,
  isNAMarket,
  getYear,
  getFormattedModelYear,
  getVehicleBadgeCopy,
} from 'utils';
import { 
  ODOMETER_THRESHOLD,
  CPO_MARKETS, Models,
  BRAND_NEW,
  GROUP_CONNECTIVITY,
  GROUP_SUPER_CHARGER,
  CLEAN,
  ADDITIONAL_DETAILS_MODAL,
  ACCIDENT,
  SUMMARY_PANEL_LEASE_TAB,
} from 'dictionary';
import {
  isInventory,
  getTransportationFeeAmount,
  getDepositAmount,
  getModel,
  getVehicleMappedForLocation,
  getModelName,
  getDeliveryRegionDisclaimer,
  getConfigurationOptions,
  getInventorySteeringWheelDisclaimer,
  getFinanceType,
  getSelectedFinanceProduct,
  getPrice,
  showRepairDisclosure,
} from 'selectors';
import { saveUserViewedDisclosures } from 'actions';
import { iconSafetyRating, iconCurrencyFilled, iconServiceFilled, iconChargeFilled } from '@tesla/design-system-icons';

import InfoBlock from '../InfoBlock';
import Specs from './Specs';
import FinanceToggle from './FinanceToggle';
import InventoryEmissions from './InventoryEmissions';
import NotificationBanner from './NotificationBanner';
import InfoMessage from './InfoMessage';
import FederalIncentiveChip from './FederalIncentiveChip';
import FederalIncentiveChipShort from './FederalIncentiveChipShort';
import IncentivesToggle from './IncentivesToggle';
import AssetLoader from '../AssetLoader';

import { formatNumber, formatCurrency } from '@tesla/coin-common-components';

const InventorySummary = ({
  isComboInventory,
  isUsedInventory,
  isWinterWheelsIncluded,
  inventoryData,
  modelName,
  showLeasePurchaseOption,
  showVehicleLocation,
  showVehicleLocationDisclaimer,
  showModelYearOverviewPage,
  showModelYearVehicleDetails,
  showFormattedModelYear,
  showLocationPrefix,
  hideVehicleTypeLabel,
  isInventoryVehicle,
  isTransportFeeEnabled,
  transportFeeAmount,
  depositAmount,
  modelCode,
  isPickupOnlyEnabled,
  showDeliveryAndRegistrationDisclaimer,
  deliveryAndRegistrationDisclaimer,
  deliveryDisclaimer,
  vehicleMapped,
  vehicleSummaryProps,
  showVehicleDetailsLineItems,
  showOdometer,
  showInTransitStatusMetro,
  isPostOrderSwap,
  isEnterpriseOrder,
  featureOptions,
  additionalInfoAfterButton,
  showDemoVin,
  enableCyberpunk,
  includeFutureYear,
  hideInventoryVin,
  titleSubStatus,
  isNewRedesign,
  isPreOrderSwap,
  isEMEA,
  isRegionNA,
  showCleanHistory,
  showRepairDisclosure,
  showDamageDisclosure,
  DamageDisclosureLatest,
  ddFile,
  repairedHistory,
}) => {
  if (_isEmpty(inventoryData)) {
    return null;
  }
  const {
    IsDemo,
    Odometer,
    TrimName,
    DemoVin,
    VIN,
    Year,
    CountryCode,
    FactoryGatedDate,
    IsFactoryGated,
    ManufacturingYear,
    ChargePortType,
    Price,
    Discount,
  } = inventoryData || {};
  const contents = [];
  const contentsAfterFeatures = [];
  const additionalInfoBeforeButton = [];

  if (!isNewRedesign) {
    // Vehicle Type
    if (!hideVehicleTypeLabel && IsDemo) {
      contents.push(i18n('Inventory.common.demoVehicle'));
    } else if (isUsedInventory) {
      const usedVehicleKey = CPO_MARKETS.includes(CountryCode) ? 'Inventory.common.cpoVehicle': 'Inventory.common.usedVehicle';
      contents.push(i18n(usedVehicleKey));
    } else if (!hideVehicleTypeLabel) {
      contents.push(i18n('Inventory.common.newVehicle'));
    }
  }

  if (!isNewRedesign && showModelYearVehicleDetails) {
    const futureYear = includeFutureYear ? 1 : 0;
    const currentYear = getYear(new Date()) + futureYear;
    if (!isUsedInventory && currentYear !== Year) {
      contents.push(i18n('Inventory.common.modelYear', {YEAR: getFormattedModelYear({year: Year})}));
    }
  }

  // Price Adjustment
  if (isNewRedesign && Discount) {
    contents.push({
      icon: {
        type: 'tds-icon',
        iconData: iconCurrencyFilled,
      },
      name: i18n('Inventory.common.priceAdjustment', {
        AMOUNT: formatCurrency(-Math.abs(Discount)),
      }),
    });
  }

  // Odometer (non-redesign)
  if (!isNewRedesign && (showOdometer || Odometer >= ODOMETER_THRESHOLD || IsDemo)) {
    const mileageUnit = i18n('Inventory.common.mileageUnit');
    let odometerData = isNewRedesign ? {
      icon: {
        type: 'tds-icon',
        iconData: iconSafetyRating,
      },
    } : {};
    if (Odometer >= ODOMETER_THRESHOLD || isUsedInventory || IsDemo) {
      odometerData.name = i18n('Inventory.common.odometerMileage', {
        DISTANCE: formatNumber(Odometer),
        UNITS: mileageUnit,
      });
    } else {
      odometerData.name = i18n('Inventory.common.odometerThreshold', {
        DISTANCE: formatNumber(ODOMETER_THRESHOLD),
        UNITS: mileageUnit,
      })
    }
    contents.push(odometerData);
  }

  // VIN
  if (!isNewRedesign) {
    if (!isComboInventory && VIN) {
      if (!(hideInventoryVin && titleSubStatus === BRAND_NEW)) {
        contents.push(`VIN ${VIN}`);
      }
    } else if (showDemoVin && DemoVin) {
      contents.push(`VIN ${DemoVin}`);
    }
  }

  const isFactoryGated = FactoryGatedDate || IsFactoryGated;
  if (!isFactoryGated && !isNewRedesign) {
    contents.push(i18n('Inventory.common.inProduction'));
  }

  // Vehicle Location
  const isCnNewInventoryComboVehicle = CountryCode === 'CN' && isComboInventory && !isUsedInventory;
  const isCN = CountryCode === 'CN';
  if ((showVehicleLocation || showInTransitStatusMetro) && !isCnNewInventoryComboVehicle) {
    const locationString = getVehicleLocationString({
      market: CountryCode,
      vehicle: vehicleMapped,
      showLocationPrefix,
      showVehicleLocationDisclaimer,
      isPickupOnlyEnabled,
      isEMEA,
      showInTransitStatusMetro,
      inventoryData,
      isUsedInventory,
      isCN
    });
    if (deliveryDisclaimer) {
      additionalInfoBeforeButton.push(deliveryDisclaimer);
    } else if (locationString) {
      contents.push(locationString);
    }
  }

  // TODO: move into vehicle badge on redesign
  if (showVehicleDetailsLineItems?.length && ManufacturingYear) {
    showVehicleDetailsLineItems.map(itm => {
      return contents.push(
        i18n(`Inventory.vehicleDetails.${itm}.description`, {
          MANUFACTURE_YEAR: getYear(ManufacturingYear),
        })
      );
    });
  }

  if (ChargePortType) {
    contents.push({
      name: i18n('Inventory.vehicleDetails.chargePort', {
        TYPE: ChargePortType,
      }),
      icon: isNewRedesign ? {
        type: 'tds-icon',
        iconData: iconChargeFilled,
      } : null,
    });
  }

  if (isWinterWheelsIncluded) {
    contentsAfterFeatures.push(i18n('Wheels.summerWinterSetsIncluded'));
  }

  if (false && isNewRedesign) {
    // check on clean history
    const icon = {
      type: 'tds-icon',
      iconData: iconServiceFilled,
    };
    if (showCleanHistory) {
      contentsAfterFeatures.push({
        title: i18n('Inventory.vehicleHistory.clean.modalTitle'),
        text: i18n('Inventory.vehicleHistory.clean.modalLink'),
        description: i18n('Inventory.vehicleHistory.clean.content'),
        componentName: ADDITIONAL_DETAILS_MODAL,
        analytics: 'clean-history-disclosure',
        icon,
      });
    }
    if (showDamageDisclosure) {
      const { filename = '', filetype = '', guid = '' } = DamageDisclosureLatest || {};
      contentsAfterFeatures.push({
        href: ddFile,
        id: filename,
        text: i18n('Inventory.vehicleHistory.damage.label'),
        analytics: 'previously-repaired-damage-disclosure',
        onClick: () => {
          saveUserViewedFiles({
            [guid]: {
              Guid: guid,
              FileType: filetype,
              FileName: filename,
              Viewed: true,
            },
          });
        },
        icon,
      });
    }
    // Repaired vehicle history with or no damage disclosures
    else if (repairedHistory) {
      contentsAfterFeatures.push({
        title: i18n('Inventory.vehicleHistory.damage.label'),
        text: i18n('Inventory.vehicleHistory.damage.modalLink'),
        componentName: ADDITIONAL_DETAILS_MODAL,
        description: i18n(
          `Inventory.vehicleHistory.damage.${isRegionNA ? 'content' : 'content_alt'}`
        ),
        analytics: 'previously-repaired-disclosure',
        icon,
      });
    }

  }

  let mergedContents = featureOptions?.length ? [...contents, ...featureOptions, ...contentsAfterFeatures] : [...contents, ...contentsAfterFeatures]
  const filteredContentGroups = ['CONNECTIVITY', 'AUTOPILOT_TRIAL']
  mergedContents = mergedContents?.filter(content => {
    return !filteredContentGroups?.includes(content?.group)
  }) || [];

  const formattedYear = getFormattedModelYear({ year: Year, showFormattedModelYear });
  const title = showModelYearOverviewPage && !isNewRedesign ? `${formattedYear} ${modelName}` : modelName;
  return (
    <div className="option-widget--container vehicle-summary-container">
      <InfoBlock
        name="vehicleName"
        title={enableCyberpunk ? TrimName : title}
        titleClass="tds-o-no_padding_bottom tds-text--h1-alt"
        secondaryTitle={isNewRedesign ? '' : (enableCyberpunk ? '' : TrimName)}
        centered
        secondaryTitleClass="tds-text--h6"
      />

      <InfoMessage />
      <InventoryEmissions />
      <NotificationBanner />
      <Specs />
      <If condition={isNewRedesign}>
        <FinanceToggle className="tds--vertical_padding-bottom" />
      </If>
      <If condition={!isPreOrderSwap && !isNewRedesign}>
        <FederalIncentiveChip />
      </If>
      <InfoBlock
        name="vehicleSummary"
        contents={mergedContents}
        centered
        isInventoryVehicle={isInventoryVehicle}
        isTransportFeeEnabled={isTransportFeeEnabled}
        transportFeeAmount={transportFeeAmount}
        depositAmount={depositAmount}
        modelCode={modelCode}
        title={isNewRedesign ? (enableCyberpunk ? '' : TrimName) : ''}
        secondaryTitle={showModelYearOverviewPage && isNewRedesign ? `${formattedYear}` : ''}
        itemClass="tds-text_color--black"
        isPickupOnlyEnabled={isPickupOnlyEnabled}
        showDeliveryAndRegistrationDisclaimer={showDeliveryAndRegistrationDisclaimer}
        deliveryAndRegistrationDisclaimer={deliveryAndRegistrationDisclaimer}
        additionalInfoBeforeButton={additionalInfoBeforeButton}
        additionalInfoAfterButton={additionalInfoAfterButton}
        {...(enableCyberpunk ? {
          itemClass: "tds--vertical_padding-bottom--1x",
          isTransportFeeEnabled: false,
          contentsClass: "tds--vertical_padding",
        } : vehicleSummaryProps)}
      />
      <If condition={!isPreOrderSwap && isNewRedesign}>
        <FederalIncentiveChipShort />
      </If>
      <If condition={showLeasePurchaseOption}>
        <div className='tds-flex tds-flex--justify-center tds-o-vertical_padding-top--1x tds-text--contrast-low tds-o-flex-direction--row'>
          <span>{i18n('FinancingOptions.lease__purchaseAvailable')}</span>
          <span className='tds--horizontal_margin-5'>
            <IncentivesToggle 
              showFinanceModalTrigger={true}   
              linkClasses='tds-link tds-text--contrast-low'
              modalCopy='common.seeTerms'
            />
          </span>
        </div>
      </If>
      <If condition={isPreOrderSwap || isNewRedesign}>
        <IncentivesToggle />
      </If>
    </div>
  );
};

InventorySummary.propTypes = {
  isComboInventory: bool.isRequired,
  isUsedInventory: bool.isRequired,
  isWinterWheelsIncluded: bool,
  inventoryData: shape({
    IsDemo: bool,
    Odometer: number,
    TrimName: string,
    VehicleRegion: string,
    VIN: string,
    Year: number,
    CountryCode: string,
    IsFactoryGated: bool,
    IsAtLocation: bool,
  }).isRequired,
  modelName: string.isRequired,
  showVehicleLocation: bool.isRequired,
  showVehicleLocationDisclaimer: bool,
  showModelYearOverviewPage: bool,
  showFormattedModelYear: bool,
  showLocationPrefix: bool,
  hideVehicleTypeLabel: bool,
  isInventoryVehicle: bool.isRequired,
  isTransportFeeEnabled: bool.isRequired,
  transportFeeAmount: number,
  depositAmount: number.isRequired,
  modelCode: string.isRequired,
  isPickupOnlyEnabled: bool,
  showDeliveryAndRegistrationDisclaimer: bool,
  deliveryAndRegistrationDisclaimer: string,
  deliveryDisclaimer: string,
  vehicleMapped: shape({}),
  vehicleSummaryProps: shape({}),
  showVehicleDetailsLineItems: arrayOf(string),
  showOdometer: bool.isRequired,
  showInTransitStatusMetro: bool.isRequired,
  isPostOrderSwap: bool.isRequired,
  hideCrossBorderInTransitStatusMetro: bool.isRequired,
  isEnterpriseOrder: bool.isRequired,
  isEMEA: bool.isRequired,
  additionalInfoAfterButton: arrayOf(string),
  showModelYearVehicleDetails: bool.isRequired,
  showDemoVin: bool,
  includeFutureYear: bool,
  showLeasePurchaseOption: bool.isRequired,
  hideInventoryVin: bool,
  titleSubStatus: string,
  showCleanHistory: bool,
  repairedHistory: bool,
  showDamageDisclosure: bool,
  ddFile: string,
  filePath: string,
  DamageDisclosureLatest: shape({
    guid: string,
    filetype: string,
    filename: string,
  }),
  isRegionNA: bool.isRequired,
};

InventorySummary.defaultProps = {
  isWinterWheelsIncluded: false,
  showVehicleLocationDisclaimer: false,
  showLocationPrefix: false,
  showModelYearOverviewPage: true,
  showFormattedModelYear: false,
  hideVehicleTypeLabel: false,
  transportFeeAmount: 0,
  isPickupOnlyEnabled: false,
  showDeliveryAndRegistrationDisclaimer: false,
  deliveryAndRegistrationDisclaimer: '',
  deliveryDisclaimer: '',
  vehicleMapped: null,
  vehicleSummaryProps: {},
  showVehicleDetailsLineItems: [],
  additionalInfoAfterButton: [],
  showDemoVin: false,
  includeFutureYear: false,
  hideInventoryVin: false,
  titleSubStatus: '',
  showCleanHistory: false,
  repairedHistory: false,
  showDamageDisclosure: false,
  ddFile: '',
  filePath: '',
  DamageDisclosureLatest: {},
};

const mapStateToProps = state => {
  const { App, ReviewDetails } = state;
  const {
    isDeliverySelectionEnabled,
    isPickupOnlyEnabled,
    showRegistrationAndDeliveryDisclaimer,
    isPostOrderSwap,
    isEnterpriseOrder,
    isLayoutMobile,
    isLayoutTablet,
    enableCyberpunk,
    hideInventoryVin,
    isInventoryReloaded: isNewRedesign,
    routes = {},
  } = App;
  const { getDMSFiles: filePath = '', getAutoCheckReport } = routes || {};
  const {
    isWinterWheelsIncluded = false,
    product,
    showVehicleLocationDisclaimer,
    showDeliverySelectionLocation,
    showModelYearOverviewPage = true,
    showFormattedModelYear = false,
    showLocationPrefix,
    showLeasePurchase = false,
    hideVehicleTypeLabel,
    isTransportFeeEnabled,
    showVehicleDetailsSection,
    showVehicleDetailsLineItems,
    showOdometer: showOdometerInitial,
    showInTransitStatusMetro = false,
    showModelYearVehicleDetails = false,
    showDemoVin = false,
    vehicleDesign: {
      isEarlyDesignSelected: isPreOrderSwap = false,
      selectedDesign = {},
    } = {},
    showAfterSavingsPriceForTrims = false,
  } = ReviewDetails;
  const showOdometedFb = isPreOrderSwap ? false : true;
  const showOdometer = showOdometerInitial || showOdometedFb;
  const { 
    configListFull,
    modelYear = '',
    modelBadge,
  } = selectedDesign || {};
  const { isComboInventory = true, isUsedInventory, data: inventoryData } = product || {};
  const {
    Model: modelCode,
    TitleSubStatus: titleSubStatus,
    OptionCodeSpecs = {},
    Odometer,
    IsDemo,
    Year,
    DamageDisclosure,
    CountryCode,
    updatedConfig = [],
    VIN,
    DemoVin,
    VehicleHistory = '',
    DamageDisclosureLatest,
  } = inventoryData || {};
  const autopilotOpts = _get(state, 'CustomGroups.AUTOPILOT_PACKAGE.options', []);
  const configOptions = _get(OptionCodeSpecs, 'C_OPTS.options', []);
  const selectedOptions = getConfigurationOptions(state);
  const { optCodes = [], copy: inventoryOptionsDisclaimer } = getInventorySteeringWheelDisclaimer(state);
  const featureOptions = isPreOrderSwap ? configListFull : updatedConfig;
  const { MODEL_3, MODEL_Y } = Models || {};

  const financeType = getFinanceType(state);   
  const selectedFinanceProduct = getSelectedFinanceProduct(state);
  const showLeasePurchaseOption = showLeasePurchase && selectedFinanceProduct?.toLowerCase()?.includes(SUMMARY_PANEL_LEASE_TAB);

  const vehiclePrice = getPrice(state, financeType, {
    afterSavings: showAfterSavingsPriceForTrims,
  });
  const inventoryDataMapped = inventoryData || selectedDesign || {};
  const trimFamilyGroup = _get(inventoryDataMapped, 'TRIM[0]');
  const { latestYear } = state?.OMS?.lexicon || {};
  const currentYear = new Date().getFullYear();

  let secondaryBadge = '';
  if (isNewRedesign && !isPreOrderSwap) {
    if (!isComboInventory && VIN) {
      if (!(hideInventoryVin && titleSubStatus === BRAND_NEW)) {
        secondaryBadge = `VIN ${VIN}`;
      }
    } else if (showDemoVin && DemoVin) {
      secondaryBadge = `VIN ${VIN}`;
    }
  }
  const isEMEA = isEMEAMarket(CountryCode);
  const isRegionNA = isNAMarket(CountryCode);
  const cleanHistory = !!(VehicleHistory && VehicleHistory.toUpperCase() === CLEAN);
  const { guid = '' } = DamageDisclosureLatest || {};

  const repairedHistory = !!(VehicleHistory && VehicleHistory.toUpperCase() === ACCIDENT);
  const showDamageDisclosure = showRepairDisclosure(state);
  const showRepairedInTitle = ((isUsedInventory && repairedHistory) || (!isUsedInventory && DamageDisclosure));
  return {
    isNewRedesign,
    isComboInventory,
    isUsedInventory,
    isWinterWheelsIncluded,
    inventoryData: inventoryDataMapped,
    modelName: getModelName(state),
    showVehicleLocation: !isEnterpriseOrder && (!isDeliverySelectionEnabled || showDeliverySelectionLocation),
    showVehicleLocationDisclaimer,
    showModelYearOverviewPage,
    showModelYearVehicleDetails: isNewRedesign ? false : showModelYearVehicleDetails,
    showFormattedModelYear,
    showLocationPrefix,
    showDemoVin,
    hideVehicleTypeLabel: isNewRedesign ? true : hideVehicleTypeLabel,
    isInventoryVehicle: isInventory(state),
    isTransportFeeEnabled,
    transportFeeAmount: getTransportationFeeAmount(state),
    depositAmount: getDepositAmount(state),
    modelCode: getModel(state),
    showLeasePurchaseOption,
    isPickupOnlyEnabled,
    showDeliveryAndRegistrationDisclaimer:
      !isEnterpriseOrder && showRegistrationAndDeliveryDisclaimer,
    deliveryAndRegistrationDisclaimer: getDeliveryAndRegistrationDisclaimer({
      state,
      vehicle: inventoryData || selectedDesign,
    }),
    deliveryDisclaimer: getDeliveryRegionDisclaimer(state),
    vehicleMapped: getVehicleMappedForLocation(state),
    vehicleSummaryProps: showVehicleDetailsSection
      ? {
          centered: false,
          isCardView: isNewRedesign,
          unordered: isNewRedesign ? false : true,
          titleSection: isNewRedesign ? '' : i18n('Inventory.vehicleDetails.label'),
          titleSectionClass: 'tds-text--h2 tds--vertical_padding',
          isTransportFeeEnabled: isDeliverySelectionEnabled ? false : isTransportFeeEnabled,
          isEnterpriseOrder,
          isNewRedesign,
          isPreOrderSwap,
          showHeaderInfo: true & isNewRedesign,
          headerInfoData: {
            pricing: vehiclePrice,
            secondaryTitle: isPreOrderSwap ? modelBadge : getVehicleBadgeCopy({
              latestYear,
              trimFamilyGroup,
              currentYear,
              showNewVehicle: !isPreOrderSwap,
              vehicleYear: Year,
              isDemo: IsDemo,
              hasDamageDisclosure: showRepairedInTitle,
              isUsedInventory,
              showOdometer,
              odometer: Odometer,
              hideVehicleTypeLabel,
              countryCode: CountryCode,
            }),
            secondaryBadge,
          },
        }
      : {},
    showVehicleDetailsLineItems,
    showOdometer,
    showInTransitStatusMetro: !(isEnterpriseOrder || isDeliverySelectionEnabled) && showInTransitStatusMetro,
    isPostOrderSwap,
    isPreOrderSwap,
    isEnterpriseOrder,
    featureOptions: isPostOrderSwap ? selectedOptions : featureOptions,
    additionalInfoAfterButton: inventoryOptionsDisclaimer ? [
      (
        <div className={classnames('tds-text--caption', {'tds--vertical_padding-bottom--4x': isLayoutMobile || isLayoutTablet})}>
          <sup>*</sup>{inventoryOptionsDisclaimer}
        </div>
      )] : [],
    enableCyberpunk,
    includeFutureYear: [MODEL_3, MODEL_Y].includes(modelCode),
    hideInventoryVin,
    titleSubStatus,
    showCleanHistory:cleanHistory && !isEMEA && isUsedInventory,
    repairedHistory,
    showDamageDisclosure,
    ddFile: guid ? filePath + guid : '',
    DamageDisclosureLatest,
    isEMEA,
    isRegionNA,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    saveUserViewedFiles: payload => {
      dispatch(saveUserViewedDisclosures(payload));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InventorySummary);
