import { useDispatch } from 'react-redux';

const useAction = actionType => {
  const dispatch = useDispatch();

  return (payload, assets) => {
    if (typeof actionType === 'string') {
      dispatch({ type: actionType, payload });
      return;
    }

    if (typeof actionType === 'function') {
      const actionReturn = actionType(payload, assets);
      if (typeof actionReturn === 'function') {
        actionReturn(dispatch);
      } else {
        dispatch(actionReturn);
      }
      return;
    }

    dispatch(payload);
  };
};

export default useAction;
