import React from 'react';
import PropTypes, { bool, number, string } from 'prop-types';
import {
  getCustomPricingIndicatorV2,
  constructUrl,
  getApplicableExtraIncentives,
  getUiOptionsNotInConfiguration,
  i18n,
  parseSelectedBy,
  cleanSpaces,
  getVatRateForForwardCalculation,
} from 'utils';
import classnames from 'classnames';

import { connect } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _toString from 'lodash/toString';
import _find from 'lodash/find';
import Analytics from 'analytics';
import {
  getPrice,
  getDestinationAndDocFee,
  getVATAmount,
  getVATPercent,
  getDownPayment,
  getFinanceTabs,
  getLeaseMileage,
  getLoanDistance,
  getLeaseTerm,
  getLoanTerm,
  getLoanInterestRate,
  getDisclaimer,
  getFinanceType,
  shouldExcludeDefaultDownpaymentVAT,
  getInventoryBasePrice,
  getSelectedFinanceProduct,
  getFinanceProductId,
  getDepositAmount,
  getShowExVat,
  isInventory,
  isReservation,
  getFinanceProductData,
  getIncentivesTotal,
  getProductTypeMapping,
  getFinancialIncentives,
  getModelName,
  getAddOnToGrossPrice,
  getPriceExcludingCoE,
  getRegistrationAge,
  getFinanceProductType,
  getExtraPricingContextAmounts,
  getLexiconBaseGroupForInventory,
  getReferralCredit,
  getTrimCode,
  getInVehicleUnitFee,
  getVehicleUpgradesTotal,
  getFinplatFormFields
} from 'selectors';
import { formatDate } from '@tesla/intl-date-time';
import { Calculator, DSServices } from '@web/tesla-rest-ds-services';

import {
  FinanceTypes,
  INCENTIVE_ECO,
  INCENTIVE_GOVERNMENT_GRANT,
  INCENTIVE_SELLER_DISCOUNT,
  INCENTIVE_PLUGIN_GRANT,
  TAX_REGISTRATION,
  TAX_REGIONAL,
  TAX_RECYCLING_FEE,
  TAX_TIRE_FEE,
  TAX_WINTER_TIRE_FEE,
  TAX_SPECIAL,
  TAX_LUXURY,
  INVENTORY_USED,
  INVENTORY_NEW,
  WEIGHT_TAX,
} from 'dictionary';
import { handleV2Deltas, handleDeltas } from '../../reducers';
import * as SummaryPanelReducer from '../../reducers/SummaryPanel';
import {
  formatCurrency,
  formatNumber,
  formatMonthlyPrice,
  formatPercent,
} from '@tesla/coin-common-components';
import { getLegalBannerClassName } from '../LegalBanner';

/**
 * Since we switched to Gotham SSM, we cannot have any non-breaking spaces (&nbsp;) in the
 * translation strings, as this will break the PDF rendering. So we filter them out.
 *
 * @param key
 * @param vars
 * @param contextVal
 * @param props
 * @returns {*}
 */
const pdfString = (key, vars = null, contextVal = null, props = {}) => {
  return cleanSpaces(i18n(key, vars, contextVal, props));
};

const addQuery = (path, query) => {
  let nQuery = query.replace('?', '');
  nQuery = !nQuery.charAt(0).includes('&') ? `&${nQuery}` : nQuery;
  return path.includes('?') ? `${path}${nQuery}` : `${path}?${nQuery}`;
};

const applySpecsOverrides = (data, configuration) => {
  const overrides = _get(data, 'overrides', []);
  if (!overrides.length) {
    return data;
  }
  const overridesData = _find(overrides, override => {
    const selectedBy = _get(override, 'selected_by', {});
    if (_isEmpty(selectedBy)) {
      return false;
    }
    return parseSelectedBy(selectedBy, configuration);
  });
  if (_isEmpty(overridesData)) {
    return data;
  }
  const specsOverrides = _get(overridesData, 'content', {});
  const specsData = { ...data, ...specsOverrides };
  return specsData;
};

class PrintPdfButton extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      payload: null,
    };
  }

  handlePrintPdfKey = e => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      this.handlePrintPdf();
    }
  };

  handlePrintPdf = () => {
    const {
      modelName,
      modelCode,
      trimName,
      trimCode,
      configuration,
      options,
      customGroups,
      beforeSavingsPrice,
      useEPAunits,
      compositor,
      hideDestinationDocFee,
      destinationAndDocFee,
      recyclingFee,
      tireFee,
      winterTireFee,
      specialTax,
      showRegistrationTax,
      registrationTax,
      firstRegistrationTax,
      governmentGrant,
      governmentIncentivesTotal,
      governmentIncentivesPeriod,
      showRegionalTaxTypes,
      regionalTaxValues,
      deliveryFee,
      showDistinctTaxTotal,
      OMVICFee,
      onRoadfeesTotal,
      extraIncentives,
      sibling,
      locale,
      countryCode,
      regionalTax,
      showRegionalTax,
      vatAmount,
      vatPercent,
      summaryRegionalFees,
      vatAmountPrecision,
      useDynamicRounding,
      showVAT,
      hideTaxesAndFeesExcluded,
      disclaimerIntlDateFormat,
      registrationType,
      disclaimerPrecedings,
      financeProducts,
      includeFeesinBatteryCalculationPayment,
      useOptionPriceOverridePayment,
      trimOptions,
      regionCode,
      registrationDetail,
      showDriveAwayPrice,
      depositAmount,
      vehiclePriceInclFees,
      gstSalePrice,
      governmentGrantLabel,
      stateContribution,
      sellerDiscount,
      compositorOptions,
      assetView,
      CustomGroups,
      configuration_group,
      batteryGroupSpecs,
      incentivesTotal,
      showDriveAwayPricewithIncentives,
      showZeroRegistrationTax,
      driveAwayPricewithIncentives,
      isInventory,
      isInventoryPriceAdjustmentsEnabled,
      regionalIncentive,
      regionName,
      regionalFeeRebate,
      additionalRegistrationFee,
      vesRebate,
      eeai,
      netArfPayable,
      roadTax,
      showVehicleTotalWithTax,
      pricingData,
      vehicleCondition,
      priceExcludingCoE,
      coeBidAmount,
      productType,
      totalPriceInDoubleCurrency,
      conversionRate,
      intlCurrency,
      weightTax,
      luxuryTax,
      baseGroupFromLexicon,
      discount,
      referralCredit,
      inUnitFee,
      showLuxuryTaxAndStampDuty,
      driverType,
      vatRateForForwardCalculation,
    } = this.props;

    const regionalTaxTypesOrder = [
      'luxury_cost_tax',
      'local_stamp_duty',
      'local_reg_costs',
      'local_ctp',
      'plate_fee',
      'delivery_fee',
      'additional_registration_fee',
      'ves_rebate',
      'eeai',
      'net_arf_payable',
      'registration_fee',
      'governmentIncentive',
      'sellerDiscount',
      'destinationDocfee',
      'omvic_fee',
      'onRoadfeesTotal',
      'road_tax',
    ];

    // First, trigger a GA event for this interaction
    Analytics.fireTagEvent({
      event: Analytics.event,
      'cfg-type': modelCode,
      interaction: 'save-design-download-pdf',
    });

    let showRegionalTaxTypesSorted = [];

    if (showRegionalTaxTypes && showRegionalTaxTypes.length > 0) {
      showRegionalTaxTypesSorted = [
        ...showRegionalTaxTypes,
        'onRoadfeesTotal',
      ];

      showRegionalTaxTypesSorted = showRegionalTaxTypesSorted.sort((a, b) => {
        const indexOfA = regionalTaxTypesOrder.indexOf(a);
        const indexOfB = regionalTaxTypesOrder.indexOf(b);

        if (indexOfA > indexOfB) {
          return 1;
        }

        if (indexOfA < indexOfB) {
          return -1;
        }

        return -1;
      });
    }

    // This prevents for example the fr_FR bonus to show up for lease/loan,
    // and prevent to show in IT line items.
    const showGovernmentIncentiveInFees = (
      summaryRegionalFees ||
      !['included', 'includedInPurchasePrice', 'includedInSavings'].includes(governmentIncentivesPeriod) &&
      showRegionalTaxTypesSorted.includes('governmentIncentive')
    );

    const feeItems = [
      ...showRegionalTaxTypesSorted.map(regionalTaxLabel => {
        switch (regionalTaxLabel) {
          case 'luxury_cost_tax':
            return {
              show: showLuxuryTaxAndStampDuty,
              label: pdfString('FinancingOptions.luxury_cost_tax'),
              value: formatCurrency(regionalTaxValues?.luxury_cost_tax),
            };
          case 'local_stamp_duty':
            return {
              show: showLuxuryTaxAndStampDuty,
              label: pdfString('FinancingOptions.local_stamp_duty'),
              value: formatCurrency(regionalTaxValues?.local_stamp_duty),
            };
          case 'local_reg_costs':
            return {
              show: _get(regionalTaxValues, 'local_reg_costs') > 0,
              label: pdfString('FinancingOptions.local_reg_costs'),
              value: formatCurrency(regionalTaxValues.local_reg_costs),
            };
          case 'delivery_fee':
            return {
              show: deliveryFee > 0,
              label: pdfString('FinancingOptions.delivery_fee'),
              value: formatCurrency(deliveryFee),
            };
          case 'registration_fee':
            return {
              show: registrationTax > 0,
              label: pdfString('SummaryPanel.disclaimers.registrationTax'),
              value: formatCurrency(registrationTax),
            };
          case 'governmentIncentive':
            return {
              show: Math.abs(governmentIncentivesTotal) > 0,
              label: pdfString('FinancingOptions.governmentIncentive'),
              value: cleanSpaces(formatCurrency(-1 * Math.abs(governmentIncentivesTotal))),
            };
          case 'sellerDiscount':
            return {
              show: Math.abs(sellerDiscount) > 0,
              label: pdfString('FinancingOptions.sellerDiscount'),
              value: cleanSpaces(formatCurrency(-1 * Math.abs(sellerDiscount))),
            };
          case 'destinationDocfee':
            const destinationFeeLabel = pdfString('FinancingOptions.destinationDocfee', null, null, {
              returnNullWhenEmpty: true,
            });
            return {
              show: destinationAndDocFee && !showDistinctTaxTotal && !hideDestinationDocFee,
              label: destinationFeeLabel || pdfString('SummaryPanel.destination_fee'),
              value: cleanSpaces(formatCurrency(destinationAndDocFee)),
            };
          case 'omvic_fee':
            return {
              show: OMVICFee > 0,
              label: pdfString('SummaryPanel.disclaimers.specialFee'),
              value: formatCurrency(OMVICFee),
            };
          case 'onRoadfeesTotal':
            return {
              show: onRoadfeesTotal,
              label: pdfString('FinancingOptions.vehicle_subTotalInclFees'),
              value: formatCurrency(onRoadfeesTotal),
            };
          case 'ves_rebate':
            return {
              show: vesRebate,
              label: pdfString('SummaryPanel.disclaimers.vesRebate', null, null, {
                specificOverride: [trimCode],
                returnNullWhenEmpty: true,
                specificOverrideOperator: 'OR',
              }),
              value: formatCurrency(vesRebate),
            };
          case 'additional_registration_fee':
            return {
              show: additionalRegistrationFee > 0,
              label: pdfString('SummaryPanel.disclaimers.additionalRegistrationFee'),
              value: formatCurrency(additionalRegistrationFee),
            };
          case 'eeai':
            return {
              show: eeai,
              label: pdfString('SummaryPanel.disclaimers.eeai'),
              value: formatCurrency(eeai),
            };
          case 'net_arf_payable':
            return {
              show: netArfPayable > 0,
              label: pdfString('SummaryPanel.disclaimers.netArfPayable'),
              value: formatCurrency(netArfPayable),
            };
          case 'road_tax':
            return {
              show: roadTax > 0,
              label: pdfString('SummaryPanel.disclaimers.roadTax'),
              value: formatCurrency(roadTax),
            };
          default:
            return {
              show: _get(regionalTaxValues, regionalTaxLabel) > 0,
              label: pdfString(`FinancingOptions.${regionalTaxLabel}`),
              value: formatCurrency(regionalTaxValues[regionalTaxLabel]),
            };
        }
      }),
      {
        show: (baseGroupFromLexicon || isInventoryPriceAdjustmentsEnabled) && discount,
        label: pdfString('Review.price_adjustment_label'),
        value: cleanSpaces(formatCurrency(-discount * vatRateForForwardCalculation)),
      },
      {
        show: !showVehicleTotalWithTax && referralCredit?.credit && referralCredit?.includedInPayments,
        label: pdfString('Referral.referrer_name'),
        value: cleanSpaces(formatCurrency(-referralCredit?.credit)),
      },
      {
        show: !!tireFee,
        label: pdfString('SummaryPanel.disclaimers.tireFee'),
        value: cleanSpaces(tireFee),
      },
      {
        show: !!winterTireFee,
        label: pdfString('SummaryPanel.disclaimers.winterTireFee'),
        value: cleanSpaces(winterTireFee),
      },
      {
        show: specialTax > 0,
        label: pdfString('SummaryPanel.disclaimers.specialTax'),
        value: formatCurrency(specialTax),
      },
      {
        show: showRegistrationTax && registrationTax,
        label: pdfString('SummaryPanel.disclaimers.registrationTax'),
        value: formatCurrency(registrationTax),
      },
      {
        show: governmentGrant > 0,
        label: pdfString('SummaryPanel.disclaimers.governmentGrant'),
        value: formatCurrency(governmentGrant),
      },
      {
        show: showRegionalTax && regionalTax > 0,
        label: pdfString('SummaryPanel.disclaimers.regionalTax'),
        value: formatCurrency(regionalTax),
      },
      {
        show: firstRegistrationTax > 0,
        label: pdfString('SummaryPanel.disclaimers.firstRegistrationTax'),
        value: formatCurrency(firstRegistrationTax),
      },
      {
        show: destinationAndDocFee > 0 && !hideDestinationDocFee,
        label: pdfString('SummaryPanel.destination_fee'),
        value: cleanSpaces(formatCurrency(destinationAndDocFee)),
      },
      {
        show: showGovernmentIncentiveInFees && !!governmentIncentivesTotal,
        label: pdfString(`SummaryPanel.disclaimers.${governmentGrantLabel}`),
        value: cleanSpaces(formatCurrency(-1 * Math.abs(governmentIncentivesTotal))),
      },
      {
        show: !!recyclingFee,
        label: pdfString('SummaryPanel.disclaimers.recyclingFee'),
        value: cleanSpaces(recyclingFee),
      },
      {
        show: weightTax > 0,
        label: pdfString('SummaryPanel.disclaimers.weightTax'),
        value: cleanSpaces(formatCurrency(weightTax)),
      },
      {
        show: luxuryTax > 0,
        label: pdfString('SummaryPanel.PaymentSummary.luxury_tax'),
        value: cleanSpaces(formatCurrency(luxuryTax)),
      },
    ].filter(Boolean);

    const getOptionsByGroup = groupName => {
      const { options: _opts = [], groups = [] } = customGroups[groupName] || {};

      if (!Array.isArray(_opts) || !Array.isArray(groups)) {
        return [];
      }

      let opts = _opts;
      if (isInventory) {
        const oldOptionCodes = configuration_group['options_by_group'][groupName] || [];
        opts = _opts.concat(oldOptionCodes);
      }

      return [
        ...opts,
        ...groups.reduce(
          (prevOpts, _groupName) => [...prevOpts, ...getOptionsByGroup(_groupName)],
          []
        ),
      ];
    };

    const optionsByGroup = Object.keys(customGroups).reduce(
      (previousGroups, groupName) => ({
        ...previousGroups,
        [groupName]: getOptionsByGroup(groupName),
      }),
      {}
    );

    const mapOptions = opts => {
      if (!Array.isArray(opts)) {
        return [];
      }

      return opts
        .map(optionCode => {
          let option = options.find(({ code }) => code === optionCode);

          if (!option && typeof optionCode === 'object') {
            option = options.find(({ code }) => code === optionCode.code);
          }

          // skip unselected options
          if (typeof option === 'undefined') {
            return null;
          }

          let { long_name: label, formattedPrice } = option;

          // Prevent of premiumConnectivityTrial in legacy cars option to print as an object
          if (option?.code === 'PCT1M' && typeof option?.long_name === 'object') {
            label = option?.name;
          }

          const pricingIndicator = getCustomPricingIndicatorV2(
            [option], // options
            configuration, // configuration
            pricingData, // priceData
            includeFeesinBatteryCalculationPayment, // includeFeesinBatteryCalculation
            useOptionPriceOverridePayment && trimOptions.includes(option.code), // useOptionPriceOverridePayment
            CustomGroups, // CustomGroups
            true // paymentPageOverride
          );
          const formattedPricingIndicator =
            typeof pricingIndicator === 'number'
              ? formatCurrency(pricingIndicator)
              : pricingIndicator;
          const value = pricingIndicator !== false ? formattedPricingIndicator : formattedPrice;

          return {
            label: cleanSpaces(label),
            value: cleanSpaces(value),
          };
        })
        .filter(Boolean);
    };

    const isLegacy = _get(window, 'tesla.product.data.IsLegacy', false);

    let groups = [];

    if (isLegacy) {
      groups = [
        {
          label: pdfString('pdf.labels.summary'),
          options: mapOptions(options),
        },
      ];
    } else {
      // @TODO: move pdf group to Lexicon?! (context?)
      groups = [
        {
          label: pdfString('pdf.labels.summary'),
          groups: ['CORE_CONFIGURATOR'],
        },
        {
          label: pdfString('pdf.labels.design'),
          groups: [
            'DRIVE',
            'PAINT',
            'SPORT_PACKAGE_OPTIONS',
            'WHEELS',
            'WINTER_WHEELS_OPTIONS',
            'INTERIOR',
            'INTERIOR_PACKAGE',
            'INTERIOR_FEATURES',
            'REAR_SEATS',
          ],
        },
        {
          label: pdfString('pdf.labels.features'),
          groups: [
            'STEERING_WHEEL',
            'AUTOPILOT',
            'AUTOPILOT_OWNERSHIP',
            'AUTOPILOT_PACKAGE',
            'TOWING',
            'COMFORT_PACKAGE',
            'PERF_FIRMWARE',
            'SUPER_CHARGER',
            'HEATED_SEAT',
          ],
        },
      ].reduce((prevGroups, { label, groups: _groups }) => {
        return [
          ...prevGroups,
          {
            label,
            options: _groups.reduce((prevOptions, groupName) => {
              return [...prevOptions, ...mapOptions(optionsByGroup[groupName])].filter(
                (v, i, arr) => {
                  return arr.findIndex(({ label: l }) => l === v.label) === i;
                }
              );
            }, []),
          },
        ];
      }, []);
    }

    if (showVehicleTotalWithTax) {
      let gstLabel = pdfString(
        'SummaryPanel.disclaimers.vehicle_GST',
        {
          GST: formatCurrency(gstSalePrice),
          VAT_AMOUNT: formatCurrency(vatAmount),
        },
        null,
        {
          specificOverride: [vehicleCondition],
          returnNullWhenEmpty: true,
          specificOverrideOperator: 'OR',
        }
      );
      gstLabel = gstLabel ? `(${gstLabel})` : '';
      groups.push({
        options: [
          destinationAndDocFee
            ? {
                show: destinationAndDocFee > 0,
                label: pdfString('FinancingOptions.destinationDocfee'),
                value: formatCurrency(destinationAndDocFee),
              }
            : {},
          {
            label: pdfString('Review.order_fee'),
            value: formatCurrency(depositAmount),
          },
          inUnitFee
            ? {
                show: inUnitFee > 0,
                label: pdfString('Review.inVehicleUnit'),
                value: formatCurrency(inUnitFee),
              }
            : {},
          referralCredit?.credit && referralCredit?.includedInPayments
            ? {
                label: pdfString('Referral.referrer_name'),
                value: cleanSpaces(formatCurrency(-referralCredit?.credit)),
              }
            : {},
          {
            label: `<strong>${pdfString('FinancingOptions.vehicle_subTotal')} ${gstLabel}</strong>`,
            value: `<strong>${formatCurrency(vehiclePriceInclFees)}</strong>`,
          },
        ],
      });
    }

    const pricesFeeItems = feeItems.filter(({ show }) => show);

    const purchasePriceLabel = showDriveAwayPrice
      ? 'FinancingOptions.drive_away_price'
      : 'Review.purchase_price';

    // this is required to show AU incentives per region after Drive away price

    let priceAfterSubsidy = [];
    if (showDriveAwayPricewithIncentives && incentivesTotal) {
      priceAfterSubsidy = [
        {
          show: regionalIncentive,
          label: pdfString('Review.regional', { LABEL: regionName }),
          value: formatCurrency(regionalIncentive),
        },
        {
          show: regionalFeeRebate,
          label: pdfString('FinancingOptions.stamp_duty_rebate'),
          value: formatCurrency(regionalFeeRebate),
        },
        {
          show: driveAwayPricewithIncentives,
          label: `<strong>${pdfString('SummaryPanel.price_after_subsidy')}</strong>`,
          value: `<strong>${formatCurrency(driveAwayPricewithIncentives)}</strong>`,
        },
      ];
    }
    const priceAfterIncentives = priceAfterSubsidy.filter(({ show }) => show);
    const pricesGroups = {
      options: [
        ...pricesFeeItems,
        {
          label: pdfString(purchasePriceLabel, null, null, {
            specificOverride: [vehicleCondition],
            returnNullWhenEmpty: true,
            specificOverrideOperator: 'OR',
          }),
          value: priceExcludingCoE || beforeSavingsPrice,
          total: true,
        },
        ...(totalPriceInDoubleCurrency
          ? [
              {
                label: '',
                value: totalPriceInDoubleCurrency,
              },
            ]
          : []),
        ...(coeBidAmount
          ? [
              {
                label: pdfString('SummaryPanel.coe_bid_label'),
                value: formatCurrency(coeBidAmount),
              },
              {
                label: pdfString('Review.purchase_price_with_coe'),
                value: beforeSavingsPrice,
                total: true,
              },
            ]
          : []),
        ...priceAfterIncentives,
      ],
    };

    // If we have some items to show here, we will add a label
    if (pricesFeeItems.length) {
      pricesGroups.label = pdfString('pdf.labels.pricesAndIncentives');
    }

    // Add the prices group at the end of the list
    groups.push(pricesGroups);

    let specs = null;

    const batterySpecs = _get(batteryGroupSpecs, `${trimCode}.rawData`, {});
    const specsOverrides = applySpecsOverrides(batterySpecs, configuration);

    const {
      range,
      topspeed: top_speed,
      acceleration: zero_to_road_limit,
      range_units,
      range_units_epa,
      range_units_override,
      use_units_override = false,
    } = specsOverrides;

    const unitPostfix = use_units_override ? '_override' : '';

    const range_units_short = _get(
      batterySpecs,
      `range_units_short${unitPostfix}`,
      _get(batterySpecs, 'range_units_short', '')
    );
    const top_speed_units_short = _get(
      batterySpecs,
      `top_speed_units_short${unitPostfix}`,
      _get(batterySpecs, 'top_speed_units_short', '')
    );
    const zero_to_road_limit_units = _get(
      batterySpecs,
      `zero_to_road_limit_units${unitPostfix}`,
      _get(batterySpecs, 'zero_to_road_limit_units', '')
    );
    const speed_units_short = _get(
      batterySpecs,
      `speed_units_short${unitPostfix}`,
      _get(batterySpecs, 'speed_units_short', '')
    );
    const top_speed_units = _get(
      batterySpecs,
      `top_speed_units${unitPostfix}`,
      _get(batterySpecs, 'top_speed_units', '')
    );

    let rangeUnitLabel = (useEPAunits ? range_units_epa : range_units) || '';

    // force range_units_tds instead of the link version
    let rangeUnitOverride;
    if (range_units_override === 'range_units_tds_link') {
      rangeUnitOverride = 'range_units_tds';
    } else {
      rangeUnitOverride = range_units_override;
    }

    if (!_isEmpty(range_units_override)) {
      rangeUnitLabel = batterySpecs[rangeUnitOverride] || rangeUnitLabel;
      if (
        rangeUnitOverride === 'range_units_epa_link' ||
        rangeUnitOverride === 'range_units_est_link'
      ) {
        const postFix = _get(rangeUnitLabel, 'postFix', false);
        const label = _get(rangeUnitLabel, 'label', '');
        const content = _get(rangeUnitLabel, 'content', '');
        rangeUnitLabel = postFix ? `${content} ${label}` : `${label} ${content}`;
      }
    }

    if (isLegacy) {
      const specsOptions = _get(window, 'tesla.product.data.OptionCodeSpecs.C_SPECS.options', []);
      const labelMap = {
        SPECS_RANGE: rangeUnitLabel,
        SPECS_TOP_SPEED: top_speed_units,
        SPECS_BATTERY_DRIVE: top_speed_units,
        SPECS_ACCELERATION: zero_to_road_limit_units,
      };
      const valueLabelMap = {
        SPECS_RANGE: range_units_short,
        SPECS_TOP_SPEED: top_speed_units_short,
        SPECS_BATTERY_DRIVE: top_speed_units_short,
        SPECS_ACCELERATION: speed_units_short,
      };

      specs = specsOptions
        .sort(({ code }) => {
          if (code === 'SPECS_RANGE') {
            return 1;
          }

          if (code === 'SPECS_TOP_SPEED' || code === 'SPECS_BATTERY_DRIVE') {
            return 2;
          }

          if (code === 'SPECS_ACCELERATION') {
            return 3;
          }

          return 0;
        })
        .map(({ code, name }) => {
          const valueParts = name.match(/((\d+ ?,?\.?)+?(\d+)?)?\d/g);
          const value = valueParts.shift();

          return {
            label: cleanSpaces(labelMap[code]),
            value: cleanSpaces(value),
            valueLabel: cleanSpaces(valueLabelMap[code]),
          };
        });
    } else if (Object.keys(specsOverrides).length > 0) {
      const zeroToLimit =
        typeof specsOverrides.acceleration === 'string'
          ? specsOverrides.acceleration
          : _toString(formatNumber(specsOverrides.acceleration, { precision: 1 }));

      specs = [
        {
          label: cleanSpaces(rangeUnitLabel),
          value: cleanSpaces(_toString(specsOverrides.range)),
          valueLabel: cleanSpaces(range_units_short),
        },
        {
          label: cleanSpaces(top_speed_units),
          value: cleanSpaces(_toString(specsOverrides.topspeed)),
          valueLabel: cleanSpaces(top_speed_units_short),
        },
        {
          label: cleanSpaces(zero_to_road_limit_units),
          value: cleanSpaces(zeroToLimit),
          valueLabel: cleanSpaces(speed_units_short),
        },
      ];
    } else if (range && top_speed && zero_to_road_limit) {
      const zeroToLimit =
        typeof zero_to_road_limit === 'string'
          ? zero_to_road_limit
          : _toString(formatNumber(zero_to_road_limit, { precision: 1 }));

      specs = [
        {
          label: cleanSpaces(rangeUnitLabel),
          value: cleanSpaces(_toString(range)),
          valueLabel: cleanSpaces(range_units_short),
        },
        {
          label: cleanSpaces(top_speed_units),
          value: cleanSpaces(_toString(top_speed)),
          valueLabel: cleanSpaces(top_speed_units_short),
        },
        {
          label: cleanSpaces(zero_to_road_limit_units),
          value: cleanSpaces(zeroToLimit),
          valueLabel: cleanSpaces(speed_units_short),
        },
      ];
    }

    let compositorUrl = '';
    if (compositorOptions) {
      compositorUrl = addQuery(
        _get(compositor, 'compositor_url', ''),
        `&view=${assetView}&size=1400&model=${modelCode}&options=${compositorOptions}`
      );
    } else {
      compositorUrl = _get(compositor, `views.${assetView}.url`, '');
    }
    compositorUrl = addQuery(compositorUrl, 'bkba_opt=2');
    const date = new Date();
    const disclaimer = pdfString('pdf.disclaimer', {
      DATE: formatDate(date, locale, disclaimerIntlDateFormat),
      REGION_CODE: regionCode,
      REGISTRANT_TYPE: pdfString(
        `Review.account_in_${registrationDetail.RegistrantType}`
      ).toLowerCase(),
    });
    const incentives = getApplicableExtraIncentives({
      incentives: extraIncentives,
      options: configuration,
      registrationType,
      governmentIncentivesTotal,
      registrationTax,
      showZeroRegistrationTax,
      winterTireFee,
      governmentGrant,
      sellerDiscount,
    })
      .filter(extraIncentive => {
        // we already display the recycling fee and tire fee as part of the fee items
        if (
          [TAX_RECYCLING_FEE, TAX_TIRE_FEE, TAX_WINTER_TIRE_FEE, TAX_SPECIAL, TAX_LUXURY, WEIGHT_TAX, INCENTIVE_SELLER_DISCOUNT].includes(
            extraIncentive
          )
        ) {
          return false;
        }

        // if the incentive is an eco bonus,
        // we possibly already display it as part of the regional tax types
        if (
          [INCENTIVE_ECO, INCENTIVE_PLUGIN_GRANT].includes(extraIncentive) &&
          showRegionalTaxTypesSorted.includes('governmentIncentive')
        ) {
          return false;
        }

        // we possibly already display the registration tax as part of the fee items
        if (extraIncentive === TAX_REGISTRATION && showRegistrationTax) {
          return false;
        }

        // we possibly already display the regional tax as part of the fee items
        if (extraIncentive === TAX_REGIONAL && showRegionalTax) {
          return false;
        }

        // we already display the government grant as part of the fee items
        if (extraIncentive === INCENTIVE_GOVERNMENT_GRANT && showGovernmentIncentiveInFees) {
          return false;
        }

        return true;
      })
      .map(extraIncentive =>
        pdfString(`Incentives.extraIncentives.${extraIncentive}`, {
          LINK_TARGET: '_blank',
          REGISTRATION_TAX: formatCurrency(registrationTax),
          REGIONAL_TAX: formatCurrency(regionalTax, { precision: 2 }),
          SPECIAL_TAX: formatCurrency(specialTax),
          LINK_SUPPORT_INCENTIVES: constructUrl('support/incentives?redirect=no', sibling, locale),
          GOVERNMENT_GRANT: formatCurrency(governmentGrant),
          INCENTIVES_INCLUDED_TOTAL: formatCurrency(Math.abs(governmentIncentivesTotal)),
          RECYCLING_FEE: recyclingFee,
          TIRE_FEE: tireFee,
          WINTER_TIRE_FEE: winterTireFee,
          STATE_CONTRIB: formatCurrency(Math.abs(stateContribution)),
          SELLER_DISCOUNT: formatCurrency(Math.abs(sellerDiscount)),
        }, null, {
          specificOverride: driverType
        })
      );
    if (showVAT) {
      incentives.unshift(
        pdfString('SummaryPanel.disclaimers.vatAmount', {
          VAT_AMOUNT: formatCurrency(vatAmount, {
            precision: vatAmountPrecision,
            dynamicRounding: useDynamicRounding,
          }),
          VAT_PERCENT: formatPercent(vatPercent),
        })
      );
    }

    if (!hideTaxesAndFeesExcluded) {
      incentives.unshift(
        pdfString('SummaryPanel.disclaimers.taxesAndFeesExcluded', null, null, {
          specificOverride: productType,
          returnNullWhenEmpty: true,
          specificOverrideOperator: 'OR',
        })
      );
    }

    if (totalPriceInDoubleCurrency) {
      incentives.unshift(
        pdfString('SummaryPanel.disclaimers.exchangeRateDisclaimer', {
          CONVERSION_RATE: formatCurrency(1 / conversionRate, {
            currency: intlCurrency,
            precision: 5,
          }),
        })
      );
    }

    const payload = {
      country: countryCode,
      modelName: isInventory && !trimName.includes(modelName) ? cleanSpaces(modelName) : '',
      modelCode: cleanSpaces(modelCode),
      trimName: cleanSpaces(trimName),
      compositorUrl,
      specs,
      groups,
      disclaimer,
      disclaimerPrecedings,
      incentives,
      financeProducts,
      vin: _get(window, 'tesla.vin'),
    };

    this.setState(
      {
        payload: JSON.stringify(payload, (key, value) => {
          if (typeof value === 'string') {
            return encodeURI(value);
          }

          return value;
        }),
      },
      () => this.formRef.current.submit()
    );
  };

  render() {
    const { isDownloadPDFEnabled, label, routes = {}, buttonDesign } = this.props;
    const { downloadPdf = '/configurator/api/v1/download-pdf' } = routes;

    if (!isDownloadPDFEnabled) {
      return null;
    }

    const { payload } = this.state;
    const { csrf_key = '', csrf_token = '' } = window?.teslaConfig ?? {};

    return (
      <form ref={this.formRef} action={downloadPdf} method="POST" rel="noopener noreferrer">
        <input type="hidden" name="payload" value={payload || ''} />
        <input type="hidden" name={csrf_key} value={csrf_token} />
        <input type="hidden" name="csrf_name" value={csrf_key} />
        <input type="hidden" name="csrf_value" value={csrf_token} />
        <button
          type="button"
          className={classnames({
            'tds-btn tds-btn--tertiary download-pdf-link tds-btn--large tds-btn--width-full': buttonDesign,
            'tds-flex-item tds-btn--link tds-btn--blue download-pdf-link tds-link--primary': !buttonDesign,
          })}
          onClick={this.handlePrintPdf}
          onKeyDown={e => this.handlePrintPdfKey(e)}
        >
          <If condition={!buttonDesign}>
            <span className="tsla-icon tsla-icon-download" />
          </If>
          {label}
        </button>
      </form>
    );
  }
}

/**
 * This will apply deltas and deltasV2 to the SummaryPanel initial state in order for us to
 * determine what will be the default finance type regardless of currently selected tab
 * (since switching tabs will overwrite the selected_tab state).
 *
 * @param InitialState
 * @param oms_params
 * @returns {*}
 */
const getDefaultFinanceType = (InitialState, oms_params) => {
  const { merge_method } = InitialState;
  let reducedState = handleDeltas(InitialState, oms_params, merge_method);
  if (InitialState.deltasV2) {
    reducedState = handleV2Deltas(InitialState, oms_params, merge_method);
  }
  return reducedState.selected_tab;
};

/**
 * This will return an array of finance products to show in the PDF.
 */
const financeDataFromState = state => {
  const omsParams = _get(window, 'tesla.omsParams', {});
  const { model, variant } = omsParams;
  const { InitialConfig = {} } = new DSServices({}).get(() => {}, { model, variant }, omsParams);
  const financeTabs = getFinanceTabs(state, { exCash: true });
  const selectedFinanceProduct = getSelectedFinanceProduct(state);
  const selectedFinanceType = getFinanceType(state);
  const defaultFinanceType = getDefaultFinanceType(
    SummaryPanelReducer.InitialState({ object: InitialConfig.SummaryPanel }),
    state.OMS.oms_params
  );
  const preferedFinanceProduct =
    selectedFinanceProduct === FinanceTypes.CASH ? defaultFinanceType : selectedFinanceProduct;

  const referralCredit = getReferralCredit(state);
  const { coeBidAmount = 0 } = state.FinancingOptions;

  // TWS-48996 ~ Grab interior view from lexicon group data or product data
  const defaultView = _get(state, 'Compositor.available_views.interior.current.view');
  const interiorView = _get(window, 'tesla.product.data.CompositorViews.interiorView', defaultView);

  let interiorImage = '';
  const compositorOptions =
    isInventory(state) || isReservation(state)
      ? _get(state, 'ReviewDetails.product.data.OptionCodeList', '')
      : '';

  if (compositorOptions) {
    const modelCode = _get(state, 'OMS.oms_params.model', '');

    interiorImage = addQuery(
      _get(state, 'Compositor.compositor_url', ''),
      `&view=${interiorView}&size=1400&model=${modelCode}&options=${compositorOptions}`
    );
  } else {
    interiorImage = _get(state, `Compositor.views.${interiorView}.url`, '');
  }

  interiorImage = addQuery(interiorImage, 'bkba_opt=2');

  return financeTabs
    .sort((firstEl, secondEl) => {
      // This sorting will define the order of the pages of the finance products.
      switch (preferedFinanceProduct) {
        case firstEl.id:
          return -1;
        case secondEl.id:
          return 1;
        default:
          return 0;
      }
    })
    .map(financeTab => {
      const unselectedOptions = getUiOptionsNotInConfiguration(state);
      const registrationType = _get(state, 'ReviewDetails.RegistrationDetail.RegistrantType', null);
      const accountType = _get(state, 'ReviewDetails.AccountDetail.AccountType');
      let customerType = registrationType || accountType;
      const regionCode = _get(state, 'SummaryPanel.region_code', '');
      const isSelectedFinanceType = financeTab.financeType === selectedFinanceType;
      const showOrderFee = _get(state, 'FinancingOptions.showOrderFee', false);
      const includeServiceFee = _get(state, 'SummaryPanel.includeServiceFee', []);
      const legalBannerElem = document.querySelector(`.${getLegalBannerClassName(financeTab.id).replace(' ', '.')}`);
      const legalBanner = legalBannerElem ? getComputedStyle(legalBannerElem).backgroundImage : '';
      const odometerMileage =
        parseFloat(_get(state, 'ReviewDetails.product.data.Odometer', 0)) || 0;
      const registrationCount = _get(
        state,
        'ReviewDetails.product.data.RegistrationDetails.registrationCount',
        0
      );
      const isDemo = _get(state, 'ReviewDetails.product.data.IsDemo', false);
      if (financeTab.id === 'lease.operational_lease_commercial') {
        customerType = 'business';
      }

      let updatedTab = {
        ...financeTab,
        downPayment: getDownPayment(state, {
          context: isSelectedFinanceType ? 'form' : 'printpdf',
          financeType: financeTab.financeType,
          excludeVAT: shouldExcludeDefaultDownpaymentVAT(state, {
            financeType: financeTab.financeType,
            financeProductId: financeTab.financeProductId,
          }),
          financeTabId: financeTab.id,
        }),
        headerImage: interiorImage,
        legalBanner,
      };
      const priceParams = {
        ...state.OMS.oms_params,
        financeType: financeTab.financeType,
        financeProductId: financeTab.financeProductId,
        downPayment: updatedTab.downPayment,
        customerType,
        options: state.Configuration.option_codes,
        extraFeeAmount: getAddOnToGrossPrice(state),
        regionCode,
        vehiclePrice: null, // This will trigger the price to be calculated based on options.
        deliveryFee: getDestinationAndDocFee(state),
        prevRegistered: registrationCount > 0 || odometerMileage > 50 || isDemo,
        newRegState:
          _get(state, 'ReviewDetails.product.data.RegistrationDetails.state', null) !== regionCode,
        orderFee: showOrderFee ? getDepositAmount(state) : 0,
      };
      const calculatorState = {
        Incentives: state.Financial.fms_incentives,
        Fees: state.Financial.fms_fees,
        Loan: state.Financial.fms_loan,
        Lease: state.Financial.fms_lease,
        Lexicon: state.OMS.lexicon,
        Configuration: {
          options: state.Configuration.option_codes,
          UnselectedOptions: unselectedOptions,
        },
      };

      // @todo make this more DRY together with FindMyTesla/Actions/index.js
      if (_get(state, 'ReviewDetails.product.isInventory')) {
        priceParams.vehiclePrice = getInventoryBasePrice(state) + getVehicleUpgradesTotal(state);
        priceParams.odometerMileage = parseFloat(
          _get(state, 'ReviewDetails.product.data.Odometer')
        );
        priceParams.inventory = (_get(state, 'ReviewDetails.product.isUsedInventory')
          ? INVENTORY_USED
          : INVENTORY_NEW
        ).toLowerCase();
        priceParams.inventoryDiscount = parseFloat(
          _get(state, 'ReviewDetails.product.data.Discount', 0)
        );
        priceParams.ActualVesselArrivalDate = _get(
          state,
          'ReviewDetails.product.data.ActualVesselArrivalDate',
          ''
        );
        priceParams.isDemo = isDemo;
        priceParams.firstRegistered =
          _get(state, 'ReviewDetails.product.data.RegistrationDetails.firstRegistered', null) ||
          _get(state, 'ReviewDetails.product.data.FirstRegistrationDate', null);
      }

      switch (financeTab.financeType) {
        case FinanceTypes.LEASE: {
          const aldDownPaymentPercent = _get(state, 'ALD.downPaymentPercent');
          const distance = isSelectedFinanceType ? getLeaseMileage(state, financeTab.id) : null;
          const term = isSelectedFinanceType ? getLeaseTerm(state, financeTab.id) : null;
          const priceData = Calculator.total(calculatorState, {
            ...priceParams,
            leaseDistance: distance,
            leaseTerm: term,
            includeServiceFee,
            aldDownPaymentPercent,
            isInventory: isInventory(state),
            priceContext: _get(state, 'App.pricingContext')
          });

          const showExVat = getShowExVat(state, {
            financeType: financeTab.financeType,
            financeProductId: financeTab.financeProductId,
          });
          const priceKey = showExVat ? 'monthlyPaymentWithoutVat' : 'monthlyPayment';
          const price = _get(priceData, `result.lease.${priceKey}`);
          const productData = getFinanceProductData(state, financeTab.id, FinanceTypes.LEASE);
          const uiSettings = _get(productData, 'uiSettings.pdf', {});
          const leaseFields = _get(state, 'PrintPdf.leaseFields', []);
          const fields = _get(uiSettings, 'leaseFields', leaseFields);
          const leaseServiceFees = _get(priceData, 'result.lease.leaseServiceFees', {});

          if (!price) {
            return null;
          }

          const usedTerm = _get(priceData, 'result.lease.leaseTerm', null);
          const usedDistance = _get(priceData, 'result.lease.leaseDistance', null);
          const usedDownPayment = _get(priceData, 'result.lease.downPayment', null);
          if (usedDownPayment || usedDownPayment === 0) {
            updatedTab.downPayment = usedDownPayment;
          }
          const usedDownPaymentPercent = _get(priceData, 'result.lease.downPaymentPercent', null);
          if (usedDownPaymentPercent) {
            updatedTab.downPaymentPercent =
              usedDownPaymentPercent < 1 ? usedDownPaymentPercent * 100 : usedDownPaymentPercent;
          }

          let downPaymentLabel = pdfString('FinancingOptions.downpaymentLabel');
          const leaseDownPaymentLabel = pdfString(
            'FinancingOptions.lease__downpaymentLabel',
            null,
            null,
            {
              specificOverride: getFinanceProductId(state, financeTab.id),
              returnNullWhenEmpty: true,
            }
          );

          if (leaseDownPaymentLabel !== null) {
            downPaymentLabel = leaseDownPaymentLabel;
          }

          const downPaymentDescription = pdfString(
            'FinancingOptions.lease__downPaymentDescription',
            { DOWNPAYMENT_MONTHS: usedDownPayment },
            null,
            {
              specificOverride: getFinanceProductId(state, financeTab.id),
              returnNullWhenEmpty: true,
            }
          );

          const downPaymentPath = _get(uiSettings, 'downPaymentPath', null);
          if (downPaymentPath) {
            updatedTab.downPayment = Math.abs(_get(priceData, downPaymentPath, 0));
          }

          updatedTab = {
            ...updatedTab,
            distance: usedDistance,
            term: usedTerm,
            price,
            lines: fields
              .map(field => {
                switch (field) {
                  case 'downPayment':
                    return {
                      label: downPaymentLabel,
                      value: cleanSpaces(
                        downPaymentDescription || formatCurrency(updatedTab.downPayment)
                      ),
                      features: downPaymentDescription !== null,
                    };
                  case 'downPaymentPercentage':
                    // Only used for 'static' percentages like in ALD products.
                    // It's not dynamically calculated at the moment.
                    return {
                      label: downPaymentLabel,
                      value: `${formatNumber(updatedTab.downPaymentPercent || 0, {
                        precision: 0,
                      })}%`,
                    };
                  case 'term':
                    return {
                      label: pdfString('common.term'),
                      value: pdfString('common.numMonths', {
                        NUM: cleanSpaces(formatNumber(usedTerm)),
                      }),
                    };
                  case 'distance':
                    return {
                      label: pdfString('FinancingOptions.lease__annualDistanceLabel'),
                      value: pdfString('FinancingOptions.lease__distanceOptionLabel', {
                        DISTANCE: formatNumber(usedDistance),
                      }),
                    };
                  case 'standardFeaturesList':
                    if (financeTab.financeProductId === 'operational_lease') {
                      return {
                        label: i18n('standardFeatures', 'common.ui'),
                        value: pdfString('FinancingOptions.features_list.standard'),
                        features: true,
                      };
                    }
                    return null;
                  case 'additionalFeaturesList':
                    if (
                      financeTab.financeProductId === 'operational_lease' &&
                      includeServiceFee.includes('operational_lease.includeServiceFee')
                    ) {
                      return {
                        label: i18n('includeServiceFee', 'common.ui'),
                        value: pdfString('FinancingOptions.features_list.additional'),
                        features: true,
                      };
                    }
                    return null;
                  case 'maintenanceService':
                  case 'tireService':
                    if (includeServiceFee.includes(`${financeTab.financeProductId}.${field}`)) {
                      return {
                        label: i18n(`FinancingOptions.lease_services.${field}`),
                        value: cleanSpaces(formatMonthlyPrice(leaseServiceFees[field])),
                      };
                    }
                    return null;
                  default:
                    return null;
                }
              })
              .filter(field => field !== null),
            total: {
              label: `${pdfString('SummaryPanel.payment__lease', null, null, {
                specificOverride: getFinanceProductId(state, financeTab.id),
              })}${showExVat ? ` ${pdfString('SummaryPanel.exVATpostfix')}` : ''}`,
              value: cleanSpaces(formatMonthlyPrice(price)),
            },
            disclaimer: cleanSpaces(
              getDisclaimer(state, {
                financeType: FinanceTypes.LEASE,
                priceData: priceData.result.lease,
                financeTabId: financeTab.id,
              })
            ),
          };
          break;
        }

        case FinanceTypes.LOAN: {
          let distance = isSelectedFinanceType ? getLoanDistance(state) : null;
          let term = isSelectedFinanceType ? getLoanTerm(state) : null;
          const loanApr = isSelectedFinanceType ? getLoanInterestRate(state) : null;
          const loanType = _get(state, 'SummaryPanel.loanType', {});
          const priceData = Calculator.total(calculatorState, {
            ...priceParams,
            loanDistance: distance,
            loanTerm: term,
            loanType,
            loanApr,
            balloonPayment: _get(state, 'SummaryPanel.balloonPayment', null),
            residualAmount: _get(state, 'SummaryPanel.residualAmount'),
            registrationAge: getRegistrationAge(state),
            isInventory: isInventory(state),
          });
          const price = _get(priceData, 'result.loan.monthlyPayment', 0);
          const productData = getFinanceProductData(
            state,
            financeTab.financeProductId,
            FinanceTypes.LOAN
          );
          const uiSettings = _get(productData, 'uiSettings.pdf', {});
          const loanFields = _get(state, 'PrintPdf.loanFields', []);
          const fields = _get(uiSettings, 'loanFields', loanFields);
          const loanFieldsAfterTotal = _get(state, 'PrintPdf.loanFieldsAfterTotal', []);
          const showInterestRate = _get(state, 'PrintPdf.showInterestRate', false);
          const loanTotalPostfix = _get(state, 'PrintPdf.loanTotalPostfix', null);
          const balloonValue = _get(priceData, 'result.loan.balloonPayment', 0);
          const aprAmount = _get(priceData, 'result.loan.interestRate', 0.0);
          const financedAmount = _get(priceData, 'result.loan.financedAmount', 0);

          // For all loan products, we show the downpayment amount:
          updatedTab.downPayment = _get(priceData, 'result.loan.downPayment.downPaymentAmount', 0);

          // For finance products other than the currently selected,
          // these params have been calculated in the Calculator.
          // We'll retrieve these and use it to display.
          if (selectedFinanceProduct !== financeTab.id) {
            distance = _get(priceData, 'result.loan.LoanAPI.variables.loanDistance', 0);
            term = _get(priceData, 'result.loan.LoanAPI.variables.loanTerm', 0);
          }

          if (showInterestRate) {
            updatedTab.apr = {
              label: pdfString('FinancingOptions.aprLabel', {}, null, {
                specificOverride: loanType,
              }),
              value: pdfString('FinancingOptions.apr', { APR: aprAmount }),
            };
          }

          let loanTotal = formatMonthlyPrice(price);
          if (loanTotalPostfix) {
            loanTotal += loanTotalPostfix;
          }

          updatedTab = {
            ...updatedTab,
            distance,
            term,
            price,
            lines: fields
              .map(field => {
                switch (field) {
                  case 'downPayment':
                    return {
                      label: pdfString('FinancingOptions.downpaymentLabel'),
                      value: cleanSpaces(formatCurrency(updatedTab.downPayment)),
                    };
                  case 'term':
                    return {
                      label: pdfString('common.term'),
                      value: pdfString('common.numMonths', {
                        NUM: formatNumber(term),
                      }),
                    };
                  case 'distance':
                    return {
                      label: pdfString('FinancingOptions.loan__loanDistanceLabel'),
                      value:
                        pdfString('FinancingOptions.mileageLabel').indexOf('{MILEAGE}') !== -1
                          ? pdfString('FinancingOptions.mileageLabel', {
                              MILEAGE: formatNumber(distance),
                            })
                          : formatNumber(distance),
                    };
                  case 'balloonPayment':
                    return {
                      label: pdfString('FinancingOptions.balloonPayment'),
                      value: formatCurrency(balloonValue),
                    };
                  case 'interestRate':
                    return {
                      label: pdfString('FinancingOptions.aprLabel'),
                      value: pdfString('FinancingOptions.apr', { APR: aprAmount }),
                    };
                  case 'financedAmount':
                    return {
                      label: pdfString('FinancingOptions.loan__financedAmountLabel'),
                      value: formatCurrency(financedAmount),
                    };
                  default:
                    return null;
                }
              })
              .filter(field => field !== null),
            total: {
              label: pdfString('SummaryPanel.payment__loan'),
              value: cleanSpaces(loanTotal),
            },
            linesAfterTotal: loanFieldsAfterTotal
              .map(field => {
                switch (field) {
                  case 'totalAmountPayable': {
                    const total = _get(priceData, 'result.loan.regional.totalAmtPaid', 0);
                    return {
                      label: pdfString('FinancingOptions.totalAmountPayable'),
                      value: formatCurrency(total),
                    };
                  }
                  default:
                    return null;
                }
              })
              .filter(field => field !== null),
            disclaimer: cleanSpaces(
              getDisclaimer(state, {
                financeType: FinanceTypes.LOAN,
                financeTabId: financeTab.id,
                priceData: priceData.result.loan,
              })
            ),
          };
          break;
        }

        case FinanceTypes.FINPLAT: {
          const financeProductData = getFinanceProductData(
            state,
            financeTab.id,
            FinanceTypes.FINPLAT
          );
          const { financeProductId } = financeTab;
          const showFinplatFields = getFinplatFormFields(state, financeProductId).flat();
          const { finplatUserInputs: inputs } = state.Forms || {};
          const userInputFlags = inputs?.flags || {};
          const savedUserInputs = inputs?.[`${financeProductId}`] || {};
          const userInput = {
            trimCode: getTrimCode(state),
          };
          const priceData = Calculator.total(calculatorState, {
            ...priceParams,
            financeProductData,
            financeProductType: getProductTypeMapping(state),
            userInput: {
              coeBid: coeBidAmount,
              ...userInputFlags,
              ...userInput,
              ...savedUserInputs,
            },
            vehiclePrice: (state.Pricing?.total - (referralCredit?.credit || 0)) ?? 0,
            vehiclePriceExAccessories: (
              state.Pricing?.calculatorResult?.data?.apiResults?.price?.vehiclePriceExAccessories ??
              state.Pricing?.total ??
              0
            ),
            vehiclePriceWithFees: _get(state, 'Pricing.cash.grossPrice', 0),
            isInventory: isInventory(state),
            optionCodes: state.Configuration.option_codes ?? []
          });

          const finplatData = _get(priceData, 'result.finplat', {});

          let monthlyPaymentLabel = pdfString('SummaryPanel.payment__finplat', null, null, {
            specificOverride: financeProductId,
          });
          const exVatPostfix = pdfString('SummaryPanel.exVATpostfix');
          const exVatMode = _get(financeProductData, 'parameters.exVatMode', false);
          if (exVatMode) {
            monthlyPaymentLabel = `${monthlyPaymentLabel} ${exVatPostfix}`;
          }

          const productType = _get(financeProductData, 'parameters.productType', '');

          const {
            monthlyPayment = null,
            calculatedDownPayment: calculatedDownPaymentOutput,
            maxResidualAmount,
            residualValue: residualAmount,
            balloonPayment
          } = finplatData?.output?.outputs ?? {};

          const {
            interestRate = 0
          } = finplatData?.output?.inputs ?? {};

          if (!monthlyPayment) {
            return null;
          }

          const cashDownPayment = _get(finplatData, 'output.inputs.cashDownPayment', 0);
          const vehiclePrice = _get(finplatData, 'output.inputs.vehiclePrice', 0);
          let normalizedCashDownPayment =
            cashDownPayment > 1 ? cashDownPayment : vehiclePrice * cashDownPayment;

          const calculatedDownPayment = !finplatData?.userInput?.cashDownPayment
            ? calculatedDownPaymentOutput
            : null;
          normalizedCashDownPayment = calculatedDownPayment || normalizedCashDownPayment;
          const distance = _get(finplatData, 'output.inputs.distanceAllowed', 0);

          const showReadOnlyDownPayment = showFinplatFields.includes('cashDownPaymentReadOnly');

          let lines = {
            cashDownPayment: {
              condition: !showReadOnlyDownPayment,
              label: pdfString('FinancingOptions.downpaymentLabel'),
              value: cleanSpaces(formatCurrency(normalizedCashDownPayment)),
            },
            cashDownPaymentReadOnly: {
              condition: showReadOnlyDownPayment,
              label: pdfString('FinancingOptions.downpaymentLabel'),
              value: cleanSpaces(formatCurrency(normalizedCashDownPayment)),
            },
            termLength: {
              condition: true,
              label: pdfString('common.term'),
              value: pdfString('common.numMonths', {
                NUM: formatNumber(_get(finplatData, 'output.inputs.termLength', 0)),
              }),
            },
            residualAmount: {
              condition: !!maxResidualAmount,
              label: pdfString('FinancingOptions.lease__residualAmount'),
              value: cleanSpaces(formatCurrency(residualAmount)),
            },
            distanceAllowed: {
              condition: !!distance,
              label: pdfString('FinancingOptions.lease__annualDistanceLabel'),
              value: pdfString('FinancingOptions.lease__distanceOptionLabel', {
                DISTANCE: formatNumber(distance),
              }),
            },
            userBalloonPaymentRate: {
              condition: !!balloonPayment,
              label: pdfString('FinancingOptions.balloonPayment'),
              value: formatCurrency(balloonPayment),
            },
            interestRate: {
              condition: true,
              label: pdfString('FinancingOptions.aprLabel', null, null, {
                specificOverride: productType
              }),
              value: pdfString('FinancingOptions.apr', { APR: formatNumber(interestRate * 100, { style: 'decimal', maxPrecision: 2 }) }),
            }
          };

          updatedTab = {
            ...updatedTab,
            lines: (showFinplatFields.length ? showFinplatFields?.map(field => lines[field]) : Object.values(lines)).filter(field => field?.condition),
            total: {
              label: [
                monthlyPaymentLabel,
                state.FinanceOptions?.showMonthlyPaymentQuote && '*'
              ].filter(Boolean).join(' '),
              value: cleanSpaces(formatMonthlyPrice(monthlyPayment)),
            },
            disclaimer: cleanSpaces(
              getDisclaimer(state, {
                financeType: FinanceTypes.FINPLAT,
                financeTabId: financeTab.id,
                priceData: finplatData,
              })
            ),
          };
          break;
        }

        default:
          break;
      }

      return updatedTab;
    })
    .filter(Boolean);
};

function mapStateToProps(state) {
  const {
    App = {},
    Configuration = {},
    Compositor = {},
    CustomGroups = {},
    Financial = {},
    FinancingOptions = {},
    FindMyTesla = {},
    Forms = {},
    OMS = {},
    Pricing = {},
    PrintPdf = {},
    ReviewDetails = {},
    SummaryPanel = {},
  } = state;

  const { useDynamicRounding = false, cashPricePrecision = 0 } = PrintPdf ?? {};
  const trimOption = (CustomGroups.TRIM?.currentSelected ?? []).find(opt => opt.selected);

  const { regionalIncentive = 0, regionName = '', regionalFeeRebate = 0 } =
    getFinancialIncentives(state) || {};

  const { apiResultsPerTrim, apiResults = {}, variables: PricindDataVariables } =
    Pricing?.calculatorResult?.data || {};

  const { fees: PricingDataFees = {}, incentives: PricingDataIncentives = {} } =
    PricindDataVariables ?? {};

  const { current: PricingApiFees = {} } = apiResults?.fees ?? {};
  const { current: PricingApiIncentives = {} } = apiResults?.incentives ?? {};

  const { vehiclePriceWithDeliveryFee = 0 } = Pricing?.cash || {};

  const totalPriceInDoubleCurrency = App.displayDoubleCurrency
    ? getExtraPricingContextAmounts(state)?.map(({ grossPrice, currency }) =>
        formatCurrency(grossPrice, { currency, useDynamicRounding: true })
      )
    : null;

  const intlCurrency = OMS.lexicon?.metadata?.pricing?.context_mapping?.configurator;

  return {
    options: [
      ...(ReviewDetails.base_configuration_options ?? []),
      ...(ReviewDetails.configuration_options ?? []),
    ],
    configuration: Configuration.option_codes ?? [],
    configuration_group: Configuration,
    customGroups: CustomGroups,
    beforeSavingsPrice: cleanSpaces(
      getPrice(state, FinanceTypes.CASH, {
        short: true,
        formatCurrencyOpts: {
          useDynamicRounding,
          precision: cashPricePrecision
        },
      })
    ),
    totalPriceInDoubleCurrency: cleanSpaces(totalPriceInDoubleCurrency?.toString()),
    useEPAunits: FindMyTesla.EPAunits?.display ?? false,
    trimName: CustomGroups.TRIM?.currentSelected?.[0]?.name ?? '',
    trimCode: trimOption?.code ?? '',
    modelName: getModelName(state),
    modelCode: OMS.oms_params.model ?? '',
    compositor: Compositor,
    hideDestinationDocFee: ReviewDetails.hideDestinationDocFee ?? false,
    destinationAndDocFee: getDestinationAndDocFee(state),
    weightTax: PricingDataFees.weight_tax?.total ?? 0,
    luxuryTax: PricingDataFees.luxury_tax?.total ?? 0,
    recyclingFee: SummaryPanel.formattedPrices?.recyclingFee ?? 0,
    tireFee: SummaryPanel.formattedPrices?.tireFee ?? 0,
    winterTireFee: SummaryPanel.formattedPrices?.winterTireFee ?? 0,
    specialTax: PricingDataFees.special_tax?.total ?? 0,
    showRegistrationTax: FinancingOptions.showRegistrationTax ?? true,
    registrationTax: PricingDataFees.registration?.total ?? 0,
    firstRegistrationTax: PricingApiFees.first_registration_tax?.total ?? 0,
    governmentGrant: PricingDataIncentives.government_grant?.total ?? 0,
    governmentIncentivesTotal: PricingApiIncentives.government?.total ?? 0,
    governmentIncentivesPeriod: PricingApiIncentives.government?.data?.[0]?.period ?? '',
    sellerDiscount: PricingApiIncentives.sellerDiscount?.total ?? 0,
    showRegionalTaxTypes: FinancingOptions.showRegionalTaxTypes ?? [],
    regionalTaxValues: Financial.fees?.current?.regional?.[0] ?? {},
    deliveryFee: PricingDataFees.delivery_fee?.total ?? 0,
    showDistinctTaxTotal: FinancingOptions.showDistinctTaxTotal ?? false,
    showLuxuryTaxAndStampDuty: FinancingOptions.showLuxuryTaxAndStampDuty ?? false,
    OMVICFee: PricingDataFees.OMVIC_fee?.total ?? 0,
    onRoadfeesTotal: PricingApiFees.regional?.total ?? null,
    regionalTax: PricingDataFees.regional?.total ?? 0,
    extraIncentives: ReviewDetails.extraIncentives ?? [],
    sibling: App.sibling ?? '',
    locale: App.locale ?? '',
    countryCode: App.countryCode ?? '',
    showRegionalTax: FinancingOptions.showRegionalTax ?? false,
    isDownloadPDFEnabled: !!(
      window?.tesla?.download_pdf_feature_enabled &&
      !App.isNativePaymentEnabled &&
      !App.isDm
    ),
    vatAmount: getVATAmount(state),
    vatPercent: getVATPercent(state),
    summaryRegionalFees: ReviewDetails.summaryRegionalFees ?? false,
    disclaimerIntlDateFormat: PrintPdf.intlDateFormat
      ? PrintPdf.intlDateFormat
      : Forms.disclaimerIntlDateFormat
      ? Forms.disclaimerIntlDateFormat
      : { dateStyle: 'long' },
    vatAmountPrecision: PrintPdf.vatAmountPrecision ?? 0,
    showVAT: ReviewDetails.showVAT ?? false,
    hideTaxesAndFeesExcluded: FinancingOptions.hideTaxesAndFeesExcluded ?? false,
    useDynamicRounding,
    registrationType: ReviewDetails.AccountDetail?.AccountType ?? null,
    disclaimerPrecedings: PrintPdf.disclaimerPrecedings ?? [],
    financeProducts: FinancingOptions.hideFinanceOnPrintPdf ? [] : financeDataFromState(state),
    includeFeesinBatteryCalculationPayment:
      FinancingOptions.includeFeesinBatteryCalculationPayment ?? false,
    useOptionPriceOverridePayment: FinancingOptions.useOptionPriceOverridePayment ?? false,
    trimOptions: CustomGroups.TRIM.options ?? [],
    regionCode: SummaryPanel.region_code ?? null,
    registrationDetail: ReviewDetails.RegistrationDetail ?? null,
    showDriveAwayPrice: ReviewDetails.showDriveAwayPrice ?? false,
    depositAmount: getDepositAmount(state),
    vehiclePriceInclFees: Math.abs(vehiclePriceWithDeliveryFee - FinancingOptions.coeBidAmount),
    gstSalePrice: PricingApiFees.regional?.data?.[0]?.variables?.gst_on_sale ?? null,
    governmentGrantLabel: PrintPdf.governmentGrantLabel ?? 'governmentIncentivesTotal',
    stateContribution: Financial.incentives?.current?.stateContribution?.[0]?.amount ?? 0,
    compositorOptions:
      isInventory(state) || isReservation(state)
        ? ReviewDetails.product?.data?.OptionCodeList ?? ''
        : '',
    assetView: ReviewDetails.assetView,
    CustomGroups,
    batteryGroupSpecs: CustomGroups.battery_group_specs?.options ?? {},
    showDriveAwayPricewithIncentives: FinancingOptions.showDriveAwayPricewithIncentives ?? false,
    driveAwayPricewithIncentives: Pricing.cash?.driveAwayPricewithIncentives ?? 0,
    incentivesTotal: getIncentivesTotal(state, { financeType: 'cash' }),
    showZeroRegistrationTax: ReviewDetails.showZeroRegistrationTax ?? false,
    isInventory: isInventory(state),
    isInventoryPriceAdjustmentsEnabled: App?.isInventoryPriceAdjustmentsEnabled ?? false,
    routes: App.routes,
    showVehiclePricePlusFees: ReviewDetails.showVehiclePricePlusFees ?? false,
    showAmountIncludingFeesInModal: FinancingOptions.showAmountIncludingFeesInModal ?? false,
    regionalIncentive,
    regionName,
    regionalFeeRebate,
    additionalRegistrationFee: PricingDataFees.additional_registration_fee?.total ?? 0,
    vesRebate: PricingDataIncentives.ves_rebate?.total ?? 0,
    eeai: PricingDataIncentives.eeai?.total ?? 0,
    netArfPayable: PricingDataFees.net_arf_payable?.total ?? 0,
    roadTax: PricingDataFees.road_tax?.total ?? 0,
    conversionRate:
      OMS.lexicon?.metadata?.copy?.conversion_rate?.[intlCurrency]?.[App.pricingContext] ?? 0,
    intlCurrency,
    showVehicleTotalWithTax: ReviewDetails.showVehicleTotalWithTax,
    pricingData: {
      ...apiResults,
      apiResultsPerTrim,
    },
    vehicleCondition: ReviewDetails.product?.condition,
    priceExcludingCoE: getPriceExcludingCoE(state),
    coeBidAmount: FinancingOptions.coeBidAmount,
    productType: getFinanceProductType(state),
    baseGroupFromLexicon: getLexiconBaseGroupForInventory(state),
    discount: ReviewDetails.product?.data?.Discount ?? 0,
    referralCredit: getReferralCredit(state),
    inUnitFee: getInVehicleUnitFee(state),
    driverType: FinancingOptions?.driverType ?? '',
    vatRateForForwardCalculation: getVatRateForForwardCalculation(state) || 1,
  };
}

PrintPdfButton.propTypes = {
  configuration: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  customGroups: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array]))
    .isRequired,
  beforeSavingsPrice: PropTypes.string.isRequired,
  useEPAunits: PropTypes.bool.isRequired,
  modelName: PropTypes.string.isRequired,
  modelCode: PropTypes.string.isRequired,
  trimName: PropTypes.string.isRequired,
  trimCode: PropTypes.string.isRequired,
  compositor: PropTypes.shape({ views: PropTypes.shape() }).isRequired,
  hideDestinationDocFee: PropTypes.bool,
  destinationAndDocFee: PropTypes.number,
  recyclingFee: PropTypes.number,
  tireFee: PropTypes.number,
  winterTireFee: PropTypes.number,
  specialTax: PropTypes.number,
  showRegistrationTax: PropTypes.bool,
  registrationTax: PropTypes.number,
  firstRegistrationTax: PropTypes.number,
  governmentGrant: PropTypes.number,
  regionalTaxValues: PropTypes.shape(),
  governmentIncentivesTotal: PropTypes.number,
  governmentIncentivesPeriod: PropTypes.string.isRequired,
  showRegionalTaxTypes: PropTypes.arrayOf(PropTypes.string),
  deliveryFee: PropTypes.number,
  showDistinctTaxTotal: PropTypes.bool,
  OMVICFee: PropTypes.number,
  onRoadfeesTotal: PropTypes.number,
  extraIncentives: PropTypes.arrayOf(PropTypes.string),
  locale: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  sibling: PropTypes.string.isRequired,
  regionalTax: PropTypes.number,
  showRegionalTax: PropTypes.bool,
  isDownloadPDFEnabled: PropTypes.bool,
  buttonDesign: PropTypes.bool,
  vatAmount: PropTypes.number,
  vatPercent: PropTypes.number,
  label: PropTypes.string,
  summaryRegionalFees: PropTypes.bool,
  vatAmountPrecision: PropTypes.number,
  showVAT: PropTypes.bool,
  useDynamicRounding: PropTypes.bool,
  hideTaxesAndFeesExcluded: PropTypes.bool.isRequired,
  disclaimerIntlDateFormat: PropTypes.shape({}).isRequired,
  registrationType: PropTypes.string.isRequired,
  disclaimerPrecedings: PropTypes.arrayOf(PropTypes.string).isRequired,
  financeProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
  includeFeesinBatteryCalculationPayment: PropTypes.bool.isRequired,
  useOptionPriceOverridePayment: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  trimOptions: PropTypes.array.isRequired,
  regionCode: PropTypes.string,
  registrationDetail: PropTypes.shape({}),
  showDriveAwayPrice: PropTypes.bool,
  depositAmount: PropTypes.number,
  vehiclePriceInclFees: PropTypes.number,
  gstSalePrice: PropTypes.number,
  governmentGrantLabel: PropTypes.string.isRequired,
  stateContribution: PropTypes.number,
  sellerDiscount: PropTypes.number,
  compositorOptions: PropTypes.string,
  assetView: PropTypes.string,
  CustomGroups: PropTypes.shape().isRequired,
  batteryGroupSpecs: PropTypes.shape().isRequired,
  incentivesTotal: number,
  showDriveAwayPricewithIncentives: PropTypes.bool,
  showZeroRegistrationTax: PropTypes.bool.isRequired,
  driveAwayPricewithIncentives: number,
  isInventory: PropTypes.bool,
  showVehiclePricePlusFees: bool,
  showAmountIncludingFeesInModal: bool,
  regionalIncentive: number,
  regionName: string,
  regionalFeeRebate: number,
  routes: PropTypes.shape(),
  additionalRegistrationFee: PropTypes.number,
  vesRebate: PropTypes.number,
  eeai: PropTypes.number,
  netArfPayable: PropTypes.number,
  roadTax: PropTypes.number,
  showVehicleTotalWithTax: PropTypes.bool,
  pricingData: PropTypes.shape(),
  vehicleCondition: PropTypes.string,
  priceExcludingCoE: PropTypes.string,
  configuration_group: PropTypes.shape({ options_by_group: PropTypes.shape() }),
  coeBidAmount: number,
  productType: string,
  totalPriceInDoubleCurrency: PropTypes.string,
  conversionRate: number,
  intlCurrency: PropTypes.string,
  weightTax: number,
  luxuryTax: number.isRequired,
  baseGroupFromLexicon: bool,
  discount: number,
  referralCredit: PropTypes.shape({}),
  inUnitFee: number,
  showLuxuryTaxAndStampDuty: bool,
  driverType: string,
  vatRateForForwardCalculation: number.isRequired,
};

PrintPdfButton.contextTypes = {
  i18n: PropTypes.func,
};

PrintPdfButton.defaultProps = {
  hideDestinationDocFee: false,
  destinationAndDocFee: 0,
  recyclingFee: 0,
  tireFee: 0,
  winterTireFee: 0,
  specialTax: null,
  showRegistrationTax: false,
  registrationTax: null,
  firstRegistrationTax: null,
  governmentGrant: null,
  governmentIncentivesTotal: 0,
  showRegionalTaxTypes: [],
  regionalTaxValues: {},
  deliveryFee: 0,
  showDistinctTaxTotal: false,
  OMVICFee: 0,
  onRoadfeesTotal: null,
  extraIncentives: [],
  regionalTax: 0,
  showRegionalTax: false,
  isDownloadPDFEnabled: false,
  buttonDesign: false,
  vatAmount: 0,
  vatPercent: 0,
  label: 'Download PDF',
  summaryRegionalFees: false,
  vatAmountPrecision: 0,
  showVAT: false,
  useDynamicRounding: false,
  regionCode: null,
  registrationDetail: null,
  showDriveAwayPrice: false,
  depositAmount: 0,
  vehiclePriceInclFees: 0,
  gstSalePrice: 0,
  stateContribution: 0,
  sellerDiscount: 0,
  compositorOptions: '',
  assetView: null,
  incentivesTotal: 0,
  showDriveAwayPricewithIncentives: false,
  driveAwayPricewithIncentives: 0,
  isInventory: false,
  showVehiclePricePlusFees: false,
  showAmountIncludingFeesInModal: false,
  regionalIncentive: 0,
  regionName: '',
  regionalFeeRebate: 0,
  routes: {},
  additionalRegistrationFee: 0,
  vesRebate: 0,
  eeai: 0,
  netArfPayable: 0,
  roadTax: 0,
  showVehicleTotalWithTax: false,
  pricingData: {},
  vehicleCondition: '',
  priceExcludingCoE: null,
  configuration_group: {},
  coeBidAmount: 0,
  productType: '',
  totalPriceInDoubleCurrency: '',
  conversionRate: 0,
  intlCurrency: '',
  weightTax: 0,
  baseGroupFromLexicon: false,
  discount: 0,
  referralCredit: {},
  inUnitFee: 0,
  showLuxuryTaxAndStampDuty: false,
  driverType: '',
};

export default connect(mapStateToProps)(PrintPdfButton);
