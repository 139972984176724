import React from 'react';
import { connect } from 'react-redux';
import { string, arrayOf, shape } from 'prop-types';
import { getTrimCode } from 'selectors';

const PostOrderDeposit = ({ postOrderDeposit, trimCode }) => {
  if (!postOrderDeposit) {
    return null;
  }
  return (
    <>
      {postOrderDeposit.map((item, index) => {
        const { title, label, value } = item || {};
        return (
          <div key={`PostOrderDeposit.${trimCode}_${index}`}>
            <p className="tds-o-padding_bottom-1x">{title}</p>
            <ol className="tds-list cf-summary-list">
              <li className="tds-list-item tds-flex">
                <span className="label--option tds-flex-item">{label}</span>
                <span className="value price-indicator tds-flex--col_1of3">{value}</span>
              </li>
            </ol>
          </div>
        );
      })}
    </>
  );
};

PostOrderDeposit.propTypes = {
  postOrderDeposit: arrayOf(
    shape({
      title: string,
      label: string,
      value: string,
    })
  ),
  trimCode: string,
};

PostOrderDeposit.defaultProps = {
  postOrderDeposit: null,
  trimCode: null,
};

function mapStateToProps(state) {
  return {
    postOrderDeposit: state?.CustomGroups?.TRIM?.post_order_deposit || null,
    trimCode: getTrimCode(state),
  };
}

export default connect(mapStateToProps)(PostOrderDeposit);
