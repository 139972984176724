/* eslint no-shadow: 0 */
import React from 'react';
import { shape, arrayOf, func, bool, string } from 'prop-types';
import _intersection from 'lodash/intersection';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { setOption, updateSubscription } from 'actions';
import { FormInputOptionGroup, FormInputOption } from '@tesla/design-system-react';
import cx from 'classnames';
import { htmlToReact, parseSelectedBy, getVatRateForForwardCalculation } from 'utils';
import { formatCurrency, formatMonthlyPrice } from '@tesla/coin-common-components';
import Analytics from '../../common/analytics';
import TextLoader from '../TextLoader';

/* Call to OptionGroup component */
const OptionGroup = ({
  options = [],
  setOptionCode,
  showVisualCheckbox,
  selectedOption,
  setSubscription,
  hasSubscriptions,
  showOptionGroup,
  classes,
  changePriceIndicator,
}) => {

  if (!showOptionGroup) {
    return null;
  }

  const setOptions = ({ code, action = 'set', group }) => {
    if (hasSubscriptions) {
      setSubscription({}, group);
    }
    const option = code || selectedOption;
    if (option) {
      setOptionCode([option], action);
    }
  }

  const onChange = option => {
    const {
      code,
      analyticsTriggerText,
      isSelected,
      subscription_type,
      group,
      price,
    } = option || {};
    if (analyticsTriggerText) {
      Analytics.fireInteractionEvent(
        showVisualCheckbox
          ? `${analyticsTriggerText}${isSelected ? 'uncheck' : 'check'}`
          : analyticsTriggerText
      );
    }
    if (subscription_type) {
      setSubscription({ code, price, type: subscription_type, group }, group);
      if (code === selectedOption) {
        setOptionCode([selectedOption]);
      }
    } else if (code) {
      setOptions(option);
    } else {
      setOptions(option);
    }
  };

  return (
    <FormInputOptionGroup className={classes}>
      {options.map(option => {
        const {
          code,
          type,
          label,
          value,
          group,
          isSelected,
          isHidden,
          caption,
          pricePostfix = '',
          included,
        } = option;
        if (isHidden) {
          return;
        }
        return (
          <FormInputOption
            showVisualCheckbox={showVisualCheckbox}
            type={showVisualCheckbox ? 'checkbox' : 'radio'}
            key={`OptionGroup:${group}:${code}:${type}`}
            id={`${code}:${type}`}
            name={`OptionGroup:${group}`}
            checked={isSelected}
            label={
              <>
                <div className="tds-flex tds-o--fullwidth tds-flex--row-nowrap">
                  <TextLoader
                    data={label}
                    className={cx(
                      'tds-flex-item tds-o-margin_right-16',

                      {
                        'tds-o-label-title--checkbox': showVisualCheckbox,
                      }
                    )}
                  />
                  <Choose>
                    <When condition={changePriceIndicator}>
                      <span
                          className={cx('tds-flex--col_1of5 tds-o-text-align_end', {
                            'tds-o-label-descriptor--checkbox': showVisualCheckbox,
                          })}
                        >
                          <TextLoader
                            data={option}
                            field="change_label"
                            className="group--options_block-container_price"
                          />
                        </span>
                    </When>
                    <Otherwise>
                      <If condition={value}>
                        <span
                          className={cx('tds-flex--col_1of5 tds-o-text-align_end', {
                            'tds-o-label-descriptor--checkbox': showVisualCheckbox,
                          })}
                        >
                          {htmlToReact(value)}
                          {pricePostfix}
                        </span>
                      </If>
                      <If condition={!value && included}>
                        <span
                          className={cx('tds-flex--col_1of5 tds-o-text-align_end', {
                            'tds-o-label-descriptor--checkbox': showVisualCheckbox,
                          })}
                        >
                          {htmlToReact(included)}
                        </span>
                      </If>
                    </Otherwise>
                  </Choose>
                </div>
                <If condition={caption}>
                  <TextLoader
                    data={caption}
                    className={cx('tds-text--caption tds-text--regular tds-o-padding_top-4', {
                      'tds-text--contrast-high': isSelected,
                    })}
                  />
                </If>
              </>
            }
            className={cx({
              'tds-o-flex-start tds--padding tds-o-flex-direction--column tds-text--body': !showVisualCheckbox,
              'list-option--padding': showVisualCheckbox,
            })}
            onChange={() => onChange(option)}
          />
        );
      })}
    </FormInputOptionGroup>
  );
};

function mapStateToProps(state, ownProps) {
  const { market } = state?.OMS?.oms_params || {};
  const { option_codes = [] } = state?.Configuration || {};
  const { isPostOrderSwap } = state?.App || {};
  const { group, options: inputOptions = [], code: groupCode, showVisualCheckbox, selected_by, classes, showVisualCheckboxInSwap, disableInSwap } = ownProps || {};
  const showCheckbox = isPostOrderSwap ? showVisualCheckboxInSwap : showVisualCheckbox;
  const showOptionGroup = selected_by ? parseSelectedBy(selected_by, option_codes, null, state) : true;
  const groupId = groupCode || group?.code;
  const subscription = state?.Subscription?.[groupId];
  const { OptionCodeList } = state?.ReviewDetails?.product?.data || {};
  const optionList = OptionCodeList?.split(',') || [];
  let selectedOption = null;
  let priceIndicator = null;
  let price = 0;
  const includedOptions = optionList?.length ? _intersection(optionList, group?.options) : [];
  const options = inputOptions.map(opt => {
    const { field, code, field_caption, subscription_type = '', field_included } = opt || {};
    if (subscription_type) {
      const { subscription_options: options = {} } = state?.OMS?.lexicon || {};
      const { value } = options?.[code]?.pricing?.find(x => x?.type === subscription_type) || {};
      price = value;
      priceIndicator = formatMonthlyPrice(value);
    } else {
      const { price, formattedPrice, formattedCashPrice, base_price, upgradePrice, ui_price_override, pricing } =
        (code && group?.current?.find(x => x?.code === code)) || {};
      const isOptionSelected = !!(code && option_codes.includes(code));
      if (isOptionSelected) {
        selectedOption = code;
      }
      const formattedBasePrice = base_price && formatCurrency(base_price);
      const priceFormatted =
        (price && (formattedCashPrice || formattedPrice)) || group?.price_indicator || '';
      const vatRateForForwardCalculation = getVatRateForForwardCalculation(state) || 1;
      const uiUpgradePrice = upgradePrice ? formatCurrency(upgradePrice * vatRateForForwardCalculation) : '';
      const hasMultipleBasePrices = pricing?.filter(price => price.type === 'base')?.length > 1;
      priceIndicator =
        !hasMultipleBasePrices && price && base_price && base_price !== price
          ? `<p>${priceFormatted}&nbsp;<s>${formattedBasePrice}</s></p>`
          : uiUpgradePrice || priceFormatted;
      if (ui_price_override) {
        const { selected_by, content } = ui_price_override || {};
        const isSelected = selected_by ? parseSelectedBy(selected_by, option_codes, null, state) : false;
        priceIndicator = isSelected ? content : priceIndicator;
      }
    }

    return {
      ...opt,
      group: groupId,
      value: priceIndicator,
      label: group?.[field] || '',
      caption: group?.[field_caption] || '',
      isSelected: subscription ? subscription?.type === subscription_type : !!(selectedOption === code) && !subscription_type,
      isHidden: includedOptions?.length && !code,
      type: subscription_type || 'base',
      price,
      included: group?.[field_included] || '',
    };
  });

  return {
    options,
    showVisualCheckbox: showCheckbox,
    selectedOption,
    hasSubscriptions: !_isEmpty(subscription),
    showOptionGroup,
    classes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setOptionCode: (option, props) => {
      if (option) {
        dispatch(setOption({ set: option }, props));
      }
    },
    setSubscription: (payload, group) => {
      dispatch(updateSubscription(payload, group));
    },
  };
}

OptionGroup.propTypes = {
  options: arrayOf(shape({})),
  setOptionCode: func.isRequired,
  showVisualCheckbox: bool,
  selectedOption: string,
  classes: string,
  changePriceIndicator: string,
};

OptionGroup.defaultProps = {
  options: [],
  showVisualCheckbox: false,
  selectedOption: null,
  changePriceIndicator: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionGroup);
