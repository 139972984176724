import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { bool, arrayOf, shape, func, string, number } from 'prop-types';
import _isString from 'lodash/isString';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _values from 'lodash/values';
import _isArray from 'lodash/isArray';

import { Form } from '@tesla/informed-tds';
import { FormFieldset, Button, ButtonGroup, Icon, StatusMessage } from '@tesla/design-system-react';
import { iconStatusWarning } from '@tesla/design-system-icons';
import { useFormState, useFormApi } from 'informed';
import { ConfirmModal, formatCurrency } from '@tesla/coin-common-components';
import {
  setOptionExtended,
  getDefaultConfiguration,
  cancelUpdateOrder,
  postMktConfig,
  setBackToProfileFlag,
  toggleAccessoryItemWithShipping,
} from 'actions';
import {
  getEditDesignAccessoriesData,
  getSelectedAccessories,
  isEnterprisePurchaseOrder,
  getDepositAmount,
} from 'selectors';
import { i18n, getVatRateForForwardCalculation } from 'utils';
import { CUSTOM_GROUP_TYPE, ORDER_FEE_TYPE } from 'dictionary';
import Analytics from 'analytics';
import TypeRenderer from './TypeRenderer';
import EditDesignConfirmModal from '../../components/EnterpriseConfirmEditDesignModal';

const ConfirmCancelModal = ({ open, onContinue, onClose }) => {
  const container = document.querySelector('#enterprise-modal-root');

  return container
    ? createPortal(
        <ConfirmModal
          open={open}
          title={i18n('Enterprise.cancelChanges')}
          body={
            <div className="tds-status_msg confirm-cancel-status">
              <Icon data={iconStatusWarning} style={{ marginInlineEnd: 'var(--tds-size-2x)' }} />
              <p>{i18n('Enterprise.confirmCancelChangesMessage')}</p>
            </div>
          }
          bodyTag="div"
          buttonLabel={i18n('Enterprise.yesCancel')}
          onClose={onClose}
          onContinue={onContinue}
        />,
        container
      )
    : null;
};

const ButtonGroupWrapper = ({ onReset, isDisabled }) => {
  const formState = useFormState();
  const formApi = useFormApi();
  return (
    <div className="cf-form-btn--group">
      <ButtonGroup>
        <Button
          type="button"
          disabled={formState.pristine}
          variant="secondary"
          onClick={() => onReset(formApi)}
        >
          {i18n('Enterprise.button_label_reset')}
        </Button>
        <Button type="submit" disabled={isDisabled && formState.pristine} variant="primary">
          {i18n('Enterprise.button_label_save')}
        </Button>
      </ButtonGroup>
    </div>
  );
};

ConfirmCancelModal.propTypes = {
  open: bool.isRequired,
  onContinue: func.isRequired,
  onClose: func.isRequired,
};

ButtonGroupWrapper.propTypes = {
  onReset: func.isRequired,
  isDisabled: bool.isRequired,
};

const MiniConfigurator = ({
  isEnabled,
  categories,
  setOption,
  savedConfig,
  config,
  resetConfiguration,
  cancelConfigurationChange,
  updateConfiguration,
  initialValues,
  baseConfigWithPrice,
  total,
  upgrades,
  goBackToProfile,
  setBackToProfile,
  promptOrderUpdate,
  showStatusMsg,
  saveWithoutPrompt,
  isPOEditdesign,
  accessories,
  selectedAccessories,
  toggleItem,
  vatRateForForwardCalculation,
}) => {
  const formApiRef = useRef();
  const [promptConfirm, setPromptConfirm] = useState(false);
  const [promptConfirmCancel, setPromptConfirmCancel] = useState(false);
  const [promptOrderUpdateConfirm, setPromptOrderUpdateConfirm] = useState(promptOrderUpdate);

  useEffect(() => {
    setPromptOrderUpdateConfirm(promptOrderUpdate);
  }, [promptOrderUpdate]);

  useEffect(() => {
    const handleBeforeunload = event => {
      const { dirty = false } = formApiRef?.current?.getFormState() || {};
      if (!promptOrderUpdateConfirm && !promptConfirmCancel && dirty) {
        event.preventDefault();
        // Chrome requires `returnValue` to be set.
        // eslint-disable-next-line no-param-reassign
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeunload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload);
    };
  }, [promptOrderUpdateConfirm, promptConfirmCancel]);

  useEffect(() => {
    if (goBackToProfile) {
      const { dirty = false } = formApiRef?.current?.getFormState() || {};
      if (dirty) {
        setPromptConfirmCancel(true);
      } else {
        cancelConfigurationChange();
      }
      setBackToProfile(false);
    }
  }, [goBackToProfile]);

  if (!isEnabled || !categories.length) {
    return null;
  }

  const saveDesign = () => {
    setPromptConfirm(false);
    Analytics.fireTagEvent({
      event: Analytics.event,
      interaction: 'save',
      label: 'edit-design',
      public_account_id: Analytics.publicAccountId,
    });
    updateConfiguration();
  };

  const onChange = ({
    value,
    optCode = null,
    analyticsPrefix = null,
    checkboxCode = null,
    type,
  }) => {
    let code = value;
    if (!_isString(value)) {
      code = optCode;
    }
    if (code) {
      Analytics.fireTagEvent({
        event: Analytics.event,
        interaction: `${analyticsPrefix.toLowerCase()}-${checkboxCode || value}`,
        label: 'edit-design',
        public_account_id: Analytics.publicAccountId,
      });
      // Handle custom group selection (accessories)
      if (type === CUSTOM_GROUP_TYPE) {
        toggleItem({ partNumber: code });
      } else {
        setOption(code);
      }
    }
  };

  const onReset = formApi => {
    formApi.reset();
    Analytics.fireTagEvent({
      event: Analytics.event,
      interaction: 'reset',
      label: 'edit-design',
      public_account_id: Analytics.publicAccountId,
    });
    resetConfiguration(savedConfig);
  };

  const optionsNameList = categories.reduce(
    (list, category) => [
      ...list,
      ...category?.currentSelected.map(option => _isArray(option?.long_name) ? option?.name : option?.long_name).filter(Boolean),
    ],
    []
  );

  return (
    <div className="t4b-edit-design--container">
      <If condition={showStatusMsg}>
        <StatusMessage
          className="tds--fade-in"
          body={i18n('Enterprise.reviewOptionsAlert')}
          type="info"
          bodyTag="div"
        />
      </If>
      <Form
        formApiRef={formApiRef}
        initialValues={initialValues}
        onSubmit={() => (saveWithoutPrompt ? saveDesign() : setPromptConfirm(true))}
      >
        <div className="tds--vertical_padding cf-mini-configurator">
          {categories.map((group, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <FormFieldset className="tds--vertical_padding" key={`MiniConfigurator:TypeRenderer:${group ? group.code : ''}:${index}`}>
              <legend
                className="tds-form-label tds--is_visually_hidden"
                style={{
                  margin: 0,
                }}
              >
                <span className="tds-text--h5" style={{ paddingBlockStart: 0 }}>
                  {`${i18n('Enterprise.edit_design')}: ${group.code}`}
                </span>
              </legend>
              <TypeRenderer
                group={group}
                onChange={params => onChange(params)}
                baseConfigWithPrice={baseConfigWithPrice}
                config={config}
                upgrades={upgrades}
                isPOEditdesign={isPOEditdesign}
                selected
                selectedAccessories={selectedAccessories}
                vatRateForForwardCalculation={vatRateForForwardCalculation}
              />
            </FormFieldset>
          ))}
          <ButtonGroupWrapper onReset={onReset} isDisabled={!showStatusMsg} />
        </div>
      </Form>
      <EditDesignConfirmModal
        open={promptConfirm}
        onClose={() => setPromptConfirm(false)}
        onContinue={saveDesign}
        options={optionsNameList}
        total={formatCurrency(total)}
        accessories={accessories}
      />
      <ConfirmCancelModal
        open={promptConfirmCancel}
        onClose={() => setPromptConfirmCancel(false)}
        onContinue={() => {
          setPromptConfirmCancel(false);
          cancelConfigurationChange();
        }}
      />
    </div>
  );
};

MiniConfigurator.propTypes = {
  isEnabled: bool,
  categories: arrayOf(shape({})),
  savedConfig: arrayOf(string),
  config: arrayOf(string),
  setOption: func.isRequired,
  resetConfiguration: func.isRequired,
  cancelConfigurationChange: func.isRequired,
  updateConfiguration: func.isRequired,
  initialValues: shape({}),
  baseConfigWithPrice: shape({}),
  total: number,
  upgrades: shape({}),
  goBackToProfile: bool,
  setBackToProfile: func.isRequired,
  promptOrderUpdate: bool,
  showStatusMsg: bool,
  saveWithoutPrompt: bool,
  isPOEditdesign: bool,
  selectedAccessories: arrayOf(string),
  toggleItem: func.isRequired,
  accessories: arrayOf(shape({})),
};

MiniConfigurator.defaultProps = {
  isEnabled: false,
  savedConfig: [],
  config: [],
  categories: [],
  initialValues: {},
  baseConfigWithPrice: {},
  total: 0,
  upgrades: {},
  goBackToProfile: false,
  promptOrderUpdate: false,
  showStatusMsg: false,
  saveWithoutPrompt: false,
  isPOEditdesign: false,
  selectedAccessories: [],
  accessories: [],
};

const mapStateToProps = state => {
  const { Configuration, CustomGroups, Enterprise = {}, Pricing, OMS = {} } = state;
  const {
    editDesignCategories = [],
    upgradeGroups = [],
    goBackToProfile = false,
    data,
    error,
    updated,
  } = Enterprise;
  const isEnterprisePO = isEnterprisePurchaseOrder(state);
  const promptOrderUpdate = !!data || !!error || !!updated;
  const { grossPrice } = Pricing;
  const { savedConfiguration, option_codes, option_codes_saved = [] } = Configuration;
  const {
    config: { mktOptionCodes = [], isConfigurationModified = false } = {},
    accessories = [],
  } = savedConfiguration || {};
  const upgrades = upgradeGroups.reduce((res, grp) => {
    const { options = [] } = CustomGroups[grp] || {};
    let upgradeOptions = {};
    options.forEach(opt => {
      upgradeOptions = {
        ...upgradeOptions,
        [opt]: _get(OMS, `lexicon.options.${opt}.price`, 0),
      };
    });
    return {
      ...res,
      ...upgradeOptions,
    };
  }, {});
  const initialValues = editDesignCategories.reduce((res, group) => {
    const { exclusive = false, currentSelected = [] } = CustomGroups[group] || {};
    const option = _get(currentSelected, `[0].code`, '');
    const categoryKey = exclusive ? group : `${group}:${option}`;
    return {
      ...res,
      [categoryKey]: exclusive ? option : option_codes_saved.includes(option),
    };
  }, {});
  const baseConfigWithPrice = mktOptionCodes.reduce((res, option) => {
    const { code, price } = option;
    return {
      ...res,
      [code]: price,
    };
  }, {});

  const accessoriesSubtotal = accessories?.reduce((acc, item) => {
    return acc + (item?.price || 0);
  }, 0);
  const categories = _values(_pick(CustomGroups, editDesignCategories));
  let selectedAccessories = [];
  if (isEnterprisePO) {
    const accessoriesCategory = getEditDesignAccessoriesData(state);
    if (accessoriesCategory) {
      categories.push(accessoriesCategory);
      selectedAccessories = Object.keys(getSelectedAccessories(state));
    }
  }
  const { paymentSourceSubType, orderPayment = 0 } = getDepositAmount(state, true) || {};
  const orderFee = paymentSourceSubType === ORDER_FEE_TYPE ? orderPayment : 0;
  return {
    isEnabled: !!savedConfiguration,
    categories,
    initialValues,
    config: option_codes,
    savedConfig: option_codes_saved,
    accessories,
    baseConfigWithPrice,
    total: grossPrice + accessoriesSubtotal + orderFee,
    upgrades,
    goBackToProfile,
    promptOrderUpdate,
    showStatusMsg: isConfigurationModified,
    saveWithoutPrompt: isEnterprisePO,
    isPOEditdesign: isEnterprisePO,
    selectedAccessories,
    vatRateForForwardCalculation: getVatRateForForwardCalculation(state) || 1,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setOption: option => {
      document.body.classList.add('using-mouse');
      if (option) {
        dispatch(setOptionExtended({ set: [option] }));
      }
    },
    toggleItem: item => dispatch(toggleAccessoryItemWithShipping(item)),
    resetConfiguration: options => {
      dispatch(getDefaultConfiguration(options));
    },
    updateConfiguration: () => {
      dispatch(postMktConfig());
    },
    cancelConfigurationChange: () => {
      dispatch(cancelUpdateOrder());
    },
    setBackToProfile: flag => {
      dispatch(setBackToProfileFlag(dispatch, flag));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MiniConfigurator);
