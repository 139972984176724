import _get from 'lodash/get';
import _union from 'lodash/union';

import { parseReferral } from 'utils';

import {
  NAVIGATION_SELECT_KEY,
  UPDATE_REFERRAL,
  FEATURES_LIST_EXPAND,
  FEATURES_LIST_COLLAPSE,
  PROCESS_CONNECT,
  SESSION_SET,
  PRICE_CHANGED,
  COMPLETE_CONFIGURATION_CHANGED,
  LOCATION_CHANGED,
  START_SESSION,
  POST_ORDER_START,
  POST_ORDER_FAIL,
  POST_PAYMENT_COMPLETE,
  ESIGN_STATUS_INIT,
  UPDATE_ESIGN_PENDING,
  UPDATE_ESIGN_STATUS,
  UPDATE_VERIFICATION_STATUS,
  OPTIMIZELY_EXP,
} from 'dictionary';

export default function Reducer(state = {}, action, { app_state }) {
  const countryCode = app_state.App?.countryCode || null;
  let isCryptoEnabled = false;
  switch (action.type) {
    case NAVIGATION_SELECT_KEY:
      return state;

    case COMPLETE_CONFIGURATION_CHANGED:
      const trimCode = _get(action, 'configurationByGroup.matchedCodes.TRIM[0]', '');
      const isModelYNV36Trim = trimCode.substring(4) >= 40;
      return {
        ...state,
        referral: parseReferral(state?.referral, isModelYNV36Trim),
      };

    case UPDATE_REFERRAL:
      return { ...state, referral: _get(action, 'data', {}) };

    case UPDATE_VERIFICATION_STATUS:
      return {...state, verificationStatus: _get(action, 'data', [])};

    case FEATURES_LIST_EXPAND:
      return {
        ...state,
        optionsWithExpandedFeatures: _union(
          state.optionsWithExpandedFeatures,
          _get(action, 'props.options', [])
        ),
        groupsWithExpandedFeatures: _union(
          state.groupsWithExpandedFeatures,
          _get(action, 'props.groups', [])
        ),
      };

    case FEATURES_LIST_COLLAPSE:
      return {
        ...state,
        optionsWithExpandedFeatures: [],
        groupsWithExpandedFeatures: [],
      };

    case PROCESS_CONNECT:
      return {
        ...state,
        connect: _get(action, 'args'),
        postConnectUrl: _get(action, 'url'),
      };

    case SESSION_SET:
      return {
        ...state,
        isSessionSet: true,
        isSessionCheckStarted: false,
      };

    case START_SESSION:
      return {
        ...state,
        isSessionCheckStarted: true,
      };

    case PRICE_CHANGED:
      return {
        ...state,
        isInitialized: true,
      };

    case LOCATION_CHANGED:
      isCryptoEnabled = state.isCryptoIpCheckEnabled
        ? countryCode === action.location?.countryCode
        : state.isCryptoEnabled;
      return {
        ...state,
        isCryptoEnabled,
        isCryptoAllowed: isCryptoEnabled ? state.isCryptoAllowed : false,
      };

    case COMPLETE_CONFIGURATION_CHANGED:
      return {
        ...state,
        isCryptoAllowed: state.isCryptoEnabled
          ? !state.isCryptoExcluded.some(val => action.options.indexOf(val) !== -1)
          : false,
      };

    case POST_ORDER_START:
      return {
        ...state,
        isPaymentInProgress: true,
      };

    case POST_ORDER_FAIL:
      const { numberOfTimesPaymentFailed: count = 0 } = state || {};
      return {
        ...state,
        isPaymentInProgress: false,
        numberOfTimesPaymentFailed: count + 1,
      };

    case POST_PAYMENT_COMPLETE:
      const { isComplete = false } = action || {};
      return {
        ...state,
        isPaymentInProgress: !isComplete,
      };

    case UPDATE_ESIGN_STATUS:
      const esignLink = _get(action, 'link', null);
      return {
        ...state,
        esignStatus: _get(action, 'status', ESIGN_STATUS_INIT),
        ...(esignLink ? { esignLink } : {}),
      };
    case UPDATE_ESIGN_PENDING:
      return {
        ...state,
        esignPending: _get(action, 'pending', false),
      };

    case OPTIMIZELY_EXP:
      return {
        ...state,
        optimizelyExpIds: action?.id ? [...new Set([...(state?.optimizelyExpIds || []), [...action?.id]])] : state?.optimizelyExpIds,
      };

    default:
      return state;
  }
}
