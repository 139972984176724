/* eslint-disable no-param-reassign */
import { formatCurrency } from '@tesla/coin-common-components';
import classnames from 'classnames';
import {
  FIELD_PICKUP_LOCATION,
  PAYMENT_TYPE_APPLEPAY,
  PICKUP_LOCATION
} from 'dictionary';
import _get from 'lodash/get';
import _indexOf from 'lodash/indexOf';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  getOrderDisclaimerSource,
  i18n,
  htmlToReact,
} from 'utils';
import PaymentForms from '../../../containers/PaymentOverview/PaymentForms';
import ContactUsLink from '../../../containers/PaymentOverview/Sections/ContactUsLink';
import LegalDisclaimers, {
  OrderLegalDisclaimers
} from '../../../containers/PaymentOverview/Sections/LegalDisclaimers';
import LegalConsent from '../../AccountForm/LegalConsent';


const PaymentWrapper = ({
  userHasApplePay,
  isCollapsedFlow,
  rnLoaded,
  depositAmount,
  ccDetailsValid,
  submitTried,
  saveMarketingConfig,
  validateAndPlacePublicOrder,
  validateAndPlaceOrder,
  cancelUpdateOrder,
  modifyOrder,
  isSaveDesignEnabled,
  isReservedPreOrder,
  isBookedPreOrder,
  enableCyberpunk,
  validateAndSaveDesign,
  isReservation,
  isCoinReloaded,
  isFixedPickupLocation,
  showInvoiceOption,
  isPaymentFormOpen,
  isPaymentInteractionRequired,
  isDeliveryDetailsRequired,
  isDeliveryDetailsValid,
  showOrderRefundDisclaimer,
  showOrderFeeDisclaimer,
  showNonRefundableDisclaimer,
  isMobile,
  isTablet,
  isSessionSet,
  dispatchCheckSession,
  model,
  modelCode,
  hasAlerts,
  payXUIRef,
  paymentTabsType,
  isUnbuildable,
  isDeliverySelectionEnabled,
  market,
  isNativePaymentEnabled,
  legalConsentValid,
  isInventory,
  isPostOrderSwap,
  showLegalConsentEditDesign,
  setTimeOfClickPlaceOrderButton,
  shouldShowEsignProcess,
  paymentType,
  disclaimerSectionAboveOrderButton,
  showContactUs,
  connect,
  isPaymentDetailsValid,
  isAccountDetailsValid,
  isInvoiceDetailsValid,
  isQtyInfoValid,
  isAccessoriesValid,
  orderPlaced,
  hideBaseField,
  validationErrors,
  isTransportFeeCollectionEnabled,
  transportationFee,
  depositAmountBase,
  deliveryPostalCode,
  showNonRefundPolicy,
  postConnectUrl,
  triggerPostConnectRedirect,
  showLegalConsentAfterPayX,
  orderAgreementUrl,
  showFinanceDisclaimerOrderPage,
  financeDisclaimer,
  simplifiedEsignFlowEnabled,
}) => {

  const [isPayxPaymentOptionSelected, setIsPayxPaymentOptionSelected] = useState(false);

  const checkIfpayxPaymentOptionSelected = payxPaymentOptionSelected => {
    setIsPayxPaymentOptionSelected(payxPaymentOptionSelected);
  };

  const payxPaymentOptionSelectedValid = () => {
    if (market === 'CN') {
      return isPayxPaymentOptionSelected;
    }
    return true;
  };

  const orderButtonDisabled =
    orderPlaced ||
    !isPaymentDetailsValid() ||
    !isAccountDetailsValid() ||
    !isInvoiceDetailsValid() ||
    !isDeliveryDetailsValid ||
    !isQtyInfoValid ||
    !isAccessoriesValid ||
    !payxPaymentOptionSelectedValid();

  const invitedOrderButtonDisabled =
    orderPlaced || !isPaymentDetailsValid() || !legalConsentValid || !isAccessoriesValid;

  const saveDesignButtonDisabled = !isAccountDetailsValid();
  const hidePaymentTable = market === 'CN' && (isReservedPreOrder || isBookedPreOrder);

  const showPickupLocation = _indexOf(hideBaseField, PICKUP_LOCATION) < 0 && !hidePaymentTable;
  const pickupLocationError = _get(validationErrors, `${FIELD_PICKUP_LOCATION}`, false);

  const modelName = _get(model, 'long_name', '');

  const hasApplePayDisclaimerBelow = market === 'US';

  const orderRefundWithTransportFee =
    isTransportFeeCollectionEnabled && transportationFee
      ? i18n('SummaryPanel.disclaimers.orderNotRefundShortWithTransportFee', {
          FEE: formatCurrency(transportationFee),
          ORDER_DEPOSIT: formatCurrency(depositAmountBase),
          POSTAL_CODE: deliveryPostalCode,
        })
      : '';

  const orderRefund = getOrderDisclaimerSource('orderRefundShort', modelCode, {
    showNonRefundPolicy,
  });

  const handlePostConnect = () => {
    setTimeout(() => {
      triggerPostConnectRedirect(postConnectUrl);
    }, 500);
  };

  return (
    <>
      {disclaimerSectionAboveOrderButton && userHasApplePay && !hasApplePayDisclaimerBelow && (
        <LegalDisclaimers type={disclaimerSectionAboveOrderButton} withRefundPolicy={false} />
      )}

      <If condition={(isNativePaymentEnabled && !isPostOrderSwap) || (isCoinReloaded && isPostOrderSwap)}>
        <If condition={showLegalConsentAfterPayX?.length}>
          <div className="tds--vertical_padding-bottom">
            <LegalConsent orderAgreementUrl={orderAgreementUrl} showLegalConsentAfterPayX={showLegalConsentAfterPayX} />
          </div>
        </If>
        <OrderLegalDisclaimers displayInline={!!isInventory} />
      </If>

      <PaymentForms
        userHasApplePay={userHasApplePay}
        isCollapsedFlow={isCollapsedFlow}
        rnLoaded={rnLoaded}
        depositAmount={depositAmount}
        ccDetailsValid={ccDetailsValid}
        submitTried={submitTried}
        orderButtonDisabled={orderButtonDisabled}
        invitedOrderButtonDisabled={invitedOrderButtonDisabled}
        saveMarketingConfig={saveMarketingConfig}
        validateAndPlacePublicOrder={validateAndPlacePublicOrder}
        validateAndPlaceOrder={validateAndPlaceOrder}
        cancelUpdateOrder={cancelUpdateOrder}
        modifyOrder={modifyOrder}
        isSaveDesignEnabled={isSaveDesignEnabled}
        isReservedPreOrder={isReservedPreOrder}
        isBookedPreOrder={isBookedPreOrder}
        enableCyberpunk={enableCyberpunk}
        validateAndSaveDesign={validateAndSaveDesign}
        saveDesignButtonDisabled={saveDesignButtonDisabled}
        showPickupLocation={showPickupLocation}
        pickupLocationError={pickupLocationError}
        isReservation={isReservation}
        isFixedPickupLocation={isFixedPickupLocation}
        showInvoiceOption={showInvoiceOption}
        isPaymentFormOpen={isPaymentFormOpen}
        isPaymentInteractionRequired={isPaymentInteractionRequired}
        isDeliveryDetailsRequired={isDeliveryDetailsRequired}
        isDeliveryDetailsValid={isDeliveryDetailsValid}
        showOrderRefundDisclaimer={showOrderRefundDisclaimer}
        showOrderFeeDisclaimer={showOrderFeeDisclaimer}
        orderRefundWithTransportFee={orderRefundWithTransportFee}
        orderRefund={orderRefund}
        showNonRefundableDisclaimer={showNonRefundableDisclaimer}
        isLayoutMobile={isMobile}
        isLayoutTablet={isTablet}
        isSessionSet={isSessionSet}
        dispatchCheckSession={dispatchCheckSession}
        modelCode={modelCode}
        modelName={modelName}
        hasAlerts={hasAlerts}
        payXUIRef={payXUIRef}
        paymentTabsType={paymentTabsType}
        isUnbuildable={isUnbuildable}
        isDeliverySelectionEnabled={isDeliverySelectionEnabled}
        market={market}
        isNativePaymentEnabled={isNativePaymentEnabled}
        legalConsentValid={legalConsentValid}
        isInventory={isInventory}
        isPostOrderSwap={isPostOrderSwap}
        isPayxPaymentOptionSelected={checkIfpayxPaymentOptionSelected}
        showLegalConsentEditDesign={showLegalConsentEditDesign}
        setTimeOfClickPlaceOrderButton={setTimeOfClickPlaceOrderButton}
        shouldShowEsignProcess={shouldShowEsignProcess}
        simplifiedEsignFlowEnabled={simplifiedEsignFlowEnabled}
      >
        <div
          className={classnames(
            'disclaimer',
            { 'edit-design': modifyOrder },
            { 'tds-text--caption tds--vertical_padding': isInventory && !isPostOrderSwap }
          )}
        >
          <OrderLegalDisclaimers displayInline={!!isInventory} />
        </div>
      </PaymentForms>

      {hasApplePayDisclaimerBelow && (paymentType === '' || paymentType === 'CC' || paymentType === PAYMENT_TYPE_APPLEPAY) && disclaimerSectionAboveOrderButton && userHasApplePay && (
        <LegalDisclaimers isBelow type={disclaimerSectionAboveOrderButton} withRefundPolicy={false} />
      )}

      <If condition={showContactUs}>
        <ContactUsLink />
      </If>

      {/* User auto login */}
      <If condition={connect}>
        <img
          className="user-profile-image"
          src={connect}
          style={{ width: '1px', height: '1px', border: 0 }}
          alt=""
          onLoad={handlePostConnect}
          onError={handlePostConnect}
        />
      </If>
      <If condition={showFinanceDisclaimerOrderPage}>
        <p className="tds-text--caption tds--vertical_padding summary-container--finance-disclaimer">
          {htmlToReact(financeDisclaimer)}
        </p>
      </If>
      {/* SECTION END */}
    </>
  )
};

PaymentWrapper.propTypes = {
  userHasApplePay: bool.isRequired,
  isCollapsedFlow: bool.isRequired,
  rnLoaded: bool.isRequired,
  depositAmount: number.isRequired,
  ccDetailsValid: bool.isRequired,
  submitTried: bool,
  saveMarketingConfig: func.isRequired,
  validateAndPlacePublicOrder: func.isRequired,
  validateAndPlaceOrder: func.isRequired,
  cancelUpdateOrder: func.isRequired,
  modifyOrder: bool.isRequired,
  isSaveDesignEnabled: bool.isRequired,
  isReservedPreOrder: bool,
  isBookedPreOrder: bool,
  enableCyberpunk: bool,
  validateAndSaveDesign: func.isRequired,
  isReservation: bool,
  isCoinReloaded: bool,
  isFixedPickupLocation: bool,
  showInvoiceOption: bool.isRequired,
  isPaymentFormOpen: bool,
  isPaymentInteractionRequired: bool,
  isDeliveryDetailsRequired: bool.isRequired,
  isDeliveryDetailsValid: bool.isRequired,
  showOrderRefundDisclaimer: bool.isRequired,
  showOrderFeeDisclaimer: string,
  showNonRefundableDisclaimer: bool,
  isMobile: bool.isRequired,
  isTablet: bool.isRequired,
  isSessionSet: bool.isRequired,
  dispatchCheckSession: func,
  modelCode: string.isRequired,
  model: shape({
    name: string,
  }).isRequired,
  hasAlerts: bool,
  payXUIRef: shape({}),
  paymentTabsType: string,
  isUnbuildable: bool,
  isDeliverySelectionEnabled: bool,
  market: string,
  isNativePaymentEnabled: bool,
  legalConsentValid: bool,
  isInventory: bool,
  isPostOrderSwap: bool,
  showLegalConsentEditDesign: bool,
  setTimeOfClickPlaceOrderButton: func,
  shouldShowEsignProcess: bool,
  showContactUs: bool,
  connect: string,
  isPaymentDetailsValid: func.isRequired,
  isAccountDetailsValid: func.isRequired,
  isInvoiceDetailsValid: func.isRequired,
  isQtyInfoValid: bool,
  isAccessoriesValid: bool,
  orderPlaced: bool.isRequired,
  hideBaseField: arrayOf(string).isRequired,
  validationErrors: shape({}).isRequired,
  isTransportFeeCollectionEnabled: bool,
  transportationFee: number,
  depositAmountBase: number.isRequired,
  deliveryPostalCode: string,
  showNonRefundPolicy: oneOfType([bool, shape({})]),
  postConnectUrl: string,
  triggerPostConnectRedirect: func.isRequired,
  disclaimerSectionAboveOrderButton: string,
  showLegalConsentAfterPayX: arrayOf(string),
  orderAgreementUrl: string,
  simplifiedEsignFlowEnabled: bool,
};

PaymentWrapper.defaultProps = {
  submitTried: false,
  isReservedPreOrder: false,
  isBookedPreOrder: false,
  enableCyberpunk: false,
  isReservation: false,
  isCoinReloaded: false,
  isFixedPickupLocation: false,
  isPaymentFormOpen: true,
  isPaymentInteractionRequired: false,
  showOrderFeeDisclaimer: false,
  showNonRefundableDisclaimer: false,
  dispatchCheckSession: () => {},
  hasAlerts: false,
  paymentTabsType: 'tabs',
  isUnbuildable: false,
  isDeliverySelectionEnabled: false,
  market: '',
  isNativePaymentEnabled: false,
  legalConsentValid: true,
  isInventory: false,
  isPostOrderSwap: false,
  showLegalConsentEditDesign: false,
  setTimeOfClickPlaceOrderButton: () => {},
  shouldShowEsignProcess: false,
  showContactUs: false,
  connect: null,
  isQtyInfoValid: true,
  isAccessoriesValid: false,
  isTransportFeeCollectionEnabled: false,
  transportationFee: 0,
  deliveryPostalCode: '',
  showNonRefundPolicy: false,
  postConnectUrl: '',
  disclaimerSectionAboveOrderButton: '',
  showLegalConsentAfterPayX: [],
  orderAgreementUrl: '',
  simplifiedEsignFlowEnabled: false,
};

PaymentWrapper.contextTypes = {
  i18n: func,
};

export default connect(null)(PaymentWrapper);
