import { Storage } from '@tesla/coin-common-components';
import _get from 'lodash/get';

import {
  REGISTRATION_TYPE_PRIVATE,
  CLEAN_FUEL_REWARD,
  CASH,
  SUMMARY_PANEL_CASH_TAB,
} from 'dictionary';
import { getCurrentModel, filterSalesRegion, getStateCodeFromFullname } from 'utils';

const tslaObj = window.tesla || {};
const { omsParams, reviewDetails, Order, isPreferredAddressEnabled } = tslaObj;


const SummaryData = Storage.get('SummaryPanel', data => {
  if (
    _get(omsParams, 'market') === _get(data, 'value.SummaryPanel.country_code', '')
  ) {
    return _get(data, `value.SummaryPanel.region_code`);
  }
  return false;
});

const region_code = isPreferredAddressEnabled ? null : _get(SummaryData, 'SummaryPanel.region_code', null);
const registrationState = _get(
  reviewDetails,
  'RegistrationDetail.RegistrationAddress.StateProvince',
  null
);
const isOrderModification = _get(Order, 'enableOrderModification', false);
const isEnterpriseCustomOrderEdit = _get(tslaObj, 'isEnterpriseCustomOrderEdit', false);
const regionCodeSaved = isOrderModification || isEnterpriseCustomOrderEdit
  ? _get(tslaObj, 'regDeliveryData.stateProvince', null)
  : null;
// TODO: calculate this logic outside of V2 Deltas?

export default ({ object }) => {
  const currentModel = getCurrentModel();
  const { isEnterpriseOrder, enterpriseRegistrationAddress } = tslaObj || {};
  const leaseProducts = _get(
    tslaObj,
    `DSServices["Lease.${currentModel}.${currentModel}"].lease`,
    []
  );
  let { deliveryRegion, vehicleRegion } = filterSalesRegion();
  if (isEnterpriseOrder && enterpriseRegistrationAddress) {
    const { state, country } = enterpriseRegistrationAddress || {};
    deliveryRegion = getStateCodeFromFullname(country, state);
  }
  let { selected_tab } = object || {};
  const { selected_tab_fallback } = object || {};

  // fallback for when ALD lease product is disabled
  if (!isEnterpriseOrder && /operational_lease/.test(selected_tab)) {
    const aldProduct = leaseProducts.find(({ id }) => `lease.${id}` === selected_tab);
    const aldEnabled = aldProduct?.incomplete === false;

    if (!aldEnabled) {
      selected_tab = selected_tab_fallback || CASH;
    }
  }

  return {
    merge_method: 'assign',
    object: {
      tabs: [],

      // default to true
      expanded: true,

      // cash panel
      includeFees: false,

      // -- lease panel --
      leaseDistance: 0,
      leaseTerm: 0,
      loanTerm: 0,
      loanDistance: 0,
      downPayment: null,
      includeServiceFee: [],

      //  -- fuel savings --
      fuelPanelOpen: false,
      fuelDistPer: null,
      fuelPrice: null,

      detailsToggleEnabled: false,

      benefitType: 'personal',

      lease: {
        payment: {},
      },
      cash: {
        payment: {},
      },
      finance: {
        payment: {},
      },
      layouts: {
        cash: {
          incentives: {},
          netPrice: {
            disclaimerLink: true,
          },
        },
        lease: {
          incentives: {},
        },
        finance: {
          incentives: {},
          netPrice: {
            disclaimerLink: true,
          },
        },
      },
      formattedPrices: {
        downPaymentPercent: null,
        referral: null,
        grossPrice: null,
        netPrice: null,
        leaseMonthlyPayment: null,
        leaseNetMonthlyPayment: null,
        leaseAmountDueAtSigning: null,
        leaseResidualAmount: null,
        financeMonthlyPayment: null,
        financeNetMonthlyPayment: null,
        financeAmountDueAtSigning: null,
        financeResidualAmount: null,
        financedAmountPayment: null,
        financeAdminFee: null,
        financeCostOfCredit: null,
        financeLoanFee: null,
        financeDownpayment: null,
        financeNominalRate: null,
        financeEffectiveRate: null,
        incentives: null,
        incentivesPerMonth: null,
        fuelSavingsPerMonth: null,
        fuelSavings: null,
        bonusValue: null,
        vat: null,
        recyclingFee: null,
        tireFee: null,
        winterTireFee: null,
        feeAmount: null,
        firstRegistrationTax: null,
        discounts: {},
        payment: null,
      },
      ...object,
      region_code,
      selected_tab,
      deliveryRegion,
      defaultDiscountedToCash: false,
      showProbableSavings: false,
      showProbableSavingsToggle: false,
    },
    deltas: {
      market: {
        AE: {
          region_code: deliveryRegion || region_code,
        },
        AU: {
          region_code:
            deliveryRegion || regionCodeSaved || registrationState || region_code || 'NSW',
          loanTermsDependsOnCustomerType: true,
          loanType: REGISTRATION_TYPE_PRIVATE,
          loanTerm: [`Financial.fms_loan.loan[0].variables.loanTerm.${REGISTRATION_TYPE_PRIVATE}`],
        },
        US: {
          region_code: region_code || 'CA',
          // for California incentive - added for reference
          selectedIncentives: [
            {
              incentiveType: 'beforeDelivery',
              key: CLEAN_FUEL_REWARD,
            },
          ],
          defaultDiscountedToCash: true,
          showProbableSavingsToggle: true,
        },
        CA: {
          region_code: deliveryRegion || region_code || vehicleRegion || 'ON',
          showProbableSavingsToggle: true,
        },
        NZ: {
          region_code: deliveryRegion || region_code || 'southIsland',
        },
        MX: {
          showProbableSavingsToggle: true,
        },
        PR: {
          showProbableSavingsToggle: true,
        },
        CL: {
          showProbableSavingsToggle: true,
        },
        SG: {
          showProbableSavingsToggle: true,
        },
      },
      model: {
        ts: {
          showSavingsDisclaimeronCashTab: false,
          showReturnPolicy: true,
          hideApplyForFinancing: true,
          showVAT: false,
          showAccountTypeSwitch: false,
          showDriveAwayPrice: false,
          showRegistrationTypeSelector: false,
          AccountDetail: {
            AccountType: 'business',
          },
          selected_tab: SUMMARY_PANEL_CASH_TAB,
        },
      },
    },
  };
};
