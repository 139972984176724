import React, { useState, useEffect } from 'react';
import { arrayOf, shape, string, func, bool } from 'prop-types';
import { useField } from 'informed';
import cx from 'classnames';
import _intersection from 'lodash/intersection';
import _has from 'lodash/has';
import { i18n } from 'utils';
import { formatCurrency } from '@tesla/coin-common-components';
import Icon from '../GroupComponents/Icon';
import { SELECTION_LABEL, TRIM } from 'dictionary';
import './index.css';

const calculatePriceDifferece = item => {
  const {
    baseConfigWithPrice,
    price,
    code,
    price_indicator,
    selectedOption,
    upgrades,
    base_plus_trim_pricing,
    group,
    vatRateForForwardCalculation = 1,
    config = [],
  } = item;

  if (code in baseConfigWithPrice) {
    return price_indicator || '';
  }
  const { options = [] } = group || {};
  const findInOptions = _intersection(options, Object.keys(baseConfigWithPrice));

  let basePrice = baseConfigWithPrice[selectedOption] || 0;
  let setOptionPrice = 0;

  if (base_plus_trim_pricing) {
    const { options = [] } = base_plus_trim_pricing || {};
    if (options.length) {
      setOptionPrice = options.reduce((res, itm) => {
        return res + (upgrades[itm] || 0);
      }, 0);
    } else {
      // If upgrades is present in baseConfig then add the upgrade price to base price
      basePrice += Object.keys(upgrades).reduce((res, itm) => {
        return res + (baseConfigWithPrice[itm] || 0);
      }, 0);
    }
  }
  const isHiddenOpt = !basePrice && !!findInOptions.length;
  if (isHiddenOpt) {
    const currOpt = findInOptions[0] || null;
    basePrice = currOpt ? baseConfigWithPrice[currOpt] || 0 : 0;
  }

  const result = (price - basePrice + setOptionPrice) * vatRateForForwardCalculation;
  const conditionalPrice = item?.pricing
    ?.find(price => price?.type === 'base')
    ?.set_rules?.find(rule => config?.includes(rule?.condition_code))?.target_value;
  if (isHiddenOpt && (!result || conditionalPrice === 0)) {
    return price_indicator || '';
  }
  const symbol = result < 0 ? ' - ' : ' + ';
  return result ? symbol + formatCurrency(Math.abs(result)) : '';
};

const CustomSelect = ({
  group,
  config,
  onChange,
  current = [],
  currentSelected,
  showSelectLabel,
  hasSelectLabel,
  useLongName,
  ...props
}) => {
  const { fieldApi, fieldState, formFieldRef } = useField(props);
  const { setValue } = fieldApi;
  const { name, long_name: longName, code: currentSelectedCode, type } = currentSelected;
  const { initialValue, dirty } = fieldState;
  const [isToggle, setToggle] = useState(false);
  const [selectedOpt, setSelected] = useState({ price: '', code: '' });
  const { code: groupCode, name: groupName = '', totalAvailable = [] } = group;
  const isSingleChoice = hasSelectLabel ? false : totalAvailable.length === 1;
  const customSelectOption = ({ code, price }) => {
    const selectedPrice = price && price.toLowerCase() !== 'included' ? price : '';
    setValue(code);
    onChange({ value: code });
    setToggle(false);
    setSelected({ price: selectedPrice, code });
  };

  useEffect(() => {
    // reset selected opt price if current selected changed dependently via agent
    if (currentSelected.code && selectedOpt.code !== currentSelected.code) {
      setSelected({ price: '', code: currentSelected.code });
    }
  }, [config, selectedOpt, currentSelected]);

  const groupNameStr = groupName || groupCode || '';
  const { code: currentCode } = currentSelected || {};
  const { baseConfigWithPrice } = props;
  const dependentOptPrice = !_has(baseConfigWithPrice, currentCode)
    ? calculatePriceDifferece({
        ...currentSelected,
        ...props,
        group,
        selectedOption: initialValue,
      })
    : '';
  const selectLabelPriceDiff = showSelectLabel
    ? calculatePriceDifferece({
        code: null,
        price: 0,
        ...props,
        group,
        selectedOption: initialValue,
      })
    : 0;

  return (
    <div className="custom-select-container" ref={formFieldRef}>
      <div
        className={cx(
          'tds-form-input tds-form-input--default tds-text_color--black tds-text--medium',
          {
            'custom-select--input--open': isToggle,
            'custom-select--input--disabled': isSingleChoice,
          }
        )}
        onClick={() => (isSingleChoice ? null : setToggle(!isToggle))}
        onKeyPress={() => (isSingleChoice ? null : setToggle(!isToggle))}
        role="listbox"
        tabIndex={0}
      >
        <span className="select-input--label">
          {type === SELECTION_LABEL
            ? i18n('Enterprise.label_no_category_option', { GROUP_NAME: groupNameStr })
            : (useLongName ? (longName || name) : name)}
        </span>
        <div style={{ display: 'flex' }}>
          <If condition={(dirty && selectedOpt.price) || dependentOptPrice}>
            <span className="select-input--value" style={{ whiteSpace: 'nowrap' }}>{selectedOpt.price || dependentOptPrice}</span>
          </If>
          <If condition={!isSingleChoice}>
            <Icon
              id="tds-arrow"
              title={isToggle ? 'Arrow Collapse' : 'Arrow Expand'}
              className={cx(
                { flipped: isToggle },
                'tds-icon tds-icon-chevron-small-180 tds-icon--small'
              )}
              style={{
                width: '20px',
                height: '20px',
                transform: `scale(1.7) translateY(0) ${!isToggle ? 'rotateX(180deg)' : ''}`,
              }}
            />
          </If>
        </div>
      </div>
      <If condition={isToggle}>
        <div className="custom-dropdown--list">
          <If condition={showSelectLabel}>
            <div
              className="tds-list tds-list--horizontal tds-text_color--black tds-text--caption"
              onClick={() =>
                customSelectOption({ code: currentSelectedCode, price: selectLabelPriceDiff })
              }
              onKeyPress={() =>
                customSelectOption({ code: currentSelectedCode, price: selectLabelPriceDiff })
              }
              role="option"
              tabIndex={0}
              aria-selected={name === `${groupCode}_selectOption`}
            >
              <span className="tds-text--medium">
                {i18n('Enterprise.label_no_category_option', { GROUP_NAME: groupNameStr })}
              </span>
              <span className="tds--align_end">{selectLabelPriceDiff}</span>
            </div>
          </If>
          {current.map(item => {
            const { name: optionName, code, hidden = {}, long_name: longName } = item;
            const { isHidden } = hidden;
            if (isHidden) {
              return null;
            }
            const nameSource = groupCode === TRIM || useLongName ? longName : optionName;
            const optionPrice = calculatePriceDifferece({
              ...item,
              ...props,
              group,
              selectedOption: initialValue,
              config,
            });
            return (
              <div
                className="tds-list tds-list--horizontal tds-text_color--black tds-text--caption"
                onClick={() => customSelectOption({ code, price: optionPrice })}
                onKeyPress={() => customSelectOption({ code, price: optionPrice })}
                role="option"
                tabIndex={0}
                aria-selected={name === optionName}
                key={`SelectOption:${optionName}:${code}`}
              >
                <span className="tds-text--medium">{nameSource}</span>
                <span className="tds--align_end tds-o-nowrap" style={{ flexBasis: '5em' }}>
                  {optionPrice}
                </span>
              </div>
            );
          })}
        </div>
      </If>
    </div>
  );
};

CustomSelect.propTypes = {
  group: shape({
    code: string,
    name: string,
  }),
  onChange: func.isRequired,
  current: arrayOf(shape({})),
  currentSelected: shape({}),
  showSelectLabel: bool,
  config: arrayOf(string),
  baseConfigWithPrice: shape({}).isRequired,
};

CustomSelect.defaultProps = {
  group: {},
  current: [],
  currentSelected: {},
  showSelectLabel: false,
  config: [],
};

export default CustomSelect;
