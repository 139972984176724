import { formatCurrency } from '@tesla/coin-common-components';
import { MODAL_SMALL, PRICING_SAVINGS_CALCULATIONS, FinanceTypes } from 'dictionary';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _map from 'lodash/map';
import { array, bool, number, string, shape } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  getFinancialIncentives,
  getModelCode,
  getRegionName,
  getUserRegionCode,
  getFinanceType,
  getPrice,
} from 'selectors';
import { i18n } from 'utils';
import { PERIOD_CREDIT, INCENTIVE_FUEL } from 'dictionary';
import ModalTrigger from '../../ModalTrigger';
import BannerModalTrigger from './BannerModalTrigger';

const Incentives = ({
  currentIncentivesFiltered,
  showIncentiveInFeesAndDiscounts,
  showAfterSavingsPriceForTrims,
  showIncetivesInSummary,
  priceAfterSavings,
  priceBeforeSavings,
  modelCode,
  modelName,
  region,
  showVehiclePriceInSummary,
  vatDisclaimer,
}) => {
  return (
    <div className="review-page--block tds-o-margin-top">
      <div className="review-page--line-items">
        <ul className="review-page--line-items-list tds-list">
          {showIncetivesInSummary && _map(currentIncentivesFiltered, (incentive, index) => {
              return (
                <li
                  key={`incentives-incentive-${incentive?.incentiveType}-${index}`}
                  className="review-page--line-item tds-list-item"
                >
                  <div className="label left">
                    <p>
                      {i18n(
                        `SummaryPanel.PaymentSummary.${incentive?.incentiveType}`,
                        {
                          REGION: incentive?.longName || region,
                        },
                        null,
                        {
                          specificOverride: [incentive?.incentiveSubType],
                          returnNullWhenEmpty: true,
                          specificOverrideOperator: 'OR',
                        }
                      )}
                    </p>
                    {incentive?.incentiveType === 'taxCredit' && (
                      <BannerModalTrigger
                        contentSource={incentive?.incentiveType}
                        label={i18n('Incentives.taxCredit_eligiblity')}
                      />
                    )}
                  </div>
                  <span className="value">{formatCurrency(incentive?.amount)}</span>
                </li>
              );
          })}
          <If condition={showIncetivesInSummary}>
            <li className="review-page--line-item tds-list-item">
              <div className="label left">
                <p>{i18n('Review.est_savings_total')}</p>
              </div>
              <span className="value">{formatCurrency(priceAfterSavings)}</span>
            </li>
          </If>
          <If condition={showVehiclePriceInSummary}>
            <li className="tds-list-item tds-flex">
              <span className="tds-flex-item tds-text--medium">{i18n('Review.vehicle_price')}</span>
              <span className="value price-indicator tds-flex--col_1of3 tds-text--medium">
                {priceBeforeSavings}
              </span>
            </li>
            <li className="tds-list-item tds-flex">
              <span className="tds-flex-item">
                <If condition={vatDisclaimer}>
                  {vatDisclaimer}
                </If>
              </span>
            </li>
          </If>
        </ul>
        <If condition={showAfterSavingsPriceForTrims}>
          <ModalTrigger
            options={{
              props: {
                componentName: 'GasSaving',
                props: {
                  genericWrapper: true,
                  size: MODAL_SMALL,
                  modelName,
                  modelCode,
                  classes: 'tds-o-text_color--20 tds-o-text-align',
                  containerCss: 'gas-saving',
                },
              },
            }}
            analyticsInteraction={PRICING_SAVINGS_CALCULATIONS}
          >
            <span className="gas-saving--link tds-link tds-text--caption cf-payment-overview__title">
              {i18n('Review.savings_link_label')}
            </span>
          </ModalTrigger>
        </If>
      </div>
    </div>
  );
};

function mapStateToProps(state, { estPurchasePrice = 0 }) {
  const { showAfterSavingsPriceForTrims = false, showVehiclePriceInSummary = false, showStaticIncentivesInSummary = false } =
    state?.ReviewDetails || {};
  const { showIncentiveInFeesAndDiscounts = [] } = state?.FinancingOptions || {};

  const { incentives } = getFinancialIncentives(state);
  const currentIncentivesFiltered = _reduce(incentives, (res, incentiveArr, key) => {
    return _reduce(incentiveArr, (res, incObj, k) => {
      if (incObj?.isUIHidden ||
          incObj?.period === PERIOD_CREDIT ||
          showIncentiveInFeesAndDiscounts?.includes(incObj?.incentiveType) ||
          (!showAfterSavingsPriceForTrims && key === INCENTIVE_FUEL)) {
        return res;
      }
      return {
        ...res,
        [`${key}:${k}`]: incObj,
      };
    }, res);
  }, {});
  const incentivesArray = Object.keys(incentives).map(key => incentives[key]);
  const totalSavings = _get(state, 'Pricing.cash.totalSavingsAmount', 0);
  const priceAfterSavings = estPurchasePrice + totalSavings;

  const modelOption = getModelCode(state);
  const modelCode = _get(state, 'OMS.oms_params.model');
  const modelName = _get(state, `OMS.lexicon.options.${modelOption}.name`, {});
  const region = getRegionName(state) || getUserRegionCode(state) || '';
  const { vehiclePriceWithoutDiscounts = 0 } = state?.Pricing?.cash || {};
  const financeType = getFinanceType(state);
  const priceBeforeSavings = getPrice(state, 'cash');
  const market = _get(state, 'OMS.oms_params.market');
  const isUsedInventory = _get(state, 'ReviewDetails.product.isUsedInventory', false);
  const showTaxAndFeeDisclaimerForUsed = _get(state, 'ReviewDetails.showTaxAndFeeDisclaimerForUsed', false);
  let vatDisclaimer = '';
  if (market === 'CN' && isUsedInventory && showTaxAndFeeDisclaimerForUsed) {
    vatDisclaimer = i18n('SummaryPanel.disclaimers.taxesAndFeesExcluded__used', null, null, {
      returnNullWhenEmpty: true,
    });
  } else {
    if (modelCode === 'm3' || modelCode === 'my') {
      vatDisclaimer = i18n('SummaryPanel.disclaimers.myVatAmount', null, null, {
        returnNullWhenEmpty: true,
      });
    } else if (modelCode === 'ms' || modelCode === 'mx') {
      vatDisclaimer = i18n('SummaryPanel.disclaimers.myVatAmountSX', null, null, {
        returnNullWhenEmpty: true,
      });
    } else {
      vatDisclaimer = '';
    }
  }

  return {
    modelCode,
    modelName,
    currentIncentivesFiltered,
    showIncentiveInFeesAndDiscounts,
    showIncetivesInSummary: showAfterSavingsPriceForTrims || showStaticIncentivesInSummary,
    showAfterSavingsPriceForTrims,
    priceAfterSavings,
    region,
    showVehiclePriceInSummary,
    vehiclePriceWithoutDiscounts,
    financeType,
    priceBeforeSavings,
    vatDisclaimer,
  };
}

Incentives.propTypes = {
  showIncentiveInFeesAndDiscounts: array,
  showIncetivesInSummary: bool,
  modelCode: string,
  modelName: string,
  currentIncentivesFiltered: shape({}),
  showAfterSavingsPriceForTrims: bool,
  priceAfterSavings: number,
  region: string,
  showVehiclePriceInSummary: bool,
  vehiclePriceWithoutDiscounts: number,
  financeType: string,
  priceBeforeSavings: string,
  vatDisclaimer: string,
};

Incentives.defaultProps = {
  showIncentiveInFeesAndDiscounts: [],
  showIncetivesInSummary: false,
  modelCode: '',
  modelName: '',
  currentIncentivesFiltered: {},
  showAfterSavingsPriceForTrims: false,
  priceAfterSavings: 0,
  region: '',
  showVehiclePriceInSummary: false,
  vehiclePriceWithoutDiscounts: 0,
  financeType: FinanceTypes.CASH,
  priceBeforeSavings: '',
};

export default connect(mapStateToProps)(Incentives);
