import React from 'react';
import { Icon } from '@tesla/design-system-react';
import { iconChevron270 } from '@tesla/design-system-icons';

const PreviousButton = ({ onClick, label, classes = '' }) =>
  onClick && (
    <button type="button" className={classes} onClick={onClick} aria-label={label}>
      <Icon data={iconChevron270} size="large" className="tds-icon-btn" />
    </button>
  );

export default PreviousButton;
