import React from 'react';
import { Icon } from '@tesla/design-system-react';
import { iconChevron90 } from '@tesla/design-system-icons';

const NextButton = ({ onClick, label, classes }) =>
  onClick && (
    <button type="button" className={classes} onClick={onClick} aria-label={label}>
      <Icon data={iconChevron90} size="large" className="tds-icon-btn" />
    </button>
  );
export default NextButton;
