import React from 'react';
import { connect } from 'react-redux';
import { array, bool, string } from 'prop-types';
import { i18n } from 'utils';
import classnames from 'classnames';
import { Card, Icon, Link } from '@tesla/design-system-react';
import { iconPdf } from '@tesla/design-system-icons';
import _get from 'lodash/get';
import { NEW, TITLE_SUB_STATUS } from 'dictionary';
import {
  getSimplifiedEsignConditionVariable,
  onlyUsedAsExhibitionVehicle,
  gotTempPlateInsuranceRecordAndExhibitionVehicle,
  gotTempPlateAndInsuranceRecord,
  gotInsuranceRecordAndExhibitionVehicle,
  gotInsuranceRecord,
  isSimplifiedEsignFlowEnabled,
} from '../../common/selectors';

const ConditionNotificationBlock = ({
  isLayoutMobile,
  isNonBrandNewConsentEnabled,
  titleStatus,
  titleSubStatus,
  getDMSFilesRoute,
  damageDisclosureGuids,
  simplifiedEsignFlowEnabled,
  onlyUsedAsExhibitionVehicleScenario,
  gotTempPlateInsuranceRecordAndExhibitionVehicleScenario,
  gotTempPlateAndInsuranceRecordScenario,
  gotInsuranceRecordAndExhibitionVehicleScenario,
  gotInsuranceRecordScenario,
}) => {
  const showConditionNotificationBlock =
    titleStatus.toLowerCase() === NEW && titleSubStatus === TITLE_SUB_STATUS.NON_BRAND_NEW;
  if (!isNonBrandNewConsentEnabled || !showConditionNotificationBlock) {
    return null;
  }

  return (
    <div
      className={classnames('condition-notification-container option-widget--container', {
        'tds--vertical_padding--6x': isLayoutMobile,
      })}
    >
      <h2 className="tds-text--center">
        {i18n('Inventory.vehicleDetails.conditionNotificationLabel')}
      </h2>
      <If condition={simplifiedEsignFlowEnabled}>
        <Choose>
          <When condition={gotInsuranceRecordScenario}>
            <span>{i18n('Inventory.vehicleDetails.gotInsuranceRecordScenario')}</span>
          </When>
          <When condition={gotTempPlateAndInsuranceRecordScenario}>
            <span>{i18n('Inventory.vehicleDetails.gotTempPlateAndInsuranceRecordScenario')}</span>
          </When>
          <When condition={onlyUsedAsExhibitionVehicleScenario}>
            <span>{i18n('Inventory.vehicleDetails.onlyUsedAsExhibitionVehicleScenario')}</span>
          </When>
          <When condition={gotTempPlateInsuranceRecordAndExhibitionVehicleScenario}>
            <span>
              {i18n(
                'Inventory.vehicleDetails.gotTempPlateInsuranceRecordAndExhibitionVehicleScenario'
              )}
            </span>
          </When>
          <When condition={gotInsuranceRecordAndExhibitionVehicleScenario}>
            <span>
              {i18n('Inventory.vehicleDetails.gotInsuranceRecordAndExhibitionVehicleScenario')}
            </span>
          </When>
        </Choose>
      </If>
      <If condition={!simplifiedEsignFlowEnabled}>
        <Card>
          <p>
            <Link
              href="https://www.tesla.cn/go/nonbrandnewinventory"
              className="tds-link--icon-leading"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon data={iconPdf} />
              <span>{i18n('Inventory.vehicleDetails.nonBrandNewVehicleNotification')}</span>
            </Link>
          </p>
          {damageDisclosureGuids?.map((guid, index) => {
            return (
              <p>
                <Link
                  href={`${getDMSFilesRoute}${guid}`}
                  className="tds-link--icon-leading"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Icon data={iconPdf} />
                  <span>{`${i18n('Inventory.vehicleDetails.workOrder')}${index + 1}`}</span>
                </Link>
              </p>
            );
          })}
        </Card>
      </If>
    </div>
  );
};

ConditionNotificationBlock.propTypes = {
  isLayoutMobile: bool,
  isNonBrandNewConsentEnabled: bool,
  titleStatus: string,
  titleSubStatus: string,
  getDMSFilesRoute: string,
  damageDisclosureGuids: array,
  simplifiedEsignFlowEnabled: bool,
  onlyUsedAsExhibitionVehicleScenario: bool,
  gotTempPlateInsuranceRecordAndExhibitionVehicleScenario: bool,
  gotTempPlateAndInsuranceRecordScenario: bool,
  gotInsuranceRecordAndExhibitionVehicleScenario: bool,
  gotInsuranceRecordScenario: bool,
};

ConditionNotificationBlock.defaultProps = {
  isNonBrandNewConsentEnabled: false,
};

function mapStateToProps(state) {
  const isLayoutMobile = _get(state, 'App.isLayoutMobile', false);
  const titleStatus = _get(state, 'ReviewDetails.product.data.TitleStatus', '');
  const titleSubStatus = _get(state, 'ReviewDetails.product.data.TitleSubStatus', '');
  const isNonBrandNewConsentEnabled = _get(state, 'App.isNonBrandNewConsentEnabled', false);
  const getDMSFilesRoute = _get(state, 'App.routes.getDMSFiles', '');
  const damageDisclosureGuids = _get(state, 'ReviewDetails.product.data.DamageDisclosureGuids', []);
  const simplifiedEsignConditionVariable = getSimplifiedEsignConditionVariable(state);
  const onlyUsedAsExhibitionVehicleScenario = onlyUsedAsExhibitionVehicle(
    simplifiedEsignConditionVariable
  );
  const gotTempPlateInsuranceRecordAndExhibitionVehicleScenario = gotTempPlateInsuranceRecordAndExhibitionVehicle(
    simplifiedEsignConditionVariable
  );
  const gotTempPlateAndInsuranceRecordScenario = gotTempPlateAndInsuranceRecord(
    simplifiedEsignConditionVariable
  );
  const gotInsuranceRecordAndExhibitionVehicleScenario = gotInsuranceRecordAndExhibitionVehicle(
    simplifiedEsignConditionVariable
  );
  const gotInsuranceRecordScenario = gotInsuranceRecord(simplifiedEsignConditionVariable);

  return {
    isLayoutMobile,
    isNonBrandNewConsentEnabled,
    titleStatus,
    titleSubStatus,
    getDMSFilesRoute,
    damageDisclosureGuids,
    onlyUsedAsExhibitionVehicleScenario,
    gotTempPlateInsuranceRecordAndExhibitionVehicleScenario,
    gotTempPlateAndInsuranceRecordScenario,
    gotInsuranceRecordAndExhibitionVehicleScenario,
    gotInsuranceRecordScenario,
    simplifiedEsignFlowEnabled: isSimplifiedEsignFlowEnabled(state),
  };
}

export default connect(mapStateToProps)(ConditionNotificationBlock);
