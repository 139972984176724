import React from 'react';
import { string, bool } from 'prop-types';

import { iconChevronSmall90, iconChevron90 } from '@tesla/design-system-icons';
import { Icon } from '@tesla/design-system-react';

const IconChevron = ({ classes, small }) => {
  return <Icon data={small ? iconChevronSmall90 : iconChevron90} className={classes} />
};

IconChevron.propTypes = {
  classes: string,
  small: bool,
};

IconChevron.defaultProps = {
  small: true,
  classes: '',
};

export default IconChevron;