import React from 'react';
import { string } from 'prop-types';
import { useSelector } from 'react-redux';
import { EXPERIMENT_TOP_BANNER_OFF } from 'dictionary';
import TopBanner from './TopBanner';
import NonTopBanner from './NonTopBanner';

const DynamicBanner = ({ dynamicBannerContentStringified }) => {
  const { appData, applicationFlow } = useSelector(state => ({
    appData: state.App,
    applicationFlow: state.ApplicationFlow,
  }));
  const { optimizelyExpIds = [] } = applicationFlow || {};
  const bannerDisabled = optimizelyExpIds?.includes(EXPERIMENT_TOP_BANNER_OFF);
  const dynamicBannerContent = dynamicBannerContentStringified
    ? JSON.parse(dynamicBannerContentStringified)
    : [];
  const contentLength = dynamicBannerContent?.length;
  const isTopBanner = !bannerDisabled && contentLength;

  if (!contentLength || (!isTopBanner)) {
    return null;
  }

  if (isTopBanner) {
    return <TopBanner dynamicBannerContentStringified={dynamicBannerContentStringified} />;
  }

  return <NonTopBanner dynamicBannerContentStringified={dynamicBannerContentStringified} />;
};

DynamicBanner.propTypes = {
  dynamicBannerContentStringified: string,
};

DynamicBanner.defaultProps = {
  dynamicBannerContentStringified: '',
};

export default DynamicBanner;
