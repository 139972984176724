import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { func, bool, number, shape, oneOfType, string, arrayOf } from 'prop-types';
import { CAROUSEL, FINANCE_CAROUSEL, SIMPLE } from 'dictionary';
import Analytics from 'analytics';
import '../../style/modal-trigger.css';
import usePrevious from '../../hooks/usePrevious';
import GioStatistics from 'gioStatistics';

let modalTriggerId = 0;

function getModalTriggerId() {
  return `modal-trigger-${modalTriggerId++}`;
}

const ModalTriggerTemplate = ({
  openModal,
  closeModal,
  isModalOpen,
  isComponentOpen,
  areSameProps,
  options,
  children,
  classes,
  type,
  isCarouselOpen,
  openFeatureModal,
  analyticsTriggerText,
  closeFeatureModal,
  groupName,
  containerCss,
  analyticsInteraction,
  tabIndex,
  selectedView,
  carouselTriggerText,
  triggerType,
  isFeatureListModalOpen,
  isFinanceModalOpen,
  openFinanceModal,
  closeFinanceModal,
  currentTriggerId,
  dataId,
  own_prop,
  selectedTab,
  setTab,
  modalClasses,
  gioEventName,
}) => {
  const previousIsModalOpen = usePrevious(isModalOpen);
  const previousIsCarouselOpen = usePrevious(isCarouselOpen);
  const previousIsFeatureListOpen = usePrevious(isFeatureListModalOpen);
  const previousIsFinanceModalOpen = usePrevious(isFinanceModalOpen);
  const triggerRef = useRef();
  const [triggerId] = useState(() => getModalTriggerId());

  const handleModalClick = () => {
    if (isModalOpen) {
      closeModal();
    } else {
      if ((isComponentOpen && !areSameProps) || isComponentOpen) {
        // close previous modal first
        closeModal();
      }
      openModal(options.props);
    }
  };

  const handleCarouselModalClick = () => {
    if (isCarouselOpen) {
      closeFeatureModal();
    }
    // groupName is passed only for the carousel modal
    // adding a check here to differentiate between add/remove or learn more button click(eg: fsd)
    if (groupName) {
      openFeatureModal({ analyticsTriggerText, groupName, selectedView, carouselTriggerText, modalClasses });
    }
  };

  const handleFinanceModalClick = () => {
    if (isFinanceModalOpen) {
      closeFinanceModal();
    }
    if (selectedTab) {
      setTab(selectedTab);
    }

    openFinanceModal({ selectedView, triggerId, ...(own_prop || {}) });
  };

  const handleClick = ({ target: trigger }) => {
    if (gioEventName) {
      GioStatistics.manuallyTrack(gioEventName);
    }
    if (analyticsInteraction) {
      Analytics.fireTagEvent({
        event: Analytics.event,
        interaction: analyticsInteraction,
        'cfg-type': Analytics.cfgType,
      });
    }

    triggerRef.current = trigger;

    switch (type) {
      case CAROUSEL:
        handleCarouselModalClick();
        break;
      case FINANCE_CAROUSEL:
        handleFinanceModalClick();
        break;
      default:
        handleModalClick();
    }
  };

  useEffect(() => {
    const didCloseFinanceModal =
      previousIsFinanceModalOpen === true && isFinanceModalOpen === false;
    const didCloseCarousel = previousIsCarouselOpen === true && isCarouselOpen === false;
    const didCloseModal = previousIsModalOpen === true && isModalOpen === false;
    const didCloseFeatureListModal =
      previousIsFeatureListOpen === true && isFeatureListModalOpen === false;
    const { current: trigger } = triggerRef;
    const isCurrentTrigger = currentTriggerId === triggerId;

    if (
      (isCurrentTrigger && didCloseFinanceModal) ||
      didCloseModal ||
      (didCloseCarousel && didCloseFeatureListModal)
    ) {
      if (trigger?.focus) {
        trigger.focus();
      }
    }
  }, [isModalOpen, isCarouselOpen, isFeatureListModalOpen, isFinanceModalOpen]);

  return (
    <div
    data-id={dataId}
    className={classnames('modal-trigger-container', { [containerCss]: containerCss })}
    >
      <button
        className={classnames(
          'modal-trigger',
          { 'tds-icon-trigger': triggerType === 'iconTrigger' },
          { 'tds-link tds-link--secondary': triggerType === 'default' },
          { 'tds-btn tds-btn--tertiary tds-btn--small': triggerType === 'buttonTriggerSmall'},
          { [classes]: classes }
        )}
        type="button"
        {...(tabIndex === -1 ? { tabIndex: '-1' } : {})}
        onClick={handleClick}
        ref={triggerRef}
      >
        {children}
      </button>
    </div>
  );
};

ModalTriggerTemplate.propTypes = {
  openModal: func.isRequired,
  closeModal: func.isRequired,
  isModalOpen: bool.isRequired,
  isComponentOpen: bool,
  areSameProps: bool,
  options: shape({ props: shape({}) }),
  children: oneOfType([func, shape({}), arrayOf(shape({})), string]).isRequired,
  classes: string,
  type: string,
  isCarouselOpen: bool.isRequired,
  openFeatureModal: func.isRequired,
  analyticsTriggerText: string,
  closeFeatureModal: func.isRequired,
  groupName: string,
  containerCss: string,
  analyticsInteraction: string,
  tabIndex: number,
  selectedView: string,
  carouselTriggerText: string,
  triggerType: string,
  isFeatureListModalOpen: bool,
  isFinanceModalOpen: bool,
  openFinanceModal: func.isRequired,
  closeFinanceModal: func.isRequired,
  currentTriggerId: string,
  dataId: string,
  selectedTab: string,
  setTab: func.isRequired,
};

ModalTriggerTemplate.defaultProps = {
  areSameProps: false,
  options: {},
  isComponentOpen: null,
  analyticsTriggerText: '',
  groupName: '',
  classes: '',
  type: SIMPLE,
  containerCss: '',
  analyticsInteraction: null,
  tabIndex: 0,
  selectedView: '',
  carouselTriggerText: null,
  triggerType: '',
  isFeatureListModalOpen: false,
  currentTriggerId: null,
  dataId: 'modal-trigger-container',
  selectedTab: '',
};

export default ModalTriggerTemplate;
