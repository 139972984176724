import React, { useEffect } from 'react';
import { string } from 'prop-types';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { Card } from '@tesla/design-system-react';
import { UI_DATA_IDS, SHOW_BANNER_MODAL } from 'dictionary';
import { htmlToReact } from 'utils';
import TextLoader from '../TextLoader';
import ActionTrigger from '../ActionTrigger';
import GioStatistics from 'gioStatistics';
import { WEB_CONFIGURATOR_BANNER_LINK } from '../../common/gioStatistics';
import useAction from '../../hooks/useActions';

const NonTopBanner = ({ dynamicBannerContentStringified }) => {
  const { applicationFlow, oms } = useSelector(state => ({
    appData: state.App,
    applicationFlow: state.ApplicationFlow,
    oms: state.OMS,
  }));
  const { referralCode = '' } = applicationFlow?.referral || {};
  const { market: countryCode } = oms?.oms_params || {};
  const dynamicBannerContent = dynamicBannerContentStringified
    ? JSON.parse(dynamicBannerContentStringified)
    : [];
  const contentLength = dynamicBannerContent?.length;

  const openBannerModal = useAction(SHOW_BANNER_MODAL);

  useEffect(() => {
    return () => {
      document.body.classList.remove('has-top-banner');
    };
  }, []);

  const isMultiBanner = contentLength > 1;
  const isCentered = contentLength < 2 && dynamicBannerContent?.[0]?.isAlignLeft !== true;
  const gioTrackBanner = () => {
    countryCode === 'CN' && GioStatistics.manuallyTrack(WEB_CONFIGURATOR_BANNER_LINK);
  };
  const onClick = data => {
    gioTrackBanner();
    const { modalContent, modalTitle, modalSubtitle, modalBtnCopy, modalBtnBehavior } = data || {};
    const content =
      modalTitle && modalContent
        ? [
            {
              modalTitle,
              modalSubtitle,
              modalContent,
              modalBtnCopy,
              modalBtnBehavior,
            },
          ]
        : [];
    openBannerModal({ content });
  };

  const addReferralToLink = content => {
    if (countryCode !== 'CN') {
      return content;
    }
    const regEx = /["']https?:\/\/.*?["']/gi;
    let urls = content.match(regEx);
    if (Array.isArray(urls)) {
      urls = _.uniq(urls);
      urls.forEach(function(url) {
        if (!url || url.indexOf('referral') >= 0 || !referralCode) {
          return;
        }
        url = url.replaceAll("'", '').replaceAll('"', '');
        let targetUrl = new URL(url);
        targetUrl.searchParams.append('referral', referralCode);
        content = _.replace(content, url, targetUrl.toString());
      });
    }
    return content;
  };

  const getNonTopBannerContent = bannerContent => {
    return (
      <>
        {bannerContent?.map((content, index) => {
          const { title, subtitle, description, btnCopy, btnBehavior, link } = content || {};
          const replacedDescription = addReferralToLink(description);
          return (
            <div key={`banner-id-${index}`} className={cx({ 'tds--padding_top-16': index })}>
              <If condition={title}>
                <TextLoader data={title} tag={{ component: 'strong' }} className="tds-text--h3" />
              </If>
              <If condition={subtitle}>
                <TextLoader
                  data={subtitle}
                  tag={{ component: 'strong' }}
                  className="tds-text--body"
                />
              </If>
              <If condition={description}>
                <TextLoader
                  data={replacedDescription}
                  transitionWrapper={false}
                  className="tds--padding_top-8 tds-text--caption"
                />
              </If>
              <If condition={btnCopy && (btnBehavior?.length || content?.modalContent)}>
                <div className="tds--padding_top-8 modal-trigger-container">
                  <Choose>
                    <When condition={btnBehavior?.length}>
                      <TextLoader
                        data={{
                          content: btnCopy,
                          behavior: btnBehavior,
                        }}
                      />
                    </When>
                    <When condition={btnCopy}>
                      <button className="modal-trigger tds-link" onClick={() => onClick(content)}>
                        {htmlToReact(btnCopy)}
                      </button>
                    </When>
                  </Choose>
                </div>
              </If>
              <If condition={link}>
                <div className="text-loader--content tds-text--center federal-tax--link tds-text--caption">
                  {htmlToReact(link)}
                </div>
              </If>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className={'tds-flex tds-flex--justify-center'}>
      <Card
        className={cx('coin-prominent--currency tds-scrim--black tds-scrim--gradient', {
          'coin-card--multibanner': isMultiBanner,
          'tds-text--center': isCentered,
        })}
        data-id={UI_DATA_IDS?.overviewSection?.banner}
      >
        <ActionTrigger>{getNonTopBannerContent(dynamicBannerContent)}</ActionTrigger>
      </Card>
    </div>
  );
};

NonTopBanner.propTypes = {
  dynamicBannerContentStringified: string,
};

NonTopBanner.defaultProps = {
  dynamicBannerContentStringified: '',
};

export default NonTopBanner;
