import 'babel-polyfill';
import { polyfillIntlDisplayNames } from './common/polyfill';
import { captureException } from '@sentry/react';

import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _isEmpty from 'lodash/isEmpty';
import _head from 'lodash/head';
import _flatMapDeep from 'lodash/flatMapDeep';
import _merge from 'lodash/merge';
import _has from 'lodash/has';
import _isArray from 'lodash/isArray';
import _groupBy from 'lodash/groupBy';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { formatCurrency, setFormatCurrencyOptions, setLocale } from '@tesla/coin-common-components';
import { DSServices } from '@web/tesla-rest-ds-services';
import { Storage } from '@tesla/coin-common-components';
import { validatePostalCode } from '@tesla/intl-address';
import { composeWithDevTools } from 'redux-devtools-extension';
import parse from 'url-parse';
import DOMPurify from 'dompurify';
import { isGrayMarket } from '@tesla/markets-vehicle';

import {
  retrieveReferralFromStorage,
  i18n,
  getLayoutTypes,
  formatLocationsData,
  getPaymentDetailsMapped,
  normalizeGrayLocale,
  getQueryParameters,
  validateLatLng,
  getStateList,
  isHashBlockedFromReLoading,
  isWeChatBrowser,
  isWeChatMini,
  getAddressByCountryCode,
  getCookieInfoOfLatestLexiconLoading,
  getPricingContextCurrency,
  getCompoundDeliveryLocations,
  getCookieConsent,
  validateRegionCode,
  isCookieConsentLightRejected,
} from 'utils';

import {
  CONTEXT_DEFAULT,
  COMPLETE,
  ORDER_TYPE_CASH,
  RESERVED,
  BOOKED,
  ORDER_PLACED,
  INVENTORY_NEW,
  INVENTORY_USED,
  NAVIGATION_VIEW_PAYMENT,
  Models,
  TRIM,
  REAR_WHEEL_PARTIAL,
  DUAL_MOTOR_PREMIUM,
  REAR_WHEEL_PREMIUM,
  SPORT_PACKAGE,
  LOAD_LATEST_LEXICON_COOKIE_NAME,
  PAYMENT_TYPE_WECHAT,
  ALL_WHEEL_DRIVE_ONE,
  ALL_WHEEL_DRIVE_TWO,
  ESIGN_STATUS_INIT,
  VERIFICATION_GROUP_MILITARY,
  COMPONENT_CONTEXT_EDITDESIGN_OLD,
  MODEL_FULL_NAME,
  COMPONENT_CONTEXT_RESERVATION_TO_ORDER,
  verificationProviderToCoreMap,
  VERIFY_PROVIDER_UBER,
  VERIFY_PROVIDER_IDME,
  VERIFICATION_GROUP_UBER_DRIVER,
  EXPERIMENT_FSD_VIDEO_BUTTON_OFF,
} from 'dictionary';

import Analytics from 'analytics';
import Main from './containers/Main';
import { getInitialState } from './reducers';
import configureStore from './store/configureStore';

import * as Navigation from './reducers/Navigation';

import './sentry';

// Import app styles
import './style/main.css';

const teslaObj = window.tesla || {};
const teslaAppInjection = window.teslaApp || {};
const {
  vehicleUpgrades = [],
  vehicleUpgradesDisplay = [],
  vehicleUpgradesAlternate = [],
  isDeliverySelectionEnabled = false,
  registrationStates = [],
  availableProvincesForAddressVerification,
  isInventorySwapEnabled = false,
  isAutoCheckEnabled,
  showPriceDiffVariationForSwap = false,
  hasAutoCheckReport,
  isOrderFeeAPIEnabled,
  omsParams,
  showTaxesAndFees = false,
  isPickupOnlyEnabled = false,
  isPreQualifyEnabled = false,
  setLocaleHeader = false,
  showSavingsAfterTaxCredit = false,
  isAccessoriesEnabled = false,
  showRecommendedAccessories = false,
  mobileVersion,
  contextApp,
  isTradeInEnabled = false,
  ggConf = {},
  isPreferredAddressEnabled = false,
  preferredAddressScriptUrl,
  isVerificationProgramEnabled = false,
  verificationStatus = [],
  isEarlyAvailabilityEnabled = false,
  isEarlyAvailabilityEnabledWithLimits = false,
} = teslaObj || {};
const optimizelyExpIds = (window?.optimizelyExpIds || []).filter((i) => DOMPurify.sanitize(i))
const { isTradeInExperimentEnabled = false } = window?.optExp || {};
const { isCookieConsentNeeded = false, isCookieConsentLightNeeded = false } = teslaObj?.env || {};
const { model, variant, market: countryCode = 'US', language = 'en' } = omsParams || {};
// Application flow injections
// @see http://10.33.137.83:50001/swagger/ for Model definitions
const Order = _get(teslaObj, 'Order', {});
const rn = _get(teslaObj, 'rn', null);
const product = _get(teslaObj, 'product', null);
const routes = _get(teslaObj, 'routes', []);
const isEnterpriseOrder = _get(teslaObj, 'isEnterpriseOrder', false);
const isPostOrderSwap = _get(teslaObj, 'isPostOrderSwap', false);
const isInventoryPriceAdjustmentsEnabled = _get(
  teslaObj,
  'isInventoryPriceAdjustmentsEnabled',
  false
);
const isFalconDeliverySelectionEnabled = _get(teslaObj, 'isFalconDeliverySelectionEnabled', false);
const useInventoryAgentOptionPrice = _get(teslaObj, 'useInventoryAgentOptionPrice', false);
const isDm = _get(teslaAppInjection, 'mobileApp', false) || _get(teslaObj, 'App.isDm', false);
const isConfiguratorInAppEnabled = _get(teslaAppInjection, 'isConfiguratorInAppEnabled', false);
const isCoinReloaded = _get(teslaObj, 'App.isCoinReloaded', false);
const isInventoryReloaded = _get(teslaObj, 'App.isInventoryReloaded', false);
// Check if app has lastest build and payment enabled then check LD flag
let isNativePaymentEnabled =
  _get(teslaAppInjection, 'isNativePaymentEnabled', false) &&
  _get(teslaObj, 'App.isNativePaymentEnabled', _get(teslaObj, 'isNativePaymentEnabled', false)) &&
  _get(teslaAppInjection, 'AccountDetail.FirstName') &&
  _get(teslaAppInjection, 'AccountDetail.LastName') &&
  _get(teslaAppInjection, 'AccountDetail.PhoneNumber') &&
  _get(teslaAppInjection, 'AccountDetail.Email');

if (countryCode === 'CN') {
  if (isDm) {
    // force add these class as mobile-app do in dark mode to compromise with incompatible android device
    var html = document.documentElement;
    html.classList.remove('tds-theme--normal');
    html.classList.add('tds-theme--dk', 'tds-scrim--black', 'theme-mobile-app', 'tds-scrim--dk');
  }
  isNativePaymentEnabled =
    isNativePaymentEnabled &&
    (_get(teslaAppInjection, 'isConfiguratorInAppEnabled', false) ||
      _get(teslaAppInjection, 'isInventoryInAppEnabled', false));
}

const isEnterprisePurchaseOrder = _get(teslaObj, 'isEnterprisePurchaseOrder', false);
const isEnterpriseCustomOrderEdit = _get(teslaObj, 'isEnterpriseCustomOrderEdit', false);
const isConfigBurst = _get(teslaObj, 'isConfigBurst', false);
const isNfaSigned = _get(teslaObj, 'isNfaSigned', false);
const isOverviewEnabled = _get(teslaObj, 'product.isOverviewEnabled', false);
const isProductWithOverview = product && isOverviewEnabled;
const modelRoute = _get(teslaObj, 'product.modelRoute', null);
const condition = _get(teslaObj, 'product.condition', null);
const marketingLexiconDate = _get(teslaObj, 'marketingLexiconDate', null);
const enableCyberpunk = [Models.CYBERTRUCK, Models.CYBERTRUCK_MP].includes(model);
const analytics = _get(window, 'tesla.env.analytics', false);
let isAvayaChatEnabled = _get(teslaObj, 'isAvayaChatEnabled', false);
if (countryCode === 'CN') {
  isAvayaChatEnabled = isAvayaChatEnabled && !isDm;
}
const isPaymentShortExpireTimeEnabled = _get(teslaObj, 'isPaymentShortExpireTimeEnabled', false);
const isAvayaChatLiteEnabled = _get(teslaObj, 'isAvayaChatLiteEnabled', false);
const isAvayaChatMobileEnabled = _get(teslaObj, 'isAvayaChatMobileEnabled', false);
const isAvayaChatTeslaAssistEnabled = _get(teslaObj, 'isAvayaChatTeslaAssistEnabled', false);
const commerceAssetsBase = _get(teslaObj, 'env.commerce.assetBaseUrl', '');
const isCookieConsentGiven = isCookieConsentNeeded ? getCookieConsent() : false;
const isCookieConsentRejected = isCookieConsentLightNeeded ? isCookieConsentLightRejected() : false;
const enableAnalytics = isCookieConsentRejected ? false : !!(analytics || isCookieConsentGiven) ?? false;
Analytics.enabled = enableAnalytics ? !!(window !== undefined && window.dataLayer) : false;
const isTaxInclusive = _get(teslaObj, 'isTaxInclusive', false);
const isNoPostalCodeMarket = _get(teslaObj, 'isNoPostalCodeMarket', false);
const isCountryWithStateCode = _get(teslaObj, 'isCountryWithStateCode', false);
const charityInvoiceTypes = _get(teslaObj, 'payments.CharityInvoiceTypes', []);
const isEnterpriseOrderPickup = isEnterpriseOrder && isPickupOnlyEnabled;
const isNewOrderProfileUrl = _get(teslaObj, 'isNewOrderProfileUrl', false);
const canModifyOrder = _get(Order, 'enableOrderModification', false);
const isReservationToOrderFlow = !!rn && !canModifyOrder;

const { Lexicon = {}, InitialConfig = {} } = new DSServices({}).get(
  () => {},
  { model, variant },
  omsParams
);
const {
  App: { defaultPricingContext },
} = InitialConfig || {};
const pricingContextMapping = _get(Lexicon, 'metadata.pricing.context_mapping', {});
// Set pricing context mapping for intl-number

const pricingContext = getPricingContextCurrency({
  context: defaultPricingContext,
  contextMap: pricingContextMapping,
});
const currencyCode = _get(Lexicon, 'metadata.currency_code') || _get(teslaObj, 'App.currencyCode');
const locale = _get(teslaObj, 'App.locale', `${language}_${countryCode}`);
const conversionRate = _get(teslaObj, 'paymentAmounts.EXTRA_DEPOSIT.coversion_rate', null);
const currency = _get(teslaObj, 'paymentAmounts.EXTRA_DEPOSIT.currency', null);

// Set override currency if present in lexicon
let currencyCodeWithContext = currencyCode;
if ((pricingContext && pricingContext !== CONTEXT_DEFAULT) || conversionRate) {
  currencyCodeWithContext = conversionRate ? currency : pricingContext;
}
// Set locale and currency for intl-number
setFormatCurrencyOptions(locale, currencyCodeWithContext);

const stateList = _reduce(
  getStateList(countryCode),
  (result, val, key) => {
    const label =
      i18n(`states.${key}`, null, 'common.geo', {
        returnNullWhenEmpty: true,
      }) || val;
    result.push({
      value: key,
      label,
    });
    return result;
  },
  []
);

const isReservation = _get(teslaObj, 'product.data.isReservation', false);
const isInventory = _has(teslaObj, 'product.data') && !isReservation;
const reviewDetails = _get(teslaObj, 'reviewDetails', {}) || {};
const {
  RegistrationDetail = {},
  ErrorCode = null,
  PreviousRN: previousRN = null,
  PaymentType: paymentType = undefined,
} = reviewDetails;

const vehicleId = _get(teslaObj, 'product.data.VIN', '');
const DeliveryLocations =
  isInventory && isDeliverySelectionEnabled
    ? Storage.get(`${vehicleId}-delivery-locations`) || {}
    : {};

const queryParams = getQueryParameters();
const { postal, coord, share, shareUrl = false, utm_campaign = '', region } = queryParams;
const isFromSaveDesignFlow = shareUrl && utm_campaign === 'save-design-lite';
const isSalesReferred = share === 'Y';
let latitude = null;
let longitude = null;
let postalCode = '';
let regionCode = null;
if (postal && validatePostalCode(postal, { countryCode })) {
  postalCode = postal;
}

if (coord) {
  const coordChunks = coord.split(',');
  const lat = coordChunks[0] || '';
  const lng = coordChunks[1] || '';
  if (lat && lng && validateLatLng(lat, lng)) {
    latitude = parseFloat(lat);
    longitude = parseFloat(lng);
  }
}

if (region && validateRegionCode(countryCode, region)) {
  regionCode = region;
}

const inventoryDeliveryDetailsStorage = isInventory
  ? Storage.get(`${vehicleId}-delivery-details`) || {}
  : {};
const DeliveryDetails = _isEmpty(inventoryDeliveryDetailsStorage)
  ? {
      PostalCode: postalCode,
      Latitude: latitude || null,
      Longitude: longitude || null,
      stateCode: regionCode || null,
    }
  : inventoryDeliveryDetailsStorage;

const orderType = ORDER_TYPE_CASH;

// Inject navgation overrides
const orderStatus = _get(Order, 'orderDetails.orderStatus', RESERVED);
const savedConfiguration = _get(teslaObj, 'savedConfiguration');
const isInvited = !_isEmpty(rn) || !_isEmpty(_get(Order, 'orderDetails.invitationDate', null));

// determine which navigation sections user is allowed to view
const isPreOrder = _get(teslaObj, 'isPreOrder', false);
const isPaymentDisabled = _get(teslaObj, 'isPaymentDisabled', false);
const isEditDesign = !!rn;
const allowedSections = Navigation.InitialState.object.allowedSections.filter(section => {
  if (isPaymentDisabled) {
    return section !== NAVIGATION_VIEW_PAYMENT;
  }
  switch (orderStatus) {
    case RESERVED:
    case BOOKED:
    case ORDER_PLACED:
    default:
      return (isEnterpriseOrder && savedConfiguration) || (product && !isOverviewEnabled)
        ? section === NAVIGATION_VIEW_PAYMENT
        : true;
  }
});

const navigationSection = (() => {
  let current_hash = window.location.hash.substring(1);
  if (
    !allowedSections.includes(current_hash) ||
    (isDeliverySelectionEnabled && (!ErrorCode || _isEmpty(DeliveryLocations))) ||
    isHashBlockedFromReLoading()
  ) {
    current_hash = _head(allowedSections);
  }
  return current_hash;
})();

let referralData = _get(teslaObj, 'referral');
if (!_isEmpty(referralData)) {
  const { products = {} } = referralData || {};
  const modelReferralData = products[model] || {};
  referralData = { ...referralData, ...modelReferralData };
}
if (!_isEmpty(referralData) && !_isEmpty(referralData.superchargerCreditAmount)) {
  referralData.formatSCCreditAmount = referralData.superchargerCreditAmount
    ? formatCurrency(referralData.superchargerCreditAmount, { showCurrSymbolForeign: true })
    : '';
  referralData.type = (referralData.type || '').toUpperCase();
}

if (rn) {
  referralData = retrieveReferralFromStorage(referralData, rn, savedConfiguration);
}

if (_has(window, 'tesla.resourceBaseUrl')) {
  __webpack_public_path__ = window.tesla.resourceBaseUrl + '/dist/';
}

const allLocations =
  countryCode === 'CN' ? formatLocationsData(getAddressByCountryCode(countryCode)) : [];
const availableCountrySelection =
  isInventory || rn
    ? []
    : _flatMapDeep(_get(teslaObj, 'App.availableLocales', [{ USA: 'en_us' }]), item =>
        _isArray(item) ? item.map(i => i.toLowerCase()) : [item.toLowerCase()]
      );
const isUsedInventory = isInventory
  ? INVENTORY_USED === _get(teslaObj, 'product.data.TitleStatus', '').toUpperCase()
  : false;
const isNewInventory = isInventory
  ? INVENTORY_NEW === _get(teslaObj, 'product.data.TitleStatus', '').toUpperCase()
  : false;
const isComboInventory = isInventory ? !!_get(teslaObj, 'product.data.Hash', '') : false;
const isTransportFeeCollectionEnabled =
  isInventory && _get(teslaObj, 'is_transport_fee_flow_enabled', false);
const tradeInType = isUsedInventory ? _get(teslaObj, 'product.data.TradeInType', null) : null;
const isBurstMode = _get(teslaObj, 'App.isBurstMode', false);
const AccountDetail =
  _get(reviewDetails, 'AccountDetail') || _get(teslaAppInjection, 'AccountDetail') || {};
const { BillingAddress = {}, VehicleDetail = {} } = reviewDetails;
const serverErrors = ErrorCode ? { [ErrorCode]: ErrorCode } : {};
const depositAmountPerCurrency = _get(teslaObj, 'paymentAmounts.EXTRA_DEPOSIT', null);
const depositAmount = currency ? depositAmountPerCurrency[currency] : depositAmountPerCurrency;
const isReservedPreOrder = _get(teslaObj, 'isReservedPreOrder', false);
const isBookedPreOrder = _get(teslaObj, 'isBookedPreOrder', false);
const isHideProfilePagePaymentType =
  countryCode === 'CN' && (isReservedPreOrder || isBookedPreOrder);
const defaultPaymentType = isHideProfilePagePaymentType ? PAYMENT_TYPE_WECHAT : undefined;
const city = _get(teslaObj, 'vehicleCity', '');
let availablePickupLocation = _get(teslaObj, 'availablePickupLocation', []);
const pickupLocations = _get(teslaObj, 'App.pickupLocations', []);
const { VrlLocks = [] } = product?.data || {};
const compoundLocations = getCompoundDeliveryLocations(VrlLocks);
const paymentObj = {
  CreditCardDetail: _get(teslaObj, 'CreditCardDetail'),
  CurrencyCode: currencyCode,
  CountryCode: countryCode,
  configs: _get(teslaObj, 'payments'),
  time: _get(teslaObj, 'time'),
  PaymentDetail: {
    PayorName: _get(teslaObj, 'CreditCardDetail.PayorName', ''),
    BillingInfoDetail: { ...BillingAddress, ...(countryCode === 'CN' ? { EntityId: 2062 } : {}) },
    CurrencyCode: currencyCode,
    CountryCode: countryCode,
    PaymentType: defaultPaymentType || paymentType,
  },
  PickupLocations: _get(teslaObj, 'PickUpLocations', []),
  provinceData: allLocations,
  acceptedCardTypes: _get(teslaObj, 'payments.ccTypes', []),
  depositAmount,
  depositAmountPerCurrency,
  currency,
  conversionRate,
  serverErrors,
};
const paymentDetail = getPaymentDetailsMapped(paymentObj);
const isEditDesignPreserved =
  !isReservationToOrderFlow && isEditDesign && _get(teslaObj, 'isPreserved', false);

const {
  query: { openId },
} = parse(window.location.href, true);
const isWeChatBrowserEnv = isWeChatBrowser();
const isWeChatMiniEnv = isWeChatMini();

if (window.tesla.wechat_pay_enabled && isWeChatBrowserEnv && !isWeChatMiniEnv && !openId) {
  // const appId = 'wxfe472af81526dbf1';
  // const appId = 'wx00f76a8e022479e7';
  const redirect_uri = encodeURIComponent(window.location.href);
  const payx_redirect_uri = encodeURIComponent(window.tesla.payx_redirect_uri + redirect_uri);
  const oAuthUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${window.tesla.wechat_appid}&redirect_uri=${payx_redirect_uri}&response_type=code&scope=snsapi_base&state=123&connect_redirect=1#wechat_redirect`;
  window.location.href = oAuthUrl;
}

const financeProductDisclaimer = _get(teslaObj, 'financeProductDisclaimer', {});
const isWebChatEnabled = _get(teslaObj, 'isWebChatEnabled', false) && !isDm;
const isCryptoEnabled = _get(teslaObj, 'cr_enabled', false);
const isCryptoIpCheckEnabled = _get(teslaObj, 'cr_ip_check_enabled', false);
const isVerifyPhoneNumberEnabled = _get(teslaObj, 'isVerifyPhoneNumberEnabled', false);
const verifyPhoneNumberHost = _get(teslaObj, 'verifyPhoneNumberHost', false);
const verifyPhoneNumberUid = _get(teslaObj, 'verifyPhoneNumberUid', false);
const orderVerifyPhoneNumberFlow = _get(teslaObj, 'orderVerifyPhoneNumberFlow', false);
const saveDesignVerifyPhoneNumberFlow = _get(teslaObj, 'saveDesignVerifyPhoneNumberFlow', false);
const configuration = _get(teslaObj, 'configuration', {});
const inventoryVerifyPhoneNumberFlow = _get(teslaObj, 'inventoryVerifyPhoneNumberFlow', false);
const requestCallbackCampaignId = _get(teslaObj, 'requestCallbackCampaignId', false);
let loadLatestLexiconCookieKey = '';
let vehicleCity = {};
if (countryCode === 'CN') {
  loadLatestLexiconCookieKey = _get(window.tesla, LOAD_LATEST_LEXICON_COOKIE_NAME);
  vehicleCity = { vehicleCity: city };
  availablePickupLocation = { availablePickupLocation };
}

const isProductWithCustomViews = _get(
  teslaObj,
  'product.data.CompositorViewsCustom.isProductWithCustomViews',
  false
);
const isUsedPhotos = Boolean(product?.data?.VehiclePhotos?.length);
const orderPlacedDate = isPostOrderSwap ? product?.orderDetails?.OrderPlacedDate || null : null;
const {
  isNonBrandNewConsentEnabled = false,
  isNonBrandNewConsentCondition = false,
  enableSimplifyNonBrandNewConsent = false,
} = window.tesla;
const subDetails = _reduce(_get(savedConfiguration, 'subscriptionDetails', []), (res, val) => {
  const {
    optionGroup: group,
    optionPrice: price,
    subscriptionType: type,
    optionCode: code,
  } = val;
  return {
    ...res,
    [group]: {
      group,
      price,
      type,
      code,
      isSelected: true,
    },
  };
}, {});
let verificationStatusArray = verificationStatus || [];
let verificationObj = {};
verificationStatusArray.forEach((s) => {
  const { provider, statusGroup, status } = s || {};
  const key = verificationProviderToCoreMap[statusGroup] ?? '';
  if (key) {
    verificationObj[key] = status;
  }
});
const { isMilitary = false, partnerDetails = [] } = Order?.orderDetails || {};
if (isMilitary) {
  verificationStatusArray.push({ statusGroup: VERIFICATION_GROUP_MILITARY, status: true, provider: VERIFY_PROVIDER_IDME });
}
partnerDetails?.map(partner => {
  const { companyName } = partner || {};
  if (companyName === VERIFY_PROVIDER_UBER) {
    verificationStatusArray.push({ statusGroup: VERIFICATION_GROUP_UBER_DRIVER, status: true, provider: VERIFY_PROVIDER_UBER });
  }
});
let extraAppOpts = Order?.useOldLexicon
  ? { lexiconComponentContext: COMPONENT_CONTEXT_EDITDESIGN_OLD }
  : {};
extraAppOpts = isReservationToOrderFlow
  ? { lexiconComponentContext: COMPONENT_CONTEXT_RESERVATION_TO_ORDER }
  : {};
const RehydratedState = _merge(
  getInitialState({
    ...omsParams,
    isInventory,
    isNewInventory,
    isUsedInventory,
    isComboInventory,
    isProductWithOverview,
    isProductWithCustomViews,
    tradeInType,
    isInvited,
    isReservation,
    isEditDesign,
    isEnterpriseOrder,
    isEnterpriseOrderPickup,
    isPostOrderSwap,
    isConfigBurst,
    isUsedPhotos,
    isWeChatMiniEnv,
    isWeChatBrowserEnv,
    isReservationToOrderFlow,
    isCoinReloaded,
    isInventoryReloaded,
  }),
  {
    Accessories: {
      assetsBase: commerceAssetsBase,
      isTaxInclusive,
      isNoPostalCodeMarket,
      isCountryWithStateCode,
    },
    App: {
      ggConf,
      setLocaleHeader,
      pricingContext,
      openId,
      isWeChatMiniEnv,
      isWeChatBrowserEnv,
      dsFunctionsEnabled: _get(teslaObj, 'dsFunctionsEnabled', false),
      analytics,
      analytics_variant_map: _get(teslaObj, 'analytics_variant_map', null),
      sibling: _get(teslaObj, 'sibling'),
      siblingBase: _get(teslaObj, 'sibling_base_url', ''),
      base_url: _get(teslaObj, 'base_url', ''),
      locale: _get(teslaObj, 'App.locale'),
      wol_key: _get(teslaObj, 'App.wol_key'),
      language,
      countryCode,
      uiCountry: _get(teslaObj, 'App.uiCountry'),
      uiLanguage: _get(teslaObj, 'App.uiLanguage'),
      uiLanguageDirection: _get(teslaObj, 'App.uiLanguageDirection', 'ltr'),
      uiLocale: _get(teslaObj, 'App.uiLocale'),
      forceZipCodeOnEditRegistrationAddress: _get(
        teslaObj,
        'App.forceZipCodeOnEditRegistrationAddress'
      ),
      availableCountrySelection,
      displayCountrySelection: availableCountrySelection.length > 1,
      isSaveDesignLiteEnabled: _get(teslaObj, 'save_design_feature_enabled', false),
      // exists for only US ms and mx
      isSaveDesignHeavyEnabled: false,
      showFederalTaxBanner: _get(teslaObj, 'show_tax_credit_banner', false),
      useTCLBanner: _get(teslaObj, 'useTCLBanner', false),
      adyenDf: _get(teslaObj, 'adyenDf', { enabled: false }),
      isApplePayInPayX: true,
      isBurstMode,
      closeOpenModalsOnNavigation: !isProductWithOverview,
      isTransportFeeCollectionEnabled,
      showInspectionModal: _get(teslaObj, 'showInspectionModal', false),
      ...getLayoutTypes(),
      isEnterpriseOrder,
      isEnterprisePurchaseOrder,
      isEnterpriseCustomOrderEdit,
      isPostOrderSwap,
      isInventoryPriceAdjustmentsEnabled,
      useInventoryAgentOptionPrice,
      isFalconDeliverySelectionEnabled,
      isConfigBurst,
      routes,
      isNfaSigned,
      isFinplatEnabled: _get(teslaObj, 'App.isFinplatEnabled', false),
      isPickupOnlyEnabled,
      isCompanyAgentIdEnabled: _get(teslaObj, 'isCompanyAgentIdEnabled', false),
      isDm,
      isNativePaymentEnabled,
      isConfiguratorInAppEnabled,
      isAvayaChatEnabled,
      isAvayaChatLiteEnabled,
      isAvayaChatMobileEnabled,
      isAvayaChatTeslaAssistEnabled,
      isPaymentShortExpireTimeEnabled,
      isNonBrandNewConsentEnabled,
      isNonBrandNewConsentCondition,
      enableSimplifyNonBrandNewConsent,
      extendedServiceAgreementUrl: _get(teslaObj, 'App.extendedServiceAgreementUrl', ''),
      isPasswordlessFlow: _get(teslaObj, 'isPasswordlessFlow', false),
      isEditDesignPreserved,
      abTestExperimentIds: _get(teslaObj, 'abTestExperimentIds', {}),
      isItemEligibilityFlowEnabled: _get(teslaObj, 'isItemEligibilityFlowEnabled', false),
      isDeliverySelectionEnabled,
      showFinancialTabsEnabled: _get(teslaObj, 'show_financial_tabs_enabled', true),
      isInventoryLinkEnabled: !isDm && !isEditDesign && !isEnterpriseOrder,
      isPreOrder,
      isPaymentDisabled,
      isAutoCheckEnabled: isAutoCheckEnabled && hasAutoCheckReport,
      isViewOnly: _get(teslaObj, 'isViewOnly', false),
      isInventorySwapEnabled,
      showPriceDiffVariationForSwap,
      vin: _get(teslaObj, 'vin', ''),
      isOrderFeeAPIEnabled,
      webChatHost: _get(teslaObj, 'web_chat_host', ''),
      isWebChatEnabled,
      isVerifyPhoneNumberEnabled,
      verifyPhoneNumberHost,
      verifyPhoneNumberUid,
      orderVerifyPhoneNumberFlow,
      saveDesignVerifyPhoneNumberFlow,
      inventoryVerifyPhoneNumberFlow,
      isFromSaveDesignFlow,
      isEnterpriseOrderPickup,
      isPreQualifyEnabled,
      isNewOrderProfileUrl,
      showSavingsAfterTaxCredit,
      isAccessoriesEnabled,
      mobileVersion,
      contextApp,
      showRecommendedAccessories,
      useShipFromAddressPerProduct: showRecommendedAccessories,
      isTradeInEnabled: !!(isTradeInEnabled && isTradeInExperimentEnabled),
      isPreferredAddressEnabled: isDm ? false : isPreferredAddressEnabled,
      requestCallbackCampaignId,
      preferredAddressScriptUrl,
      isCookieConsentLightNeeded,
      isCoinReloaded,
      isInventoryReloaded,
      showSinglePage: isCoinReloaded && !isEnterpriseOrder && !teslaObj.priceAcceptance?.pending,
      isVerificationProgramEnabled,
      isEarlyAvailabilityEnabled,
      isEarlyAvailabilityEnabledWithLimits,
      ownersManualLink: [
        '/ownersmanual',
        MODEL_FULL_NAME[model],
        isGrayMarket(countryCode) ? 'en_eu' : locale.toLowerCase(),
      ].join('/'),
      configuration,
      showSubscriptionInConfig: _get(teslaObj, 'App.showSubscriptionInConfig', false),
      showFsdVideoExp: !optimizelyExpIds?.includes(EXPERIMENT_FSD_VIDEO_BUTTON_OFF),
      pickupLocations,
      ...extraAppOpts,
      isFinplatV2Enabled: _get(teslaObj, 'App.isFinplatV2Enabled', false),
      hasScene: _get(teslaObj, 'App.hasScene', false),
      isNewModelY: _get(teslaObj, 'App.isNewModelY', false),
      isNewModelYMixed: _get(teslaObj, 'App.isNewModelYMixed', false),
    },
    ApplicationFlow: {
      orderStatus,
      alertDetails: _get(Order, 'alertDetails'),
      isInvited,
      canModifyOrder,
      isReservationToOrderFlow,
      isUnbuildable: _get(Order, 'orderDetails.isUnbuildable', false),
      surveyCompleted: _get(Order, 'alertDetails.survey', false) === COMPLETE,
      survey: {
        results: {
          orderType,
        },
      },
      referral: referralData,
      isSessionSet: !!rn || isInventory,
      isCryptoEnabled,
      isCryptoIpCheckEnabled: isCryptoEnabled && isCryptoIpCheckEnabled,
      reservationAmountDue: _get(Order, 'orderDetails.reservationAmountDue', 0),
      orderPlacedDate: _get(Order, 'orderDetails.orderPlacedDate', orderPlacedDate),
      isReservedPreOrder,
      isBookedPreOrder,
      reservationAmountReceived: _get(Order, 'orderDetails.reservationAmountReceived', 0),
      orderCreated: false,
      ...(isNonBrandNewConsentEnabled && isNonBrandNewConsentCondition
        ? {
            esignStatus: ESIGN_STATUS_INIT,
            esignPending: false,
          }
        : {}),
      verificationStatus: verificationStatusArray,
      optimizelyExpIds,
    },
    ApplePay: {
      enabled: !!_get(teslaObj, 'ap_enabled'),
      apMerchantId: _get(teslaObj, 'ap_merchant_id'),
    },
    Enterprise: {
      t4bAccountId: teslaObj?.accountIdPub || '',
      t4bAccountHeader: teslaObj?.t4bAccountHeader || {},
      t4bNotifications: teslaObj?.t4bNotifications || [],
      t4bServiceTabEnabled: teslaObj?.t4bServiceTabEnabled || false,
      t4bShopTabEnabled: teslaObj?.t4bShopTabEnabled || false,
      t4bNewMarketExpEnabled: teslaObj?.t4bNewMarketExpEnabled || false,
    },
    Payment: {
      ...paymentObj,
      PaymentDetail: {
        ...paymentObj.PaymentDetail,
        ...paymentDetail,
      },
      charityInvoiceTypes,
    },
    Subscription: {
      ...subDetails,
    },
    DeliveryDate: {
      startDate: _get(teslaObj, 'DeliveryDate.eddStartDate'),
      endDate: _get(teslaObj, 'DeliveryDate.eddEndDate'),
    },
    Location: {
      sources: {
        registration: { ..._get(teslaObj, 'Survey.registrationAddress', {}), countryCode },
        delivery: { ..._get(teslaObj, 'Survey.deliveryAddress', {}), countryCode },
      },
    },
    Configuration: {
      rn,
      savedConfiguration,
      marketingLexiconDate,
      pricingChangeConfirmed: !!rn && getCookieInfoOfLatestLexiconLoading(),
      preservedPricingGroups: [
        TRIM,
        REAR_WHEEL_PARTIAL,
        DUAL_MOTOR_PREMIUM,
        REAR_WHEEL_PREMIUM,
        SPORT_PACKAGE,
        ALL_WHEEL_DRIVE_TWO,
        ALL_WHEEL_DRIVE_ONE,
      ],
      ...(loadLatestLexiconCookieKey ? { loadLatestLexiconCookieKey } : {}),
    },
    FinancingOptions: {
      showTaxesAndFees,
    },

    Navigation: {
      hideNavigation: !isProductWithOverview,
      allowedSections,
      section: navigationSection,
    },
    Address: {
      stateList,
    },
    ReviewDetails: {
      product: product
        ? {
            isInventory: !isReservation,
            isUsedInventory,
            isNewInventory,
            isComboInventory,
            type: product.type,
            data: product.data,
            orderDetails: product.orderDetails,
            isProductWithOverview: isOverviewEnabled,
            isReservation,
            modelRoute,
            condition,
            deliveryRegion: product.deliveryRegion,
          }
        : null,
      errorState: ErrorCode,
      previousRN,
      AccountDetail: {
        ...AccountDetail,
        ...verificationObj,
      },
      RegistrationDetail,
      VehicleDetail,
      DeliveryDetails,
      isSalesReferred: isInventory && isSalesReferred,
      inventoryUpgrades: _groupBy(
        vehicleUpgradesDisplay?.length ? vehicleUpgradesDisplay : vehicleUpgrades,
        'category'
      ),
      inventoryUpgradesInAlternateCurrency: _groupBy(vehicleUpgradesAlternate, 'category'),
      vehicleUpgradesInDefaultCurrency: vehicleUpgrades,
      DeliveryLocations: {
        available: _get(teslaObj, 'DeliveryLocations', null),
        ...compoundLocations,
        ...vehicleCity,
        ...availablePickupLocation,
        ...DeliveryLocations,
      },
      registrationStates,
      initialEDD: _get(teslaObj, 'eddData', null),
      availableProvincesForAddressVerification,
      DeliveryContact: {
        isDeliveryContactValid: !isEnterpriseOrderPickup,
      },
    },
    Financial: {
      ...(_isEmpty(financeProductDisclaimer)
        ? {}
        : {
            disclaimers: financeProductDisclaimer,
          }),
    },
    SummaryPanel: {
      ...(DeliveryDetails?.stateCode && { region_code: DeliveryDetails?.stateCode }),
    },
  }
);

// Google Analytics
// add window.dataLayer if it does not already exist
if (!window.dataLayer) {
  window.dataLayer = [];
}

if (_get(RehydratedState, 'App.reduxLogger')) {
  // eslint-disable-next-line no-console
  console.log('App init with the state:', RehydratedState);
}

// call set locale (to enable all localization behaviors);
const normalizedLocale = normalizeGrayLocale(locale);
setLocale(normalizedLocale, '_');

// const sentryReduxEnhancer = Sentry.createReduxEnhancer({
//   // Optionally pass options
// });

const composeWithReduxDevTools = RehydratedState.App.debug && composeWithDevTools;
const store = configureStore(RehydratedState, composeWithReduxDevTools);
const renderDom = () => {
  render(
    <Provider store={store}>
      <Main />
    </Provider>,
    document.getElementById('root')
  );
};
const polyfillAwait = polyfillIntlDisplayNames(locale)
  .then(success => {
    renderDom();
  })
  .catch(e => {
    captureException(`Polyfill failed: ${e}`);
    renderDom();
  });
