import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import _upperFirst from 'lodash/upperFirst';
import { Loader, FormFieldset, Button, Link } from '@tesla/design-system-react';
import { Form, Input } from '@tesla/informed-tds';
import { utils } from 'informed';
import { i18n, zipCodeValidator } from 'utils';
import {
  getPostalCode,
  getRegionName,
  getEstimateDeliveryDate,
  isEarlyDesignSelected,
  isNV36Trim,
} from 'selectors';
import { PAYMENT_SUMMARY } from 'dictionary';
import {
  getEarlyAvailability,
  getGeoLocation,
  setDeliveryDetailsValidFlag,
  resetVehicleDesign,
} from 'actions';
import { bool, func, string } from 'prop-types';
import cx from 'classnames';
import Analytics from 'analytics';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import PreOrderSwap from '../PreOrderSwap';

const DeliveryZip = ({
  isEnabled,
  getAvailability,
  postalCode,
  onPostalChange,
  market,
  regionName,
  city,
  stateCode,
  options,
  isLoading,
  deliveryTiming,
  source = '',
  validateOnMount,
  error,
  enableCyberpunk,
  isModalOpen,
  showStaticRegion,
  resetToCustomDesign,
}) => {
  const [isVisible, ref] = useIntersectionObserver({
    threshold: 0,
  });
  const [showInput, setShowInput] = useState(!postalCode || !regionName);
  const formApiRef = useRef();
  const formState = formApiRef?.current?.getFormState();
  const { values, invalid, valid } = formState || {};
  const deliveryZip = values?.postalCode || '';

  useEffect(() => {
    if (isVisible && isEnabled) {
      if (postalCode && !error) {
        getAvailability();
      }
    }

    if (valid && deliveryZip && deliveryZip !== postalCode) {
      resetToCustomDesign();
      formApiRef?.current?.reset();
    }
  }, [isVisible, options, postalCode]);

  if (!isEnabled) {
    return null;
  }

  const { debounce } = utils;

  const onChangeZip = ({ value, valid }) => {
    if (valid && value) {
      onPostalChange(value);
    }
  };

  const validateInvalidZip = value => {
    return new Promise(resolve => {
      setTimeout(() => {
        if (error && source !== PAYMENT_SUMMARY && value === postalCode) {
          return resolve(i18n('TradeIn.error__postalCode'));
        }
        return resolve();
      }, 0);
    });
  };

  const PostalCode = ({ label, classes }) => {
    return (
      <Input
        name="postalCode"
        id="postalCode"
        maxLength={20}
        label={label}
        validate={value => zipCodeValidator(value, market)}
        asyncValidate={validateInvalidZip}
        required
        showErrorIfError
        validateOn="change"
        data-id="delivery-postal-code-textbox"
        placeholder={i18n('DeliveryLocation.enter_delivery_zip')}
        onChange={debounce(value => onChangeZip(value), 800)}
        keep={{ value: true, touched: true }}
        autocomplete="off"
        className={classes}
      />
    );
  };

  return (
    <div
      ref={ref}
      className={cx('observer-placeholder delivery-zip--container', {
        'tds-text--center': !source,
      })}
      id="early-delivery"
      key={`early-delivery:${source}`}
    >
      <Form
        formApiRef={formApiRef}
        allowEmptyStrings
        validateOnMount={validateOnMount}
        initialValues={{ postalCode }}
        errorMessage={{
          required: _upperFirst(i18n('common.errors__required')),
        }}
        className="tds-o-horizontail-margin--4x"
        keepState
      >
        <Choose>
          <When condition={source === PAYMENT_SUMMARY}>
            <FormFieldset className="tds--vertical_padding--large">
              <PostalCode
                label={i18n('DeliveryLocation.delivery_zip')}
                classes="tds-o-font-size--16"
              />
            </FormFieldset>
          </When>
          <Otherwise>
            <Choose>
              <When condition={showInput || error}>
                <p className="tds-o-padding_bottom-4 tds-text--h3 tds-text--contrast-high">
                  {i18n('DeliveryLocation.enter_delivery_zip')}
                </p>
                <div
                  className={cx(
                    'tds-o-flex--no-wrap region-selector--container tds-o-padding_bottom-4',
                    { 'tds-o-flex--inline': !enableCyberpunk }
                  )}
                >
                  <div className="tds-flex-item">
                    <PostalCode />
                  </div>
                  <div className="tds-flex-item tds-flex--col_1of3">
                    <Button
                      className={cx({ 'tds-o-min-width-auto': enableCyberpunk })}
                      disabled={invalid || error}
                      onClick={() => {
                        Analytics.fireInteractionEvent('update-delivery-location-payment');
                        setTimeout(() => {
                          setShowInput(false);
                        }, 300);
                      }}
                    >
                      {i18n('common.update')}
                    </Button>
                  </div>
                </div>
              </When>
              <Otherwise>
                <Loader show={isLoading && !isModalOpen} />
                <If condition={deliveryTiming && (!isLoading || isModalOpen)}>
                  <div className="tds-text--h3 tds-text--contrast-high">{deliveryTiming}</div>
                </If>
                <div className="tds-o-padding_bottom-4">
                  {i18n('DeliveryLocation.delivery_zip')}
                </div>
                <Choose>
                  <When condition={showStaticRegion}>
                    <span className="tds-text--contrast-low">
                      {city ? `${city}, ${stateCode}, ${postalCode}` : `${stateCode} ${postalCode}`}
                    </span>
                  </When>
                  <Otherwise>
                    <Link
                      onClick={() => {
                        Analytics.fireInteractionEvent('change-delivery-location-payment');
                        setShowInput(true);
                      }}
                    >
                      <span className="tds-text--contrast-low">
                        {city
                          ? `${city}, ${stateCode}, ${postalCode}`
                          : `${stateCode} ${postalCode}`}
                      </span>
                    </Link>
                  </Otherwise>
                </Choose>
                <PreOrderSwap />
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </Form>
    </div>
  );
};

function mapStateToProps(state, { source = '' }) {
  const {
    App,
    OMS,
    DeliveryTiming: { isAvailableNow, isLoading = false } = {},
    Configuration: { option_string } = {},
    ReviewDetails: {
      isDeliveryDetailsValid,
      DeliveryDetails: { error, city, stateCode } = {},
    } = {},
    Modal: { open: isModalOpen } = {},
    CustomGroups: { BATTERY_AND_DRIVE = {} } = {},
  } = state;
  const { market } = OMS?.oms_params || {};
  const zipCode = getPostalCode(state);
  const { enableCyberpunk, isEarlyAvailabilityEnabledWithLimits = false } = App || {};
  const deliveryTiming = isAvailableNow
    ? i18n('common.available_today')
    : i18n('common.available_later_date', {
        DATE: getEstimateDeliveryDate(state)?.deliveryWindowDisplay || '',
      });
  const modelName = BATTERY_AND_DRIVE?.name_new || BATTERY_AND_DRIVE?.name || '';
  return {
    isEnabled: App?.isEarlyAvailabilityEnabled,
    postalCode: source !== PAYMENT_SUMMARY && !isDeliveryDetailsValid && !error ? '' : zipCode,
    market,
    regionName: getRegionName(state),
    city,
    stateCode,
    options: option_string,
    isLoading,
    deliveryTiming: isEarlyAvailabilityEnabledWithLimits || isNV36Trim(state) ? i18n('Review.model_label', { MODEL: modelName }) : deliveryTiming,
    isDeliveryDetailsValid,
    validateOnMount: !!(zipCode && !isDeliveryDetailsValid) || !!error,
    error,
    enableCyberpunk,
    isModalOpen,
    showStaticRegion: isEarlyDesignSelected(state),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getAvailability: () => dispatch(getEarlyAvailability()),
    onPostalChange: zipCode => dispatch(getGeoLocation(zipCode, { setPreferredAddress: true })),
    setZipValidFlag: flag => dispatch(setDeliveryDetailsValidFlag(flag)),
    resetToCustomDesign: () => dispatch(resetVehicleDesign()),
  };
}

DeliveryZip.propTypes = {
  isEnabled: bool,
  postalCode: string,
  market: string,
  regionName: string,
  validateOnMount: bool.isRequired,
  enableCyberpunk: bool,
  showStaticRegion: bool,
  resetToCustomDesign: func.isRequired,
};

DeliveryZip.defaultProps = {
  isEnabled: false,
  postalCode: '',
  market: '',
  regionName: '',
  enableCyberpunk: false,
  showStaticRegion: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryZip);
